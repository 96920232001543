import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { Table } from 'primeng/table';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { Utils } from 'src/modules/utils/shared/utils';
import { TableSizer } from '../../models/table-sizer.model';

@Component({
    selector: 'sm-table',
    templateUrl: './sm-table.component.html',
    styleUrls: ['./sm-table.component.scss']
})
export class SmTableComponent {

    static defaultActionButtons = false;
    static defaultFilterButtons = true;
    static defaultNoWrapCell = false;
    static convertCsvToExcel: (csv: string) => Promise<string>;

    _Utils = Utils;

    _data: TableData;
    @Input()
    groupRowsBy: string = null;
    @Input()
    allowDelete = false;
    @Input()
    allowInsert = false;
    @Input()
    allowSort = true;
    @Input()
    allowExport = true;
    @Input()
    allowFilter = true;
    @Input()
    allowGlobalFilter = false;
    @Input()
    filterButtons = SmTableComponent.defaultFilterButtons;
    @Input()
    filterRow = false;
    @Input()
    allowSelection = true;
    @Input()
    actionButtons = SmTableComponent.defaultActionButtons;
    @Input()
    noWrapCell = SmTableComponent.defaultNoWrapCell;
    @Input()
    gridLines = true;
    @Input()
    striped = true;
    @Input()
    rotateHeaders = false;
    @Input()
    virtualScrollHeight = 30;
    @Input()
    deltaHeight: number = null;
    @Input()
    deltaHeightAuto = false;
    @Input()
    scrollHeight: string = null;

    _selection: any;
    @Output()
    selectionChange = new EventEmitter<any>();

    _useTableSizer = false;
    @Output()
    onRowSelect = new EventEmitter<TableRow>();
    @Output()
    onDelete = new EventEmitter<[TableRow, number]>();
    @Output()
    onCellClicked = new EventEmitter<[TableRow, string, string]>();
    @Output()
    onCellEdited = new EventEmitter<[TableRow, string]>();
    multiSortMeta: any;
    @ViewChild("table")
    table: Table;
    @ViewChild("cm") cm: ContextMenu;

    filterFieldNames: string[] = [];
    contextMenu: MenuItem[] = [];

    constructor(private app: MainAppService, private elementRef: ElementRef, private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef) {
    }

    getDeltaHeight(): number {
        //Kleiner Summand basierend auf window.innerHeight, damit sich der Wert ändert, wenn die Fenstergröße sich ändert. Sonst wird scheinbar keine ChangeDetection angestoßen
        return !Utils.isNoe(this.scrollHeight) ? 0 : (this.deltaHeightAuto ? this.elementRef.nativeElement.getBoundingClientRect().top + 20 : this.deltaHeight) + window.innerHeight / 100000;
    }

    updateChanges(): void {
        this.changeDetectorRef.detectChanges();
    }

    @Input()
    set data(value: TableData) {
        this._data = value;
        this.multiSortMeta = this._data?.sort != null ? this._data.sort.map(c => ({ field: c.id, order: c.ascending ? 1 : -1})) : null;
        this.filterFieldNames = this._data?.columns.map(c => "values." + c.id) ?? [];
        this.updateChanges();
        this.handleResize();
    }

    @Input()
    set useTableSizer(value: boolean) {
        if (value != this._useTableSizer) {
            this._useTableSizer = value;
            this.handleResize();
        }
    }

    @Input()
    set selection(value: any) {
        this._selection = value;
    }

    @HostListener('window:resize')
    handleResize(): void {
        if (!this._useTableSizer || this._data == null) {
            return;
        }
        GuiUtils.angularTimer(() => {
        const componentWidth = this.elementRef.nativeElement.getBoundingClientRect().width - 20; //-20 wegen Scrollbar

        let widths = this._data.columns.map(col => TableSizer.instance.calculate(this._data, col));

        let widthLeft = componentWidth;
        for (let w of widths) {
            if (w.type == "px") {
                widthLeft -= w.value;
            }
        }
        let percLeft = 100;
        let restWidth = 0;
        for (let w of widths) {
            if (w.type == "%") {
                percLeft -= w.value;
            }
            else if (w.type == "%r") {
                restWidth += w.value;
            }
        }

        for (let w of widths) {
            w.col.calculatedWidth = (w.type == "px" ? w.value : w.type == "%" ? widthLeft / 100 * w.value : widthLeft / 100 * percLeft * Utils.divSafe(w.value, restWidth)) + "px";
        }

        console.log(componentWidth + "! " + Utils.arrayItemsToString(widths, " + ", w => w.col.calculatedWidth) + " = " + Utils.arraySum(widths.map(w => Utils.toNumber(Utils.stringRemoveSuffix(w.col.calculatedWidth, "px")))));
        });
    }

    _onRowSelect(event: any): void {
        this.selectionChange.emit(this._selection);
        this.onRowSelect.emit(event.data as TableRow);
    }

    customSort(event: any): void {
        if (!Utils.isArray(event.multiSortMeta)) {
            return;
        }
        let fieldIndexes = event.multiSortMeta.map(sort => this._data.columns.findIndex(col => col.id == sort.field));
        event.data.sort((data1: TableRow, data2: TableRow) => {
            for (let i = 0; i < fieldIndexes.length; i++) {
                let result = Utils.cmp(data1.values[event.multiSortMeta[i].field], data2.values[event.multiSortMeta[i].field]) * event.multiSortMeta[i].order;
                if (result != 0) {
                    return result;
                }
            }
            return 0;
        });
    }

    async deleteItem(item: TableRow, index: number): Promise<void> {
        if (await this.app.messageDialog.yesNo("Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?", "Warnung")) {
            this.onDelete.emit([item, index]);
            this._data.rows.splice(index, 1);
            this._data.rows = [...this._data.rows];
        }
    }

    async addItem(): Promise<void> {
        if (this._data.generator != null) {
            let row = await this._data.generator();
            if (row != null) {
                this._data.rows.push(row);
            }
        }
    }

    openSettings(event: any): void {
        this.contextMenu = Utils.arrayWithoutNull([
            {
                label: "Filterzeile ein-/ausblenden",
                command: this.toggleFilterRow.bind(this)
            },
            {
                label: "Export als CSV",
                command: this.exportCsv.bind(this)
            },
            SmTableComponent.convertCsvToExcel != null ? {
                label: "Export als XLSX",
                command: this.exportXlsx.bind(this)
            } : null
        ]);
        GuiUtils.angularTimer(() => this.cm.show(this.app.getCurrentMousePosEvent()));
        event.stopPropagation();
    }

    exportCsv(): void {
        GuiUtils.downloadBytesAsFile(GuiUtils.stringAsciiToArrayBuffer(this._data.toCsv(this.table.filteredValue as TableRow[])), "Tabelle.csv");
    }

    async exportXlsx(): Promise<void> {
        let csv = this._data.toCsv(this.table.filteredValue as TableRow[]);
        let xlsx = await SmTableComponent.convertCsvToExcel(csv);
        GuiUtils.downloadBytesAsFile(GuiUtils.base64ToArrayBuffer(xlsx), "Tabelle.xlsx");
    }

    toggleFilterRow(): void {
        this.filterRow = !this.filterRow;
    }

    setFilter(columnName: string, value: any): void {
        this.filterRow = true;
        this.table.filter(value, "values." + columnName, "equals");

    }

    cellClicked(item: TableRow, column: string, subItem: string): void {
        this.onCellClicked.emit([item, column, subItem]);
    }

    cellEdited(item: TableRow, column: string): void {
        this.onCellEdited.emit([item, column]);
    }

    filterGlobal(event: any): void {
        this.table.filterGlobal(event.target.value, 'contains');
    }
}

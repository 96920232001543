import { Type } from "class-transformer";
import { EnaioOrganisationObject } from "./EnaioOrganisationObject";
import { EnaioUserGroup } from "./EnaioUserGroup";

export class EnaioUser {
    userName: string;
    guid: string;
    fullName: string;
    id = 0;
    accountType = 0;
    description: string;
    isServerOrAnonymous = false;
    changed = false;
    languageId = 0;
    locked = false;
    loginCount = 0;
    loginStation: string;
    @Type(() => Date)
    loginTime: Date;
    mail: string;
    userProfile = 0;
    serverId = 0;
    station: string;
    supervisor = false;
    @Type(() => Date)
    validFrom: Date;
    @Type(() => Date)
    validTo: Date;
    password: string;
    @Type(() => EnaioUserGroup)
    userGroups: EnaioUserGroup[];
    sysRoles: number[];
    @Type(() => EnaioOrganisationObject)
    wfUser: EnaioOrganisationObject;
    isProfileUser = true;
}

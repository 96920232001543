import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioAccessType } from 'src/modules/enaio/shared/EnaioAccessType';
import { EnaioCallGetUserGroupList } from 'src/modules/enaio/shared/EnaioCallGetUserGroupList';
import { EnaioCallGetUserInfo } from 'src/modules/enaio/shared/EnaioCallGetUserInfo';
import { EnaioCallGetUserList } from 'src/modules/enaio/shared/EnaioCallGetUserList';
import { EnaioCallGetUserPermissions } from 'src/modules/enaio/shared/EnaioCallGetUserPermissions';
import { EnaioCallGetUserPrefs } from 'src/modules/enaio/shared/EnaioCallGetUserPrefs';
import { EnaioCheckPermissionAccessTypeResult } from 'src/modules/enaio/shared/EnaioCheckPermissionAccessTypeResult';
import { EnaioCheckPermissionResult } from 'src/modules/enaio/shared/EnaioCheckPermissionResult';
import { EnaioObjectIdAndType } from 'src/modules/enaio/shared/EnaioObjectIdAndType';
import { EnaioObjectTypeId } from 'src/modules/enaio/shared/EnaioObjectTypeId';
import { EnaioUser } from 'src/modules/enaio/shared/EnaioUser';
import { EnaioUserGroup } from 'src/modules/enaio/shared/EnaioUserGroup';
import { EnaioUserIdentification } from 'src/modules/enaio/shared/EnaioUserIdentification';
import { EnaioUserPrefs } from 'src/modules/enaio/shared/EnaioUserPrefs';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableCell } from 'src/modules/sm-base/models/table-cell.model';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
  selector: 'app-enaio-users',
  templateUrl: './enaio-user.component.html',
  styleUrls: ['./enaio-user.component.scss']
})
export class EnaioUserComponent extends ComponentView {

    users: EnaioUser[];
    userGroups: EnaioUserGroup[];
    objectTypes: EnaioObjectTypeId[];
    compareToUsersGui: FrontendFieldListItem[] = [];
    archivesGui: FrontendFieldListItem[] = [];
    prefs: EnaioUserPrefs;
    userTable: TableData;
    prefsTable: TableData;
    selectedUser: string;
    selectedCompareToUser: string;
    selectedArchive: EnaioObjectTypeId;

    async initParams(): Promise<boolean> {
        this.users = Utils.arraySortBy(await DhTools.enaioCall(Utils.fromPlain(EnaioCallGetUserList, {})), u => u.userName);
        this.objectTypes = Utils.arraySortBy(await DhTools.backendCall("api/enaio/getObjectTypes").list(EnaioObjectTypeId), ot => ot.archive.name + " - " + ot.objectType.name);
        this.userGroups = await DhTools.enaioCall(Utils.fromPlain(EnaioCallGetUserGroupList, {}));

        this.archivesGui = this.objectTypes.filter(ot => ot.archive.internalName == ot.objectType.internalName).map(ot => new FrontendFieldListItem(ot, ot.archive.name));
        this.compareToUsersGui = [new FrontendFieldListItem(null, ""), ...this.users.map(m => new FrontendFieldListItem(m.userName, m.userName))];

        return true;
    }

    async selectUser(): Promise<void> {
        await this.run();
    }

    async getPermissions(userName: string, objectTypes: EnaioObjectTypeId[]): Promise<EnaioCheckPermissionResult[]> {
        if (Utils.isNoe(userName)) {
            return null;
        }

        let user = await DhTools.enaioCall<EnaioUser>(Utils.fromPlain(EnaioCallGetUserInfo, {
            userId: EnaioUserIdentification.byUserName(userName),
            returnGroups: true
        }));
        let call = Utils.fromPlain(EnaioCallGetUserPermissions, {
            user: EnaioUserIdentification.byUserName(userName),
            detailed: true
        });
        let result = await DhTools.enaioCall<EnaioCheckPermissionResult[]>(call);

        if (user != null) {
            for (let objectType of objectTypes) {
                for (let group of user.userGroups) {
                    if (result.find(p => p.fromUserGroup == group.id && p.forObject.typeId == objectType.objectType.id) == null) {
                        result.push(Utils.fromPlain(EnaioCheckPermissionResult, {
                            fromUserGroup: group.id,
                            forObject: new EnaioObjectIdAndType(0, objectType.objectType.id),
                            accessTypes: Utils.getEnumValues(EnaioAccessType).map(type => Utils.fromPlain(EnaioCheckPermissionAccessTypeResult, {
                                accessType: type,
                                allow: false
                            }))
                        }));
                    }
                }
            }
        }

        return result;
    }

    async run(): Promise<void> {
        let objectTypes = this.objectTypes.filter(ot => ot.archive.internalName == this.selectedArchive.archive.internalName);
        let permissions = await this.getPermissions(this.selectedUser, objectTypes);
        let permissionsCompare = this.selectedCompareToUser == null ? null : await this.getPermissions(this.selectedCompareToUser, objectTypes);

        let userGroups = this.userGroups.filter(ug => permissions.some(p => p.fromUserGroup == ug.id) || permissionsCompare?.some(p => p.fromUserGroup == ug.id));

        this.userTable = new TableData([
            new TableColumn("group", "Gruppe"),
            new TableColumn("delete", "Objekt löschen", TableCellType.text),
            new TableColumn("readMetaData", "Indexdaten lesen", TableCellType.text),
            new TableColumn("writeMetaData", "Indexdaten schreiben", TableCellType.text),
            new TableColumn("update", "Objekt schreiben", TableCellType.text),
            new TableColumn("openExec", "Objekt ausgeben", TableCellType.text)
        ], Utils.arrayExplode(objectTypes, ot => userGroups.map(ug => {
            let p1 = permissions.find(p => p.fromUserGroup == ug.id && p.forObject.typeId == ot.objectType.id);
            let p2 = permissionsCompare?.find(p => p.fromUserGroup == ug.id && p.forObject.typeId == ot.objectType.id);
            return new TableRow(ug, {
                objectType: ot.objectType.name,
                group: ug.name,
                delete: this.getUserRightCell2(p1, p2, permissionsCompare != null, EnaioAccessType.delete),
                readMetaData: this.getUserRightCell2(p1, p2, permissionsCompare != null, EnaioAccessType.readMetaData),
                writeMetaData: this.getUserRightCell2(p1, p2, permissionsCompare != null, EnaioAccessType.writeMetaData),
                update: this.getUserRightCell2(p1, p2, permissionsCompare != null, EnaioAccessType.update),
                openExec: this.getUserRightCell2(p1, p2, permissionsCompare != null, EnaioAccessType.openExec)
            } as OrdinaryObject);
        })));

        this.prefs = await DhTools.enaioCall<EnaioUserPrefs>(Utils.fromPlain(EnaioCallGetUserPrefs, {
            user: EnaioUserIdentification.byUserName(this.selectedUser)
        }));

        this.prefsTable = new TableData([
            new TableColumn("key", "Schlüssel"),
            new TableColumn("value", "Wert")
        ], Utils.getOwnPropertyNames(this.prefs.raw).map(key => new TableRow(key, {
                key,
                value: this.prefs.raw[key]
            } as OrdinaryObject)));
    }

    private getUserRightCell2(p: EnaioCheckPermissionResult, pCompare: EnaioCheckPermissionResult, useCompare: boolean, type: EnaioAccessType): TableCell {
        if (useCompare) {
            let c1 = this.getUserRightCell(p, type);
            let c2 = this.getUserRightCell(pCompare, type);
            return c1.value == c2.value ? c1 : new TableCell(c1.value + " vs " + c2.value, { styleClass: "xbg-orange-400" });
        }
        else {
            return this.getUserRightCell(p, type);
        }
    }


    private getUserRightCell(p: EnaioCheckPermissionResult, type: EnaioAccessType): TableCell {
        if (p == null) {
            return new TableCell("---", { styleClass: "xbg-gray-400" });
        }
        let data = p?.accessTypes.find(t => t.accessType == type);
        return data?.allow ? !Utils.isNoe(data.readableClause) ? new TableCell(data.readableClause, { styleClass: "xbg-yellow-400" }) : !Utils.isNoe(data.clause) ? new TableCell(data.clause, { styleClass: "xbg-yellow-400" }) :
            new TableCell("Ja", { styleClass: "xbg-green-400" }) : new TableCell("Nein", { styleClass: "xbg-red-400" });
    }
}

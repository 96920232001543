import { Component, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DateIntv } from 'src/modules/enaio/shared/DateIntv';
import { EnaioCallSelect } from 'src/modules/enaio/shared/EnaioCallSelect';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { SimLogResultEntry } from 'src/modules/enaio/shared/SimLogResultEntry';
import { SmFormComponent } from 'src/modules/sm-base/components/sm-form/sm-form.component';
import { FrontendFieldAddon } from 'src/modules/sm-base/models/frontend-field-addon.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { Progressor } from 'src/modules/utils/misc/Progressor';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Wrapper } from 'src/modules/utils/misc/wrapper.model';
import { DhTools } from '../../models/dh-tools.model';
import { SimLogQuery } from '../../models/sim-log-query.model';
import { DocumentHandlerMainComponent } from '../document-handler-main/document-handler-main.component';

@Component({
  selector: 'app-sim-log',
  templateUrl: './sim-log.component.html',
  styleUrls: ['./sim-log.component.scss']
})
export class SimLogComponent extends ComponentView {

    simLogDirs: string[];
    simLogQueries: SimLogQuery[];
    formInput: FrontendFormDefinition;
    actionButtons: MenuItem[];
    result: SimLogResultEntry[];
    resultTable: TableData;
    progressor = new Wrapper<Progressor>();

    @ViewChild("form") form: SmFormComponent;

    async initParams(): Promise<boolean> {
        this.simLogDirs = await DhTools.backendCall("api/dh/getSimLogDirs").listStrings();
        this.simLogQueries = await DhTools.backendCall("api/dh/getSimLogQueries").list(SimLogQuery);

        this.formInput = new FrontendFormDefinition([
            new FrontendFieldDefinition("configNames", "Konfigurationen", FrontendFieldType.comboBoxMulti, { mandatory: true, listItems: this.simLogDirs.map(m => new FrontendFieldListItem(m, m)), value: this.simLogDirs}),
            new FrontendFieldDefinition("beginDate", "Startdatum", FrontendFieldType.datePicker, { value: Utils.dateStartOf(Utils.dateAdd(new Date(), "day", -7), "day"), datePickerWithTime: true}),
            new FrontendFieldDefinition("endDate", "Enddatum", FrontendFieldType.datePicker, { value: Utils.dateEndOf(new Date(), "day"), datePickerWithTime: true,
                addOnButtons: [new FrontendFieldAddon("", "", "fas fa-ellipsis", true)], onAddOnClick: (addOn, evt) => DhTools.showDateIntervalOptions(evt, this.viewContainerRef, this.formInput) }),
            new FrontendFieldDefinition("query", "Abfrage", FrontendFieldType.comboBox, { value: this.simLogQueries[0], mandatory: true, dropdownEditable: false, listItems: this.simLogQueries.map(q => new FrontendFieldListItem(q, q.name))}),
            new FrontendFieldDefinition("conversion", "Ausgabeformat", FrontendFieldType.text)
        ]);

        this.formInput.getField("query").onValueChanged = () => {
            this.formInput.fields = this.formInput.fields.filter(f => !f.id.startsWith("field_"));
            let query = this.formInput.getValue("query") as SimLogQuery;
            if (query != null) {
                for (let match of query.condition.matchAll(/\[\[(.*?)\]\]/g)) {
                    let placeholder = match[1];
                    let newField = new FrontendFieldDefinition("field_" + placeholder, placeholder, FrontendFieldType.text);
                    this.formInput.fields = [...this.formInput.fields, newField];
                    if (placeholder == "Objekt-ID") {
                        newField.addOnButtons = [
                            new FrontendFieldAddon("", "", "fas fa-ellipsis", true)
                        ];
                        newField.onAddOnClick = (addOn, evt) => this.showObjectIdOptions(evt, placeholder);
                    }
                }
            }
            this.formInput.setValue("conversion", query.result);
            this.form.form = this.formInput;
        };
        this.formInput.getField("query").onValueChanged();
        return true;
    }

    showObjectIdOptions(evt: MouseEvent, placeholder: string): void {
        let model = [
            {
                label: "Anlagezeitpunkt übernehmen",
                command: async () => this.useObjectDate(placeholder, 0)
            },
            {
                label: "Änderungszeitpunkt übernehmen",
                command: async () => this.useObjectDate(placeholder, 1)
            },
            {
                label: "Lebensdauer übernehmen",
                command: async () => this.useObjectDate(placeholder, 2)
            }
        ];
        GuiUtils.showContextMenu(evt, this.viewContainerRef, model);
    }


    async load(configName: string, query: string, beginDate?: Date, endDate?: Date, fields?: OrdinaryObject): Promise<void> {
        this.formInput.setValue("configNames", [Utils.getFileNameWithoutPath(Utils.stringRemoveSuffix(Utils.stringDef(configName), ".json"))]);
        this.formInput.setValue("query", this.simLogQueries.find(q => q.name == query));
        await this.formInput.getField("query").onValueChanged?.();
        if (beginDate != null) {
            this.formInput.setValue("beginDate", beginDate);
        }
        if (endDate != null) {
            this.formInput.setValue("endDate", endDate);
        }
        if (fields != null) {
            for (let prop of Utils.getOwnPropertyNames(fields)) {
                this.formInput.setValue("field_" + prop, fields[prop]);
            }
        }
    }

    async useObjectDate(fieldName: string, type: number): Promise<void> {
        let o = Utils.arrayGetSafe(await DhTools.enaioCall<EnaioDocument[]>(Utils.fromPlain(EnaioCallSelect, {
            selectObjectId: Utils.toNumber(Utils.getSubstringTo(Utils.stringDef(this.formInput.getValue("field_" + fieldName) as string, ""), ";").trim()),
            getBaseParams: true
        })), 0);
        if (o == null) {
            this.app.showToast("error", "Fehler", "Objekt-ID nicht gefunden");
            return;
        }
        this.formInput.setValue("beginDate", Utils.dateAdd(type == 0 || type == 2 ? o.baseParams.creationDate : o.baseParams.modificationDate, "hour", -1));
        this.formInput.setValue("endDate", Utils.dateAdd(type == 0 ? o.baseParams.creationDate : o.baseParams.modificationDate, "hour", 1));
    }

    async run(): Promise<void> {
        this.result = null;
        let query = this.formInput.getValue("query") as SimLogQuery;
        let q = query.condition;
        for (let match of query.condition.matchAll(/\[\[(.*?)\]\]/g)) {
            let placeholder = match[1];
            let v = this.formInput.getField("field_" + placeholder).getValue() as string;
            if (placeholder == "Objekt-ID") {
                v = Utils.getSubstringTo(this.formInput.getValue("field_" + placeholder) as string, ";").trim();
            }
            q = q.replace(match[0], v);
        }

        DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/dh/simLogSearch", {
            dateIntvs: [Utils.fromPlain(DateIntv, {
                start: this.formInput.getValue("beginDate"),
                end: this.formInput.getValue("endDate")
            })],
            regex: Utils.arrayItemsToString(this.formInput.getValue("configNames") as string[], "|"),
            query: q,
            conversion: this.formInput.getValue("conversion"),
            returnFullLog: true
        }).getText(), async result => {
            this.result = Utils.fromPlainArray(SimLogResultEntry, Utils.fromJson(result.value) as any[]);
            this.resultTable = new TableData([
                new TableColumn("date", "Datum", TableCellType.dateTime, { width2: "qc" }),
                new TableColumn("fileName", "Dateiname", TableCellType.text, { width2: "200px" }),
                new TableColumn("configName", "Konfiguration", TableCellType.text, { width2: "200px" }),
                new TableColumn("id", "Objekt-ID", TableCellType.text, { width2: "ra" }),
                new TableColumn("value", "Wert", TableCellType.text, { width2: "ra" })
            ], this.result.map(entry => new TableRow(entry, {
                date: entry.date,
                fileName: Utils.getFileNameWithoutPath(entry.fileName),
                configName: entry.configName,
                id: entry.id,
                value: entry.value
            }), [new TableSortColumn("date")]));
            this.actionButtons = await DocumentHandlerMainComponent.instance?.generateContextMenu(Utils.arrayItemsToString(this.result, "\n", entry => entry.value));
        });
    }

}

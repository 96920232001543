import { Component, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioCallGetProcessesEx } from 'src/modules/enaio/shared/EnaioCallGetProcessesEx';
import { EnaioCallGetWorkflowError } from 'src/modules/enaio/shared/EnaioCallGetWorkflowError';
import { EnaioCallGetWorkflowModels } from 'src/modules/enaio/shared/EnaioCallGetWorkflowModels';
import { EnaioCallSelect } from 'src/modules/enaio/shared/EnaioCallSelect';
import { EnaioCondition } from 'src/modules/enaio/shared/EnaioCondition';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { EnaioObjectId } from 'src/modules/enaio/shared/EnaioObjectId';
import { EnaioProcess } from 'src/modules/enaio/shared/EnaioProcess';
import { EnaioProcessState } from 'src/modules/enaio/shared/EnaioProcessState';
import { EnaioWorkflowDefinitionSelectData } from 'src/modules/enaio/shared/EnaioWorkflowDefinitionSelectData';
import { EnaioWorkflowModel } from 'src/modules/enaio/shared/EnaioWorkflowModel';
import { GeneralObjectKey } from 'src/modules/enaio/shared/GeneralObjectKey';
import { EnaioHelper } from 'src/modules/enaio/shared/enaio-helper.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableCell } from 'src/modules/sm-base/models/table-cell.model';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject, OrdinaryObjectNumber } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhObjectActions } from '../../models/dh-object-actions.model';
import { DhTools } from '../../models/dh-tools.model';
import { DocumentHandlerMainComponent } from '../document-handler-main/document-handler-main.component';

class EnaioConditionGui {
    constructor(public field = "", public value = "", public comparison = "=") {
    }
}

@Component({
  selector: 'app-enaio-workflows',
  templateUrl: './enaio-workflows.component.html',
  styleUrls: ['./enaio-workflows.component.scss']
})
export class EnaioWorkflowsComponent extends ComponentView {

    models: EnaioWorkflowModel[];
    families: { id: string; name: string }[];
    formFilter: FrontendFormDefinition;
    wfTable: TableData;
    processes: EnaioProcess[];
    docsById: OrdinaryObjectNumber<EnaioDocument> = {};
    lastCall: EnaioCallGetProcessesEx;
    unrollVariables = false;
    contextMenu: MenuItem[] = [];

    @ViewChild("cm") cm: ContextMenu;

    async initParams(): Promise<boolean> {
        this.models = Utils.arraySortBy(await DhTools.enaioCall<EnaioWorkflowModel[]>(Utils.fromPlain(EnaioCallGetWorkflowModels, { getDefinition: true, selectData: [EnaioWorkflowDefinitionSelectData.dataFields]})), m => m.version);
        this.families = Utils.arraySort(Utils.arrayGetUnique(this.models.map(m => ({id: m.family.id, name: m.family.name})), m => m.name));
        this.formFilter = new FrontendFormDefinition([
            new FrontendFieldDefinition("processId", "Prozess-ID", FrontendFieldType.text, { value: ""}),
            new FrontendFieldDefinition("processName", "Prozessname", FrontendFieldType.text, { value: ""}),
            new FrontendFieldDefinition("workItemId", "Aktivitäts-ID", FrontendFieldType.text, { value: ""}),
            new FrontendFieldDefinition("familyName", "Workflow-Familie", FrontendFieldType.comboBox, { value: -1, dropdownEditable: false,
                listItems: [new FrontendFieldListItem(-1, "&lt;Alle&gt;", { html: true, labelPlain: "<Alle>" }),
                ...this.families.map(f => new FrontendFieldListItem(f, f.name))], onValueChanged: this.workflowFamilyChanged.bind(this)}),
            new FrontendFieldDefinition("modelAllGreater", "Workflow-Modell", FrontendFieldType.comboBox, { dropdownEditable: false, listItems: ['=', '>='].map(x => new FrontendFieldListItem(x, x)), value: "=", guiGroup: "modelName", guiCols: 3}),
            new FrontendFieldDefinition("modelName", "Workflow-Modell", FrontendFieldType.comboBox, { dropdownEditable: false, guiGroup: "modelName", guiCols: 9}),
            new FrontendFieldDefinition("onlyStates", "Status", FrontendFieldType.comboBoxMulti, { value: Utils.getEnumValues(EnaioProcessState), dropdownEditable: false, listItems: Utils.getEnumValues(EnaioProcessState).map(s => new FrontendFieldListItem(s, EnaioHelper.processStateToString(s as EnaioProcessState)))}),
            new FrontendFieldDefinition("includeFinishedWorkflows", "Inklusive Abgeschlossener", FrontendFieldType.checkBox, {value: false}),
            new FrontendFieldDefinition("getVariables", "Variablenwerte holen", FrontendFieldType.checkBox, {value: false}),
            new FrontendFieldDefinition("getDocumentIds", "Dokumenten-IDs holen", FrontendFieldType.checkBox, {value: false}),
            new FrontendFieldDefinition("getDocumentIdsPerObjectType", "Dokumenten-IDs pro Objekttyp holen", FrontendFieldType.checkBox, {value: false}),
            new FrontendFieldDefinition("getInboxUsers", "Bearbeiter holen", FrontendFieldType.checkBox, {value: false}),
            new FrontendFieldDefinition("getLastActivityDateDetailed", "Datum der letzten Aktivität (inkl. Personalisierung) holen", FrontendFieldType.checkBox, {value: false}),
            new FrontendFieldDefinition("queryDates", "Nach Datum filtern", FrontendFieldType.checkBox, { value: false, onValueChanged: () => this.updateFormVisibility() }),
            new FrontendFieldDefinition("startDateFrom", "Startdatum nach", FrontendFieldType.datePicker, { datePickerWithTime: true}),
            new FrontendFieldDefinition("startDateTo", "Startdatum vor", FrontendFieldType.datePicker, { datePickerWithTime: true}),
            new FrontendFieldDefinition("endDateFrom", "Enddatum nach", FrontendFieldType.datePicker, { datePickerWithTime: true}),
            new FrontendFieldDefinition("endDateTo", "Enddatum vor", FrontendFieldType.datePicker, { datePickerWithTime: true}),
            new FrontendFieldDefinition("actionDateFrom", "Aktion durchgeführt nach", FrontendFieldType.datePicker, { datePickerWithTime: true}),
            new FrontendFieldDefinition("actionDateTo", "Aktion durchgeführt vor", FrontendFieldType.datePicker, { datePickerWithTime: true}),
            new FrontendFieldDefinition("forObjectId", "Nach Objekt-ID in Akte suchen", FrontendFieldType.number, { value: 0}),
            new FrontendFieldDefinition("useVariableConditions", "Variablen-Bedingungen", FrontendFieldType.checkBox, {value: false, onValueChanged: () => {
                this.formFilter.getField("variableConditions").visible = this.formFilter.getValue("useVariableConditions");
            }}),
            new FrontendFieldDefinition("variableConditions", "", FrontendFieldType.table, { value: [], visible: false, tableColumns: [
                new TableColumn("field", "Feld", TableCellType.text, { width: "150px", editor: new FrontendFieldDefinition("field", "Feld", FrontendFieldType.comboBox, { dropdownEditable: true }) }),
           //     new TableColumn("Operator", "comparison", TableCellType.text, { width: "30px" }),
                new TableColumn("value", "Wert", TableCellType.text, { width: "250px" })
            ], generateEmptyTableItem: (): any => new EnaioConditionGui("", "")})
        ]);
        this.workflowFamilyChanged();
        this.updateFormVisibility();

        return true;
    }

    updateFormVisibility(): void {
        let queryDates = this.formFilter.getValue("queryDates") as boolean;
        this.formFilter.getField("startDateFrom").visible = queryDates;
        this.formFilter.getField("startDateTo").visible = queryDates;
        this.formFilter.getField("endDateFrom").visible = queryDates;
        this.formFilter.getField("endDateTo").visible = queryDates;
        this.formFilter.getField("actionDateFrom").visible = queryDates;
        this.formFilter.getField("actionDateTo").visible = queryDates;
    }

    workflowFamilyChanged(): void {
        let familyId = this.formFilter.getValue("familyName") == -1 ? null : this.formFilter.getValue("familyName")?.id;
        let models = familyId == null ? null : this.models.filter(m => m.family.id == familyId);
        let items = [new FrontendFieldListItem(-1, "&lt;Alle&gt;", { html: true, labelPlain: "<Alle>" }),
            ...models == null ? [] : Utils.arraySort(models.map(m => new FrontendFieldListItem(m.id, m.getHtmlTitle(), { html: true, labelPlain: m.name, comparable: Utils.expandNumber(1000000 - m.version, 7) })))];
        this.formFilter.getField("modelName").listItems = items;
        this.formFilter.setValue("modelName", Utils.arrayGetSafe(items, 0)?.value);
        this.formFilter.getField("variableConditions").tableColumns[0].editor.listItems = [new FrontendFieldListItem("*", "*"),
            ...models == null ? [] : Utils.arraySort(Utils.arrayGetUnique(Utils.arrayExplode(models, m => m.definition.dataFields.map(df => df.name)))).map(v => new FrontendFieldListItem(v, v))];
    }

    async filter(): Promise<void> {
        let onlyStates = this.formFilter.getValue("onlyStates") as EnaioProcessState[];
        if (onlyStates.length == Utils.getEnumValues(EnaioProcessState).length) {
            onlyStates = null;
        }
        let queryDates = this.formFilter.getValue("queryDates") as boolean;
        this.lastCall = Utils.fromPlain(EnaioCallGetProcessesEx, Utils.objectRemoveNullValues({
            familyName: this.formFilter.getValue("modelName") == -1 && this.formFilter.getValue("familyName") != -1 ? this.families.find(f => f.id == this.formFilter.getValue("familyName")?.id).name : null,
            modelName: this.formFilter.getValue("modelName") != -1 ? this.formFilter.getValue("modelName") : null,
            processId: this.formFilter.getValue("processId"),
            processName: this.formFilter.getValue("processName"),
            workItemId: this.formFilter.getValue("workItemId"),
            onlyStates,
            includeFinishedWorkflows: this.formFilter.getValue("includeFinishedWorkflows"),
            getVariables: this.formFilter.getValue("getVariables"),
            getDocumentIds: this.formFilter.getValue("getDocumentIds"),
            getInboxUsers: this.formFilter.getValue("getInboxUsers"),
            getLastActivityDateDetailed: this.formFilter.getValue("getLastActivityDateDetailed"),
            startDateFrom: queryDates && this.formFilter.getValue("startDateFrom") != "" ? this.formFilter.getValue("startDateFrom") : null,
            startDateTo: queryDates && this.formFilter.getValue("startDateTo") != "" ? Utils.dateEndOf(this.formFilter.getValue("startDateTo") as Date, "day") : null,
            endDateFrom: queryDates && this.formFilter.getValue("endDateFrom") != "" ? this.formFilter.getValue("endDateFrom") : null,
            endDateTo: queryDates && this.formFilter.getValue("endDateTo") != "" ? Utils.dateEndOf(this.formFilter.getValue("endDateTo") as Date, "day") : null,
            actionDateFrom: queryDates && this.formFilter.getValue("actionDateFrom") != "" ? this.formFilter.getValue("actionDateFrom") : null,
            actionDateTo: queryDates && this.formFilter.getValue("actionDateTo") != "" ? Utils.dateEndOf(this.formFilter.getValue("actionDateTo") as Date, "day") : null,
            forObjectId: this.formFilter.getValue("forObjectId"),
            conditions: this.formFilter.getValue("useVariableConditions") ? this.formFilter.getValue("variableConditions").
                map(condition => new EnaioCondition(EnaioObjectId.byName((condition as EnaioConditionGui).field), (condition as EnaioConditionGui).value, "="/*(condition as EnaioConditionGui).comparison*/)) : null
        }));
        this.processes = await DhTools.enaioCall<EnaioProcess[]>(this.lastCall);

        if (this.formFilter.getValue("getDocumentIdsPerObjectType")) {
            this.docsById = Utils.arrayToNumberMap(await DhTools.enaioCall<EnaioDocument[]>(Utils.fromPlain(EnaioCallSelect, {
                selectMultipleObjectIds: Utils.arrayGetUnique(Utils.arrayExplode(this.processes, p => p.documentIds))
            })), doc => doc.id, doc => doc);
        }

        this.fillTable();
    }

    fillTable(): void {
        let allVars = Utils.arraySort(Utils.arrayGetUnique(Utils.arrayExplode(this.processes, p => Utils.getOwnPropertyNames(this.unrollVariables ? Utils.flatten(p.variables) : p.variables))));

        let docOts = this.formFilter.getValue("getDocumentIdsPerObjectType") ? Utils.arraySort(Utils.arrayGetUnique(Utils.objectGetValues(this.docsById).map(doc => doc.objectType.getBestDisplayName()))) : [];

        this.wfTable = new TableData(Utils.arrayWithoutNull([
            new TableColumn("_tools", "", TableCellType.button, { width: "60px" }),
            new TableColumn("name", "Name", TableCellType.text, { width: "250px" }),
            new TableColumn("id", "ID", TableCellType.text, { width: "250px" }),
            new TableColumn("state", "Status", TableCellType.text, { width: "100px" }),
            new TableColumn("modelName", "Modellname", TableCellType.text, { width: "250px" }),
            new TableColumn("activityName", "Aktivität", TableCellType.text, { width: "150px" }),
            new TableColumn("creationTime", "Erstellzeit", TableCellType.dateTime, { width: "130px" }),
            new TableColumn("lastActivityDate", "Letzte", TableCellType.dateTime, { width: "130px" }),
            this.lastCall.getLastActivityDateDetailed ? new TableColumn("lastActivityDateDetailed", "Letzte Aktivität (inkl. Pers.)", TableCellType.dateTime, { width: "130px" }) : null,
            this.lastCall.getDocumentIds ? new TableColumn("documentIds", "Dokumenten-IDs", TableCellType.text, { width: "200px" }) : null,
            this.lastCall.getInboxUsers ? new TableColumn("inboxUsers", "Bearbeiter", TableCellType.text, { width: "150px" }) : null,
            this.lastCall.getInboxUsers ? new TableColumn("personalisedBy", "Personalisiert", TableCellType.text, { width: "150px" }) : null,
            ...docOts.map(ot => new TableColumn("docOt_" + ot, "Dok " + ot, TableCellType.text, { width: "200px", noWrap: true})),
            ...allVars.map(v => new TableColumn("variable_" + v, v, TableCellType.text, { width: "200px", noWrap: true }))
        ]), this.processes.map(p => new TableRow(p, ({
            _tools: "fas fa-cog",
            name: p.name,
            id: p.id,
            activityName: p.currentActivity?.name,
            creationTime: p.creationTime,
            lastActivityDate: p.lastActivityDate,
            lastActivityDateDetailed: p.lastActivityDateDetailed,
            modelName: new TableCell(Utils.arrayFindAndConvert(this.models, m => m.id == p.modelId, m => m.name, "<" + p.modelId + ">"), { internal: [new GeneralObjectKey("enaio.workflowModel", { id: p.modelId })], actionProviders: [new DhObjectActions()] }),
            state: EnaioHelper.processStateToString(p.state),
            documentIds: new TableCell(Utils.arrayItemsToString(p.documentIds), { internal: p.documentIds.map(id => new GeneralObjectKey("enaio.object", { id })), actionProviders: [new DhObjectActions()] }),
            inboxUsers: Utils.stringCombine(Utils.arrayItemsToString(Utils.arraySort((p.currentActivity?.inboxUsers ?? []).map(u => u.userName))), Utils.arrayItemsToString(Utils.arraySort((p.currentActivity?.inboxRoles ?? []).map(r => r.name))), ", "),
            personalisedBy: p.currentActivity?.personalisedBy?.userName ?? "",
            ...Utils.arrayToPlainMap(docOts, ot => "docOt_" + ot, ot =>
                new TableCell(Utils.arrayItemsToString(p.documentIds.filter(id => Utils.hasProperty(this.docsById, id) && this.docsById[id].objectType.getBestDisplayName() == ot)), { internal: p.documentIds.map(id => new GeneralObjectKey("enaio.object", { id })), actionProviders: [new DhObjectActions()] })
            ),
            ...Utils.arrayToPlainMap(allVars, v => "variable_" + v, v => {
                let value = Utils.getPropertyNested(p.variables, v);
                return value != null ? Utils.replaceAll(Utils.toString(value), "\\r?\\n", " ") : "---";
            })
        }) as OrdinaryObject, null, p.state == EnaioProcessState.error ? "xbg-red-400" : null)));
    }

    cellClicked(event): void {
        if (event[1] == "_tools") {
            let p = event[0].raw as EnaioProcess;
            this.contextMenu = [
                {
                    label: "Detailansicht",
                    command: async () => {
                        DocumentHandlerMainComponent.instance.viewEnaioWorkflow.processId = p.id;
                        await DocumentHandlerMainComponent.instance.viewEnaioWorkflow.load();
                        await DocumentHandlerMainComponent.instance.activateTab("enaioWorkflow");
                    }
                },
                {
                    label: "Workflow-Fehler ermitteln",
                    command: async () => this.getWorkflowError(p)
                }
            ];
            GuiUtils.angularTimer(() => this.cm.show(this.app.getCurrentMousePosEvent()));
        }
    }

    async getWorkflowError(p: EnaioProcess): Promise<void> {
        let errorMessage = await DhTools.enaioCall<string>(Utils.fromPlain(EnaioCallGetWorkflowError, { processId: p.id }));
        if (errorMessage == "null") {
            await this.app.messageDialog.info("Es konnte kein Fehler gefunden werden", "Information");
        }
        else {
            await this.app.messageDialog.info(Utils.replaceAll(errorMessage, "\n", "<br>"), "Fehler", { monospace: true });
        }
    }
}

<div class="grid">
    <p-tree #configsTreeComponent class="flex-item-fill full-height-200 tree-items-full-width" [value]="scriptsTree" selectionMode="single"
                    [(selection)]="scriptsTreeSelected" (selectionChange)="selectScript()">
                </p-tree>
    <div class="col-10">
        <sm-toolbar>
       <!--     <p-button icon="fas fa-syntax" label="Syntax prüfen" (onClick)="syntaxCheck()">
            </p-button>
        --> <p-checkbox name="showIncluded" [(ngModel)]="showIncluded" [binary]="true" (ngModelChange)="calculateCode()"></p-checkbox>
            <label class="form-label mr-5">Inkludierte Skripte anhängen</label>
            <input pInputText class="p-inputtext-sm width300" type="text" placeholder="Suche" [(ngModel)]="searchText" (keypress)="search($event)"/>
            <p-checkbox name="searchRegex" [(ngModel)]="searchRegex" [binary]="true"></p-checkbox>
            <label class="form-label mr-5">Regex</label>

    <!--        <p-button icon="fas fa-save" label="Änderungen speichern" (onClick)="save()">
            </p-button>
        -->  </sm-toolbar>
        <app-ace-editor #aceEvent [text]="code" [mode]="sel?.language" [style.height]="'calc(100vh - ' + (searchResults.length > 0 ? '620px' : '250px') + ')'" (contextMenu)="onContextMenu($event)"></app-ace-editor>
        <p-tabView *ngIf="searchResults.length > 0" class="mt-3" [(activeIndex)]="searchResultTabIndex" [controlClose]=true (onClose)="closeSearchResultsTab($event)">
            <p-tabPanel *ngFor="let result of searchResults" [header]="result.title" [closable]=true>
                <sm-table-on-push class="col-12 monospace" [data]="result.guiTable" scrollHeight="300px" (onRowSelect)="selectFound($event)"></sm-table-on-push>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioObjectTypeImportParams {
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    @Type(() => EnaioObjectId)
    destObjectType: EnaioObjectId;
    includeChildren: boolean = null;
    documents: boolean = null;
    fixedParentId = 0;
    import = true;
    fieldNameSourceObjectId: string;
    @Type(() => EnaioObjectId)
    keyFields: EnaioObjectId[];
    keyFieldsAll = false;
}

import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';

@Component({
  selector: 'app-test-invoice-approval',
  templateUrl: './test-invoice-approval.component.html',
  styleUrls: ['./test-invoice-approval.component.scss']
})
export class TestInvoiceApprovalComponent extends ComponentView {

    type;

    form: FrontendFormDefinition;
    formFilter: FrontendFormDefinition;
    tree: TreeNode2[] = [];

    constructor() {
        super();
        this.neededParams = { type: "number" };
    }
    async initParams(): Promise<boolean> {
        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("bemerkung", "Bemerkung", FrontendFieldType.text)
        ]);
        this.formFilter = new FrontendFormDefinition([
            new FrontendFieldDefinition("fromDate", "Datumsintervall", FrontendFieldType.datePicker, { guiGroup: "x", guiCols: 6 }),
            new FrontendFieldDefinition("endDate", "Enddatum", FrontendFieldType.datePicker, { guiGroup: "x", guiCols: 6 })
        ]);

     /*   this.tree = [
            {
                label: "Test-Firma",
                data: ["Test-Firma", "", "", ""],
                children: [
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "4234243234", "999,99", "10.11.2023"]
                    },
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "213566", "122,12", "10.11.2023"]
                    },
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "213577", "432,78", "09.11.2023"]
                    },
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "213578", "15", "09.11.2023"]
                    },
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "213580", "1800", "08.11.2023"]
                    }
                ]
            },
            {
                label: "Demo-Firma",
                data: ["Demo-Firma", "", "", ""],
                children: [
                    {
                        label: "Demo-Firma",
                        data: ["Demo-Firma", "4234243234", "999,99", "10.11.2023"]
                    }
                ]
            },
            {
                label: "Beispiel-Firma",
                data: ["Beispiel-Firma", "", "", ""],
                children: [
                    {
                        label: "Beispiel-Firma",
                        data: ["Beispiel-Firma", "4234243234", "999,99", "10.11.2023"]
                    }
                ]
            }
        ];
*/
        this.tree = [
            {
                label: "Heute",
                data: ["Heute", "", "", ""],
                children: [
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "4234243234", "999,99", "28.02.2024"]
                    },
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "213566", "122,12", "28.02.2024"]
                    },
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "213577", "432,78", "28.02.2024"]
                    },
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "213578", "15", "28.02.2024"]
                    },
                    {
                        label: "Test-Firma",
                        data: ["Test-Firma", "213580", "1800", "28.02.2024"]
                    }
                ]
            },
            {
                label: "Gestern",
                data: ["Gestern", "", "", ""],
                children: [
                    {
                        label: "Demo-Firma",
                        data: ["Demo-Firma", "4234243234", "999,99", "27.02.2024"]
                    }
                ]
            },
            {
                label: "Letzte Woche",
                data: ["Letzte Woche", "", "", ""],
                children: [
                    {
                        label: "Beispiel-Firma",
                        data: ["Beispiel-Firma", "4234243234", "999,99", "23.02.2024"]
                    }
                ]
            }
        ];

        return Promise.resolve(true);
    }
}

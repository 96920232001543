import { Component, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FileUploadService } from 'src/modules/sm-base/services/file-upload-service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'app-travel-costs-form',
    templateUrl: './travel-costs-form.component.html',
    styleUrls: ['./travel-costs-form.component.scss']
  })
  export class TravelCostsFormComponent extends ComponentView {

    messageService = inject(MessageService);
    fileUpload = inject(FileUploadService);

    done = false;
    uploaded: string;
    form: FrontendFormDefinition;

    async initParams(): Promise<boolean> {
        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("lastName", "Name", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("firstName", "Vorname", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("dateFrom", "Datum von", FrontendFieldType.datePicker, { mandatory: true }),
            new FrontendFieldDefinition("dateTo", "Datum bis", FrontendFieldType.datePicker, { mandatory: true }),
            new FrontendFieldDefinition("reason", "Zweck der Reise", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("typeOfVehicle", "Art des Verkehrsmittels", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("hotelName", "Hotelname", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("expensesDriving", "Fahrtkosten", FrontendFieldType.number, { mandatory: true, value: 0 }),
            new FrontendFieldDefinition("expensesSubsistence", "Verpflegungskosten", FrontendFieldType.number, { mandatory: true, value: 0 }),
            new FrontendFieldDefinition("expensesAccomodation", "Hotelkosten", FrontendFieldType.number, { mandatory: true, value: 0 })
        ]);
        return Promise.resolve(true);
    }

    async upload(): Promise<void> {
        this.uploaded = Utils.bytesToBase64((await this.fileUpload.chooseFile())[0].contents);
    }

    async send(): Promise<void> {
        let errors = this.form.validate();
        if (errors != null) {
            this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 3000, detail: Utils.toString(Utils.objectGetValues(errors)[0])});
            return;
        }

        let item: OrdinaryObject = {};
        this.form.get(item);
        item.uploaded = this.uploaded;

        await RestEndpoint.main().body(item).post().body(item).run("api/tst/test/insertTravelCosts").getText();
        this.done = true;
    }
}

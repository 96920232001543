import { Component, Input, ViewChild } from '@angular/core';
import { ConfigEditorTreeComponent } from 'src/modules/sm-base/components/config-editor-tree/config-editor-tree.component';
import { ConfigDefinition } from 'src/modules/sm-base/models/config-definition.model';
import { ConfigFieldDefinition } from 'src/modules/sm-base/models/config-field-definition.model';
import { ConfigFieldType } from 'src/modules/sm-base/models/config-field-type.enum';
import { ConfigProperty } from 'src/modules/sm-base/models/config-property.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
  selector: 'app-config-changer-config',
  templateUrl: './config-changer-config.component.html',
  styleUrls: ['./config-changer-config.component.scss']
})
export class ConfigChangerConfigComponent {

    _def: ConfigDefinition;
    _plain: OrdinaryObject = {};

    config: ConfigProperty;

    @ViewChild("configEditorTree") configEditorTree: ConfigEditorTreeComponent;

    @Input()
    set def(value: ConfigDefinition) {
        this._def = value;
        this.update();
    }

    @Input()
    set plain(value: OrdinaryObject) {
        this._plain = value;
        this.update();
    }

    private update(): void {
        let d = this._def.classes[0];
        let mainFieldDef = Utils.fromPlain(ConfigFieldDefinition, { name: "main", type: ConfigFieldType.object, typeName: d.name});
        this.config = new ConfigProperty({}, d.name, true, mainFieldDef, [], this._plain);
        this.config.properties = mainFieldDef.getProperties(d, this._plain, this._def);

        GuiUtils.angularTimer(() => this.configEditorTree.setObject(this.config));
    }
}

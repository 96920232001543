<ng-container *ngIf="def != null && _options != null">
    <sm-toolbar>
        <p-button icon="fas fa-run" label="Export starten" (onClick)="import()"></p-button>
        <p-button icon="fas fa-times" label="Abbrechen" (onClick)="cancel()"></p-button>
    </sm-toolbar>
    <sm-form [form]="form">
    </sm-form>
    <p-panel *ngFor="let archive of _options.archives; index as index" [header]="'Schrank ' + archive.archive.name">
        <sm-form [form]="forms[index]">
        </sm-form>
        <sm-table [data]="tables[index]"></sm-table>
</p-panel>
</ng-container>

import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DhTools } from '../../models/dh-tools.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';

@Component({
    selector: 'app-misc-tools',
    templateUrl: './misc-tools.component.html',
    styleUrls: ['./misc-tools.component.scss']
    })
    export class MiscToolsComponent extends ComponentView {

    keptAlive = false;

    async initParams(): Promise<boolean> {
        return Promise.resolve(true);
    }

    async tryKeepAlive(): Promise<void> {
        await DhTools.backendCall("api/tools/tryKeepAlive").getText();
        this.keptAlive = true;
    }

    async simpleCryptoEncrypt(): Promise<void> {
        let value = await this.app.messageDialog.input(new FrontendFieldDefinition("value", "Text", FrontendFieldType.text), "Klartext");
        if (value == null) {
            return;
        }
        let converted = await DhTools.backendCall("api/tools/simpleCryptoEncrypt", { value }).getText();

        await this.app.copyToClipboardOrDialog(converted, "Verschlüsselter Text", "Der verschlüsselte Text befindet sich in der Zwischenablage");
    }

    async simpleCryptoDecrypt(): Promise<void> {
        let value = await this.app.messageDialog.input(new FrontendFieldDefinition("value", "Text", FrontendFieldType.text), "Cipher-Text");
        if (value == null) {
            return;
        }
        let converted = await DhTools.backendCall("api/tools/simpleCryptoDecrypt", { value }).getText();

        await this.app.copyToClipboardOrDialog(converted, "Entschlüsselter Text", "Der entschlüsselte Text befindet sich in der Zwischenablage");
    }

    async setGermanKeyboard(): Promise<void> {
        await DhTools.backendCall("api/tools/setGermanKeyboard").getText();
    }
}

<ng-container *transloco="let t">
    <div #main class="grid">
        <p-messages *ngIf="item != null && item.historicCurrentId > 0" class="width100p" severity="error">
            <ng-template pTemplate>
                <div class="ml-2" >{{t('lea.learningContent.messages.historicVersion')}}
                    <a [routerLink]="['/enaio-learn', 'learning-content', item.historicCurrentId]" (click)="setCloseOk()">{{t('lea.learningContent.messages.jumpToCurrent')}}</a></div>
            </ng-template>
        </p-messages>
        <div *ngIf="item != null && item.historicCurrentId <= 0" class="col-12" style="text-align: left">
            <p-button class="toolbar-element" icon="fas fa-save" [label]="t('general.save')" [disabled]="!maySave" (onClick)="save()">
            </p-button>
            <p-button class="toolbar-element" icon="fas fa-glasses" [label]="forwardMessage" [disabled]="!maySave" (onClick)="saveAndForward()">
            </p-button>
            <p-button *ngIf="item != null && item.getStatus() == _LearningContentStatus.inInspection" class="toolbar-element" icon="fas fa-square-check" [label]="t('lea.learningContent.backToAuthor')" [disabled]="!maySave" (onClick)="backToAuthor()">
            </p-button>
            <p-button *ngIf="mayDelete" class="toolbar-element" icon="fas fa-trash" [label]="t('general.delete')" (onClick)="deleteContent()">
            </p-button>
            <p-button class="toolbar-element" icon="fas fa-times" [label]="t('general.cancel')" (onClick)="cancel()">
            </p-button>
            <p-tag *ngIf="publishStatusText != null" [styleClass]="'publish-status-text'" [value]="publishStatusText" [severity]="publishStatusColor"></p-tag>
        </div>
        <p-tabView #tabView *ngIf="item != null" class="col-12" [(activeIndex)]="activeTab" (onChange)="changeTab()">
            <p-tabPanel [header]="t('lea.learningContent.mainData')">
                <div class="grid p-jc-center">
                    <p-card class="col-12 lg:col-6">
                        <sm-form [form]="form">
                        </sm-form>
                    </p-card>
                </div>
            </p-tabPanel>
            <p-tabPanel [header]="t('lea.learningContent.targetAudience')">
                <div class="grid">
                    <span class="col-12 mb-3">
                        {{t('lea.learningContent.targetAudienceType')}}
                        <p-dropdown class="toolbar-element" [styleClass]="'width300'" id="mandatoryType"
                            [options]="targetAudienceTypes"
                            [(ngModel)]="item.mandatoryType">
                        </p-dropdown>
                        <p-message severity="info" [text]="t('lea.learningContent.targetAudienceInfo')" styleClass="mb-4"></p-message>
                    </span>
                    <div *ngIf="!item.mandatoryType.includes('012')" class="col-12 lg:col-6">
                        <h6>{{t('lea.learningContent.targetAudienceDepartments')}}</h6>
                        <p-tree [value]="costCentersTree" selectionMode="checkbox" [(selection)]="selectedCostCenters"></p-tree>
                    </div>
                    <div *ngIf="!item.mandatoryType.includes('3')" class="col-12 lg:col-6">
                        <h6>{{t('lea.learningContent.targetAudienceJobDescriptions')}}</h6>
                        <p-tree [value]="jobDescriptionsTree" selectionMode="checkbox" [(selection)]="selectedJobDescriptions"></p-tree>
                    </div>
                    <div *ngIf="!item.mandatoryType.includes('4')" class="col-12 lg:col-6">
                        <h6>{{t('lea.learningContent.targetAudienceUserGroups')}}</h6>
                        <p-tree [value]="userGroupsTree" selectionMode="checkbox" [(selection)]="selectedUserGroups"></p-tree>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel [header]="t('lea.learningContent.inspectionLoop')" class="inspection-loop">
                <div class="inspection-loop-info" [innerHTML]="t('lea.learningContent.messages.inspectionLoopInfo') | keepHtml"></div>
                <div class="virtual-phase">
                    {{t("lea.learningContent.publishStatus.phaseNameEditing")}}
                    <span class="ml-2">
                        <i class="fas inspection-loop-icon" [class.fa-check]="this.item.getStatus() != _LearningContentStatus.unpublished" [class.status-done]="this.item.getStatus() != _LearningContentStatus.unpublished"></i>
                    </span>
                </div>
                <div class="p-timeline-event-connector">
                </div>
                <p-timeline [value]="contentPhases" [align]="'right'">
                    <ng-template pTemplate="marker" let-phase>
                        <span class="custom-marker p-shadow-2">
                            <i class="fas inspection-loop-icon" [class.fa-check]="phase.done" [class.fa-glasses]="phase.current" [class.fa-hourglass]="phase.toDo" [class.status-done]="phase.done" [class.status-current]="phase.current" [class.status-todo]="phase.toDo"></i>
                        </span>
                    </ng-template>
                    <ng-template pTemplate="content" let-phase>
                        <span class="status inspection-loop" [class.status-done]="phase.done" [class.status-current]="phase.current" [class.status-todo]="phase.toDo">{{phase.phaseName}}</span>
                    </ng-template>
                    <ng-template pTemplate="opposite" let-phase>
                        <ng-container *ngFor="let responsible of phase.getAllUserNames()">
                            <p-chip *ngIf="!phase.done || responsible == phase.inspectedByUserName" class="responsible-chip inspection-loop" [styleClass]="'inspection-loop'" [label]="responsible + (responsible == phase.inspectedByUserName ? ' ' + (phase.inspectedDate | date: 'dd.MM.yyyy') : '')" [class.user-has-inspected]="responsible == phase.inspectedByUserName"></p-chip>
                        </ng-container>
                    </ng-template>
                </p-timeline>
                <div class="p-timeline-event-connector">
                </div>
                <div class="virtual-phase">
                    {{this.item.getStatus() == _LearningContentStatus.published ? t("lea.learningContent.publishStatus.phaseNamePublished") : t("lea.learningContent.publishStatus.phaseNamePublishing")}}
                    <span class="ml-2">
                        <i class="fas inspection-loop-icon" [class.fa-check]="this.item.getStatus() == _LearningContentStatus.published" [class.fa-hourglass]="this.item.getStatus() != _LearningContentStatus.published" [class.status-done]="this.item.getStatus() == _LearningContentStatus.published" [class.status-todo]="this.item.getStatus() != _LearningContentStatus.published"></i>
                    </span>
                </div>
            </p-tabPanel>
            <p-tabPanel [header]="t('lea.learningContent.chapters')">
                <chapter-list #chapterlist [items]="item.chapters" [emptyItem]="emptyChapter" [addText]="t('lea.learningContent.chapter.add')" (selectedIndexChange)="selectChapter($event[0])" (addItem)="addChapter(true)"></chapter-list>
                <div class="grid" [style]="{height: '100%'}">
                    <p-card *ngIf="selectedChapter != null" class="col-12 lg:col-6 lg:col-offset-3" [style]="{height: '100%'}">
                        <p-tabView [(activeIndex)]="activeTabChapter">
                            <p-tabPanel [header]="t('lea.learningContent.chapter.general')">
                                <sm-form [form]="chapterForm">
                                </sm-form>
                            </p-tabPanel>
                            <p-tabPanel [header]="t('lea.learningContent.chapter.text')">
                                <ngx-jodit #joditEditor [options]="joditConfig" [(value)]="selectedChapter.text"></ngx-jodit>
                            </p-tabPanel>
                            <p-tabPanel [header]="t('lea.learningContent.chapter.questions')">
                                <chapter-list [items]="selectedChapter.questions" labelField="question" [canRearrange]=false [emptyItem]="emptyQuestion" [addText]="t('lea.learningContent.chapter.question.add')" (selectedIndexChange)="selectQuestion($event[0])" ></chapter-list>
                                <p-card *ngIf="selectedQuestion != null">
                                    <div class="form-group p-field grid">
                                        <label class="col-12 form-label">{{t('lea.learningContent.chapter.question.question')}}</label>
                                        <div class="col-12">
                                            <input [style.width]="'100%'" pInputText name="question" [style.position]="'relative'" [(ngModel)]="selectedQuestion.question">
                                        </div>
                                    </div>
                                    <div class="form-group p-field grid">
                                        <label class="col-12 form-label">{{t('lea.learningContent.chapter.question.correctAnswers')}}</label>
                                        <string-list-editor class="col-12" [items]="selectedQuestion.correctAnswers" [addTitle]="t('lea.learningContent.chapter.question.addAnswer')"></string-list-editor>
                                    </div>
                                    <div class="form-group p-field grid">
                                        <label class="col-12 form-label">{{t('lea.learningContent.chapter.question.wrongAnswers')}}</label>
                                        <string-list-editor class="col-12" [items]="selectedQuestion.wrongAnswers" [addTitle]="t('lea.learningContent.chapter.question.addAnswer')"></string-list-editor>
                                    </div>
                                </p-card>
                            </p-tabPanel>
                            <p-tabPanel [header]="t('lea.learningContent.chapter.citations')">
                                <div class="form-group p-field grid mt-2">
                                    <edit-citations class="col-12" [items]="selectedChapter.citations"></edit-citations>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel [header]="t('lea.learningContent.chapter.attachments')">
                                <sm-table [data]="attachmentsTable" [allowDelete]=true [allowExport]=false [allowFilter]=false [allowInsert]=true [allowSort]=false [allowSelection]=false (onCellClicked)="downloadAttachment($event)" (onDelete)="deleteAttachment($event)">
                                </sm-table>
                            </p-tabPanel>
                        </p-tabView>
                    </p-card>
                    </div>
            </p-tabPanel>
            <p-tabPanel [header]="t('lea.learningContent.finalExam.title')">
                <div class="grid p-jc-center">
                    <p-card class="col-12 lg:col-6">
                        <p-message severity="info" [text]="t('lea.learningContent.finalExam.info')" styleClass="mb-4"></p-message>
                        <sm-form [form]="finalExamForm">
                        </sm-form>
                        <div *ngIf="item.finalExam">
                            <chapter-list [items]="item.finalExamQuestions" labelField="question" [canRearrange]=false [emptyItem]="emptyQuestion" [addText]="t('lea.learningContent.chapter.question.add')" (selectedIndexChange)="selectFinalExamQuestion($event[0])" ></chapter-list>
                            <p-card *ngIf="selectedFinalExamQuestion != null">
                                <div class="form-group p-field grid">
                                    <label class="col-12 form-label">{{t('lea.learningContent.chapter.question.question')}}</label>
                                    <div class="col-12">
                                        <input [style.width]="'100%'" pInputText name="question" [style.position]="'relative'" [(ngModel)]="selectedFinalExamQuestion.question">
                                    </div>
                                </div>
                                <div class="form-group p-field grid">
                                    <label class="col-12 form-label">{{t('lea.learningContent.chapter.question.correctAnswers')}}</label>
                                    <string-list-editor class="col-12" [items]="selectedFinalExamQuestion.correctAnswers" [addTitle]="t('lea.learningContent.chapter.question.addAnswer')"></string-list-editor>
                                </div>
                                <div class="form-group p-field grid">
                                    <label class="col-12 form-label">{{t('lea.learningContent.chapter.question.wrongAnswers')}}</label>
                                    <string-list-editor class="col-12" [items]="selectedFinalExamQuestion.wrongAnswers" [addTitle]="t('lea.learningContent.chapter.question.addAnswer')"></string-list-editor>
                                </div>
                            </p-card>
                        </div>
                    </p-card>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
    <button *ngIf="activeTab > 0" class="p-button-rounded prev-button p-button-lg" pButton pRipple type="button" icon="fas fa-arrow-left" (click)="previousTab()"></button>
    <button *ngIf="activeTab < 3" class="p-button-rounded next-button p-button-lg" pButton pRipple type="button" icon="fas fa-arrow-right" (click)="nextTab()"></button>
    <p-speedDial *ngIf="activeTab == 3" [model]="nextButtonItems" [buttonClassName]="'next-button-speed'" direction="up"></p-speedDial>
</ng-container>

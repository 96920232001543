import { ErrorHandler } from "@angular/core";
import { Config } from "src/modules/app-template/models/config.model";
import { Utils } from 'src/modules/utils/shared/utils';
import { ErrorHandlingService } from '../services/error-handling-service';
import { LocatorService } from '../services/locator.service';
import { ErrorInformation } from '../shared/error-information.model';
import { HttpErrorResponse } from "@angular/common/http";

export class SmErrorHandler implements ErrorHandler {

    static instance: SmErrorHandler;
    onError: ((error: any) => void)[] = [];

    constructor() {
        SmErrorHandler.instance = this;
    }

    handleError(error: any): void {
        for (let f of this.onError) {
            f(error);
        }

        if (Utils.isString(error.message) && error instanceof HttpErrorResponse && (error.message.endsWith("500 Internal Server Error") || error.message.endsWith("0 Unknown Error") || error.message.includes('"status":401,"statusText":"Unauthorized"'))) {
            return;
        }

        console.log(error);
        console.trace();

        //if (Config.get().reportErrorsToBackend) {
            LocatorService.injector.get(ErrorHandlingService).reportToBackend(new ErrorInformation(error as Error));
       // }
    }
}

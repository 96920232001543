import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModelDescription } from '../models/model-description.model';
import { Map2 } from 'src/modules/utils/shared/map2.model';

@Injectable()
export class ModelDescriptionService {

    private data = new Map2<string, ModelDescription>();

    constructor(private http: HttpClient) {
    }

    register(desc: ModelDescription): void {
        this.data.set(desc.id, desc);
    }

    getAll(): IterableIterator<ModelDescription> {
        return this.data.values();
    }

    getModelDescription(name: string): ModelDescription {
        return this.data.get(name);
    }
}

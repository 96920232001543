import { Component, Input, ViewChild } from '@angular/core';
import { FrontendScriptDefinition } from '../../models/frontend-script-definition.model';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { ArrayOrSingle } from 'src/modules/sm-base/shared/array-or-single.model';
import { MenuItem, TreeNode } from 'primeng/api';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { FrontendScriptSearchResult } from '../../models/frontend-script-search-result.model';
import { FrontendScriptFound } from '../../models/frontend-script-found.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { AceEditorComponent } from 'src/modules/sm-base/components/ace-editor/ace-editor.component';
import { AceSelection } from 'src/modules/sm-base/models/ace-selection.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';

@Component({
  selector: 'app-frontend-script-editor',
  templateUrl: './frontend-script-editor.component.html',
  styleUrl: './frontend-script-editor.component.scss'
})
export class FrontendScriptEditorComponent {

    _scripts: FrontendScriptDefinition[] = [];

    scriptsTree: TreeNode2[];
    scriptsTreeSelected: ArrayOrSingle<TreeNode>;
    sel: FrontendScriptDefinition;
    code = "";
    showIncluded = false;
    searchRegex = false;
    searchText = "";
    searchResults: FrontendScriptSearchResult[] = [];
    searchResultTabIndex = 0;
    selectedFound = 0;

    @ViewChild("aceEvent")
    aceEvent: AceEditorComponent;

    constructor (private app: MainAppService) {
    }

    @Input()
    public set scripts(value: FrontendScriptDefinition[]) {
        this._scripts = value;
        this.scriptsTreeSelected = null;
        this.scriptsTree = GuiUtils.generateTree(this._scripts, s => [...s.parents, s.name], (item, key, index) => ({ data: index == key.length - 1 ? item : null, label: key[index], expanded: true}), true);
    }

    selectScript(): void {
        this.sel = (this.scriptsTreeSelected as TreeNode).data as FrontendScriptDefinition;
        this.calculateCode();
    }

    syntaxCheck(): void {

    }

    save(): void {

    }

    calculateCode(): void {
        this.code = this.sel?.code;
        if (this.showIncluded) {
            for (let inc of this.sel.includes) {
                let script = this._scripts.find(script2 => script2.id == inc);
                if (script != null) {
                    this.aceEvent.addDivider(Utils.splitLines(this.code).length + 1);
                    this.code += "\n\n'!!!!!!!!!!!!!!!!!!!! INKLUDIERTES SKRIPT: " + script.name + "\n\n" + script.code;
                }
            }
        }
    }

    search(event: any): void {
        if (event == null || event.keyCode == 13) {
            let result = new FrontendScriptSearchResult(Utils.crop(this.searchText, 20, true));
            for (let script of this._scripts) {
                result.found = [...result.found, ...this.findOccurrencesInText(script.code, this.searchText, script.id, this.searchRegex)];
            }
            result.createTable(this._scripts);
            this.searchResults = [...this.searchResults, result];
            this.searchText = "";
            GuiUtils.angularTimer(() => {
                this.searchResultTabIndex = this.searchResults.length - 1;
            });
        }
    }

    goToDeclaration(name: string): void {
        let rx = new RegExp("\\s*((public|private)\\s+)?(function|sub)\\s+" + name, "gi");
        let index = 0;
        for (let line of Utils.splitLines(this.code)) {
            if (rx.exec(line) != null) {
                this.aceEvent.jumpToLine(index + 1, true);
                break;
            }
            index++;
        }
        if (!this.showIncluded) {
            for (let inc of this.sel.includes) {
                let script = this._scripts.find(script2 => script2.id == inc);
                if (script != null) {
                    index = 0;
                    for (let line of Utils.splitLines(script.code)) {
                        if (rx.exec(line) != null) {
                            this.scriptsTreeSelected = GuiUtils.flattenTree(this.scriptsTree).find(node => node.data == script);
                            this.selectScript();
                            GuiUtils.angularTimer(() => {
                                this.aceEvent.jumpToLine(index + 1, true);
                            });
                            break;
                        }
                        index++;
                    }
                }
            }
        }
    }

    findOccurrencesInText(code: string, query: string, scriptId: string, regex: boolean): FrontendScriptFound[] {
        const results: FrontendScriptFound[] = [];
        const lines = code.split('\n');
        let searchPattern = new RegExp(regex ? query : query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "gi");

        lines.forEach((line, index) => {
            let match: any;
            while ((match = searchPattern.exec(line)) !== null) {
                // Erstellen Sie den hervorgehobenen HTML-Text
                let start: number = match.index;
                let end: number = match.index + match[0].length;
                let l = line.substring(0, start) + "<span class=\"code-highlight\">" + line.substring(start, end) + "</span>" + line.substring(end);
                results.push(new FrontendScriptFound(scriptId, index + 1, l));
            }
        });

        return results;
    }

    closeSearchResultsTab(event: any): void {
        this.searchResults.splice(event.inpdex - 1, 1);
        GuiUtils.angularTimer(() => {
            this.searchResultTabIndex = 0;
        });
    }

    selectFound(event: any): void {
        let f = event.raw as FrontendScriptFound;
        let script = this._scripts.find(s => s.id == f.scriptId);
        this.scriptsTreeSelected = GuiUtils.flattenTree(this.scriptsTree).find(node => node.data == script);
        this.selectScript();
        GuiUtils.angularTimer(() => {
            this.aceEvent.jumpToLine(f.lineNumber, true);
        });
    }

    onContextMenu(sel: AceSelection): void {

        let menu: MenuItem[] = [
            {
                label: "Suche nach " + Utils.crop(sel.selection, 30, true),
                icon: "fas fa-search",
                command: _ => {
                    this.searchText = sel.selection;
                    this.search(null);
                }
            },
            {
                label: "Finde Deklaration " + Utils.crop(sel.token, 30, true),
                icon: "fas fa-search",
                command: _ => {
                    this.goToDeclaration(sel.token);
                }
            }
        ];
        this.app.openContextMenu(menu);
    }
}

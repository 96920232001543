import { Config } from "src/modules/app-template/models/config.model";

export const environment: Partial<Config> = {
	pageTitle: "DWKD Lernportal",
	backendUrl: "",
	production: true,
	activeModules: ["EnaioLearnModule"],
	showBreadcrumbs: true,
	breadcrumbsInToolBar: false,
	showSidebar: false,
	privacyPolicy: true
};

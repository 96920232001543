import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DatevRechercheGroup } from 'src/modules/enaio/shared/DatevRechercheGroup';
import { DatevRechercheType } from 'src/modules/enaio/shared/DatevRechercheType';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableCell } from 'src/modules/sm-base/models/table-cell.model';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-datev-invoice-summary',
    templateUrl: './datev-invoice-summary.component.html',
    styleUrls: ['./datev-invoice-summary.component.scss']
  })
  export class DatevInvoiceSummaryComponent extends ComponentView {

    _Utils = Utils;

    form: FrontendFormDefinition;
    result: DatevRechercheGroup[];
    table: TableData;
    source = "enaio";

    async initParams(): Promise<boolean> {
        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("fromDate", "Startdatum", FrontendFieldType.datePicker, { value: Utils.dateStartOf(new Date(), "year") })
        ]);
        return Promise.resolve(true);
    }

    async get(): Promise<void> {
        this.result = await DhTools.backendCall("api/datev/rechercheAll", { fromDate: this.form.getValue("fromDate")}).list(DatevRechercheGroup);
        this.updateTable();
    }

    updateTable(): void {
        this.table = new TableData([
            new TableColumn("action", "", TableCellType.button),
            new TableColumn("documentNumber", "Rechnungsnummer"),
            new TableColumn("amount", "Betrag"),
            new TableColumn("numEnaioObjects", "enaio Objekte", TableCellType.number),
            new TableColumn("numEnaioWorkflows", "enaio Workflows", TableCellType.number),
            new TableColumn("numDatev", "datev", TableCellType.number),
            new TableColumn("numDhLog", "DH Log", TableCellType.number),
            new TableColumn("numDhDone", "DH Done", TableCellType.number),
            new TableColumn("numDhError", "DH Error", TableCellType.number)
        ], Utils.arrayWithoutNull(this.result.map(g => {
            let numEnaioObjects = g.items.filter(item => item.type == DatevRechercheType.enaioObject).length;
            let numEnaioWorkflows = g.items.filter(item => item.type == DatevRechercheType.enaioWorkflow).length;
            let numDatev = g.items.filter(item => item.type == DatevRechercheType.datev).length;
            let numDhLog = g.items.filter(item => item.type == DatevRechercheType.dhLog).length;
            let numDhDone = g.items.filter(item => item.type == DatevRechercheType.dhDone).length;
            let numDhError = g.items.filter(item => item.type == DatevRechercheType.dhError).length;
            if (this.source == "enaio" && numEnaioObjects == 0 || this.source == "datev" && numDatev == 0) {
                return null;
            }
            return new TableRow(g, {
                action: "fas fa-cog",
                documentNumber: g.documentNumber,
                amount: g.amount,
                numEnaioObjects: new TableCell(numEnaioObjects, { styleClass: this.source != "enaio" && numEnaioObjects == 0 ? "xbg-red-400" : "" }),
                numEnaioWorkflows: new TableCell(numEnaioWorkflows, { styleClass: numEnaioWorkflows == 0 ? "xbg-red-400" : "" }),
                numDatev: new TableCell(numDatev, { styleClass: this.source != "datev" && numDatev == 0 ? "xbg-red-400" : "" }),
                numDhLog: new TableCell(numDhLog, { styleClass: numDhLog == 0 ? "xbg-red-400" : "" }),
                numDhDone: new TableCell(numDhDone, { styleClass: numDhDone + numDhError == 0 ? "xbg-red-400" : "" }),
                numDhError: new TableCell(numDhError, { styleClass: numDhDone + numDhError == 0 ? "xbg-red-400" : "" })
            });
    })), [new TableSortColumn("documentNumber"), new TableSortColumn("amount")]);

    }

    async clickCell(cell: any): Promise<void> {
        if (cell[1] == "action") {
            let item = (cell[0].raw as DatevRechercheGroup).items.filter(item2 => item2.type == DatevRechercheType.enaioObject);
            if (!Utils.isNoe(item)) {
                await DhTools.backendCall("api/enaio/openDocument", { id: item[0].id }).getText();
            }
        }
    }
}

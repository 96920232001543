<ng-container *ngIf="invalidBrowser">
    <h1>Sie verwenden einen nicht unterstützten Browser.</h1>
    <p>Diese Anwendung unterstützt Chrome, Firefox, Edge und Safari</p>
</ng-container>
<div lang="de" *ngIf="inited">
    <ng-container *ngIf="privacyPolicy && !privacyPolicyAccepted">
        <p-dialog header="Datenschutzerklärung" [(visible)]="!privacyPolicyAccepted" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"
        [draggable]="false" [resizable]="false" [closable]="false">
            <div innerHtml="{{privacyPolicyHtml}}"></div>
            <ng-template pTemplate="footer">
                <p-button icon="fas fa-times" (click)="declinePrivacyPolicy()" label="Ablehnen und ausloggen" styleClass="p-button-text"></p-button>
                <p-button icon="fas fa-check" (click)="acceptPrivacyPolicy()" label="Akzeptieren" styleClass="p-button-text"></p-button>
            </ng-template>
        </p-dialog>
    </ng-container>
    <div class="layout-wrapper" [class.layout-wrapper-menu-active]="sidebarActive" [class.layout-overlay-menu]="overlay" [class.p-input-filled]="inputStyle === 'filled'" [class.p-ripple-disabled]="!ripple">
        <p-toast position="top-center"></p-toast>
        <app-topbar [class.hidden]="!app.showTopbar"></app-topbar>
        <app-sidebar *ngIf="app.showSidebar"></app-sidebar>

        <div class="layout-main" [class.layout-main-no-sidebar]="!app.showSidebar" [class.layout-spinner]="app.showSpinner">
            <div [class.topbar-spacer]="app.showTopbar" [class.no-topbar-spacer]="!app.showTopbar">
            </div>
            <p-breadcrumb *ngIf="app.breadcrumbsVisible && !app.breadcrumbsInToolBar && !app.showSpinner" class="breadcrumbs-in-toolbar breadcrumbs" [home]="app.breadcrumbsHome" [model]="app.breadcrumbsItems"
                [style]="{background: 'white', border: '0px', padding: '1rem 1.5rem 0.5rem 1.5rem'}"></p-breadcrumb>

            <div>
                <div class="overlay" *ngIf="app.showSpinner">
                    <div class="spinner-wrapper" >
                        <p-progressSpinner class="spinner"></p-progressSpinner>

                    </div>
                </div>

                <div class="layout-main-content" [class.hidden]="app.showSpinner">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
    <p-avatar *ngIf="app.storedErrors.length > 0" class="bug-icon" icon="fas fa-bug" styleClass="mr-2" size="xlarge" shape="circle" [pTooltip]="app.storedErrors[0]" tooltipPosition="top" (click)="app.storedErrors = []"></p-avatar>
</div>
<p-contextMenu #cm class="xyz" [model]="app.contextMenu" [style.width]="'500px'" [appendTo]="'body'"></p-contextMenu>

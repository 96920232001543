import { Type } from "class-transformer";
import { PlotAxisDefinition } from "./PlotAxisDefinition";
import { PlotType } from "./PlotType";

export class PlotDefinition {
    type: PlotType;
    @Type(() => PlotAxisDefinition)
    xAxis: PlotAxisDefinition;
    @Type(() => PlotAxisDefinition)
    yAxis: PlotAxisDefinition;
}

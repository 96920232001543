export class FrontendScriptFound {
    scriptId: string;
    lineNumber: number;
    line: string;

    constructor(scriptId: string, lineNumber: number, line: string) {
        this.scriptId = scriptId;
        this.lineNumber = lineNumber;
        this.line = line;
    }
}

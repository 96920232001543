<sm-toolbar>
    <input pInputText class="p-inputtext-sm width300" type="text" placeholder="Prozess-ID" [(ngModel)]="processId"/>
    <p-button icon="fas fa-load" label="Laden" (onClick)="load()">
    </p-button>
    <span *ngIf="p != null" class="ml-5">Name: <b>{{p.name}}</b></span>
    <span *ngIf="p != null" class="ml-3">Startdatum: <b>{{p.creationTime | date:'dd.MM.yyyy HH:mm:ss'}}</b></span>
    <span *ngIf="p?.endTime != null" class="ml-3">Enddatum: <b>{{p.endTime | date:'dd.MM.yyyy HH:mm:ss'}}</b></span>
</sm-toolbar>
<p-tabView *ngIf="p != null">
    <p-tabPanel header="Variablenbaum">
        <p-treeTable [value]="variablesTree" [style.height]="'700px'">
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Value</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr [ttRow]="rowNode" [ttContextMenuRow]="rowNode">
                    <td>
                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                        {{rowData.key}}
                    </td>
                    <td>{{rowData.value}}</td>
                </tr>
            </ng-template>
        </p-treeTable>
    </p-tabPanel>
    <p-tabPanel header="Historie">
        <sm-toolbar>
            <p-checkbox name="showVariables" [(ngModel)]="showVariables" [binary]="true" (ngModelChange)="fillTable()"></p-checkbox>
            <label class="form-label mr-3">Variablen anzeigen</label>
            <p-checkbox name="unrollVariables" [(ngModel)]="unrollVariables" [binary]="true" (ngModelChange)="fillTable()"></p-checkbox>
            <label class="form-label mr-3">Variablen entrollen</label>
            <p-checkbox name="onlyChanges" [(ngModel)]="onlyChanges" [binary]="true" (ngModelChange)="fillTable()"></p-checkbox>
            <label class="form-label mr-3">Nur Änderungen anzeigen</label>
            <p-checkbox name="markChanges" [(ngModel)]="markChanges" [binary]="true" (ngModelChange)="fillTable()"></p-checkbox>
            <label class="form-label mr-3">Änderungen markieren</label>
            <p-checkbox name="onlyUserActivities" [(ngModel)]="onlyUserActivities" [binary]="true" (ngModelChange)="fillTable()"></p-checkbox>
            <label class="form-label mr-3">Nur Benutzer-Aktivitäten</label>
        </sm-toolbar>
        <sm-table-on-push [data]="historyTable" [deltaHeight]=260 (onCellEdited)="cellEdited($event)">
        </sm-table-on-push>
    </p-tabPanel>
</p-tabView>

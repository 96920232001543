import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { CipherMateConfig } from "./CipherMateConfig";
import { EnaioAssignment } from "./EnaioAssignment";
import { EnaioCall } from "./EnaioCall";
import { EnaioCpmQueueConditions } from "./EnaioCpmQueueConditions";
import { EnaioDocument } from "./EnaioDocument";
import { EnaioInsertVariantData } from "./EnaioInsertVariantData";
import { EnaioLocation } from "./EnaioLocation";
import { EnaioObjectId } from "./EnaioObjectId";
import { EnaioRemarkObject } from "./EnaioRemarkObject";
import { EnaioTableAssignment } from "./EnaioTableAssignment";
import { EnaioUpdateMultipleStrategy } from "./EnaioUpdateMultipleStrategy";
import { EnaioUpsertResult } from "./EnaioUpsertResult";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioCallUpdate extends EnaioCall <EnaioUpsertResult> {
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    @Type(() => EnaioLocation)
    location: EnaioLocation;
    @Type(() => EnaioAssignment)
    fields: EnaioAssignment[];
    @Type(() => EnaioTableAssignment)
    tableFields: EnaioTableAssignment[];
    documentFileName: string;
    documentBase64: string;
    documentUseExtension: string;
    insertIfMissing = false;
    @Type(() => EnaioLocation)
    locationIfMissing: EnaioLocation;
    replaceTableFields = true;
    onlyUpdateIfChanged = true;
    documentConsideredChange = true;
    investigateChanges = false;
    @Type(() => EnaioDocument)
    compareToExisting: EnaioDocument[];
    updateId = 0;
    linkToId = 0;
    linkToIdOsFile = 0;
    additionalLocationIds: number[];
    deleteAdditionalLocationIds: number[];
    moveToLocation = 0;
    moveFromLocationHint = 0;
    deleteDocument = false;
    intoWfTray = false;
    typeless = false;
    typelessMainType = 0;
    replaceExistingFiles = true;
    @Type(() => EnaioRemarkObject)
    remarkObjects: EnaioRemarkObject[];
    @Type(() => EnaioInsertVariantData)
    insertVariant: EnaioInsertVariantData;
    @Type(() => CipherMateConfig)
    cipherMateEncrypt: CipherMateConfig;
    setArchivable = false;
    autoTruncateColumns = false;
    ignoreAll = false;
    ignoreInvalidCatalogueValues = false;
    ignoreEmptyMandatoryFields = false;
    ignoreMissingUserRights = false;
    ignoreKeyFields = false;
    ignoreReadOnlyFields = false;
    @Type(() => EnaioObjectId)
    insertOnlyFields: EnaioObjectId[];
    mailImport = false;
    updateMultipleStrategy: EnaioUpdateMultipleStrategy;
    rawOptions: OrdinaryObject<string>;
    @Type(() => EnaioCpmQueueConditions)
    cpmQueueConditions: EnaioCpmQueueConditions;
    cpmQueueImmediatelyRemove = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioUpsertResult);
    }

}

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FrontendFormDefinition } from '../../models/frontend-form-definition.model';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
@Component({
  selector: 'app-crud-edit',
  templateUrl: './crud-edit.component.html',
  styleUrls: ['./crud-edit.component.scss']
})
export class CrudEditComponent extends ComponentView {

    @Input()
    form: FrontendFormDefinition;

    @Output()
    save = new EventEmitter();

    saveInternal(): void {
        this.save.emit(null);
    }

    cancel(): void {
        this.app.navigateBack();
    }

}

import { Component, Input } from '@angular/core';
import { GenericFieldDefinition } from '../../models/generic-field-definition.model';
import { FrontendFormDefinition } from '../../models/frontend-form-definition.model';
import { FrontendFieldDefinition } from '../../models/frontend-field-definition.model';
import { FrontendFieldType } from '../../models/frontend-field-type.enum';
import { FrontendFieldListItem } from '../../models/frontend-field-list-item.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { PlotDefinition } from 'src/modules/enaio/shared/PlotDefinition';
import { PlotType } from 'src/modules/enaio/shared/PlotType';
import { PlotReducer } from 'src/modules/enaio/shared/PlotReducer';

@Component({
  selector: 'app-plot-definition',
  templateUrl: './plot-definition.component.html',
  styleUrl: './plot-definition.component.scss'
})
export class PlotDefinitionComponent {

    @Input()
    fields: GenericFieldDefinition[];
    @Input()
    plot: PlotDefinition;

    form: FrontendFormDefinition;

    public generateForm(): void {
        let reducerListItems = [
            new FrontendFieldListItem(PlotReducer.none, "Keiner"),
            new FrontendFieldListItem(PlotReducer.count, "Anzahl")
        ];

        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("xAxisLabel", "X-Achse", FrontendFieldType.text, { hasControl: false }),
            new FrontendFieldDefinition("xAxis.field", "Feld", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: Utils.arraySortBy(this.fields, field => field.name).map(field => new FrontendFieldListItem(field, field.name))}),
            new FrontendFieldDefinition("xAxis.reducer", "Aggregat", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: reducerListItems}),
            new FrontendFieldDefinition("xAxis.field2", "Feld 2 (optional)", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: Utils.arraySortBy(this.fields, field => field.name).map(field => new FrontendFieldListItem(field, field.name))}),
            new FrontendFieldDefinition("yAxisLabel", "Y-Achse", FrontendFieldType.text, { hasControl: false }),
            new FrontendFieldDefinition("yAxis.field", "Feld", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: Utils.arraySortBy(this.fields, field => field.name).map(field => new FrontendFieldListItem(field, field.name))}),
            new FrontendFieldDefinition("yAxis.reducer", "Aggregat", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: reducerListItems}),
            new FrontendFieldDefinition("yAxis.field2", "Feld 2 (optional)", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: Utils.arraySortBy(this.fields, field => field.name).map(field => new FrontendFieldListItem(field, field.name))}),
            new FrontendFieldDefinition("type", "Diagrammtyp", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: [
                new FrontendFieldListItem(PlotType.line, "Linie"),
                new FrontendFieldListItem(PlotType.area, "Fläche"),
                new FrontendFieldListItem(PlotType.barY, "Balken vertikal"),
                new FrontendFieldListItem(PlotType.barX, "Balken horizontal"),
                new FrontendFieldListItem(PlotType.dot, "Punkte"),
                new FrontendFieldListItem(PlotType.tickY, "Tick vertikal"),
                new FrontendFieldListItem(PlotType.tickX, "Tick horizontal"),
                new FrontendFieldListItem(PlotType.pie, "Torte")
            ]})
        ]);
        this.form.fill(this.plot);
    }

    public getDefinition(): PlotDefinition {
        this.form.get(this.plot);
        return this.plot;
    }
}

import { Component, ViewChild, inject } from '@angular/core';
import { Tree } from 'primeng/tree';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { TreeSelectionHelper } from 'src/modules/utils/misc/tree-selection-helper';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegisterSettings } from '../../shared/class-register-settings.entity';
import { CourseParticipant } from '../../shared/course-participant.entity';
import { Course } from '../../shared/course.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';
import { Pupil } from '../../shared/pupil.model';
import { SchoolType } from '../../shared/school-type.enum';
import { Teacher } from '../../shared/teacher.model';

@Component({
  selector: 'app-edit-class-course',
  templateUrl: './edit-class-course.component.html',
  styleUrls: ['./edit-class-course.component.scss']
})
export class EditClassCourseComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    year: number;
    id: number;

    item: Course;
    form: FrontendFormDefinition;
    pupils: Pupil[];
    teachers: Teacher[];
    settings: ClassRegisterSettings;

    pupilsTree: TreeSelectionHelper;

    @ViewChild("#tree") tree: Tree;

    constructor() {
        super();
        this.neededParams = { year: "number?", id: "number" };
    }

    async initParams(): Promise<boolean> {
        if (this.year == null) {
            this.year = EnaioCertificatesTools.getCurrentYear();
        }
        this.item = await RestEndpoint.main().findByIdOrEmpty(Course, this.id).run("api/cer/classregister/course").get(Course);

        if (this.pupils == null) {
            this.pupils = await this.service.restGetPupilsForYear(EnaioCertificatesTools.getSchoolYearFromYear(this.year));
        }
        if (this.teachers == null) {
            this.teachers = await this.service.restGetTeachers();
        }
        if (this.settings == null) {
            this.settings = await this.service.restGetClassRegisterSettings(this.year, false); //Warum konstant false?
        }

        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("type", "Schulzweig", FrontendFieldType.comboBox, {
                mandatory: true,
                dropdownEditable: false,
                listItems: Utils.arrayMapWithIndex(EnaioCertificatesTools.getSchoolTypeNames(), (n, index) => new FrontendFieldListItem(index, n)),
                onValueChanged: this.changeSchoolType.bind(this)
            }),
            new FrontendFieldDefinition("title", "Bezeichnung", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("group", "Gruppe", FrontendFieldType.text),
            new FrontendFieldDefinition("instructor", "Kursleiter", FrontendFieldType.comboBox, {
                mandatory: true,
                dropdownEditable: false,
                listItems: this.teachers.map(t => new FrontendFieldListItem(t.userName, t.getDisplayName()))
            }),
            new FrontendFieldDefinition("endDate", "Enddatum", FrontendFieldType.datePicker),
            new FrontendFieldDefinition("forCertificate", "Zeugnisrelevant", FrontendFieldType.checkBox, {
                onValueChanged: this.changeForCertificate.bind(this)
            }),
            new FrontendFieldDefinition("titleForCertificate", "Abweichende Bezeichnung im Zeugnis", FrontendFieldType.text),
            new FrontendFieldDefinition("forGrades", "Klassenstufen", FrontendFieldType.listBox, {
                listBoxCheckBoxes: true,
                listItems: Utils.getRange(1, 13).map(g => new FrontendFieldListItem(g, "Klassenstufe " + g)),
                onValueChanged: this.updatePupilsTree.bind(this)
            })
        ]);

        this.form.fill(this.item);
        this.changeSchoolType();
        this.changeForCertificate();
        this.pupilsTree = new TreeSelectionHelper(this.tree);
        this.updatePupilsTree();
        this.pupilsTree.setSelected(this.item.participants.map(p => p.pupilId));

        this.app.updateNavigation("Kursbuch", [
            this.service.getHomeCourses(),
            { label: this.item.id == 0 ? "Neu" : this.item.title, routerLink: ["/enaio-certificates", "class-registers", "course", this.item.id] }
        ]);
        return true;
    }

    changeForCertificate(): void {
        this.form.getField("titleForCertificate").visible = this.form.getValue("forCertificate");
    }

    changeSchoolType(): void {
        let type = this.form.getValue("type") as SchoolType;
        this.form.getField("instructor").listItems = this.teachers.filter(t => t.isFor(type, this.settings)).map(t => new FrontendFieldListItem(t.userName, t.getDisplayName()));
    }

    updatePupilsTree(): void {
        if (this.pupils == null) {
            return;
        }
        let sel = this.form.getField("forGrades").value as any[];
        let items: TreeNode2[] = EnaioCertificatesTools.getAllGrades().filter(g => sel.includes(g)).map(g => ({ label: "Klassenstufe " + g, data: "g" + g, children: [] }));
        for (let pupil of this.pupils) {
            let n1 = items.find(node => node.data == "g" + pupil.grade);
            if (n1 != null) {
                let n2 = n1.children.find(node => node.data == "c" + pupil.grade + pupil.title);
                if (n2 == null) {
                    n2 = { label: pupil.getClassDisplayName(), data: "c" + pupil.grade + pupil.title, children: [] };
                    n1.children.push(n2);
                }
                let n3 = {label: pupil.getFullName(), data: pupil.id};
                n2.children.push(n3);
            }
        }
        for (let node of items) {
            node.children = Utils.arraySortBy(node.children, n => n.label);
            for (let node2 of node.children) {
                node2.children = Utils.arraySortBy(node2.children, n => n.label);
            }
        }
        this.pupilsTree.setItems(items, true);
    }

    async save(): Promise<void> {
        if (!this.app.validateForm(this.form)) {
            return;
        }

        this.form.get(this.item);
        if (this.item.id == 0) {
            this.item.year = this.year;
            this.item.isHalfYear = false;
        }
        this.item.participants = this.pupilsTree.getSelected().filter(id => Utils.isNumber(id)).map(id => Utils.fromPlain(CourseParticipant, { pupilId: id}));
        await this.app.saveDataHandler(async () => RestEndpoint.main().upsert(this.item).run("api/cer/classregister/course").getText(), this, true, true);
    }

    cancel(): void {
        this.app.navigateBack();
    }
}


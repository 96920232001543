/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { Competency } from "src/modules/enaio-certificates/shared/competency.model";
import { Pupil } from "src/modules/enaio-certificates/shared/pupil.model";
import { CustomInitializer } from "src/modules/sm-base/shared/custom-initializer.interface";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, OneToMany, PrimaryColumn } from "typeorm";
import { ConductGrade } from "./conduct-grade.entity";
import { Exam } from "./exam.entity";
import { GradeHistory } from "./grade-history.entity";
import { SchoolClass } from "./school-class.model";
import { SchoolTypeSettings } from "./school-type-settings.entity";
import { Map2 } from "src/modules/utils/shared/map2.model";

@Entity()
export class GradeBookSchoolClass implements CustomInitializer {
    @PrimaryColumn()
    id: number = 0;

    @Column({default: false})
    isHalfYear: boolean = false;

    @OneToMany(() => Exam, o => o.schoolClass)
    @Type(() => Exam)
    exams: Exam[];

    @OneToMany(() => ConductGrade, o => o.schoolClass)
    @Type(() => ConductGrade)
    conductGrades: ConductGrade[];

    @OneToMany(() => GradeHistory, o => o.schoolClass)
    @Type(() => GradeHistory)
    history: GradeHistory[];

    customInitializer(): void {
        this.exams = Utils.arrayEnsure(this.exams);
        this.conductGrades = Utils.arrayEnsure(this.conductGrades);
        this.history = Utils.arrayEnsure(this.history);
        this.exams.forEach(e => e.customInitializer());
    }

    fillMissingGrades(pupils: Pupil[], conductCompetencies: Competency[]): void {
        for (let exam of this.exams) {
            exam.fillMissingGrades(pupils);
        }

        for (let pupil of pupils) {
            for (let competency of conductCompetencies) {
                if (!this.conductGrades.some(g => g.pupilId == pupil.id && g.competencyId == competency.id)) {
                    this.conductGrades.push(Utils.fromPlain(ConductGrade, { pupilId: pupil.id, competencyId: competency.id }));
                }
            }
        }
    }

    getConductGradeForPupil(pupilId: number, competencyId: number): ConductGrade {
        return this.conductGrades.find(g => g.pupilId == pupilId && g.competencyId == competencyId) || new ConductGrade();
    }

    getConductGradeMap(): { [key: string]: ConductGrade } {
        let result: { [key: string]: ConductGrade } = {};
        for (let g of this.conductGrades) {
            result[g.pupilId.toString() + "|" + g.competencyId.toString()] = g;
        }
        return result;
    }

    getPupilAverage(settings: SchoolTypeSettings, pupilId: number, schoolClass: SchoolClass, domain: string = null): number {
        let count = 0;
        let sum = 0;
        let byDomain = Utils.arrayToMultiMapKeys(this.exams, e => e.domain);
        for (let d of Utils.getOwnPropertyNames(byDomain)) {
            if (domain != null && d != domain) {
                continue;
            }
            let ds = settings.domainSettings.find(ds2 => ds2.domain == d);
            let byTypeId = Utils.arrayToMultiMapKeys(byDomain[d], e => e.examType == null ? "0" : Utils.toString(e.examType.id));
            let typeGgn = byDomain[d].find(e => e.examType?.title == "gGN")?.examType;
            let typeKlausur = byDomain[d].find(e => e.examType?.title == "Klausuren")?.examType;
            let ggnFactor = settings.getGgnFactor(domain, schoolClass.grade);
            let kgnFactor = settings.getKgnFactor(domain, schoolClass.grade);
            for (let typeId of Utils.getOwnPropertyNames(byTypeId)) {
                let factor = ds != null && typeId != null ? ds.getExamTypeFactor(Utils.toNumber(typeId)) : 1;
                if (schoolClass.usesExamTypeCalculation()) {
                    let tid = Utils.toNumber(typeId);
                    factor = tid == typeGgn?.id || tid == typeKlausur?.id ? ggnFactor : kgnFactor;
                }
                let subCount = 0;
                let subSum = 0;
                for (let exam of byTypeId[typeId]) {
                    let pupil = exam.grades.find(g => g.pupilId == pupilId);
                    if (pupil?.isRated()) {
                        subCount += 1;
                        subSum += pupil.grade;
                    }
                }
                if (subCount > 0) {
                    let subAvg = subSum / subCount;
                     count += factor;
                    sum += subAvg * factor;
                }
            }
        }

        return count == 0 ? null : sum / count;
    }

    getPupilAverageTextualGrade(settings: SchoolTypeSettings, pupilId: number, grade: number, schoolClass: SchoolClass, domain: string = null): number {
        let count = 0;
        let sum = 0;
        let gs = settings.getGradeSettings(grade);
        let byDomain = Utils.arrayToMultiMapKeys(this.exams, e => e.domain);
        for (let d of Utils.getOwnPropertyNames(byDomain)) {
            if (domain != null && d != domain) {
                continue;
            }
            let ds = settings.domainSettings.find(ds2 => ds2.domain == d);
            let byTypeId = Utils.arrayToMultiMapKeys(byDomain[d], e => e.examType == null ? "0" : Utils.toString(e.examType.id));
            let typeGgn = byDomain[d].find(e => e.examType?.title == "gGN")?.examType;
            let typeKlausur = byDomain[d].find(e => e.examType?.title == "Klausuren")?.examType;
            let ggnFactor = settings.getGgnFactor(domain, schoolClass.grade);
            let kgnFactor = settings.getKgnFactor(domain, schoolClass.grade);
            for (let typeId of Utils.getOwnPropertyNames(byTypeId)) {
                let factor = ds != null && typeId != null ? ds.getExamTypeFactor(Utils.toNumber(typeId)) : 1;
                if (schoolClass.usesExamTypeCalculation()) {
                    let tid = Utils.toNumber(typeId);
                    factor = tid == typeGgn?.id || tid == typeKlausur?.id ? ggnFactor : kgnFactor;
                }
                let subCount = 0;
                let subSum = 0;
                for (let exam of byTypeId[typeId]) {
                    let pupil = exam.grades.find(g => g.pupilId == pupilId);
                    if (pupil?.isRated()) {
                        let tg = gs.getTextualGrade(pupil.grade, true);
                        let g = tg == "" ? 0 : Utils.toNumber(tg);
                        subCount += 1;
                        subSum += g;
                    }
                }
                if (subCount > 0) {
                    let subAvg = subSum / subCount;
                    count += factor;
                    sum += subAvg * factor;
                }
            }
        }

        return count == 0 ? null : sum / count;
    }

    getPupilAverageOld(settings: SchoolTypeSettings, pupilId: number, domain: string = null): number {
        let count = 0;
        let sum = 0;
        for (let exam of this.exams) {
            if (domain != null && exam.domain != domain) {
                continue;
            }
            let ds = settings.domainSettings.find(ds2 => ds2.domain == exam.domain);
            let factor = ds != null && exam.examType != null ? ds.getExamTypeFactor(exam.examType.id) : 1;
            let pupil = exam.grades.find(g => g.pupilId == pupilId);
            if (pupil?.isRated()) {
                count += factor;
                sum += pupil.grade * factor;
            }
        }

        return count == 0 ? null : sum / count;
    }

    getPupilAverageTextualGradeOld(settings: SchoolTypeSettings, pupilId: number, grade: number, domain: string = null): number {
        let count = 0;
        let sum = 0;
        let gs = settings.getGradeSettings(grade);
        for (let exam of this.exams) {
            if (domain != null && exam.domain != domain) {
                continue;
            }
            let ds = settings.domainSettings.find(ds2 => ds2.domain == exam.domain);
            let factor = ds != null && exam.examType != null ? ds.getExamTypeFactor(exam.examType.id) : 1;
            let pupil = exam.grades.find(g => g.pupilId == pupilId);
            if (pupil?.isRated()) {
                let tg = gs.getTextualGrade(pupil.grade, true);
                let g = tg == "" ? 0 : Utils.toNumber(tg);
                count += factor;
                sum += g * factor;
            }
        }

        return count == 0 ? null : sum / count;
    }

    getPupilConductAverage(pupilId: number): number {
        let count = 0;
        let sum = 0;
        for (let g of this.conductGrades) {
            if (g.pupilId == pupilId && g.isRated()) {
                count++;
                sum += g.grade;
            }
        }

        return count == 0 ? null : sum / count;
    }

    getPupilAverageMap(settings: SchoolTypeSettings, schoolClass: SchoolClass): OrdinaryObject<number> {
        let result: OrdinaryObject<number> = {};
        let domains = Utils.arrayGetUnique(this.exams.map(e => e.domain));
        let pupils = Utils.arrayGetUnique([...Utils.arrayExplode(this.exams, e => e.grades).map(g => g.pupilId), ...this.conductGrades.map(g => g.pupilId)]);
        let byPupil = new Map2<number, number[]>();
        for (let pupil of pupils) {
            for (let domain of domains) {
                let avg = this.getPupilAverage(settings, pupil, schoolClass, domain);
                result[pupil + "|" + domain] = avg;
                if (avg > 0) {
                    byPupil.getOrAdd(pupil, () => []).push(avg);
                }
            }
            result[pupil + "|conduct"] = this.getPupilConductAverage(pupil);
        }
        for (let pupil of byPupil.keys()) {
            result[pupil + "|"] = Utils.arraySum(byPupil.get(pupil)) / Math.max(1, byPupil.get(pupil).length);
        }
        return result;
    }

    getPupilAverageMapTextualGrade(settings: SchoolTypeSettings, grade: number, schoolClass: SchoolClass): OrdinaryObject<number> {
        let result: OrdinaryObject<number> = {};
        let domains = Utils.arrayGetUnique(this.exams.map(e => e.domain));
        let pupils = Utils.arrayGetUnique([...Utils.arrayExplode(this.exams, e => e.grades).map(g => g.pupilId), ...this.conductGrades.map(g => g.pupilId)]);
        let byPupil = new Map2<number, number[]>();
        for (let pupil of pupils) {
            for (let domain of domains) {
                let avg = this.getPupilAverageTextualGrade(settings, pupil, grade, schoolClass, domain);
                result[pupil + "|" + domain] = avg;
                if (avg > 0) {
                    byPupil.getOrAdd(pupil, () => []).push(avg);
                }
            }
        }
        for (let pupil of byPupil.keys()) {
            result[pupil + "|"] = Utils.arraySum(byPupil.get(pupil)) / Math.max(1, byPupil.get(pupil).length);
        }
        return result;
    }

}

import { Component, inject } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { Competency } from 'src/modules/enaio-certificates/shared/competency.model';
import { Pupil } from 'src/modules/enaio-certificates/shared/pupil.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { CertificateGrade } from '../../shared/certificate-grade.entity';
import { Certificate } from '../../shared/certificate.entity';
import { CourseParticipantDto } from '../../shared/course-participant.dto';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';
import { GlobalSettings } from '../../shared/global-settings.entity';
import { GradeBookSchoolClass } from '../../shared/grade-book-school-class.entity';
import { GradeType } from '../../shared/grade-type.enum';
import { GuiCertificate } from '../../shared/gui-certificate.model';
import { GuiMarkAssignment } from '../../shared/gui-mark-assignment.model';
import { SchoolClass } from '../../shared/school-class.model';
import { SchoolTypeSettings } from '../../shared/school-type-settings.entity';
import { SchoolType } from '../../shared/school-type.enum';

export class GuiDomainGrade {
    constructor(public domain: string, public label: string, public group: string, public average: number, public o: CertificateGrade, public firstOfGroup = false) {
        this.average = this.average == null ? null : Utils.toNumber(Utils.restrictDecimalDigits(this.average, 2));
    }
}

@Component({
    selector: 'app-edit-certificate',
    templateUrl: './edit-certificate.component.html',
    styleUrls: ['./edit-certificate.component.scss']
})
export class EditCertificateComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    _Utils = Utils;
    _SchoolType = SchoolType;
    _GradeType = GradeType;

    schoolClassId = 0;
    isHalfYear = false;
    pupilId = 0;

    isAdmin = false;
    loaded = false;
    nothingFound = false;
    schoolClass: SchoolClass;
    pupil: Pupil;
    editingAllowed: boolean = null;
    settings: SchoolTypeSettings;
    certificate: Certificate;
    isGradeCertificate = false;
    competencies: Competency[] = [];
    certificateGradeItems: FrontendFieldListItem[];
    gradeBookSchoolClass: GradeBookSchoolClass;
    courses: CourseParticipantDto[];
    grades: GuiDomainGrade[];
    guiCertificate: GuiCertificate;
    originalGuiCertificate: GuiCertificate;
    domains: SelectItem[] = [];
    selectedDomain = "";
    markNames: string[];
    addTeacherNameForDomains: string[] = [];
    lastYearCertId = 0;

    keepSelectedDomain = false;

    constructor() {
        super();
        this.neededParams = { schoolClassId: "number", isHalfYear: "bool", pupilId: "number" };
    }

    async initParams(): Promise<boolean> {
        await this.init();
        return true;
    }

    async init(updateSelectedDomain = true): Promise<void> {
        if (this.keepSelectedDomain) {
            this.keepSelectedDomain = false;
            updateSelectedDomain = false;
        }

        this.isAdmin = await this.service.isAdminForCertificates();
        this.loaded = false;
        this.nothingFound = false;

        let restResult = await Promise.all([
            RestEndpoint.main().find({ schoolClassId: this.schoolClassId, pupilId: this.pupilId, isHalfYear: this.isHalfYear }).run("api/cer/certificate").get(Certificate),
            this.service.restGetPupil(this.pupilId),
            this.service.restGetSchoolClass(this.schoolClassId),
            RestEndpoint.main().find({}).run("api/cer/globalsettings").get(GlobalSettings)
        ]);

        this.pupil = restResult[1];
        this.schoolClass = restResult[2];
        this.editingAllowed = restResult[3].editingAllowed;
        this.app.updateNavigation("Zeugnis bearbeiten für " + this.pupil.getFullName(), [
            this.service.getHomeCertificates(),
            { label: this.pupil.grade + this.pupil.title, routerLink: ["/enaio-certificates/pupils", this.schoolClassId, this.isHalfYear] },
            { label: this.pupil.getFullName(), routerLink: ["/enaio-certificates/certificate", this.schoolClassId, this.isHalfYear, this.pupilId] }
        ]);

        this.settings = await RestEndpoint.main().find({schoolType: this.schoolClass.getSchoolType(), year: this.schoolClass.getYearNumber(), isHalfYear: this.isHalfYear}).run("api/cer/schooltypesettings").get(SchoolTypeSettings);

        let hiddenCompetencies: number[] = [];
        if (this.settings.canCopyLastYear(this.schoolClass.grade)) {
            this.lastYearCertId = await RestEndpoint.main().query({ pupilId: this.pupil.id, schoolClassId: this.schoolClass.id, isHalfYear: this.isHalfYear}).run("api/cer/certificate/canCopyLastYear").getNumber();
            if (restResult[0] == null && this.lastYearCertId > 0 && await this.app.messageDialog.yesNo("Möchten Sie die ausgewählten Kompetenzen für dieses Zeugnis aus dem vorherigen Schuljahr übernehmen?", "Übernahme")) {
                hiddenCompetencies = await this.loadHiddenCompetencies();
            }
        }

        this.certificate = restResult[0] || new Certificate();
        this.certificate.customInitializer();
        this.certificate.schoolClassId = this.schoolClassId;
        this.certificate.pupilId = this.pupilId;
        this.certificate.isHalfYear = this.isHalfYear;
        this.isGradeCertificate = this.schoolClass.usesGradeCertificates();

        this.competencies = restResult[0] == null || this.isGradeCertificate ?
            await RestEndpoint.main().find({ schoolClassId: this.schoolClassId, pupilId: this.pupilId }).run("api/cer/competency").list(Competency) : null;

        if (this.isGradeCertificate || this.pupil.secondForeignLanguage == "WPU") {
            this.courses = (await RestEndpoint.main().query({year: this.schoolClass.getYearNumber(), isHalfYear: this.isHalfYear, pupilIds: this.pupilId}).run("api/cer/classregister/coursesforpupils").list(CourseParticipantDto)).filter(c => c.forCertificate);
        }

        this.calculate(hiddenCompetencies);

        if (this.isGradeCertificate) {
            this.certificateGradeItems = ["", "1", "2", "3", "4", "5", "6", "erteilt", "nicht bewertbar"].map(gi => new FrontendFieldListItem(gi, gi));
            this.gradeBookSchoolClass = await RestEndpoint.main().find({ schoolClassId: this.schoolClassId, isHalfYear: this.isHalfYear }).run("api/cer/gradebookschoolclass").get(GradeBookSchoolClass);
            let averageGrades = this.gradeBookSchoolClass.getPupilAverageMap(this.settings, this.schoolClass);
            let domainGroups = EnaioCertificatesTools.getDomainGroupsForMarkCertificates();

            let domains = Utils.arraySort(Utils.arrayGetUnique(this.competencies.filter(c => !c.isConductGrade()).map(c => c.domain)));
            let plainDomains = Utils.arrayGetUnique([
                "Fleiß",
                "Zuverlässigkeit",
                "Umgangsformen",
                "Teamfähigkeit",
                ...domains,
                ...this.certificate.certificateGrades.filter(course => this.courses.some(c => c.courseId == course.id)).map(cg => cg.domain),
                ...this.courses.map(course => "course-" + course.courseId)]);
            if (this.courses.length > 0 && !plainDomains.includes("course-total")) {
                plainDomains.push("course-total");
            }
            if (this.courses.length == 0 && plainDomains.includes("course-total")) {
                plainDomains = Utils.arrayWithout(plainDomains, "course-total");
            }

            plainDomains = Utils.arrayWithout(plainDomains, "Kompetenzentwicklung");
            //Kleiner HACK. Muss noch rausfinden wieso diese beiden nicht automatisch reinkommen
            if (!plainDomains.includes("Sport")) {
                plainDomains.push("Sport");
            }
            if (!plainDomains.includes("Musik")) {
                plainDomains.push("Musik");
            }
            let d = Utils.arraySort(plainDomains.map(domain => ({ domain, group: EnaioCertificatesTools.getDomainGroupForMarkCertificate(domain)})),
                (domain1, domain2) => Utils.cmpMulti([domainGroups.indexOf(domain1.group), domainGroups.indexOf(domain2.group), domain1.domain, domain2.domain]));

            this.grades = d.map(domain => new GuiDomainGrade(domain.domain,
                domain.domain == "course-total" ? "Wahlpflichtunterricht Gesamt" : domain.domain.startsWith("course-") ? this.courses.find(course => course.courseId == Utils.toNumber(Utils.stringRemovePrefix(domain.domain, "course-")))?.courseNameForCertificate ?? domain.domain : domain.domain,
                domain.group, Utils.getPropertyDef(averageGrades, this.pupil.id + "|" + domain.domain, null) as number,
                this.certificate.certificateGrades.find(g => g.pupilId == this.pupil.id && g.domain == domain.domain) ?? new CertificateGrade(this.pupil.id, domain.domain)));

            for (let i = 0; i < this.grades.length; i++) {
                if (i == 0 || this.grades[i - 1].group != this.grades[i].group) {
                    this.grades[i].firstOfGroup = true;
                }
            }
        }
        else {
            this.gradeBookSchoolClass = null;
        }

        if (this.guiCertificate.domains.size() > 0) {
            if (updateSelectedDomain || !this.guiCertificate.domains.has(this.selectedDomain)) {
                this.selectedDomain = this.domains[0].value;
            }
            this.selectedDomainChanged();
            this.loaded = true;
        }
        else {
            this.nothingFound = true;
        }
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? "" : null;
    }

    isChanged(): boolean {
        const updated = this.getUpdateCertificate();
        return updated.marks.length > 0 || updated.domains.length > 0;
    }

    getUpdateCertificate(forceFull = false): Certificate {
        let result = Certificate.fromGui(this.guiCertificate);
        result.certificateGrades = this.grades != null ? this.grades.map(g => g.o) : [];
        for (let domain of this.addTeacherNameForDomains) {
            result.domains.find(d => d.domain == domain).teacher = this.app.getUserName();
        }
        if (result.id === 0 || forceFull) {
            return result;
        }

        let oldCert = Certificate.fromGui(this.originalGuiCertificate);
        result.marks = Utils.getOnlyUpdatedEntries(result.marks, oldCert.marks, (item1, item2) => item1.id === item2.id, (item1, item2) => item2.wasUpdated(item1));
        result.domains = Utils.getOnlyUpdatedEntries(result.domains, oldCert.domains, (item1, item2) => item1.id === item2.id, (item1, item2) => item2.wasUpdated(item1));

        return result;
    }

    selectedDomainChanged(): void {
        this.markNames = EnaioCertificatesTools.getMarkNames(this.schoolClass.getSchoolType(), this.schoolClass.grade, this.selectedDomain, this.settings, true).slice(1);
    }

    calculate(hiddenCompetencies: number[]): void {
        this.guiCertificate = this.certificate.toGui(this.competencies, hiddenCompetencies, this.pupil.secondForeignLanguage, this.courses);
        this.originalGuiCertificate = this.certificate.toGui(this.competencies);
        let domainNames = Utils.arrayFromIt(this.guiCertificate.domains.values()).filter(domain => domain.hasAnyValidMarks(this.pupil)).sort((a, b) => Utils.cmp(a.getSortPriority(), b.getSortPriority())).map(s => s.domain);
        this.domains = domainNames.map(s => ({ label: s, value: s }));
    }

    async preview(): Promise<void> {
        if (await this.app.messageDialog.show("Das Speichern oder Drucken dieses Dokumentes ist strengstens untersagt!", "Warnung", [this.app.messageDialog.cancel, this.app.messageDialog.confirm]) !== "confirm") {
            return;
        }

        let id = await RestEndpoint.main().post().body(this.getUpdateCertificate(true)).run("api/cer/certificate/generatedocumentfordata").get(Certificate);
        window.open(RestEndpoint.main().query({id: id.id}).action("api/cer/certificate/generateddocument").generateUrl());
    }

    async saveDocument(): Promise<void> {
        if (await this.app.messageDialog.yesNo("Soll das Zeugnis als Dokument in enaio abgelegt werden?", "Bestätigung")) {
            this.app.showSpinner = true;
            await this.save();
            await RestEndpoint.main().query({ id: this.guiCertificate.id, forHalfYear: EnaioCertificatesTools.getCertificateIsForHalfYear(new Date()) }).run("api/cer/certificate/savedocument").getText();
            this.app.showSpinner = false;
        }
    }

    async saveClick(plusPupilIndex: number): Promise<void> {
        this.app.showSpinner = true;
        await this.save(plusPupilIndex);
        this.app.showSpinner = false;
    }

    async refresh(): Promise<void> {
        if (this.isChanged()) {
            await this.app.messageDialog.info("Bitte speichern Sie zuerst vorgenommene Änderungen");
            return;
        }

        this.app.showSpinner = true;
        let changes = await RestEndpoint.main().query({id: this.certificate.id, previewOnly: true}).run("api/cer/certificate/refresh").listStrings();
        if (changes.length === 0) {
            this.app.showSpinner = false;
            await this.app.messageDialog.info("Es wurden keine Änderungen in den zugrundeliegenden Kompetenzen festgestellt. Das Zeugnis bleibt unverändert");
        }
        else {
            this.app.showSpinner = false;
            if (await this.app.messageDialog.yesNo("Folgende Änderungen werden vorgenommen. Fortfahren?<br><br>" + Utils.arrayItemsToString(changes, "<br>"), "Bestätigung")) {
                this.app.showSpinner = true;
                await RestEndpoint.main().query({id: this.certificate.id, previewOnly: false}).run("api/cer/certificate/refresh").getText();
                await this.init(false);
                this.app.showSpinner = false;
            }
        }

        this.app.showSpinner = false;
    }

    async save(plusPupilIndex = 0): Promise<any> {
        await RestEndpoint.main().upsert(this.getUpdateCertificate()).run("api/cer/certificate").getText();

        if (plusPupilIndex != 0) {
            let pupils = await this.service.restGetPupils(this.schoolClass.id);
            let index = pupils.findIndex(p => p.id == this.pupil.id);
            if (index == -1) {
                await this.app.messageDialog.info("Konnte nicht automatisch zu nächstem Zeugnis springen");
            }
            else {
                let p = pupils[(index + plusPupilIndex + pupils.length) % pupils.length];
                this.keepSelectedDomain = true;
                this.skipCloseCheck = true;
                await this.app.navigateTo(["/enaio-certificates", "certificate", this.schoolClass.id, this.isHalfYear ? 1 : 0, p.id]);
            }
        }

        await this.init(false);
        this.originalGuiCertificate = Utils.cloneDeep(this.guiCertificate);
    }

    markVisibilityChanged(event: any, mark: GuiMarkAssignment): void {
        mark.hidden = !event.checked;
    }

    level2VisibilityChanged(event: any, mark: GuiMarkAssignment): void {
        let v = event.value == null ? false : event.value;
        for (let m of this.guiCertificate.domains.get(this.selectedDomain).marksFlat) {
            if (m.level1 == mark.level1 && m.level2 == mark.level2) {
                m.hidden = !v;
            }
        }
    }

    level1VisibilityChanged(event: any, mark: GuiMarkAssignment): void {
        let v = event.value == null ? false : event.value;
        for (let m of this.guiCertificate.domains.get(this.selectedDomain).marksFlat) {
            if (m.level1 == mark.level1) {
                m.hidden = !v;
            }
        }
    }

    getLevel1Hidden(mark: GuiMarkAssignment): boolean {
        let unique = Utils.arrayGetUnique(this.guiCertificate.domains.get(this.selectedDomain).marksFlat.filter(m => this.guiCertificate.domains.get(this.selectedDomain) && m.level1 == mark.level1).map(m => m.hidden));
        return unique.includes(true) ? unique.includes(false) ? false : null : true;
    }

    getLevel2Hidden(mark: GuiMarkAssignment): boolean {
        let unique = Utils.arrayGetUnique(this.guiCertificate.domains.get(this.selectedDomain).marksFlat.filter(m => this.guiCertificate.domains.get(this.selectedDomain) && m.level1 == mark.level1 && m.level2 == mark.level2).map(m => m.hidden));
        return unique.includes(true) ? unique.includes(false) ? false : null : true;
    }

    async loadHiddenCompetencies(): Promise<number[]> {
        return RestEndpoint.main().query({ certificateId: this.lastYearCertId }).run("api/cer/certificate/getHiddenCompetencies").listNumbers();
    }

    async copyFromLastYear(): Promise<void> {
        if (!await this.app.messageDialog.yesNo("Möchten Sie die ausgewählten Kompetenzen für dieses Zeugnis aus dem vorherigen Schuljahr übernehmen? Eine eventuell bereits getätige Auswahl wird überschrieben", "Übernahme")) {
            return;
        }
        let hiddenCompetencies = await this.loadHiddenCompetencies();
        for (let domain of this.guiCertificate.domains.values()) {
            for (let mark of domain.marksFlat) {
                mark.hidden = mark.competencyId > 0 && hiddenCompetencies.includes(mark.competencyId);
            }
        }
    }

    somethingChanged(): void {
        let c = this.getUpdateCertificate();
        this.addTeacherNameForDomains = Utils.arrayFromIt(this.guiCertificate.domains.keys()).filter(d => (c.id == 0 && c.marks.some(m => m.domain == d && m.mark > 0) || c.id > 0 && c.marks.some(m => m.domain == d)) && Utils.isNoe(this.guiCertificate.domains.get(d)?.teacher));
    }
}

import { OrdinaryObjectNumber } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { TableCellType } from "./table-cell-type.enum";
import { TableColumn } from "./table-column.model";
import { TableData } from "./table-data.model";
import { TableSizerCol } from "./table-sizer-col.model";

export class TableSizer {

    /*
      qc: Quick Compact: Anhand des Datentyps ohne Betrachtung der Inhalte schnell eine kompakte Größe ermitteln
      ra: Rest Auto: Restliche Breite verwenden
      <n>d: Platz für n Dezimalstellen reservieren
      <n>%
      <n>px
    */

    static instance = new TableSizer();

    private canvas: HTMLCanvasElement;
    private context: CanvasRenderingContext2D;

    private dateTimeWidth: number = null;
    private dateWidth: number = null;
    private durationWidth: number = null;
    private byteSizeWidth: number = null;
    private yesNoWidth: number = null;
    private buttonWidth = 20;
    private numberWidth: OrdinaryObjectNumber<number> = {};

    constructor() {
        this.canvas = document.createElement("canvas");
        this.context = this.canvas.getContext("2d");
        this.context.font = "Open Sans 12px";
    }

    calculate(table: TableData, col: TableColumn): TableSizerCol {
        if (col.width2 == null) {
            throw new Error("Konnte Breite für '" + col.width2 + "' nicht berechnen");
        }
        let headerWidth = this.measure(col.caption) * 1.65 + 50;
        //Quick Compact
        if (col.width2 == "qc") {
            if (col.cellType == TableCellType.dateTime) {
                if (this.dateTimeWidth == null) {
                    this.dateTimeWidth = this.measure("00:00:00 00:00:00") + 50;
                }
                return new TableSizerCol(col, Math.max(headerWidth, this.dateTimeWidth), "px");
            }
            else if (col.cellType == TableCellType.date) {
                if (this.dateWidth == null) {
                    this.dateWidth = this.measure("00:00:00") + 50;
                }
                return new TableSizerCol(col, Math.max(headerWidth, this.dateWidth), "px");
            }
            else if (col.cellType == TableCellType.duration) {
                if (this.durationWidth == null) {
                    this.durationWidth = this.measure("100s 100ms") + 50;
                }
                return new TableSizerCol(col, Math.max(headerWidth, this.durationWidth), "px");
            }
            else if (col.cellType == TableCellType.byteSize) {
                if (this.byteSizeWidth == null) {
                    this.byteSizeWidth = this.measure("1000 Bytes") + 50;
                }
                return new TableSizerCol(col, Math.max(headerWidth, this.byteSizeWidth), "px");
            }
            else if (col.cellType == TableCellType.yesNo) {
                if (this.yesNoWidth == null) {
                    this.yesNoWidth = 60;
                }
                return new TableSizerCol(col, Math.max(headerWidth, this.yesNoWidth), "px");
            }
            else {
                throw new Error("Konnte Breite für '" + col.width2 + "' für Datentyp '" + col.cellType + "' nicht berechnen");
            }
        }
        //Rest Auto
        else if (col.width2 == "ra") {
            let max = 0;
            for (let row of table.rows) {
                max = Math.max(max, this.measure(Utils.toString(row.values[col.id])) + 30);
            }
            return new TableSizerCol(col, max, "%r");
        }
        else if (col.width2.endsWith("d")) {
            let numDigits = Utils.toNumber(Utils.stringRemoveSuffix(col.width2, "d"));
            if (col.cellType == TableCellType.button) {
                return new TableSizerCol(col, Math.max(headerWidth, this.buttonWidth * numDigits + 20), "px");
            }
            let w = this.numberWidth[numDigits];
            if (w == null) {
                w = this.measure("0".repeat(Math.min(100, numDigits))) + 50;
                this.numberWidth[numDigits] = w;
            }
            return new TableSizerCol(col, Math.max(headerWidth, w), "px");
        }
        else if (col.width2.endsWith("px")) {
            return new TableSizerCol(col, Utils.toNumber(Utils.stringRemoveSuffix(col.width2, "px")), "px");
        }
        else if (col.width2.endsWith("%")) {
            return new TableSizerCol(col, Utils.toNumber(Utils.stringRemoveSuffix(col.width2, "%")), "%");
        }
        else {
            throw new Error("Konnte Breite für '" + col.width2 + "' nicht berechnen");
        }
    }

    private measure(text: string): number {
        return this.context.measureText(text).width;
    }
}

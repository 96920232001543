import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassLesson } from '../../shared/class-lesson.entity';
import { ClassRegisterSettings } from '../../shared/class-register-settings.entity';
import { ClassRegister } from '../../shared/class-register.entity';
import { ClassTimetableEntry } from '../../shared/class-timetable-entry.entity';
import { Course } from '../../shared/course.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';

@Component({
  selector: 'app-class-week',
  templateUrl: './class-week.component.html',
  styleUrls: ['./class-week.component.scss']
})
export class ClassWeekComponent extends ComponentView {

    _Utils = Utils;

    service = inject(EnaioCertificateService);

    minWeekDay = 0;
    maxWeekDay = 4;
    weekDayNames = Utils.getWeekDayNames();
    rowIndexes = Utils.getRange(this.minWeekDay, this.maxWeekDay);

    id: number;
    date: Date;
    weekTitle: string;
    isBWeek = false;
    isFuture = false;
    isAdmin = false;
    classRegister: ClassRegister;
    settings: ClassRegisterSettings;
    courses: Course[];

    cells: Slot[][];

    displayModalSelectLesson = false;
    displayModalSelectedSlot: Slot;
    modalSelectLessonEntries: LessonSlot[];

    calendarWeeksGui: FrontendFieldListItem[] = [];

    constructor() {
        super();
        this.neededParams = { id: "number", date: "date?" };
    }

    async initParams(): Promise<boolean> {
        if (this.date == null) {
            await this.app.navigateTo(["/enaio-certificates", "class-registers", "week", this.id, Utils.dateFormat(Utils.dateStartOf(new Date(), "week"), "yyyymmdd")]);
            return false;
        }

        this.weekTitle = "KW " + Utils.dateCalendarWeek(this.date) + " (" + Utils.dateFormat(Utils.dateAdd(this.date, "day", this.minWeekDay), "dd.mm.yyyy") + " - " + Utils.dateFormat(Utils.dateAdd(this.date, "day", this.maxWeekDay), "dd.mm.yyyy") + ")";

        this.classRegister = await this.service.restGetClassRegister(this.id, ["timetableEntries", "holidays", "fieldTrips"], this.date, true);
        this.settings = await this.service.restGetClassRegisterSettings(this.classRegister.enaioSchoolClass.getYearNumber(), this.classRegister.isHalfYear);
        this.courses = await RestEndpoint.main().query({ year: this.classRegister.enaioSchoolClass.getYearNumber(), isHalfYear: this.classRegister.isHalfYear, grade: this.classRegister.enaioSchoolClass.grade, schoolType: this.classRegister.enaioSchoolClass.getSchoolType() }).run("api/cer/classregister/courses").list(Course);

        this.isAdmin = await this.service.isSecretary();
        this.isBWeek = this.settings.isBWeek(this.date);
        this.isFuture = !this.isAdmin && this.date > Utils.dateStartOfNext(new Date(), "week");

        this.calendarWeeksGui = this.settings.getWeekDates().map(week => new FrontendFieldListItem(week, "KW " + Utils.dateCalendarWeek(week) + " (" + Utils.dateFormat(Utils.dateAdd(week, "day", this.minWeekDay), "dd.mm.yyyy") + " - " + Utils.dateFormat(Utils.dateAdd(week, "day", this.maxWeekDay), "dd.mm.yyyy") + ")"));

        this.classRegister.timetableEntries = this.classRegister.timetableEntries.filter(entry => entry.hour >= 0 && entry.hour < EnaioCertificatesTools.getMaxHoursPerDay() && entry.weekDay >= 0 && entry.weekDay < 7);

        this.updateCells();

        this.classRegister.lessons = this.classRegister.lessons.filter(entry => entry.hour >= 0 && entry.hour < this.cells.length);
        for (let entry of this.classRegister.lessons) {
            this.cells[entry.hour][entry.getWeekDay()].lessons.push(entry);
        }

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Unterrichtswoche", routerLink: ["/enaio-certificates", "class-registers", "week", this.id, Utils.dateFormat(this.date, "yyyymmdd")] }
        ]);
        return true;
    }

    async prevWeek(): Promise<void> {
        let newDate = Utils.dateAdd(this.date, "day", -7);
        if (newDate >= this.settings.firstDayOfSchoolYear) {
            await this.app.navigateTo(["/enaio-certificates", "class-registers", "week", this.id, Utils.dateFormat(newDate, "yyyymmdd")]);
        }
    }

    async nextWeek(): Promise<void> {
        let newDate = Utils.dateAdd(this.date, "day", 7);
        if (newDate <= this.settings.lastDayOfSchoolYear) {
            await this.app.navigateTo(["/enaio-certificates", "class-registers", "week", this.id, Utils.dateFormat(newDate, "yyyymmdd")]);
        }
    }

    async changeWeek(): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "class-registers", "week", this.id, Utils.dateFormat(this.date, "yyyymmdd")]);
    }

    async clickLesson(slot: Slot): Promise<void> {
        if (this.isFuture || slot.timeTableEntry == null || slot.getStatus() == "slash" || slot.isHoliday) {
            return;
        }
        if (EnaioCertificatesTools.getSpecialDomainCourses() == slot.domain) {
            let matchingCourses = this.courses.filter(c => slot.courseIds.includes(c.id));
            if (slot.lessons.filter(l => l.course != null).length == 0 && matchingCourses.length == 0) {
                await this.app.messageDialog.info("Es wurden keine eingetragenen Kurse für die Klassenstufe " + this.classRegister.enaioSchoolClass.grade + " gefunden");
                return;
            }
            let possibleSub = Utils.arrayGetUnique([...matchingCourses, ...slot.lessons.filter(l => l.course != null).map(l => l.course)], c => c.id);
            this.modalSelectLessonEntries = [];
            for (let course of possibleSub) {
                let ls = new LessonSlot(course.title, course.id, "times", slot.lessons.find(l => l.course != null && l.course.id == course.id));
                if (ls.lesson != null) {
                    ls.status = ls.lesson.skipped ? "A" : ls.lesson.isDone() ? "check" : "hourglass";
                }
                this.modalSelectLessonEntries.push(ls);
            }
            this.modalSelectLessonEntries = Utils.arraySortBy(this.modalSelectLessonEntries, e => e.domain);
            this.displayModalSelectLesson = true;
            this.displayModalSelectedSlot = slot;
        }
        else {
            let possibleSub = Utils.arrayGetUnique([...EnaioCertificatesTools.getSpecialDomainChildren(slot.domain), ...slot.lessons.map(l => l.domain)]);
            if (possibleSub.length > 1) {
                this.modalSelectLessonEntries = [];
                for (let domain of possibleSub) {
                    let ls = new LessonSlot(domain, 0, "times", slot.lessons.find(l => l.domain == domain));
                    if (ls.lesson != null) {
                        ls.status = ls.lesson.skipped ? "A" : ls.lesson.isDone() ? "check" : "hourglass";
                    }
                    this.modalSelectLessonEntries.push(ls);
                }
                this.displayModalSelectLesson = true;
                this.displayModalSelectedSlot = slot;
            }
            else {
                await this.openLesson(Utils.isNoe(slot.lessons) ? null : slot.lessons[0].id, Utils.dateAdd(this.date, "day", slot.weekDay), slot.hour, possibleSub[0], null);
            }
        }
    }

    async modalSelectSlot(ls: LessonSlot): Promise<void> {
        await this.openLesson(ls.lesson?.id, Utils.dateAdd(this.date, "day", this.displayModalSelectedSlot.weekDay), this.displayModalSelectedSlot.hour, ls.courseId > 0 ? "" : ls.domain, ls.courseId);
        this.displayModalSelectLesson = false;
    }

    getStatusToolTip(status: string): string {
        return status == "slash" ? "Keine Unterrichtseinheit" : status == "times" ? "Bearbeitung noch nicht begonnen" : status == "hourglass" ? "In Bearbeitung" : status == "A" ? "Fällt aus/Ausgefallen" : "Abgeschlossen";
    }

    private async openLesson(id: number, date: Date, hour: number, domain: string, courseId: number): Promise<void> {
        if (id == null || id == 0) {
            let idPrev = await this.service.restGetLessonId(this.classRegister.id, date, hour - 1, domain, courseId, false);
            let copy = idPrev > 0 && await this.app.messageDialog.yesNo("Es handelt sich um den zweiten Teil einer Doppelstunde. Sollen die Informationen des ersten Teils übernommen werden?", "Frage");

            id = await this.service.restGetLessonId(this.classRegister.id, date, hour, domain, courseId, true, copy ? idPrev : 0);
        }
        if (id > 0) {
            await this.app.navigateTo(["/enaio-certificates", "class-registers", "lesson", id, this.classRegister.id]);
        }
    }

    private updateCells(): void {
        this.cells = [];
        for (let i = 0; i < EnaioCertificatesTools.getMaxHoursPerDay(); i++) {
            let row: Slot[] = [];
            for (let j = 0; j < 7; j++) {
                row.push(new Slot(Utils.dateAdd(this.date, "day", j), i, j, "", "", [], null, [], this.settings, this.classRegister));
            }
            this.cells.push(row);
        }

        for (let entry of this.classRegister.getTimetableEntriesForDate(this.date)) {
            let cell = this.cells[entry.hour][entry.weekDay];
            cell.domain = entry.getDomainForWeekType(this.isBWeek);
            cell.courseTitle = entry.getCourseTitleForWeekType(this.isBWeek);
            cell.courseIds = entry.getCourseIdsForWeekType(this.isBWeek);
            cell.timeTableEntry = entry;
        }

    }
}

class Slot {

    isHoliday = false;

    constructor(public date: Date, public hour: number, public weekDay: number, public domain: string, public courseTitle: string,
        public courseIds: number[], public timeTableEntry: ClassTimetableEntry, public lessons: ClassLesson[], public settings: ClassRegisterSettings, public classRegister: ClassRegister) {
        this.isHoliday = !this.settings.isSchoolDay(date) || !this.classRegister.isSchoolHour(date, hour);
    }

    getStatus(): string {
        return this.timeTableEntry == null ? "" : this.settings.isPlaceholderDomain(this.domain) ? "slash" : Utils.isNoe(this.lessons) || !Utils.isNoe(this.courseIds) && this.courseIds.length > this.lessons.length ? "times" :
            this.lessons.find(l => l.skipped) ? "A" : this.lessons.find(l => !l.isDone()) ? "hourglass" : "check";
    }

    getSubstituteDomain(): string {
        return Utils.arrayFindAndConvert(this.lessons, l => l.isSubstitute, l => l.substituteDomain);
    }

    getTitle(): string {
        return this.domain + (this.domain == EnaioCertificatesTools.getSpecialDomainCourses() && !Utils.isNoe(this.courseTitle) ? " (" + this.courseTitle + ")" : "");
    }
}

class LessonSlot {
    constructor(public domain: string, public courseId: number, public status: string, public lesson: ClassLesson) {
    }

    getSubstituteDomain(): string {
        return this.lesson?.isSubstitute ? this.lesson.substituteDomain : null;
    }
}

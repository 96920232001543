export enum EnaioWorkflowEventType {
    beforeForward = 1,
    afterForward = 2,
    beforeForwardTo = 3,
    buttonClick = 4,
    beforeOpen = 5,
    afterSignature = 6,
    beforeCancel = 7,
    simulateMaskEdit = 8,
    enterPage = 9,
    valueChanged = 10,
    focusGained = 11,
    beforeAddRow = 12,
    beforeDeleteRow = 13,
    cellValueChanged = 14,
    cellFocusGained = 15,
    leavePage = 16,
    startActivity = 10_000,
    endActivity = 10_001,
    beforeStartSubProc = 10_002,
    personalizeWorkItem = 10_003,
    getWorkItemParams = 10_004,
    cancelWorkItem = 10_005,
    timerFired = 10_006,
    startInstance = 10_007,
    endInstance = 10_008,
    terminateActivity = 10_011,
    clientGlobal = 1_000_000,
    serverGlobal = 1_000_001
}
export namespace EnaioWorkflowEventType{
    export function getName(value: EnaioWorkflowEventType): string {
        switch (value) {
            case EnaioWorkflowEventType.beforeForward:
                return "beforeForward";
            case EnaioWorkflowEventType.afterForward:
                return "afterForward";
            case EnaioWorkflowEventType.beforeForwardTo:
                return "beforeForwardTo";
            case EnaioWorkflowEventType.buttonClick:
                return "buttonClick";
            case EnaioWorkflowEventType.beforeOpen:
                return "beforeOpen";
            case EnaioWorkflowEventType.afterSignature:
                return "afterSignature";
            case EnaioWorkflowEventType.beforeCancel:
                return "beforeCancel";
            case EnaioWorkflowEventType.simulateMaskEdit:
                return "simulateMaskEdit";
            case EnaioWorkflowEventType.enterPage:
                return "enterPage";
            case EnaioWorkflowEventType.valueChanged:
                return "valueChanged";
            case EnaioWorkflowEventType.focusGained:
                return "focusGained";
            case EnaioWorkflowEventType.beforeAddRow:
                return "beforeAddRow";
            case EnaioWorkflowEventType.beforeDeleteRow:
                return "beforeDeleteRow";
            case EnaioWorkflowEventType.cellValueChanged:
                return "cellValueChanged";
            case EnaioWorkflowEventType.cellFocusGained:
                return "cellFocusGained";
            case EnaioWorkflowEventType.leavePage:
                return "leavePage";
            case EnaioWorkflowEventType.startActivity:
                return "startActivity";
            case EnaioWorkflowEventType.endActivity:
                return "endActivity";
            case EnaioWorkflowEventType.beforeStartSubProc:
                return "beforeStartSubProc";
            case EnaioWorkflowEventType.personalizeWorkItem:
                return "personalizeWorkItem";
            case EnaioWorkflowEventType.getWorkItemParams:
                return "getWorkItemParams";
            case EnaioWorkflowEventType.cancelWorkItem:
                return "cancelWorkItem";
            case EnaioWorkflowEventType.timerFired:
                return "timerFired";
            case EnaioWorkflowEventType.startInstance:
                return "startInstance";
            case EnaioWorkflowEventType.endInstance:
                return "endInstance";
            case EnaioWorkflowEventType.terminateActivity:
                return "terminateActivity";
            case EnaioWorkflowEventType.clientGlobal:
                return "clientGlobal";
            case EnaioWorkflowEventType.serverGlobal:
                return "serverGlobal";
        }
    }
}

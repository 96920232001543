import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { Course } from '../../shared/course.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';

@Component({
  selector: 'app-edit-class-courses',
  templateUrl: './edit-class-courses.component.html',
  styleUrls: ['./edit-class-courses.component.scss']
})
export class EditClassCoursesComponent implements OnInit {

    year: number;
    listData: Course[];
    table: TableData;

    constructor(private app: MainAppService, private route: ActivatedRoute, private service: EnaioCertificateService) {
    }

    ngOnInit(): void {
        this.app.subscribeToParamsChanges(this.route, this.init.bind(this));
    }

    async init(params: any): Promise<void> {
        this.year = Utils.toNumber(params.year ?? EnaioCertificatesTools.getCurrentYear());
        this.listData = await this.app.loadDataHandler(async () => RestEndpoint.main().query({year: this.year, isHalfYear: false}).run("api/cer/classregister/courses").list(Course), this);
        this.table = new TableData([
            new TableColumn("type", "Schulzweig"),
            new TableColumn("title", "Bezeichnung"),
            new TableColumn("group", "Gruppe"),
            new TableColumn("grades", "Klassenstufen"),
            new TableColumn("instructor", "Kursleiter"),
            new TableColumn("forCertificate", "Zeugnisrelevant", TableCellType.yesNo)
        ], this.listData.map(item => new TableRow(item, {
            type: Utils.arrayGetSafe(EnaioCertificatesTools.getSchoolTypeNames(), item.type, Utils.toString(item.type)),
            title: item.title,
            group: item.group,
            grades: Utils.arrayItemsToString(item.forGrades),
            instructor: item.instructor,
            forCertificate: item.forCertificate
        })), [new TableSortColumn("type"), new TableSortColumn("title")]);

        this.app.updateNavigation("Kursbuch", [
            this.service.getHomeCourses()
        ]);
    }
}

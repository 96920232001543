import { ElementRef, ViewContainerRef } from "@angular/core";
import { Routes } from "@angular/router";
import * as FileSaver from 'file-saver';
import { CanDeactivateGuard } from "src/modules/sm-base/misc/can-deactivate.guard";
import { Utils } from "../shared/utils";
import { TreeNode2 } from "./tree-node2.model";
import { ContextMenu } from "primeng/contextmenu";
import { MenuItem } from "primeng/api";
import { OrdinaryObject } from "../shared/ordinary-object.model";

export class GuiUtilsInternal {

    private usedSingletonNames = new Set<string>();

    //Das ist ein Timer, um Dinge durchzuführen, nachdem die nächste ChangeDetection gelaufen ist.
    angularTimer(onTimer: () => void): any {
        return Utils.setTimerOnce(1, onTimer);
    }

    base64ToArrayBuffer(base64: string): ArrayBuffer {
        let binary_string = window.atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    bytesToStringAscii(bytes: ArrayBuffer): string {
        return new TextDecoder('ascii').decode(bytes);
    }

    checkSingleton(o: object): boolean {
        let name = Utils.getTypeName(o);
        if (this.usedSingletonNames.has(name)) {
            throw new Error("Der Singleton " + name + " wurde bereits einmal registriert. Bitte prüfen, dass er nicht in der 'providers' Sektion des Moduls aufgeführt wird.");
        }
        else {
            this.usedSingletonNames.add(name);
            return true;
        }
    }

    colorStringToRgba(col: string): number {
        let canvas = document.createElement('canvas');
        canvas.width = 1;
        canvas.height = 1;
        let ctx = canvas.getContext('2d');

        ctx.clearRect(0, 0, 1, 1);
        ctx.fillStyle = '#000';
        ctx.fillStyle = col;
        let computed = ctx.fillStyle;
        ctx.fillStyle = '#fff';
        ctx.fillStyle = col;
        if (computed !== ctx.fillStyle) {
            return null;
        }
        ctx.fillRect(0, 0, 1, 1);
        let arr = [...ctx.getImageData(0, 0, 1, 1).data];

        return arr.length == 4 ? arr[0] << 24 | arr[1] << 16 | arr[2] << 8 | arr[3] : null;
    }

    isClipboardReadSupported(): boolean {
        return navigator.clipboard?.readText != null;
    }

    isClipboardWriteSupported(): boolean {
        return navigator.clipboard?.writeText != null;
    }

    async copyToClipboard(text: string): Promise<boolean> {
        if (!this.isClipboardWriteSupported()) {
            return false;
        }
        await navigator.clipboard.writeText(text);
        return true;
    }

    async fromClipboard(): Promise<string> {
        return navigator.clipboard.readText();
    }

    downloadBytesAsFile(arr: ArrayBuffer, fileName = "document.pdf"): void {
        let byteArray = new Uint8Array(arr);
        let a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
        a.download = fileName;

        // Append anchor to body.
        document.body.appendChild(a);
        a.click();
        // Remove anchor from body
        document.body.removeChild(a);
    }

    flattenTree(nodes: TreeNode2[]): TreeNode2[] {
        let result = [...nodes];
        for (let node of nodes) {
            if (!Utils.isNoe(node.children)) {
                result = [...result, ...this.flattenTree(node.children)];
            }
        }
        return result;
    }

    generateTree<T>(items: T[], toTreeConverter: (item: T) => any[], toNodeConverter: (item: T, key: any[], index: number) => TreeNode2, sort = true): TreeNode2[] {
        let result: TreeNode2[] = [];
        for (let item of items) {
            let keys = toTreeConverter(item);
            let currentChildren = result;
            let index = 0;
            for (let key of keys) {
                let node = Utils.arrayGetOrAdd(currentChildren, tn => tn.label == key, () => toNodeConverter(item, keys, index));
                if (node.children == null && index != keys.length - 1) {
                    node.children = [];
                }
                currentChildren = node.children;
                index++;
            }
        }
        if (sort) {
            result = this.sortTreeNodes(result);
        }
        return result;
    }

    getCurrentHostName(): string {
        return window.location.hostname;
    }

    getHtmlElementDebugString(e: HTMLElement): string {
        let text = e.innerText;
        if (text != null) {
            text = Utils.crop(Utils.replaceAll(Utils.replaceAll(text, "\r", " "), "\n", " "), 30, true);
        }
        let result = e.nodeName + "." + e.className + (text != null ? " (" + text + ")" : "");
        if (e.parentElement != null) {
            result += " < " + this.getHtmlElementDebugString(e.parentElement);
        }
        return result;
    }

    htmlToElement(html: string): Node {
        let template = document.createElement('template');
        template.innerHTML = html.trim();
        return template.content.firstChild;
    }

    isComponentVisible(elementRef: ElementRef): boolean {
        return elementRef.nativeElement?.offsetParent != null;
    }

    objectToTree(o: any, sort = true): TreeNode2[] {
        let result: TreeNode2[] = [];
        for (let key of Utils.getOwnPropertyNames(o)) {
            let node: TreeNode2 = {label: key, data: {key, value: Utils.isObject(o[key]) ? "" : Utils.toString(o[key])}};
            result.push(node);
            if (Utils.isObject(o[key])) {
                node.children = this.objectToTree(o[key]);
            }
        }
        if (sort) {
            result = this.sortTreeNodes(result);
        }
        return result;
    }

    saveFileAs(blob: Blob, fileName: string): void {
        //False Positive
        // eslint-disable-next-line deprecation/deprecation
        FileSaver.saveAs(blob, fileName, { autoBom: false });
    }

    selectAll(event: any): void {
        event.target.select();
    }

    showContextMenu(evt: OrdinaryObject, viewContainerRef: ViewContainerRef, model: MenuItem[]): void {
        const component = viewContainerRef.createComponent(ContextMenu);

        component.instance.model = model;
        this.angularTimer(() => component.instance.show(evt));
    }

    sortTreeNodes(nodes: TreeNode2[]): TreeNode2[] {
        let result = Utils.arraySortBy(nodes, tn => (tn.sortText ?? "") + tn.label);
        for (let child of nodes) {
            if (child.children != null) {
                child.children = this.sortTreeNodes(child.children as TreeNode2[]);
            }
        }
        return result;
    }

    stringAsciiToArrayBuffer(s: string): ArrayBuffer {
        let len = s.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = s.charCodeAt(i);
        }
        return bytes.buffer;
    }

    updateRoutes(routes: Routes): Routes {
    for (let route of routes) {
        if (route.canDeactivate == null) {
            route.canDeactivate = [CanDeactivateGuard];
        }
    }

    return routes;
}


    xmlToJson(xml: string): string {
        return require('xml-js').xml2json(xml, {compact: true, spaces: 4});
    }

    xmlToObject(xml: string): any {
        return Utils.fromJson(this.xmlToJson(xml));
    }

}

export let GuiUtils = new GuiUtilsInternal();

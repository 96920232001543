import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegister } from '../../shared/class-register.entity';

@Component({
  selector: 'app-class-history',
  templateUrl: './class-history.component.html',
  styleUrls: ['./class-history.component.scss']
})
export class ClassHistoryComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;
    classRegister: ClassRegister;
    table: TableData;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, ["history"], null, true);

        this.table = new TableData([
            new TableColumn("changeDate", "Datum/Uhrzeit", TableCellType.dateTime),
            new TableColumn("changedBy", "Benutzer"),
            new TableColumn("modification", "Änderung")
        ], this.classRegister.history.map(hi => new TableRow(hi, {
            changeDate: hi.changeDate,
            changedBy: hi.changedBy,
            modification: hi.modification
        })), [new TableSortColumn("changeDate", false)]);

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Historie", routerLink: ["/enaio-certificates", "class-registers", "history", this.id] }
        ]);
        return true;
    }
}

import { CommonModule } from '@angular/common';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PrimeModule } from '../prime/prime.module';
import { AceEditorComponent } from './components/ace-editor/ace-editor.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { CheckBoxWithCountComponent } from './components/check-box-with-count/check-box-with-count.component';
import { ColorEditorComponent } from './components/color-editor/color-editor.component';
import { ConfigEditorTreeComponent } from './components/config-editor-tree/config-editor-tree.component';
import { CopyClipboardTextComponent } from './components/copy-clipboard-text/copy-clipboard-text.component';
import { CrudListComponent } from './components/crud-list/crud-list.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { EditGeneralUserComponent } from './components/edit-general-user/edit-general-user.component';
import { EditGeneralUsersComponent } from './components/edit-general-users/edit-general-users.component';
import { FirstLoginComponent } from './components/first-login/first-login.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { InplaceObjectListEditorComponent } from './components/inplace-object-list-editor/inplace-object-list-editor.component';
import { LoginComponent } from './components/login/login.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { ProgressorOverlayComponent } from './components/progressor-overlay/progressor-overlay.component';
import { QuickHelpComponent } from './components/quick-help/quick-help.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SmFileUploadInternalComponent } from './components/sm-file-upload-internal/sm-file-upload-internal.component';
import { SmFormFieldInternalComponent } from './components/sm-form-field-internal/sm-form-field-internal.component';
import { SmFormFieldWrapperComponent } from './components/sm-form-field-wrapper/sm-form-field-wrapper.component';
import { SmFormFieldComponent } from './components/sm-form-field/sm-form-field.component';
import { SmFormComponent } from './components/sm-form/sm-form.component';
import { SmOverviewBoxComponent } from './components/sm-overview-box/sm-overview-box.component';
import { SmSwitchComponent } from './components/sm-switch/sm-switch.component';
import { SmTableCellComponent } from './components/sm-table-cell/sm-table-cell.component';
import { SmTableOnPushComponent } from './components/sm-table-on-push/sm-table-on-push.component';
import { SmTableComponent } from './components/sm-table/sm-table.component';
import { SmToolbarComponent } from './components/sm-toolbar/sm-toolbar.component';
import { SmTreeTableCellComponent } from './components/sm-tree-table-cell/sm-tree-table-cell.component';
import { StringListEditorComponent } from './components/string-list-editor/string-list-editor.component';
import { TagButtonComponent } from './components/tag-button/tag-button.component';
import { TreeItemWithIconsComponent } from './components/tree-item-with-icons/tree-item-with-icons.component';
import { WaitContainerComponent } from './components/wait-container/wait-container.component';
import { WaitingFormComponent } from './components/waiting-form/waiting-form.component';
import { DisableDirective } from './directives/disable.directive';
import { InsertionPointDirective } from './directives/insertion-point.directive';
import { VarDirective } from './directives/ng-var.directive';
import { SmErrorHandler } from './misc/sm-error-handler';
import { BypassSecurityPipe } from './pipes/bypass-security.pipe';
import { KeepHtmlPipe } from "./pipes/keep-html.pipe";
import { ErrorHandlingService } from './services/error-handling-service';
import { FileUploadService } from './services/file-upload-service';
import { LocatorService } from './services/locator.service';
import { MessageDialogService } from './services/message-dialog.service';
import { ModelDescriptionService } from './services/model-description.service';
import { ScriptService } from './services/script-service';
import { SmBaseRoutingModule } from './sm-base-routing.module';
import { CrudEditComponent } from './components/crud-edit/crud-edit.component';
import { TextDiffComponent } from './components/text-diff/text-diff.component';
import { SmFileUploadComponent } from './components/sm-file-upload/sm-file-upload.component';
import { PlotComponent } from './components/plot/plot.component';
import { PlotDefinitionComponent } from './components/plot-definition/plot-definition.component';

@NgModule({
    declarations: [
        MessageDialogComponent,
        LoginComponent,
        VarDirective,
        DisableDirective,
        KeepHtmlPipe,
        InsertionPointDirective,
        BypassSecurityPipe,
        SmFormFieldComponent,
        SmFormComponent,
        SmOverviewBoxComponent,
        SmFileUploadComponent,
        SmFileUploadInternalComponent,
        FormDialogComponent,
        SmFormFieldInternalComponent,
        SmTableCellComponent,
        SmTableComponent,
        CrudListComponent,
        CrudEditComponent,
        InplaceObjectListEditorComponent,
        RegisterComponent,
        ActivateUserComponent,
        QuickHelpComponent,
        FirstLoginComponent,
        ResetPasswordComponent,
        ColorEditorComponent,
        StringListEditorComponent,
        WaitingFormComponent,
        CheckBoxWithCountComponent,
        SmFormFieldWrapperComponent,
        SmTreeTableCellComponent,
        DashboardCardComponent,
        TagButtonComponent,
        SmToolbarComponent,
        SmTableOnPushComponent,
        AceEditorComponent,
        WaitContainerComponent,
        SmSwitchComponent,
        TreeItemWithIconsComponent,
        CopyClipboardTextComponent,
        ProgressorOverlayComponent,
        EditGeneralUsersComponent,
        EditGeneralUserComponent,
        ConfigEditorTreeComponent,
        TextDiffComponent,
        PlotComponent,
        PlotDefinitionComponent
    ],
    imports: [
        CommonModule,
        SmBaseRoutingModule,
        ReactiveFormsModule,
        PrimeModule
    ],
    exports: [
        SmFormComponent,
        SmToolbarComponent,
        SmOverviewBoxComponent,
        SmFormFieldComponent,
        SmTableCellComponent,
        SmTreeTableCellComponent,
        SmTableComponent,
        SmTableOnPushComponent,
        SmFileUploadComponent,
        MessageDialogComponent,
        VarDirective,
        DisableDirective,
        InsertionPointDirective,
        KeepHtmlPipe,
        BypassSecurityPipe,
        CrudListComponent,
        CrudEditComponent,
        InplaceObjectListEditorComponent,
        QuickHelpComponent,
        StringListEditorComponent,
        CheckBoxWithCountComponent,
        DashboardCardComponent,
        TagButtonComponent,
        AceEditorComponent,
        WaitContainerComponent,
        SmSwitchComponent,
        TreeItemWithIconsComponent,
        CopyClipboardTextComponent,
        ProgressorOverlayComponent,
        ConfigEditorTreeComponent,
        TextDiffComponent,
        PlotComponent,
        PlotDefinitionComponent
    ],
    providers: [
        ModelDescriptionService,
        ScriptService,
        MessageDialogService,
        FileUploadService,
        ErrorHandlingService,
        { provide: ErrorHandler, useClass: SmErrorHandler }
    ]
})
export class SmBaseModule {

    constructor(private injector: Injector) {
        LocatorService.injector = injector;
    }
}

import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegister } from '../../shared/class-register.entity';
import { Course } from '../../shared/course.entity';
import { HomeworkDto } from '../../shared/homework.dto';

@Component({
  selector: 'app-class-analyses-homework',
  templateUrl: './class-analyses-homework.component.html',
  styleUrls: ['./class-analyses-homework.component.scss']
})
export class ClassAnalysesHomeworkComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;
    classRegister: ClassRegister;
    courses: Course[];
    homework: HomeworkDto[];
    table: TableData;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, ["timetableEntries"], null, true);
        this.courses = await RestEndpoint.main().query({ year: this.classRegister.enaioSchoolClass.getYearNumber(), isHalfYear: this.classRegister.isHalfYear, grade: this.classRegister.enaioSchoolClass.grade, schoolType: this.classRegister.enaioSchoolClass.getSchoolType() }).run("api/cer/classregister/courses").list(Course);
        this.homework = await RestEndpoint.main().query({id: this.id}).run("api/cer/classregister/homework").list(HomeworkDto);

        this.table = new TableData([
            new TableColumn("date", "Datum", TableCellType.date),
            new TableColumn("hour", "Stunde", TableCellType.number),
            new TableColumn("domain", "Fach"),
            new TableColumn("homework", "Hausaufgaben")
        ], this.homework.map(hw => new TableRow(hw, {
            date: hw.date,
            hour: hw.hour + 1,
            domain: hw.courseId > 0 ? Utils.arrayFindAndConvert(this.courses, c => c.id == hw.courseId, c => c.title, "<Unbekannter Kurs>") : hw.domain,
            homework: hw.homework
        })), [new TableSortColumn("date", false), new TableSortColumn("hour", false)]);

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Auswertungen", routerLink: ["/enaio-certificates", "class-registers", "analyses", this.id] },
            { label: "Übersicht von Hausaufgaben", routerLink: ["/enaio-certificates", "class-registers", "analyses", "homework", this.id] }
        ]);
        return true;
    }

    async selectRow(event: any): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "class-registers", "lesson", (event.raw as HomeworkDto).lessonId, this.classRegister.id]);
    }
}

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as Plot from "@observablehq/plot";
import { PlotDefinition } from 'src/modules/enaio/shared/PlotDefinition';
import { PlotReducer } from 'src/modules/enaio/shared/PlotReducer';
import { PlotResult } from 'src/modules/enaio/shared/PlotResult';
import { PlotType } from 'src/modules/enaio/shared/PlotType';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
  selector: 'app-plot',
  templateUrl: './plot.component.html',
  styleUrl: './plot.component.scss'
})
export class PlotComponent {

    @Input()
    def: PlotDefinition;

    @Input()
    data: PlotResult;

    height = 1000;
    width = 1000;

    @ViewChild("plotDiv", {read: ElementRef}) plotDiv: ElementRef;

    public generate(def: PlotDefinition, data: PlotResult): void {
        this.def = def;
        this.data = data;
        let options: any = !Utils.isNoe(def.xAxis.field2) ? {x1: row => row[0], x2: row => row[2], y: row => row[1]} : {x: row => row[0], y: row => row[1]};
        if (def.yAxis.reducer == PlotReducer.count) {
            options = Plot.groupX({ y: "count" }, { x: options.x });
        }

        let marks: Plot.Markish[] = [];
        switch (def.type) {
            case  PlotType.line:
                marks = [...marks, Plot.line(this.data.rows, options as Plot.LineOptions)];
                break;
            case  PlotType.area:
                options.x1 = options.x;
                options.y1 = options.y;
                delete options.x;
                delete options.y;
                marks = [...marks, Plot.area(this.data.rows, options as Plot.AreaOptions)];
                break;
            case  PlotType.barX:
                marks = [...marks, Plot.barX(this.data.rows, options as Plot.BarXOptions)];
                break;
            case  PlotType.barY:
                marks = [...marks, Plot.barY(this.data.rows, options as Plot.BarYOptions)];
                break;
            case  PlotType.dot:
                marks = [...marks, Plot.dot(this.data.rows, options as Plot.DotOptions)];
                break;
            case  PlotType.tickX:
                marks = [...marks, Plot.tickX(this.data.rows, options as Plot.TickXOptions)];
                break;
            case  PlotType.tickY:
                marks = [...marks, Plot.tickY(this.data.rows, options as Plot.TickXOptions)];
                break;
            case  PlotType.pie:
                marks = [...marks, Plot.tickY(this.data.rows, options as Plot.TickXOptions)];
                break;
            }
        let plot = Plot.plot({
            height: def.type == PlotType.barX && this.data.rows.length > 50 ? this.data.rows.length * 20 : this.height - 10,
            marks
        });
        this.plotDiv.nativeElement.innerHTML = "";
        this.plotDiv.nativeElement.appendChild(plot);
    }

}

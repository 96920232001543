import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegisterSettings } from '../../shared/class-register-settings.entity';
import { ClassRegister } from '../../shared/class-register.entity';
import { ClassTimetableEntry } from '../../shared/class-timetable-entry.entity';
import { Course } from '../../shared/course.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';

@Component({
  selector: 'app-class-timetable',
  templateUrl: './class-timetable.component.html',
  styleUrls: ['./class-timetable.component.scss']
})
export class ClassTimetableComponent extends ComponentView {

    _Utils = Utils;

    service = inject(EnaioCertificateService);

    id: number;

    minWeekDay = 0;
    maxWeekDay = 4;
    weekDayNames = Utils.getWeekDayNames();
    rowIndexes = Utils.getRange(this.minWeekDay, this.maxWeekDay);

    classRegister: ClassRegister;
    settings: ClassRegisterSettings;
    courses: Course[];
    orgData: ClassTimetableEntry[];
    validDates: FrontendFieldListItem[];
    validDate: Date;
    oldValidDate: Date;

    domains: string[];
    domainsGui: FrontendFieldListItem[];
    cells: Cell[][];

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, ["timetableEntries"], null, true);
        this.settings = await this.service.restGetClassRegisterSettings(this.classRegister.enaioSchoolClass.getYearNumber(), this.classRegister.isHalfYear);
        this.courses = await RestEndpoint.main().query({ year: this.classRegister.enaioSchoolClass.getYearNumber(), isHalfYear: this.classRegister.isHalfYear, grade: this.classRegister.enaioSchoolClass.grade, schoolType: this.classRegister.enaioSchoolClass.getSchoolType() }).run("api/cer/classregister/courses").list(Course);
        this.domains = await this.service.restGetDomains(this.classRegister.enaioSchoolClass.grade, this.classRegister.enaioSchoolClass.getSchoolType(), true, this.settings, this.settings);
        this.domainsGui = this.domains.map(d => new FrontendFieldListItem(d, d));

        this.classRegister.timetableEntries = this.classRegister.timetableEntries.filter(entry => entry.hour >= 0 && entry.hour < EnaioCertificatesTools.getMaxHoursPerDay() && entry.weekDay >= 0 && entry.weekDay < 7);

        this.calculateValidDates();
        this.validDate = this.validDates[0].value;
        this.oldValidDate = this.validDate;

        this.updateCells();

        this.orgData = Utils.cloneDeep(this.classRegister.timetableEntries);

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Stundenplan", routerLink: ["/enaio-certificates", "class-registers", "timetable", this.id] }
        ]);
        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? "" : null;
    }

    isChanged(): boolean {
        return !Utils.equalsDeep(this.fromGui(this.validDate), this.orgData);
    }

    private fromGui(validDate: Date): ClassTimetableEntry[] {
        let result = Utils.cloneDeep(this.classRegister.timetableEntries);
        for (let entry of result) {
            if (Utils.dateEq(entry.validFrom, validDate)) {
                let cell = this.cells[entry.hour][entry.weekDay];
                entry.domain = cell.domain;
                entry.domainWeekB = cell.domainWeekB;
                entry.courseTitle = cell.courseTitle;
                entry.courseTitleWeekB = cell.courseTitleWeekB;
                entry.courseIds = cell.courseIds;
                entry.courseIdsWeekB = cell.courseIdsWeekB;
            }
        }
        result = result.filter(entry => entry.domain != "");

        for (let i = 0; i < this.cells.length; i++) {
            for (let j = 0; j < this.cells[i].length; j++) {
                let cell = this.cells[i][j];
                if (cell.domain != "") {
                    let ex = result.find(e => e.hour == i && e.weekDay == j && Utils.dateEq(e.validFrom, validDate));
                    if (ex == null) {
                        result.push(Utils.fromPlain(ClassTimetableEntry, {
                            hour: i,
                            weekDay: j,
                            validFrom: validDate,
                            domain: cell.domain,
                            domainWeekB: cell.domainWeekB,
                            courseTitle: cell.courseTitle,
                            courseTitleWeekB: cell.courseTitleWeekB,
                            courseIds: cell.courseIds,
                            courseIdsWeekB: cell.courseIdsWeekB
                        }));
                    }
                }
            }
        }
        return result;
    }

    private updateCells(): void {
        this.cells = [];
        for (let i = 0; i < EnaioCertificatesTools.getMaxHoursPerDay(); i++) {
            this.cells.push(Utils.getRange(1, 7).map(_ => new Cell("", "", "", "", [], [], false)));
        }

        for (let entry of this.classRegister.getTimetableEntriesForDate(this.validDate)) {
            let cell = this.cells[entry.hour][entry.weekDay];
            cell.domain = entry.domain;
            cell.domainWeekB = entry.domainWeekB;
            cell.courseTitle = entry.courseTitle;
            cell.courseTitleWeekB = entry.courseTitleWeekB;
            cell.courseIds = entry.courseIds;
            cell.courseIdsWeekB = entry.courseIdsWeekB;
            cell.useAlternating = entry.domainWeekB != "";
        }
    }

    private calculateValidDates(enforceDate?: Date): void {
        let dates = this.classRegister.getTimetableValidDates();
        if (enforceDate != null && !dates.includes(enforceDate)) {
            dates.push(enforceDate);
        }
        this.validDates = [new FrontendFieldListItem(null, Utils.dateFormatDefaultDate(this.settings.firstDayOfSchoolYear) + " (Schuljahresbeginn)"), ...dates.map(d => new FrontendFieldListItem(d, Utils.dateFormatDefaultDate(d)))];
    }

    async save(): Promise<void> {
        await this.app.saveDataHandler(async () => RestEndpoint.main().query({ id: this.id}).body(this.fromGui(this.validDate)).post().run("api/cer/classregister/timetable").getText(), this, true, true);
    }

    switchAb(cell: Cell): void {
        cell.useAlternating = !cell.useAlternating;
        cell.domainWeekB = "";
    }

    selectValidDate(item: Date): void {
        this.classRegister.timetableEntries = this.fromGui(this.oldValidDate);
        this.validDate = item;
        this.oldValidDate = this.validDate;
        this.updateCells();
    }

    variantIsEditable(): boolean {
        let d = this.validDate ?? this.settings.firstDayOfSchoolYear;
        return Utils.dateLess(new Date(), d);
    }

    async addValidDate(): Promise<void> {
        let listItems = this.settings.getWeekDates().filter(d => Utils.dateGeq(d, new Date()) && !Utils.dateEq(d, this.settings.firstDayOfSchoolYear) &&
        this.validDates.find(vd => Utils.dateEq(vd.value as Date, d)) == null).map(d => new FrontendFieldListItem(d, Utils.dateFormatDefaultDate(d)));
        let validDate = await this.app.messageDialog.input<Date>(new FrontendFieldDefinition("validDate", "Gültig ab", FrontendFieldType.comboBox,
            { mandatory: true, dropdownEditable: false, listItems }), "Gültigkeitsdatum wählen");
        if (validDate == null) {
            return;
        }
        this.classRegister.duplicateTimetableEntries(this.classRegister.getTimetablePrevValidDate(validDate), validDate);
        this.calculateValidDates(validDate);
        this.selectValidDate(validDate);
    }

    async editCourses(cell: Cell, isBWeek: boolean): Promise<void> {
        let form = new FrontendFormDefinition([
            new FrontendFieldDefinition("courseIds", "Kurse", FrontendFieldType.comboBoxMulti, {
                mandatory: true,
                dropdownEditable: false,
                listItems: this.courses.map(c => new FrontendFieldListItem(c.id, c.title)),
                value: isBWeek ? cell.courseIdsWeekB : cell.courseIds
            }),
            new FrontendFieldDefinition("courseTitle", "Titel", FrontendFieldType.text, {
                value: isBWeek ? cell.courseTitleWeekB : cell.courseTitle
            })
        ]);

        if (await this.app.messageDialog.form(form, "Kurse konfigurieren") == "cancel") {
            return;
        }

        if (isBWeek) {
            cell.courseTitleWeekB = form.getValue("courseTitle");
            cell.courseIdsWeekB = form.getValue("courseIds");
        }
        else {
            cell.courseTitle = form.getValue("courseTitle");
            cell.courseIds = form.getValue("courseIds");
        }
    }
}

class Cell {
    constructor(public domain: string, public domainWeekB: string, public courseTitle: string, public courseTitleWeekB: string, public courseIds: number[], public courseIdsWeekB: number[], public useAlternating: boolean) {
    }

    getDomainForWeekType(isBWeek: boolean, aIfEmpty = true): string {
        return isBWeek ? aIfEmpty && Utils.isNoe(this.domainWeekB) ? this.domain : this.domainWeekB : this.domain;
    }

    isCourses(isBWeek: boolean): boolean {
        return this.getDomainForWeekType(isBWeek, false) == EnaioCertificatesTools.getSpecialDomainCourses();
    }

    getTitle(isBWeek: boolean): string {
        let ct = isBWeek ? this.courseTitleWeekB : this.courseTitle;
        return (isBWeek ? this.domainWeekB : this.domain) + (this.isCourses(isBWeek) && !Utils.isNoe(ct) ? " (" + ct + ")" : "");
    }
}

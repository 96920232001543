import { GenericDataType } from "./GenericDataType";
import { PlotReducer } from "./PlotReducer";
import { PlotValueConverter } from "./PlotValueConverter";

export class PlotAxisDefinition {
    field: string;
    reducer: PlotReducer;
    valueConverter: PlotValueConverter;
    scriptConverter: string;
    dataType: GenericDataType;
    field2: string;
}

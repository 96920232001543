import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DhConfigInfo } from 'src/modules/enaio/shared/DhConfigInfo';
import { SingleRunMetrics } from 'src/modules/enaio/shared/SingleRunMetrics';
import { FrontendFieldAddon } from 'src/modules/sm-base/models/frontend-field-addon.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhCustomer } from '../../models/dh-customer.model';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-document-handler-runs',
    templateUrl: './document-handler-runs.component.html',
    styleUrls: ['./document-handler-runs.component.scss']
    })
export class DocumentHandlerRunsComponent extends ComponentView {

    customers: DhCustomer[];
    configNames: string[];
    data: SingleRunMetrics[];
    formInput: FrontendFormDefinition;
    tableData: TableData;
    oneColumnPerConfig = false;
    oneRowPerConfig = true;
    onlySelectedConfigs = true;
    onlyWithWork = false;
    onlyWithError = false;
    queriedConfigs: string[] = [];

    async initParams(): Promise<boolean> {
        this.customers = await DhTools.backendCall("api/dh/listCustomers").list(DhCustomer);
        this.configNames = [];
        for (let customer of this.customers) {
            if (customer.name != "tools") {
                this.configNames = [...this.configNames, ...(await DhTools.backendCall("api/dh/listConfigs", {customer: customer.name}).list(DhConfigInfo)).map(item => Utils.getFileNameWithoutPathAndExtension(item.name))];
            }
        }
        this.configNames = Utils.arraySort(Utils.arrayGetUnique(this.configNames));

        this.formInput = new FrontendFormDefinition([
            new FrontendFieldDefinition("configNames", "Konfigurationen", FrontendFieldType.comboBoxMulti, { mandatory: true, listItems: this.configNames.map(m => new FrontendFieldListItem(m, m)), value: this.configNames}),
            new FrontendFieldDefinition("includeUnknownConfigs", "Unbekannte (alte) Konfigurationen einschließen", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("beginDate", "Startdatum", FrontendFieldType.datePicker, { datePickerWithTime: true, value: Utils.dateStartOf(new Date(), "day")}),
            new FrontendFieldDefinition("endDate", "Enddatum", FrontendFieldType.datePicker, { datePickerWithTime: true, value: Utils.dateEndOf(new Date(), "day"),
                addOnButtons: [new FrontendFieldAddon("", "", "fas fa-ellipsis", true)], onAddOnClick: (addOn, evt) => DhTools.showDateIntervalOptions(evt, this.viewContainerRef, this.formInput) }),
            new FrontendFieldDefinition("onlyWithWork", "Nur mit bearbeiteten Objekten", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("onlyWithError", "Nur mit Fehler-Objekten", FrontendFieldType.checkBox)
        ]);

        return true;
    }

    load(configName: string): void {
        this.formInput.setValue("configNames", [Utils.getFileNameWithoutPath(Utils.stringDef(configName))]);
    }

    async run(): Promise<void> {
        this.queriedConfigs = (this.formInput.getValue("configNames") as string[]).map(s => Utils.stringRemoveSuffix(s, ".json"));
        this.data = await DhTools.backendCall("api/dh/singleRunMetrics", {
            configNames: Utils.arrayItemsToString(this.queriedConfigs, "|"),
            includeUnknownConfigs: this.formInput.getValue("includeUnknownConfigs") as boolean,
            beginDate: this.formInput.getValue("beginDate") as Date,
            endDate: this.formInput.getValue("endDate") as Date,
            onlyWithWork: this.formInput.getValue("onlyWithWork") as boolean,
            onlyWithError: this.formInput.getValue("onlyWithError") as boolean
        }).list(SingleRunMetrics);
        this.onlyWithWork = this.formInput.getValue("onlyWithWork") as boolean;
        this.onlyWithError = this.formInput.getValue("onlyWithError") as boolean;
        this.updateTable();
    }

    setOneColumnPerConfig(): void {
        if (this.oneColumnPerConfig) {
            this.oneRowPerConfig = false;
        }
        this.updateTable();
    }

    setOneRowPerConfig(): void {
        if (this.oneRowPerConfig) {
            this.oneColumnPerConfig = false;
        }
        this.updateTable();
    }

    updateTable(): void {
        let configNames = Utils.arraySort(Utils.arrayGetUnique(Utils.arrayExplode(this.data, item => item.configs.map(item2 => item2.configName))));

        if (this.oneRowPerConfig) {
            this.tableData = new TableData(Utils.arrayWithoutNull([
                new TableColumn("configName", "Konfiguration", TableCellType.text, { width2: "100%" }),
                new TableColumn("startDate", "Startdatum", TableCellType.dateTime, { width2: "qc" }),
                new TableColumn("endDate", "Enddatum", TableCellType.dateTime, { width2: "qc" }),
                new TableColumn("time", "Dauer", TableCellType.duration, { width2: "qc" }),
                new TableColumn("cpuMillis", "CPU Zeit", TableCellType.duration, { width2: "qc" }),
                new TableColumn("numObjects", "Objekte", TableCellType.number, { width2: "8d" }),
                new TableColumn("numObjectsWithWork", "Bearbeitet", TableCellType.number, { width2: "8d" }),
                new TableColumn("numErrorObjects", "Fehlerhaft", TableCellType.number, { width2: "8d" }),
                new TableColumn("usedMem", "Speicherverbrauch", TableCellType.byteSize, { width2: "qc" })
            ]), Utils.arrayExplode(this.data, d => d.configs).filter(item =>
                (!this.onlySelectedConfigs || this.queriedConfigs.includes(item.configName)) &&
                (!this.onlyWithWork || item.numObjectsWithWork > 0) && (!this.onlyWithError || item.numErrorObjects > 0)).map(item => new TableRow(item, {
                configName: item.configName,
                startDate: item.startDate,
                endDate: item.endDate,
                time: item.getRunTime(),
                cpuMillis: item.cpuMillis,
                numObjects: item.numObjects,
                numObjectsWithWork: item.numObjectsWithWork,
                numErrorObjects: item.numErrorObjects,
                usedMem: item.getUsedMem()
            }), [new TableSortColumn("startDate", false)]));
        }
        else {
            this.tableData = new TableData(Utils.arrayWithoutNull([
                new TableColumn("startDate", "Datum", TableCellType.dateTime, { width2: "qc" }),
                new TableColumn("commandLine", "Kommandozeile", TableCellType.text, { width2: "ra" }),
                this.oneColumnPerConfig ? null : new TableColumn("configs", "Konfigurationen", TableCellType.text, { width2: "ra" }),
                ...this.oneColumnPerConfig ? configNames.map(item => new TableColumn("config_" + item, item, TableCellType.yesNo, { width2: "qc" })) : []
            ]), this.data.map(item => new TableRow(item, {
                startDate: item.startDate,
                commandLine: item.commandLine,
                configs: this.oneColumnPerConfig ? null : Utils.arrayItemsToString(item.configs.map(item2 => item2.configName)),
                ...this.oneColumnPerConfig ? Utils.arrayToMap(configNames, item2 => "config_" + item2, item2 => item.configs.some(item3 => item3.configName == item2)) : {}
            }), [new TableSortColumn("date", false)]));
        }
    }

}

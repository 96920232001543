import { TableData } from "src/modules/sm-base/models/table-data.model";
import { FrontendScriptFound } from "./frontend-script-found.model";
import { TableColumn } from "src/modules/sm-base/models/table-column.model";
import { TableCellType } from "src/modules/sm-base/models/table-cell-type.enum";
import { TableSortColumn } from "src/modules/sm-base/models/table-sort-column.model";
import { TableRow } from "src/modules/sm-base/models/table-row.model";
import { FrontendScriptDefinition } from "./frontend-script-definition.model";
import { TableCell } from "src/modules/sm-base/models/table-cell.model";

export class FrontendScriptSearchResult {
    title: string;
    found: FrontendScriptFound[] = [];
    guiTable: TableData;

    constructor(title: string) {
        this.title = title;
    }

    createTable(scripts: FrontendScriptDefinition[]): void {
        this.guiTable = new TableData([
            new TableColumn("script", "Skript"),
            new TableColumn("lineNumber", "Zeile", TableCellType.number),
            new TableColumn("line", "Zeile")
        ], this.found.map(f => new TableRow(f, {
            script: new TableCell(scripts.find(s => s.id == f.scriptId).getFullName(), { styleClass: "monospace" }),
            lineNumber: new TableCell(f.lineNumber, { styleClass: "monospace" }),
            line: new TableCell(f.line, { keepHtml: true, styleClass: "monospace" })
        })), [ new TableSortColumn("script"), new TableSortColumn("lineNumber")]);
    }
}

import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
  selector: 'app-password-retriever',
  templateUrl: './password-retriever.component.html',
  styleUrls: ['./password-retriever.component.scss']
})
export class PasswordRetrieverComponent extends ComponentView {

    form: FrontendFormDefinition;
    result = "";

    async initParams(): Promise<boolean> {
        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("source", "Quelle", FrontendFieldType.comboBox, { value: "Talend", mandatory: true, dropdownEditable: false, listItems: ["Talend"].map(f => new FrontendFieldListItem(f, f))}),
            new FrontendFieldDefinition("dir", "Verzeichnis (muss die .java Sourcen des Talend-Projekts enthalten)", FrontendFieldType.text, { mandatory: true})
        ]);

        return Promise.resolve(true);
    }

    async get(): Promise<void> {
        this.result = Utils.arrayItemsToString(await DhTools.backendCall("api/tools/getTalendPasswords", { dir: this.form.getValue("dir") }).listStrings(), "\n");
    }

}

import { Component, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioDocumentsDefinitionComponent } from 'src/modules/enaio-documents/components/enaio-documents-definition/enaio-documents-definition.component';
import { EnaioDocumentFrontendResult } from 'src/modules/enaio-documents/models/enaio-document-frontend-result.model';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
  selector: 'app-personell-file',
  templateUrl: './personell-file.component.html',
  styleUrl: './personell-file.component.scss'
})
export class PersonellFileComponent extends ComponentView {

    personellFile: EnaioDocument;
    personell: EnaioDocument[];
    definitions: EnaioDocumentFrontendResult[] = [];
    personellGui: MenuItem[];
    documentTypesGui: MenuItem[];
    selectedPersonell = 0;
    selectedDocumentType = "vacationRequests";

    @ViewChild("defComponent")
    defComponent: EnaioDocumentsDefinitionComponent;

    async initParams(): Promise<boolean> {
        this.personellFile = await RestEndpoint.main().run("api/wfl/tbr/getPersonellFile").get(EnaioDocument);
        this.personell = Utils.arraySortBy(await RestEndpoint.main().run("api/wfl/tbr/getPersonell").list(EnaioDocument), doc => doc.getStringByInternal("Name") + ", " + doc.getStringByInternal("Vorname"));
        this.definitions = Utils.arraySortBy(await RestEndpoint.main().query({ includeResultCounts: false }).run("api/doc/def").list(EnaioDocumentFrontendResult), def => def.name);
        if (this.personellFile == null || !this.personellFile.getBoolByInternal("Zeitwirtschaft")) {
            this.definitions = this.definitions.filter(s => s.defId != "flexDaysRequests");
        }

        this.personellGui = [{ label: this.personellFile.getStringByInternal("Name") + ", " + this.personellFile.getStringByInternal("Vorname"), value: 0 },
            ...this.personell.map(p => ({ label: p.getStringByInternal("Name") + ", " + p.getStringByInternal("Vorname"), value: p.id }))];
        this.documentTypesGui = this.definitions.map(def => ({ label: def.name, value: def.defId }));

        GuiUtils.angularTimer(async () => this.change());

        this.app.updateNavigation("Personalakte", [
            { label: "Personalakte", routerLink: ["/enaio-workflows", "personell-file"]}
        ]);

        return true;
    }

    async change(): Promise<void> {
        this.defComponent.defId = this.selectedDocumentType;
        this.defComponent.queryOptions = this.selectedPersonell == 0 ? null : {
            folderId: this.selectedPersonell
        };
        await this.defComponent.reload();
    }
}

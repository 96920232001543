<div class="grid">
    <div class="col-2">
        <sm-form  [form]="formGeneral">
        </sm-form>
        <sm-form *ngIf="formSpecific != null" [form]="formSpecific">
        </sm-form>
        <p-button icon="fas fa-run" label="Starten" (onClick)="start()"></p-button>
    </div>
    <p-tabView class="col-10" [(activeIndex)]="activeWatcherTab" (onChange)="changeTab()">
        <p-tabPanel *ngFor="let watcher of registeredWatchers" [header]="watcher.guid">
            <sm-toolbar>
            </sm-toolbar>
            <sm-table-on-push *ngIf="resultsTable != null" [deltaHeight]=300 [data]="resultsTable">
            </sm-table-on-push>
        </p-tabPanel>
    </p-tabView>
</div>

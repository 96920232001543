import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { UserInteractionItem } from 'src/modules/utils/shared/user-interaction-item.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { RestEndpoint } from '../models/rest-endpoint.model';
import { ErrorInformation } from '../shared/error-information.model';
import { Map2 } from 'src/modules/utils/shared/map2.model';

@Injectable()
export class ErrorHandlingService {

    reportCount = new Map2<number, number>();

    constructor(private app: MainAppService, private deviceDetector: DeviceDetectorService, private router: Router) {
    }

    reportToBackend(error: ErrorInformation): void {
        try {
            //Benutzer hat manuell ungültige URL eingegeben
            if (error.message.includes("NG04002: Cannot match any routes") && this.app.userInteractionHistory.length >= 1 && this.app.userInteractionHistory.length <= 2 && this.app.userInteractionHistory[0].type == "URL") {
                return;
            }

            if (error.message.startsWith("Uncaught (in promise): HttpErrorResponse: ")) {
                let errorMessage = Utils.stringRemovePrefix(error.message, "Uncaught (in promise): HttpErrorResponse: ");
                let o = Utils.fromJson(errorMessage);
                //BackendResponse
                if (o.status == 500 && o.error.startsWith("{\"items\":[")) {
                    return;
                }
                //InternalServerError, wird schon über Backend-Error-Log geloggt
                if (o.status == 500) {
                    return;
                }
                //Gateway Timeout
                if (o.status == 504) {
                    return;
                }
                //Bad Gateway (Server nicht erreichbar)
                if (o.status == 502) {
                    return;
                }
                //Forbidden
                if (o.status == 403) {
                    return;
                }
                if (error.message.includes("Keine Verbindung zum Backendd")) {
                    return;
                }
            }
        }
        catch (e) {
        }

        this.app.userInteractionHistory.push(new UserInteractionItem(new Date(), "ERROR", ""));
        let errorDate = Utils.dateFormatDefault(new Date());
        Utils.setTimerOnce(5000, () => {
            if (!Utils.isNoe(error.trace)) {
                let key = Utils.getStringHash(Utils.getSubstring(error.trace, 0, 1000));
                let reportCount = this.reportCount.get(key);
                if (reportCount == null) {
                    reportCount = 0;
                }
                if (reportCount >= 3) {
                    return;
                }
                this.reportCount.set(key, reportCount + 1);
            }
            error.additional.browser = this.deviceDetector.browser;
            error.additional.browserVersion = this.deviceDetector.browser_version;
            error.additional.device = this.deviceDetector.device;
            error.additional.os = this.deviceDetector.os;
            error.additional.osVersion = this.deviceDetector.os_version;
            error.additional.isDesktop = this.deviceDetector.isDesktop();
            error.additional.isMobile = this.deviceDetector.isMobile();
            error.additional.isTablet = this.deviceDetector.isTablet();
            error.additional.userName = this.app.getUserName();
            error.additional.url = this.router.url;
            error.additional.backendVersion = this.app.backendVersion;
            error.additional.errorDate = errorDate;
            error.additional.loadedDate = this.app.loadedDate != null ? Utils.dateFormatDefault(this.app.loadedDate) : "???";
            error.additional.userInteractionHistory = Utils.arrayReverse(Utils.arraySubList(Utils.arraySort(this.app.userInteractionHistory), this.app.userInteractionHistory.length - 200, this.app.userInteractionHistory.length)).map(item => item.toString());

            RestEndpoint.main().post().body(error).run("api/app/error-reporting");
        });
    }
}

import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningContentRatingsDto } from '../../shared/learning-content-ratings.dto';

@Component({
    selector: 'app-analyses-ratings',
    templateUrl: './analyses-ratings.component.html',
    styleUrls: ['./analyses-ratings.component.scss']
  })
  export class AnalysesRatingsComponent extends ComponentView {

    service = inject(EnaioLearnService);

    data: LearningContentRatingsDto[];
  table: TableData;

  async initParams(): Promise<boolean> {
    this.service.updateNavigation(null, [
      { label: this.app.t("lea.analyses.title"), routerLink: ["/enaio-learn", "analyses"] },
      { label: this.app.t("lea.analyses.ratings.title"), routerLink: ["/enaio-learn", "analyses", "ratings"] }
    ]);
    await this.getData();
    return true;
  }

  async getData(): Promise<void> {
    this.data = await RestEndpoint.main().run("api/lrn/learningcontent/analysesRatings").list(LearningContentRatingsDto);

    let ratingTexts = Utils.arrayGetUnique(Utils.arrayExplode(this.data, item => item.ratings.map(item2 => item2.text)));

    this.table = new TableData([
      new TableColumn("learningContentTitle", "Lerninhalt"),
      new TableColumn("department", "Einrichtung"),
      ...ratingTexts.map(item => new TableColumn("rating_" + item, item))
  ], this.data.map(p => new TableRow(p, {
      learningContentTitle: p.learningContentTitle,
      department: Utils.stringDef(p.department, "<Alle>"),
      ...(Utils.arrayToPlainMap(ratingTexts, item => "rating_" + item, item => Utils.arrayFindAndConvert(p.ratings, item2 => item2.text == item, item2 => Utils.formatNumber(item2.ratingAvg, 2) + " (" + item2.numRatings + ")", "---")) as OrdinaryObject)
  })), [new TableSortColumn("learningContentTitle"), new TableSortColumn("department")]);
  }
}

export enum PlotReducer {
    none = 0,
    count = 1,
    min = 2,
    max = 3
}
export namespace PlotReducer{
    export function getName(value: PlotReducer): string {
        switch (value) {
            case PlotReducer.none:
                return "none";
            case PlotReducer.count:
                return "count";
            case PlotReducer.min:
                return "min";
            case PlotReducer.max:
                return "max";
        }
    }
}

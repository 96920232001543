import { Component, ViewChild } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DatabaseValueFound } from 'src/modules/enaio/shared/DatabaseValueFound';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { ConfigEditorTreeComponent } from '../../../sm-base/components/config-editor-tree/config-editor-tree.component';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { MenuItem } from 'primeng/api';
import { DbConnectionConfig } from 'src/modules/enaio/shared/DbConnectionConfig';
import { Wrapper } from 'src/modules/utils/misc/wrapper.model';
import { Progressor } from 'src/modules/utils/misc/Progressor';
import { ConfigDefinition } from 'src/modules/sm-base/models/config-definition.model';
import { ConfigProperty } from 'src/modules/sm-base/models/config-property.model';
import { ConfigFieldDefinition } from 'src/modules/sm-base/models/config-field-definition.model';
import { ConfigFieldType } from 'src/modules/sm-base/models/config-field-type.enum';

@Component({
  selector: 'app-database-tools',
  templateUrl: './database-tools.component.html',
  styleUrls: ['./database-tools.component.scss']
})
export class DatabaseToolsComponent extends ComponentView {

    def: ConfigDefinition;
    config: ConfigProperty;
    findValueForm: FrontendFormDefinition;
    findValueTable: TableData;
    dumpForm: FrontendFormDefinition;
    compareForm: FrontendFormDefinition;
    compareTable: TableData;
    changes = "";
    progressor = new Wrapper<Progressor>();

    connectionItems: MenuItem[] = [];

    @ViewChild("configEditorTree") configEditorTree: ConfigEditorTreeComponent;

    async initParams(): Promise<boolean> {
        this.findValueForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("query", "Suche nach", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("like", "LIKE Operator", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("schemaNameRegex", "Schema-Name (Regex)", FrontendFieldType.text),
            new FrontendFieldDefinition("tableNameRegex", "Tabellen-Name (Regex)", FrontendFieldType.text),
            new FrontendFieldDefinition("excludeTableNameRegex", "Tabellen-Name ausschließen (Regex)", FrontendFieldType.text),
            new FrontendFieldDefinition("throttlingMsecs", "Throttling (ms warten nach jedem geprüften Feld)", FrontendFieldType.number, { value: 0 })
        ]);

        this.dumpForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("tableNameRegex", "Tabellen-Name (Regex)", FrontendFieldType.text),
            new FrontendFieldDefinition("excludeTableNameRegex", "Tabellen-Name ausschließen (Regex)", FrontendFieldType.text)
        ]);

        await this.reloadDumps();

        this.def = await DhTools.backendCall("api/config/definition").get(ConfigDefinition);
        this.def.init();
        this.configEditorTree.def = this.def;

        let plain = {};

        let mainFieldDef = Utils.fromPlain(ConfigFieldDefinition, { name: "main", type: ConfigFieldType.object, typeName: "CsUtils.DbConnectionConfig"});
        this.config = new ConfigProperty({}, "CsUtils.DbConnectionConfig", true, mainFieldDef, [], plain);
        let d = this.def.getByName("CsUtils.DbConnectionConfig");
        this.config.properties = mainFieldDef.getProperties(d, plain, this.def);

        this.configEditorTree.setObject(this.config);

        return Promise.resolve(true);
    }

    async updateConnectionItems(): Promise<void> {
        let items = await DhTools.backendCall("api/dh/getConfigConnections", { typeName: "CsUtils.DbConnectionConfig" }).getOrdinaryObject(DbConnectionConfig);
        this.connectionItems = [
        {
            label: "Aus Clipboard",
            command: async () => {
                await this.loadConnection(true);
            }
        },
        {
            label: "Eingabe ...",
            command: async () => {
                await this.loadConnection();
            }
        },
        ...Utils.getOwnPropertyNames(items).map(item => ({
            label: item,
            command: () => {
                this.fillConnection(items[item]);
            }
        }))];
    }

    async loadConnection(useClip = false): Promise<void> {
        let s = "";
        if (useClip) {
            s = await GuiUtils.fromClipboard();
        }
        else {
            s = await this.app.messageDialog.input(new FrontendFieldDefinition("", "Verbindung", FrontendFieldType.textArea, { value: GuiUtils.isClipboardReadSupported() ? await GuiUtils.fromClipboard() : ""}), "Datenbankverbindung im DocumentHandler-JSON-Format");
            if (s == null) {
                return;
            }
        }
        this.fillConnection(Utils.fromJson(s) as OrdinaryObject);
    }

    async odbcToRaw(): Promise<void> {
        let dsn = await this.app.messageDialog.input(new FrontendFieldDefinition("dsn", "DSN", FrontendFieldType.text, { mandatory: true }), "Eingabe der ODBC DSN");
        if (dsn != null) {
            let con = await DhTools.backendCall("api/tools/databaseOdbcToSpecific", { dsn }).get(DbConnectionConfig);
            if (con.className == "CsUtils.OdbcDbConnection") {
                this.app.showToast("error", "Fehler", "Die ODBC Verbindung konnte nicht analysiert werden");
            }
             this.fillConnection(con);
        }
    }

    fillConnection(connection: OrdinaryObject): void {
        let mainFieldDef = Utils.fromPlain(ConfigFieldDefinition, { name: "main", type: ConfigFieldType.object, typeName: "CsUtils.DbConnectionConfig"});
        this.config = new ConfigProperty({}, "CsUtils.DbConnectionConfig", true, mainFieldDef, [], connection);
        let d = this.def.getByName("CsUtils.DbConnectionConfig");
        this.config.properties = mainFieldDef.getProperties(d, connection, this.def);

        this.configEditorTree.setObject(this.config);
    }

    async findValues(): Promise<void> {
        DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/tools/databaseFindValue", {
            connectionConfig: this.configEditorTree.getObject(),
            query: this.findValueForm.getValue("query"),
            like: this.findValueForm.getValue("like"),
            schemaNameRegex: this.findValueForm.getValue("schemaNameRegex"),
            tableNameRegex: this.findValueForm.getValue("tableNameRegex"),
            excludeTableNameRegex: this.findValueForm.getValue("excludeTableNameRegex"),
            throttlingMsecs: this.findValueForm.getValue("throttlingMsecs")
        }).getText(), r => {
            let result = Utils.fromPlainArray(DatabaseValueFound, Utils.fromJson(r.value) as any[]);
            this.findValueTable = new TableData([
                new TableColumn("table", "Tabelle"),
                new TableColumn("columnName", "Spalte"),
                new TableColumn("count", "Anzahl")
            ], result.map(item => new TableRow(item, {
                table: item.tableName.tableName,
                columnName: item.columnName,
                count: item.count
            })));
        });
    }

    async dump(): Promise<void> {
        await DhTools.backendCall("api/tools/databaseDump", {
            connectionConfig: this.configEditorTree.getObject(),
            tableNameRegex: this.dumpForm.getValue("tableNameRegex"),
            excludeTableNameRegex: this.dumpForm.getValue("excludeTableNameRegex")
        }).getString();
    }

    async compare(): Promise<void> {
        this.changes = Utils.arrayItemsToString(await DhTools.backendCall("api/tools/databaseCompareDumps", {
            dump1: this.compareForm.getValue("dump1"),
            dump2: this.compareForm.getValue("dump2")
        }).listStrings(), "\n");
    }

    async reloadDumps(): Promise<void> {
        let dumps = await DhTools.backendCall("api/tools/databaseGetDumps").listStrings();

        this.compareForm = new FrontendFormDefinition([
            new FrontendFieldDefinition("dump1", "Dump 1", FrontendFieldType.comboBox, { listItems: dumps.map(d => new FrontendFieldListItem(d, d)) }),
            new FrontendFieldDefinition("dump2", "Dump 2", FrontendFieldType.comboBox, { listItems: dumps.map(d => new FrontendFieldListItem(d, d)) })
        ]);
    }

    async openDbeaver(): Promise<void> {
        await DhTools.backendCall("api/tools/openDbeaverConnection", { connection: this.configEditorTree.getObject()}).getText();
    }

    async copyJsonToClipboard(): Promise<void> {
        await GuiUtils.copyToClipboard(Utils.toJson(this.configEditorTree.getObject()));
    }
}

import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DatevRechercheResult } from 'src/modules/enaio/shared/DatevRechercheResult';
import { EnaioHelper } from 'src/modules/enaio/shared/enaio-helper.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
  selector: 'app-datev-invoice-search',
  templateUrl: './datev-invoice-search.component.html',
  styleUrls: ['./datev-invoice-search.component.scss']
})
export class DatevInvoiceSearchComponent extends ComponentView {

    _Utils = Utils;

    formFilter: FrontendFormDefinition;
    result: DatevRechercheResult[];
    table: TableData;

    async initParams(): Promise<boolean> {
        this.formFilter = new FrontendFormDefinition([
            new FrontendFieldDefinition("documentNumber", "Rechnungsnummer", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("amount", "Betrag", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("firstYear", "Startjahr", FrontendFieldType.number, { value: new Date().getFullYear() - 1 })
        ]);
        return Promise.resolve(true);
    }

    async search(): Promise<void> {
        this.result = await DhTools.backendCall("api/datev/recherche", Utils.objectRemoveNullValues({
            documentNumber: this.formFilter.getValue("documentNumber") != "" ? this.formFilter.getValue("documentNumber") : null,
            amount: this.formFilter.getValue("amount") != "" ? this.formFilter.getValue("amount") : null,
            fromDate: Utils.toNumber(this.formFilter.getValue("firstYear")) > 0 ? new Date(Utils.toNumber(this.formFilter.getValue("firstYear")), 0, 0) : null
        })).list(DatevRechercheResult);

        this.table = new TableData([
            new TableColumn("action", "", TableCellType.button),
            new TableColumn("type", "Typ"),
            new TableColumn("id", "ID"),
            new TableColumn("date", "Datum", TableCellType.date),
            new TableColumn("documentNumber", "Rechnungsnummer"),
            new TableColumn("amount", "Betrag", TableCellType.number),
            new TableColumn("accountNumber", "Kontonummer"),
            new TableColumn("contraAccountNumber", "Gegenkontonummer")
        ], Utils.arrayWithoutNull(this.result.map(item => new TableRow(item, {
                action: Utils.arrayItemsToString(Utils.arrayWithoutNull(["fas fa-eye", item.id?.endsWith('.json') ? "fas fa-check" : null]), ","),
                type: EnaioHelper.datevRechercheTypeToString(item.type),
                id: item.id,
                date: item.date,
                documentNumber: item.documentNumber,
                amount: item.amount,
                accountNumber: item.accountNumber,
                contraAccountNumber: item.contraAccountNumber
            }))));
    }

    async restore(item: DatevRechercheResult): Promise<void> {
        await DhTools.backendCall("api/datev/restore", { fileName: item.id}).getString();
    }

    async clickCell(cell: any): Promise<void> {
        if (cell[1] == "action") {
            if (cell[2] == "fas fa-eye") {
                this.app.messageDialog.textArea("Details", Utils.toJson((cell[0].raw as DatevRechercheResult).data, true));
            }
            else if (cell[2] == "fas fa-check") {
                await this.restore(cell[0].raw as DatevRechercheResult);
            }
        }
    }
}

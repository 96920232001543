<app-wait-container class="flex-col full-height-140x" [style]="'overflow-x: clip'" [loading]="loading">
    <sm-toolbar class="flex-item-fixed">
        <p-button icon="fas fa-refresh" label="Aktualisieren" (onClick)="refresh()">
        </p-button>
        <p-button icon="fas fa-save" label="Objektdefinition exportieren" (onClick)="exportObjectDef()">
        </p-button>
        <p-button icon="fas fa-lightbulb" label="Anzahl Objekte ermitteln" (onClick)="getObjectCounts()">
        </p-button>
    </sm-toolbar>
    <div class="flex-row flex-item-fill">
        <p-tree class="flex-item-fixed width400 data-view-100p tree-items-full-width" [value]="tree" selectionMode="single" [filter]=true [filterBy]="'data.name,data.internalName,data.tableName'" [(selection)]="selectedObjectType" (selectionChange)="selectedObjectTypeChanged($event)">
            <ng-template let-node pTemplate="default">
                <tree-item-with-icons [icons]="node.icons">
                    {{node.label}}
                    <span *ngIf="node.data?.internalName != null" class="internal-name"> ({{node.data?.internalName}})</span>
                    <span *ngIf="_Utils.hasProperty(objectCounts, node.data?.internalName)" class="col-red"> {{objectCounts[node.data?.internalName]}}</span>
                </tree-item-with-icons>
            </ng-template>
        </p-tree>
        <p-tabView *ngIf="selectedObjectType != null" class="flex-item-fill" [(activeIndex)]="tabIndex" (activeIndexChange)="activeTabChanged()">
            <p-tabPanel header="Definition">
                <div class="grid">
                    <sm-toolbar class="grid col-12">
                        <p-splitButton label="DH Generator" icon="fas fa-folder" [model]="dhGeneratorItems"></p-splitButton>
                        <span class="ml-5">Name: <app-copy-clipboard-text [text]="getSelectedObjectType()?.name"></app-copy-clipboard-text></span>
                        <span class="ml-5">Interner Name: <app-copy-clipboard-text [text]="getSelectedObjectType()?.internalName"></app-copy-clipboard-text></span>
                        <span class="ml-5">ID: <app-copy-clipboard-text [text]="'' + getSelectedObjectType()?.Id"></app-copy-clipboard-text></span>
                        <span class="ml-5">Tabellenname: <app-copy-clipboard-text [text]="getSelectedObjectType()?.tableName"></app-copy-clipboard-text></span>
                        <span class="ml-5">Anzahl Objekte: {{_Utils.hasProperty(objectCounts, getSelectedObjectType()?.internalName) ? objectCounts[getSelectedObjectType()?.internalName] : '???'}}</span>
                        <p-button *ngIf="getSelectedObjectType() != null && !_Utils.hasProperty(objectCounts, getSelectedObjectType().internalName)" class="p-button-sm" icon="fas fa-lightbulb" label="Ermitteln" (onClick)="getObjectCounts(getSelectedObjectType()?.internalName)"></p-button>
                        <p-button *ngIf="getSelectedObjectType() != null" class="p-button-sm" icon="fas fa-lightbulb" label="Trefferliste in enaio" (onClick)="openResultList(getSelectedObjectType())"></p-button>
                        <p-button *ngIf="getSelectedObjectType() != null" class="p-button-sm" icon="fas fa-cog" label="Rendition Cache-fixen" (onClick)="fixRenditionCache(getSelectedObjectType())"></p-button>
                    </sm-toolbar>
                    <sm-table-on-push class="col-12" [deltaHeight]=350 [data]="fieldsTable" [allowGlobalFilter]=true></sm-table-on-push>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Events">
                <div class="grid">
                    <p-listbox class="col-3 full-height-240" scrollHeight="calc(100vh - 280)" [options]="visEvents" [(ngModel)]="selectedEvent" optionLabel="0" (onChange)="selectedEventChanged()">
                    </p-listbox>
                    <div class="col-9">
                        <sm-toolbar>
                            <p-button icon="fas fa-syntax" label="Syntax prüfen" (onClick)="syntaxCheck()">
                            </p-button>
                            <p-button icon="fas fa-save" label="Änderungen speichern" (onClick)="saveClientEvent()">
                            </p-button>
                        </sm-toolbar>
                        <app-ace-editor #aceEvent [text]="selectedEvent != null ? selectedEvent[1].code : ''" mode="vbscript" style="height:calc(100vh - 350px)"></app-ace-editor>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Import & Export">
                <sm-toolbar>
                    <p-button icon="fas fa-save" label="Exportieren" (onClick)="exportPrepare()"></p-button>
                    <p-button icon="fas fa-load" label="Importieren" (onClick)="importPrepare()"></p-button>
                </sm-toolbar>
                <app-enaio-import-options [def]="def" [options]="importOptions" (onAction)="import($event)">
                </app-enaio-import-options>
                <app-enaio-export-options [def]="def" [options]="exportOptions" (onAction)="export($event)">
                </app-enaio-export-options>
            </p-tabPanel>
            <p-tabPanel header="Benutzerrechte">
                <sm-table-on-push *ngIf="userRightsTable != null" [deltaHeight]=170 [data]="userRightsTable">
                </sm-table-on-push>
            </p-tabPanel>
            <p-tabPanel header="Relationen">
                <div class="grid">
                    <sm-table-on-push *ngIf="relationsFromTable != null" class="col-6" [deltaHeight]=150 [data]="relationsFromTable">
                    </sm-table-on-push>
                    <sm-table-on-push *ngIf="relationsToTable != null" class="col-6" [deltaHeight]=150 [data]="relationsToTable">
                    </sm-table-on-push>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Eindeutige/Mehrfache Objekte finden">
                <p-tabView [(activeIndex)]="findDupsTabIndex">
                    <p-tabPanel header="Abfrage">
                        <sm-toolbar>
                            <span>Maximale Ergebnismenge: <p-inputNumber [(ngModel)]="maxDups"></p-inputNumber></span>
                            <span class="ml-3">Finde: <p-dropdown [styleClass]="'width200'" [options]="[{label: 'Mehrfache', value: 2}, {label: 'Eindeutige', value: 1}]" optionLabel="label" optionValue="value" [(ngModel)]="dupType"></p-dropdown></span>
                            <span *ngIf="dupType == 2" class="ml-3">Minimalmenge pro Schlüsselkombination: <p-inputNumber [(ngModel)]="minCount"></p-inputNumber></span>
                            <p-button class="ml-3" icon="fas fa-load" label="Ermitteln" (onClick)="getDuplicateDatasets()"></p-button>
                        </sm-toolbar>
                        <sm-table-on-push [deltaHeight]=350 [data]="fieldsSelectTable"></sm-table-on-push>
                    </p-tabPanel>
                    <p-tabPanel *ngIf="duplicateDatasetsTable != null" header="Ergebnis">
                        <sm-toolbar *ngIf="noDupIds || duplicateDatasets.length > 0">
                            <p-button *ngIf="!noDupIds && duplicateDatasets.length > 0" icon="fas fa-copy" label="Doppelte IDs kopieren (außer Älteste)" (onClick)="copyDupIds(true)"></p-button>
                            <p-button *ngIf="!noDupIds && duplicateDatasets.length > 0" icon="fas fa-copy" label="Doppelte IDs kopieren (außer Neueste)" (onClick)="copyDupIds(false)"></p-button>
                            <p-splitButton *ngIf="getSelectedObjectType() != null" icon="fas fa-open" label="Trefferliste in enaio öffnen" (onClick)="openDupsInEnaio()" [model]="openDupsMenu"></p-splitButton>
                            <p-message *ngIf="noDupIds" severity="error" text="Das Anzeigen von IDs funktioniert nur für MSSQL >= 2017 oder Postgres"></p-message>
                        </sm-toolbar>
                        <sm-table-on-push class="col-8" [deltaHeight]=250 [data]="duplicateDatasetsTable"></sm-table-on-push>
                    </p-tabPanel>
                </p-tabView>
            </p-tabPanel>
            <p-tabPanel header="Feldwerte suchen">
                <div class="grid">
                    <div class="col-2">
                        <sm-form #form [form]="findValueForm">
                        </sm-form>
                        <p-button icon="fas fa-run" label="Suchen" (onClick)="findValues()"></p-button>
                    </div>
                    <sm-table-on-push class="col-10" [deltaHeight]=350 [data]="findValueTable"></sm-table-on-push>
            </div>
            </p-tabPanel>
            <p-tabPanel header="Historie">
                <div class="grid">
                    <div class="col-2">
                        <sm-form #form [form]="historyForm">
                        </sm-form>
                        <p-button icon="fas fa-run" label="Historie" (onClick)="loadHistory()"></p-button>
                    </div>
                    <sm-table-on-push class="col-10" [deltaHeight]=350 [data]="historyTable"></sm-table-on-push>
            </div>
            </p-tabPanel>
            <p-tabPanel header="Visualisierung"><div class="grid">
                <div class="col-2">
                    <app-plot-definition #plotDefComponent [fields]="plotFields" [plot]="plotDefinition"></app-plot-definition>
                    <p-button class="mt-3" icon="fas fa-chart-pie" label="Generieren" (onClick)="generatePlot()"></p-button>
                    </div>
                    <div class="col-10">
                        <app-plot #plot class="mt-3"></app-plot>
                    </div>
                    </div>
            </p-tabPanel>
        </p-tabView>
    </div>
    <app-progressor-overlay [progressor]="progressor.value"></app-progressor-overlay>
</app-wait-container>

<p-tabView [class.tab-view-no-pad]="tabIndex > 0" [(activeIndex)]="tabIndex" [controlClose]=true (onClose)="closeInstance($event)" (onChange)="tabIndexChange()">
    <p-tabPanel header="Konfigurationen">
        <div class="flex-row">
            <div *ngIf="inited" class="flex-col flex-item-fixed width400">
                <div class="flex-item-fixed">
                    <p-dropdown [styleClass]="'width100p'" [options]="customers" optionLabel="name" optionValue="name"
                     [(ngModel)]="selectedCustomer" (onChange)="changeCustomer()"></p-dropdown>
                <!--     <tag-button class="tb" value="F" severity="error" [active]="anyFilters" [clickable]=true toolTip="Filter" (onClick)="openFilterDialog()"></tag-button>-->
                </div>
                <p-tree #configsTreeComponent class="flex-item-fill full-height-200 tree-items-full-width" [value]="configsTree" selectionMode="single"
                    [(selection)]="configsTreeSelected" [contextMenu]="cm" (selectionChange)="selectConfig()">
                    <ng-template let-node pTemplate="default">
                        <tree-item-with-icons [label]="node.label" [icons]="_Utils.arrayWithoutNull([
                            node.data?.configInfo?.hasRuntimeError ? 'fas fa-bug' : null,
                            node.data?.configInfo?.hasPlannedTask ? 'fas fa-cog' : null,
                            node.data?.verificationFailed ? 'fas fa-mosquito col-red' : null,
                            !isFileActive(node.data) ? 'fas fa-circle-xmark' : null])"></tree-item-with-icons>
                    </ng-template>
                </p-tree>
            </div>
            <p-tabView class="flex-item-fill" [class.hidden]="_Utils.asSingle(configsTreeSelected)?.data == null || _Utils.asSingle(configsTreeSelected).data.type == _DhCustomerFileType.configFolder" [(activeIndex)]="subTabIndex" (onChange)="subTabIndexChange()">
                <p-tabPanel header="Datei editieren">
                    <sm-toolbar *ngIf="_Utils.asSingle(configsTreeSelected)?.data?.type == _DhCustomerFileType.config">
                        <p-button icon="fas fa-person-walking" [pTooltip]="'Starten'" (onClick)="run()">
                        </p-button>
                        <tag-button class="tb" value="D" severity="info" [active]="runParamsDebug" [clickable]=true toolTip="Debug-Modus an/aus" (onClick)="toggleRunParamsDebug()"></tag-button>
                        <tag-button class="tb" value="V" severity="warn" [active]="runParamsVerbose" [clickable]=true toolTip="Verbose" (onClick)="toggleRunParamsVerbose()"></tag-button>
                        <tag-button class="tb" value="S" severity="warn" [active]="runParamsSparse" [clickable]=true toolTip="Sparse" (onClick)="toggleRunParamsSparse()"></tag-button>
                        <tag-button class="tb" value="T" severity="error" [active]="runParamsSingleStep" [clickable]=true toolTip="Single Step" (onClick)="toggleRunParamsSingleStep()"></tag-button>
                        <tag-button class="tb" value="A" severity="error" [active]="runParamsSingleStepAfterEveryObject" [clickable]=true toolTip="Single Step nach jedem Objekt" (onClick)="toggleRunParamsSingleStepAfterEveryObject()"></tag-button>
                        <input pInputText class="p-inputtext-sm width300" type="text" placeholder="Weitere Kommandozeilenparameter" [(ngModel)]="runParamsOther"/>
                        <p-button icon="fas fa-ellipsis" [pTooltip]="'Weitere Kommandozeilenparameter'" (onClick)="setCommandLineParams()">
                        </p-button>
                        <p-button icon="fas fa-save" [pTooltip]="'Datei speichern'" [disabled]="isSaved()" (onClick)="save()">
                        </p-button>
                        <p-button icon="fas fa-highlighter" label="Format" (onClick)="prettify()">
                        </p-button>
                        <p-button icon="fas fa-highlighter" [label]="isCurrentFileActive() ? 'Config deaktivieren' : 'Config aktivieren'" (onClick)="switchConfigActive()">
                        </p-button>
                        <p-button icon="fas fa-attach" label="Attach" (onClick)="attach()">
                        </p-button>
                        <p-splitButton label="Verzeichnisse" icon="fas fa-folder" [model]="openDirItems"></p-splitButton>
                        <p-splitButton label="Auswertung" icon="fas fa-chart-pie" [model]="analyzeItems"></p-splitButton>
                        <p-splitButton label="Kunde" icon="fas fa-folder" [model]="customerItems"></p-splitButton>
                        <tag-button class="tb" value="H" severity="error" [active]="useExperimentalHelp" [clickable]=true toolTip="Experimentell: Hilfe/Code Completion/IntelliSense etc."(onClick)="toggleUseExperimentalHelp()"></tag-button>
                        <p-splitButton *ngIf="app.advancedDeveloper" label="DH-Entwickler" icon="fas fa-developer" [model]="dhDevItems"></p-splitButton>
                    </sm-toolbar>
                    <sm-toolbar *ngIf="_Utils.asSingle(configsTreeSelected)?.data?.type != _DhCustomerFileType.config">
                        <p-button icon="fas fa-save" label="Speichern" [disabled]="isSaved()" (onClick)="save()">
                        </p-button>
                    </sm-toolbar>
                    <app-ace-editor #editor mode="json" [(text)]="currentJson" style="height:calc(100vh - 300px)"></app-ace-editor>
                    <div *ngIf="useExperimentalHelp">
                        {{currentHelpText}}
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Fehler">
                    <div class="grid">
                        <sm-toolbar class="grid col-12">
                            <p-button icon="fas fa-redo-alt" label="Aktualisieren" (onClick)="getErrors()">
                            </p-button>
                            <p-button icon="fas fa-check" label="Alle Bestätigen" (onClick)="confirmAllErrors()">
                            </p-button>
                        </sm-toolbar>
                        <sm-table-on-push *ngIf="errorTable != null" class="col-12" [data]="errorTable" [deltaHeight]=320 (onCellClicked)="errorCellClicked($event)"></sm-table-on-push>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </p-tabPanel>
    <p-tabPanel *ngFor="let instance of runningInstances" [header]="instance.configName" [closable]=true>
        <app-document-handler-instance #instancePanels [guid]="instance.guid">
        </app-document-handler-instance>
    </p-tabPanel>
</p-tabView>
<p-contextMenu #cm [model]="configMenuItems"></p-contextMenu>
<app-progressor-overlay [progressor]="progressor.value" [showCalculated]=false></app-progressor-overlay>

import { Component, Directive, Input, TemplateRef } from '@angular/core';
import { FrontendFieldAddon } from '../../models/frontend-field-addon.model';
import { FrontendFieldDefinition } from '../../models/frontend-field-definition.model';

@Directive({
    selector: '[sm-form-field-wrapper-child]'
  })
  export class SmFormFieldWrapperChild {
    constructor(public templateRef: TemplateRef<unknown>) {
    }
  }

@Component({
  selector: 'app-sm-form-field-wrapper',
  templateUrl: './sm-form-field-wrapper.component.html',
  styleUrls: ['./sm-form-field-wrapper.component.scss']
})
export class SmFormFieldWrapperComponent {

    @Input()
    field: FrontendFieldDefinition = null;

    @Input()
    child: TemplateRef<any>;

    clickAddOn(addOn: FrontendFieldAddon, event: MouseEvent): void {
        if (this.field.onAddOnClick != null) {
            this.field.onAddOnClick(addOn, event);
        }
    }
}

/*eslint-disable*/
import { Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as ace from "ace-builds";
import { MenuItem } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { AceSelection } from '../../models/ace-selection.model';

@Component({
    selector: 'app-ace-editor',
    templateUrl: './ace-editor.component.html',
    styleUrls: ['./ace-editor.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AceEditorComponent),
        multi: true
    }]
})
export class AceEditorComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Output() textChanged = new EventEmitter();
    @Output() textChange = new EventEmitter();
    @Input() style: any = {};
    _options: any = {
        tabSize: 2
    };
    _readOnly: boolean = false;
    _theme: string = "github";
    _mode: any = "html";
    _autoUpdateContent: boolean = true;
    _editor: any;
    _durationBeforeCallback: number = 0;
    _text: string = "";
    oldText: any;
    timeoutSaving: any;

    highlights = [];
    dividers = [];

    @Output() contextMenu = new EventEmitter<AceSelection>();

    constructor(private app: MainAppService, elementRef: ElementRef, private zone: NgZone) {
        //Muss hier oben stehen, da ein Aufruf in init zu spät ist
        ace.config.set('basePath', '/assets/ace');
        let el = elementRef.nativeElement;
        this.zone.runOutsideAngular(() => {
            this._editor = ace['edit'](el);
        });
        this._editor.$blockScrolling = Infinity;
    }

    ngOnInit() {
        this.init();
        this.initEvents();
    }

    ngOnDestroy() {
        this._editor.destroy();
    }

    init() {
        this.setOptions(this._options || {});
        this.setTheme(this._theme);
        this.setMode(this._mode);
        this.setReadOnly(this._readOnly);

        this._editor.container.addEventListener("click", e => {
            if (e.ctrlKey) {
                let cursorPosition = this._editor.getCursorPosition();
                let token = this._editor.session.getTokenAt(cursorPosition.row, cursorPosition.column);

                if (token) {
                   /* var line = getLineForName(token.value);
                    if (line !== undefined) {
                        editor.gotoLine(line, 0, true);
                    }*/
                }
            }
        });

        this._editor.container.addEventListener("contextmenu", e => {
            e.preventDefault();

            let pos = this._editor.renderer.screenToTextCoordinates(e.clientX, e.clientY);
            let token = this._editor.session.getTokenAt(pos.row, pos.column);
            let selection = this._editor.getSelectedText();

            this.contextMenu.emit(new AceSelection(pos.row, pos.column, token.value, selection));

            return false;
        });

        this._editor.on("mousemove", e => {
            let pos = e.getDocumentPosition();
            let token = this._editor.session.getTokenAt(pos.row, pos.column);
        /*    console.log(token.type);
            if (token && token.type === "identifier") {
                this._editor.container.style.cursor = 'pointer';
            } else {
                this._editor.container.style.cursor = 'default';
            }
        */ });
    }

    initEvents() {
        this._editor.on('change', () => this.updateText());
        this._editor.on('paste', () => this.updateText());
    }

    updateText() {
        let newVal = this._editor.getValue();
        if (newVal === this.oldText) {
            return;
        }
        if (!this._durationBeforeCallback) {
            this._text = newVal;
            this.zone.run(() => {
                this.textChange.emit(newVal);
                this.textChanged.emit(newVal);
            });
            this._onChange(newVal);
        } else {
            if (this.timeoutSaving) {
                clearTimeout(this.timeoutSaving);
            }

            this.timeoutSaving = Utils.setTimerOnce(this._durationBeforeCallback, () => {
                this._text = newVal;
                this.zone.run(() => {
                    this.textChange.emit(newVal);
                    this.textChanged.emit(newVal);
                });
                this.timeoutSaving = null;
            });
        }
        this.oldText = newVal;
    }

    @Input() set options(options: any) {
        this.setOptions(options);
    }

    setOptions(options: any) {
        this._options = options;
        this._editor.setOptions(options || {});
    }

    @Input() set readOnly(readOnly: any) {
        this.setReadOnly(readOnly);
    }

    setReadOnly(readOnly: any) {
        this._readOnly = readOnly;
        this._editor.setReadOnly(readOnly);
    }

    @Input() set theme(theme: any) {
        this.setTheme(theme);
    }

    setTheme(theme: any) {
        this._theme = theme;
        this._editor.setTheme(`ace/theme/${theme}`);
    }

    @Input() set mode(mode: any) {
        this.setMode(mode);
    }

    setMode(mode: any) {
        this._mode = mode;
        if (typeof this._mode === 'object') {
            this._editor.getSession().setMode(this._mode);
        } else {
            this._editor.getSession().setMode(`ace/mode/${this._mode}`);
        }
    }

    get value() {
        return this.text;
    }

    @Input()
    set value(value: string) {
        this.setText(value);
    }

    writeValue(value: any) {
        this.setText(value);
    }

    private _onChange = (_: any) => {
    };

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    private _onTouched = () => {
    };

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    get text() {
        return this._text;
    }

    @Input()
    set text(text: string) {
        this.setText(text);
    }

    setText(text: any) {
        if (text === null || text === undefined) {
            text = "";
        }
        if (this._text !== text && this._autoUpdateContent === true) {
            this._text = text;
            this._editor.setValue(text);
            this._onChange(text);
            this._editor.clearSelection();
        }
    }

    @Input() set autoUpdateContent(status: any) {
        this.setAutoUpdateContent(status);
    }

    setAutoUpdateContent(status: any) {
        this._autoUpdateContent = status;
    }

    @Input() set durationBeforeCallback(num: number) {
        this.setDurationBeforeCallback(num);
    }

    setDurationBeforeCallback(num: number) {
        this._durationBeforeCallback = num;
    }

    getEditor() {
        return this._editor;
    }

    focus() {
        this._editor.focus();
    }

    jumpToLine(line: number, highlight?: boolean): void {
        this._editor.scrollToLine(line, true, true, function () {});
        this._editor.gotoLine(line, 0, true);
        this.highlightLine(line);
    }

    highlightLine(lineNumber: number) {
        let Range = ace.require('ace/range').Range;

        for (let highlight of this.highlights) {
            this._editor.session.removeMarker(highlight);
        }
        this.highlights.push(this._editor.session.addMarker(new Range(lineNumber - 1, 0, lineNumber - 1, Number.MAX_VALUE), "custom-highlight", "fullLine"));
    }

    addDivider(lineNumber: number) {
        var Range = ace.require('ace/range').Range;
        var range = new Range(lineNumber, 0, lineNumber, 1);
        for (let divider of this.dividers) {
            this._editor.session.removeMarker(divider);
        }
        this.dividers.push(this._editor.session.addMarker(range, "line-divider", "fullLine"));

      /*  var lineWidget = {
            row: lineNumber,
            fixedWidth: true,
            coverGutter: false,
            el: document.createElement("div"),
            type: "lineWidget"
        };

        lineWidget.el.className = 'line-divider';
        lineWidget.el.style.height = '2px';
        lineWidget.el.style.width = '100%';
        lineWidget.el.style.backgroundColor = '#000';

        this._editor.session.addDynamicMarker(lineWidget, true);*/
    }

}

import { Type } from "class-transformer";
import { FrontendFieldListItem } from "src/modules/sm-base/models/frontend-field-list-item.model";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { ConfigDefinition } from "./config-definition.model";
import { ConfigFieldDefinition } from "./config-field-definition.model";
import { ConfigProperty } from "./config-property.model";

export class ConfigClassDefinition {
    name: string;
    baseName: string;
    @Type(() => ConfigFieldDefinition)
    fields: ConfigFieldDefinition[];
    subClasses: ConfigClassDefinition[] = [];
    guiListItems: FrontendFieldListItem[] = [];

    makeDefaultObject(): OrdinaryObject {
        let result: OrdinaryObject = {};
        for (let field of this.fields) {
            result[field.name] = field.defaultValue;
        }
        return result;
    }

    getProperties(o: any, globalDef: ConfigDefinition): ConfigProperty[] {
        return Utils.arrayWithoutNull(this.fields.map(field => field.toProperty(o, globalDef)));
    }

    isDerivedFrom(typeName: string, globalDef: ConfigDefinition): boolean {
        return this.baseName == typeName || globalDef.getByName(this.baseName)?.isDerivedFrom(typeName, globalDef);
    }

    getSimpleName(): string {
        return Utils.getSubstringFromLast(this.name, ".");
    }
}

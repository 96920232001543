import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassDuty } from '../../shared/class-duty.entity';
import { ClassRegister } from '../../shared/class-register.entity';
import { Pupil } from '../../shared/pupil.model';

@Component({
    selector: 'app-class-duties',
    templateUrl: './class-duties.component.html',
    styleUrls: ['./class-duties.component.scss']
})
export class ClassDutiesComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;

    schoolClassId: number;
    classRegister: ClassRegister;
    showDivider = false;

    dutyNames: string[] = [];
    rows: ClassDuty[] = [];
    orgRows: ClassDuty[] = [];
    classPupils: Pupil[];
    pupilsGui: FrontendFieldListItem[] = [];
    pupilsGuiSubstitute: FrontendFieldListItem[] = [];
    isPupil: OrdinaryObject<boolean> = {};
    classDuties: ClassDuty[] = [];

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, ["duties"], null, true);
        this.dutyNames = await RestEndpoint.main().query({ year: this.classRegister.enaioSchoolClass.getYearNumber(), isHalfYear: false }).run("api/cer/classregister/dutynames").listStrings();
        this.classPupils = [new Pupil(), ...await this.service.restGetPupils(this.classRegister.schoolClassId)];
        this.pupilsGui = this.classPupils.map(d => new FrontendFieldListItem(d.id, d.id == 0 ? "" : d.getFullName()));
        this.pupilsGuiSubstitute = this.pupilsGui.filter(p => p.value != 0);

        this.rows = [];
        for (let entry of this.classRegister.duties) {
            this.classDuties.push(entry);
        }

        for (let dutyname of this.dutyNames) {
            let cd: ClassDuty = new ClassDuty();
            cd.customInitializer();
            cd.type = dutyname;
            this.rows.push(cd);
            this.classDuties.push(cd);
        }

        for (let entry of this.classRegister.duties) {
            this.rows[this.rows.findIndex(e => e.type == entry.type)] = entry;
        }

        for (let entry of this.rows) {
            this.isPupil[entry.type] = entry.main == 0 && entry.mainText == "" || entry.main != 0;
        }

        this.orgRows = Utils.cloneDeep(this.rows);

        this.app.updateNavigation(null, [
            { label: "Digitales Klassenbuch", routerLink: ["/enaio-certificates", "class-registers", "home"] },
            { label: "Klasse " + this.classRegister.enaioSchoolClass.getDisplayName(), routerLink: ["/enaio-certificates", "class-registers", "class-register", this.id] },
            { label: "Klassenämter", routerLink: ["/enaio-certificates", "class-registers", "class-duties", this.id] }]);

        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? "" : null;
    }

    isChanged(): boolean {
        return !Utils.equalsDeep(this.rows, this.orgRows);
    }

    async save(): Promise<void> {
        await this.app.saveDataHandler(async () => RestEndpoint.main().query({ id: this.id }).body(this.rows).post().run("api/cer/classregister/classDuties").getText(), this, true, true);
    }

    addRow(): void {
        this.rows.push(new ClassDuty());
    }

    changeClassDuty(event: any, row: ClassDuty): void {
        row.type = event;
    }

    changeMain(event: any, row: ClassDuty): void {
        if (typeof event == "string") {
            row.main = 0;
            row.mainText = event;
        }
        else {
            row.mainText = "";
            row.main = event;
        }
    }

    changeSubstitutes(event: any, row: ClassDuty): void {
        row.substitutes = event;
    }

    getPupil(pupilId: number): string {
        let pupil: Pupil = this.classPupils.find(p => p.id == pupilId);
        return pupil.firstName + " " + pupil.lastName;
    }

    getPupilList(pupilIds: number[]): string[] {
        let list: string[] = [];

        for (let pId of pupilIds) {
            let pupil: Pupil = this.classPupils.find(p => p.id == pId);
            list.push(pupil.firstName + " " + pupil.lastName);
        }
        return list;
    }

    changeIsPupil(event: any, rowType: string): void {
        let row: ClassDuty = this.rows.find(r => r.type == rowType);
        if (event.checked) {
            row.mainText = "";
            row.substitutesText = "";
        }
        else {
            row.main = 0;
            row.substitutes.splice(0);
        }
    }
}

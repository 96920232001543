import { Transform, Type } from "class-transformer";
import { SimLogStatKey } from "src/modules/enaio/shared/SimLogStatKey";
import { KeyValuePair } from "src/modules/utils/shared/key-value-pair.model";
import { Utils } from "src/modules/utils/shared/utils";

export class HandlerConfigState {
    configName: string;
    workObjectsCount = 0;
    currentWorkObjectIndex = 0;
    currentWorkObjectName: number;
    currentWorkerName: string;
    sparse: boolean;
    verbose: boolean;
    singleStep: boolean;
    singleStepAfterEveryObject: boolean;
    breakpointReached: boolean;
    breakpointRequested: boolean;
    globalLoopItem: string;
    finished: boolean;
    errorCount: number;
    @Type(() => KeyValuePair)
    @Transform(params => {
        for (let o of params.value) {
            o.Key = Utils.fromPlain(SimLogStatKey, o.Key as Partial<SimLogStatKey>);
        }
        return params.value;
    })
    actionCount: KeyValuePair<SimLogStatKey, number>[] = [];
}

<div class="grid">
    <sm-table-on-push *ngIf="table != null" class="col-12" [data]="table" [scrollHeight]="'calc(50vh - 100px)'" (onRowSelect)="rowSelect($event)">
    </sm-table-on-push>
    <p-tabView class="col-12" [style.height]="'calc(50vh - 100px)'">
        <p-tabPanel header="Expressions">
            <sm-table-on-push *ngIf="expressionsTable != null" [data]="expressionsTable" [scrollHeight]="'calc(50vh - 100px)'">
            </sm-table-on-push>
        </p-tabPanel>
        <p-tabPanel header="Quellcode">
            <textarea #textAreaSourceCode *ngIf="export != null" spellcheck="false" wrap="off" pInputTextarea class="source-code mono" [ngModel]="export.source"></textarea>
        </p-tabPanel>
        <p-tabPanel header="Fehlermeldung">
            <textarea #textAreaSourceCode *ngIf="export != null" spellcheck="false" pInputTextarea class="source-code mono" [ngModel]="export.errorMessage"></textarea>
        </p-tabPanel>
    </p-tabView>

</div>

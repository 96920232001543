import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { TableCell } from 'src/modules/sm-base/models/table-cell.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { Utils } from 'src/modules/utils/shared/utils';
import { FrontendFieldDefinition } from '../../models/frontend-field-definition.model';
import { FrontendFormDefinition } from '../../models/frontend-form-definition.model';
import { ObjectActionWithObject } from '../../shared/object-action-with-object.model';
import { TableCellType } from '../../models/table-cell-type.enum';
import { ObjectAction } from '../../shared/object-action.model';
import { SmTableComponent } from '../sm-table/sm-table.component';
import { FrontendFieldListItem } from '../../models/frontend-field-list-item.model';

@Component({
    selector: 'sm-table-cell',
    templateUrl: './sm-table-cell.component.html',
    styleUrls: ['./sm-table-cell.component.scss']
})
export class SmTableCellComponent implements OnInit {

    static globalActions: ObjectAction[] = [];

    _Utils = Utils;

    _TableCellType = TableCellType;
    @Input()
    type: TableCellType;
    _value: any;
    @Input()
    editable = false;
    @Input()
    editableMaxLength = 0;
    @Input()
    showNull = true;
    @Input()
    actionButtons = false;
    @Input()
    noWrapCell = false;
    _editor: FrontendFieldDefinition;
    @Output()
    clicked = new EventEmitter<string>();
    tableCell: TableCell;
    @Output()
    valueChange = new EventEmitter<any>();
    @Input()
    parentTable: SmTableComponent;
    @Input()
    columnIdOnParent: string;

    editorForm: FrontendFormDefinition;
    mouseInside = false;

    constructor(private app: MainAppService, private el: ElementRef, private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.renderer.listen(this.el.nativeElement, 'mouseenter', _ => {
        this.mouseEnter();
        });
        this.renderer.listen(this.el.nativeElement, 'mouseleave', _ => {
            this.mouseLeave();
        });
    }

    @Input()
    set editor(value: FrontendFieldDefinition) {
        this._editor = value;
        this.editorForm = value == null ? null : new FrontendFormDefinition([value]);
        if (this.editorForm != null) {
            value.hasLabel = false;
            value.onValueChanged = this.afterFormEdit.bind(this);
        }
    }

    @Input()
    set value(value: any) {
        if (value instanceof TableCell) {
            this._value = value.value;
            this.tableCell = value;
        }
        else {
            this._value = value;
            this.tableCell = null;
        }
    }

    buttonClick(event: any, icon: string = null): void {
        this.clicked.emit(icon);
        event.preventDefault();
    }

    switchBool(): void {
        this.setValue(!this._value);
    }

    afterFormEdit(): void {
        this._value = this.editorForm.fields[0].value;
        this.afterEdit();
    }

    afterEdit(): void {
        this.valueChange.emit(this._value);
    }

    private setValue(value: any): void {
        this._value = value;
        if (this.tableCell != null) {
            this.tableCell.value = value;
        }
        this.valueChange.emit(value);
    }

    getListItemSpeakingName(): string {
        if (this._editor?.listItems == null) {
            return this._value;
        }
        let result = this._editor.listItems.find(li => li.value == (this._value instanceof FrontendFieldListItem ? this._value.value : this._value));
        return result != null ? result.label : this.value;
    }

    mouseEnter(): void {
        this.mouseInside = true;
        if (this.actionButtons) {
            this.changeDetectorRef.detectChanges();
        }
    }

    mouseLeave(): void {
        this.mouseInside = false;
        if (this.actionButtons) {
            this.changeDetectorRef.detectChanges();
        }
    }

    getObjectActions(): ObjectActionWithObject[] {
        let s = Utils.toString(this._value);
        return [
            new ObjectActionWithObject({
                getCaption: () => "In Zwischenablage kopieren",
                isUsable: value => Utils.isString(value),
                perform: async value => {
                    await GuiUtils.copyToClipboard(value as string);
                    return null;
                }
            }, s),
            new ObjectActionWithObject({
                getCaption: () => "Text in Fenster öffnen",
                isUsable: value => Utils.isString(value),
                perform: value => {
                    this.app.messageDialog.textArea("Zellentext", value as string, null, true);
                    return null;
                }
            }, s),
            new ObjectActionWithObject({
                getCaption: () => "Texte dieser Spalte in Fenster öffnen",
                isUsable: _ => this.parentTable != null && !Utils.isNoe(this.columnIdOnParent),
                perform: _ => {
                    this.app.messageDialog.textArea("Spaltenwerte", Utils.arrayItemsToString(this.parentTable._data.rows, "\n", row => row.values[this.columnIdOnParent]), null, true);
                    return null;
                }
            }, s),
            new ObjectActionWithObject({
                getCaption: () => "Nur Zeilen mit diesem Wert anzeigen",
                isUsable: _ => this.parentTable?.allowFilter && !Utils.isNoe(this.columnIdOnParent),
                perform: (value) => {
                    this.parentTable.setFilter(this.columnIdOnParent, value);
                    return null;
                }
            }, s),
            ...SmTableCellComponent.globalActions.map(a => new ObjectActionWithObject(a, s)),
            ...this.tableCell?.internal == null || this.tableCell?.actionProviders == null ? [] :
                Utils.arrayExplode(this.tableCell.actionProviders, ap => Utils.arrayExplode(this.tableCell.internal, key => ap.getActions(key).map(a => new ObjectActionWithObject(a, key))))
        ];
    }

    actionButtonClick(event: any): void {
        let menu: MenuItem[] = this.getObjectActions().filter(a => a.action.isUsable(a.on)).map(a => ({
            label: a.action.getCaption(a.on),
            icon: "fas fa-open",
            command: async _ => a.action.perform(a.on)
        }));
        this.app.openContextMenu(menu);
        event.preventDefault();
    }

    handleTooltip(event: any): void {
        const element = event.element;
        element.setAttribute('title', Utils.toString(this._value)); //Utils.replaceAll(Utils.replaceAll(Utils.toString(this._value), "\n", "&#13;"), "\r", "&#10;"));
      }

}

import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { Pupil } from 'src/modules/enaio-certificates/shared/pupil.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { Certificate } from '../../shared/certificate.entity';

@Component({
    selector: 'enaio-certificates-pupil-list',
    templateUrl: './pupil-list.component.html',
    styleUrls: ['./pupil-list.component.scss']
})
export class PupilListComponent extends ComponentView {

    _Utils = Utils;

    service = inject(EnaioCertificateService);

    schoolClassId: number;
    isHalfYear: boolean;
    isAdmin = false;
    pupils: Pupil[] = [];

    constructor() {
        super();
        this.neededParams = { schoolClassId: "number", isHalfYear: "bool" };
    }

    async initParams(): Promise<boolean> {
        let restResult = await Promise.all([
            this.service.restGetPupils(this.schoolClassId),
            RestEndpoint.main().find({ schoolClassId: this.schoolClassId, onlyStatus: true }).run("api/cer/certificate").list(Certificate),
            this.service.restGetSchoolClass(this.schoolClassId)
        ]);
        this.pupils = restResult[0];
        for (let certificate of restResult[1]) {
            let found = this.pupils.find(p => p.id === certificate.pupilId);
            if (found != null) {
                found.certificateStatus = certificate.status;
            }
        }
        this.isAdmin = await this.service.isAdminForCertificates();
        this.app.updateNavigation(null, [
            this.service.getHomeCertificates(),
            { label: "Klasse " + restResult[2].getDisplayName(), routerLink: ["/enaio-certificates", "pupils", this.schoolClassId, this.isHalfYear ? 1 : 0] }
        ]);
        return true;
    }

    async selectRow(event): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "certificate", this.schoolClassId, this.isHalfYear ? 1 : 0, event.data.id]);
    }

    async refresh(): Promise<void> {
        this.app.showSpinner = true;
        let changes = await RestEndpoint.main().query({id: this.schoolClassId, isHalfYear: this.isHalfYear, previewOnly: true}).run("api/cer/certificate/refreshForSchoolClass").listStrings();
        if (changes.length === 0) {
            this.app.showSpinner = false;
            await this.app.messageDialog.info("Es wurden keine Änderungen in den zugrundeliegenden Kompetenzen festgestellt. Die Zeugnisse bleiben unverändert");
        }
        else {
            this.app.showSpinner = false;
            if (await this.app.messageDialog.yesNo("Folgende Änderungen werden vorgenommen. Fortfahren?<br><br>" + Utils.arrayItemsToString(changes, "<br>"), "Bestätigung")) {
                this.app.showSpinner = true;
                await RestEndpoint.main().query({id: this.schoolClassId, isHalfYear: this.isHalfYear, previewOnly: false}).run("api/cer/certificate/refreshForSchoolClass").listStrings();
                this.app.showSpinner = false;
            }
        }

        this.app.showSpinner = false;
    }


}

import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningUserFlat } from '../../shared/learning-user-flat.model';

@Component({
    selector: 'app-edit-learning-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent extends ComponentView {

    service = inject(EnaioLearnService);

    id: number;
    item: LearningUserFlat;
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.form = this.app.tForm("lea.user", new FrontendFormDefinition([
            new FrontendFieldDefinition("userName", null, FrontendFieldType.text, { enabled: false }),
            new FrontendFieldDefinition("firstName", null, FrontendFieldType.text, { enabled: false }),
            new FrontendFieldDefinition("lastName", null, FrontendFieldType.text, { enabled: false }),
            new FrontendFieldDefinition("email", null, FrontendFieldType.text, { enabled: false }),
            new FrontendFieldDefinition("joinedDate", null, FrontendFieldType.datePicker, { enabled: false }),
            new FrontendFieldDefinition("costCenterString", null, FrontendFieldType.text, { enabled: false }),
            new FrontendFieldDefinition("jobDescriptionString", null, FrontendFieldType.text, { enabled: false }),
            new FrontendFieldDefinition("isAdmin", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("mayModifyItems", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("mayRunAnalyses", null, FrontendFieldType.checkBox)
        ]));

        this.item = await this.app.loadDataHandler(async () => RestEndpoint.main().findByIdOrEmpty(LearningUserFlat, this.id).run("api/lrn/learninguserflat").get(LearningUserFlat), this);

        (this.item as any).costCenterString = Utils.arrayItemsToString(this.item.costCenterNames);
        (this.item as any).jobDescriptionString = Utils.arrayItemsToString(this.item.jobDescriptionNames);

        this.form.fill(this.item);
        this.service.updateAdminNavigation("lea.user", this.item, "users", "user");
        return true;
    }

    async save(): Promise<void> {
        if (!this.app.validateForm(this.form)) {
            return;
        }

        this.form.get(this.item);
        await this.app.saveDataHandler(async () => RestEndpoint.main().upsert(this.item).run("api/lrn/learninguserflat").getText(), this, true, true);
    }

}

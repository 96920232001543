<ng-container>
    <div class="grid">
        <sm-toolbar class="col-12">
            <ng-container *ngIf="personellGui?.length > 0">Personalakte: <p-dropdown [styleClass]="'width300'" [options]="personellGui" [(ngModel)]="selectedPersonell" (onChange)="change()"></p-dropdown></ng-container>
            <span *ngIf="documentTypesGui?.length > 0" class="ml-3">Dokumentart: <p-dropdown [styleClass]="'width300'" [options]="documentTypesGui" [(ngModel)]="selectedDocumentType" (onChange)="change()"></p-dropdown></span>
        </sm-toolbar>
    </div>
    <app-enaio-documents-definition #defComponent [embeddedComponent]=true [defId]="selectedDocumentType">

    </app-enaio-documents-definition>
</ng-container>

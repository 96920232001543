/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { CustomInitializer } from "src/modules/sm-base/shared/custom-initializer.interface";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, OneToMany } from "typeorm";
import { CertificateDomain } from "./certificate-domain.entity";
import { CertificateGrade } from "./certificate-grade.entity";
import { CertificateStatus } from "./certificate.status.enum";
import { Competency } from "./competency.model";
import { GuiCertificateDomain } from "./gui-certificate-domain.model";
import { GuiCertificate } from "./gui-certificate.model";
import { GuiMarkAssignment } from "./gui-mark-assignment.model";
import { MarkAssignment } from "./mark-assignment.entity";
import { CourseParticipantDto } from "./course-participant.dto";
import { Map2 } from "src/modules/utils/shared/map2.model";

@Entity()
export class Certificate extends SmBaseEntity implements CustomInitializer {
    @Column({default: 0})
    pupilId: number = 0;

    @Column({default: 0})
    schoolClassId: number = 0;

    @Column({default: false})
    isHalfYear: boolean = false;

    @Column({default: 0})
    enaioId: number = 0;

    @Column({default: 0})
    halfYearEnaioId: number = 0;

    @Column({default: CertificateStatus.none})
    status: CertificateStatus = CertificateStatus.none;

    @OneToMany((_type) => CertificateDomain, o => o.certificate)
    @Type(() => CertificateDomain)
    domains: CertificateDomain[];

    @OneToMany((_type) => MarkAssignment, o => o.certificate)
    @Type(() => MarkAssignment)
    marks: MarkAssignment[];

    @OneToMany(() => CertificateGrade, o => o.certificate)
    @Type(() => CertificateGrade)
    certificateGrades: CertificateGrade[];

    customInitializer(): void {
        this.domains = Utils.arrayEnsure(this.domains);
        this.marks = Utils.arrayEnsure(this.marks);
        this.certificateGrades = Utils.arrayEnsure(this.certificateGrades);
    }

    static fromGui(other: GuiCertificate): Certificate {
        let result = new Certificate();
        result.id = other.id;
        result.pupilId = other.pupilId;
        result.schoolClassId = other.schoolClassId;
        result.isHalfYear = other.isHalfYear;
        result.status = other.status;
        result.domains = [];
        result.marks = [];
        for (let guiDomain of other.domains.values()) {
            let domain = new CertificateDomain();
            result.domains.push(domain);
            domain.id = guiDomain.id;
            domain.domain = guiDomain.domain;
            domain.teacher = guiDomain.teacher;
            domain.topics = guiDomain.topics;
            for (let guiMark of guiDomain.marksFlat) {
                let mark = new MarkAssignment();
                result.marks.push(mark);
                mark.id = guiMark.id;
                mark.competencyId = guiMark.competencyId;
                mark.courseId = guiMark.courseId;
                mark.domain = guiMark.domain;
                mark.level1 = guiMark.level1;
                mark.level2 = guiMark.level2;
                mark.title = guiMark.title;
                mark.sortDomain = guiMark.sortDomain;
                mark.sortLevel1 = guiMark.sortLevel1;
                mark.sortLevel2 = guiMark.sortLevel2;
                mark.sortTitle = guiMark.sortTitle;
                mark.optional = guiMark.optional;
                mark.mark = guiMark.mark;
                mark.trend = guiMark.trend;
                mark.note = guiMark.note;
                mark.hidden = guiMark.hidden;
                mark.assignedBy = guiMark.assignedBy;
                mark.assignedDate = guiMark.assignedDate;
            }
        }

        return result;
    }

    toGui(competencies: Competency[] = null, hiddenCompetencyIds: number[] = [], secondLanguage: string = null, courses: CourseParticipantDto[] = []): GuiCertificate {
        let result = new GuiCertificate();
        result.id = this.id;
        result.pupilId = this.pupilId;
        result.schoolClassId = this.schoolClassId;
        result.isHalfYear = this.isHalfYear;
        result.status = this.status;
        result.certificateGrades = this.certificateGrades;
        result.domains = new Map2<string, GuiCertificateDomain>();

        for (let domain of Utils.arraySafeIt(this.domains)) {
            let guiDomain = new GuiCertificateDomain();
            result.domains.set(domain.domain, guiDomain);
            guiDomain.id = domain.id;
            guiDomain.domain = domain.domain;
            guiDomain.teacher = domain.teacher;
            guiDomain.topics = domain.topics;
            guiDomain.averageGrade = domain.averageGrade;
        }

        let marks = [...this.marks];

        if (secondLanguage == "WPU") {
            let d = result.domains.get("WPU");
            if (d == null) {
                d = new GuiCertificateDomain();
                d.domain = "WPU";
                result.domains.set("WPU", d);
            }

            if (courses != null) {
                for (let course of courses) {
                    let mark = marks.find(m => m.courseId == course.courseId);
                    if (mark == null) {
                        mark = Utils.fromPlain(MarkAssignment, {
                            courseId: course.courseId,
                            domain: "WPU",
                            title: course.courseNameForCertificate
                        });
                        marks.push(mark);
                    }
                }
                if (courses.length > 0) {
                    let mark = marks.find(m => m.courseId == 1_000_000_000);
                    if (mark == null) {
                        mark = Utils.fromPlain(MarkAssignment, {
                            courseId: 1_000_000_000,
                            domain: "WPU",
                            title: "Wahlpflichtunterricht gesamt"
                        });
                        marks.push(mark);
                    }
                }

                marks = marks.filter(mark => mark.courseId == 0 || mark.courseId == 1_000_000_000 || mark.mark > 0 || courses.some(item => item.courseId == mark.courseId));
            }
        }

        for (let mark of Utils.arraySafeIt(marks)) {
            let guiDomain = result.domains.get(mark.domain);
            if (guiDomain == null) {
               console.log("Kein Fach mit dem Namen " + mark.domain + " gefunden");
            }
            else {
                guiDomain.marks.getOrAdd(mark.level1, () => new Map2<string, GuiMarkAssignment[]>()).getOrAdd(mark.level2, () => []).push(mark.toGui());
            }
        }

        if (this.id === 0 && competencies != null) {
            for (let competency of competencies) {
                let guiDomain = result.domains.getOrAdd(competency.domain, () => Utils.fromPlain(GuiCertificateDomain, { domain: competency.domain }));
                //TODO Warum doppelt?
                if (guiDomain.topics === "" && competency.topics !== "") {
                    guiDomain.topics = competency.topics;
                }
                guiDomain.marks.getOrAdd(competency.level1, () => new Map2<string, GuiMarkAssignment[]>()).getOrAdd(competency.level2, () => []).push(
                    Utils.fromPlain(MarkAssignment, {
                        competencyId: competency.id,
                        domain: competency.domain,
                        level1: competency.level1,
                        level2: competency.level2,
                        title: competency.title,
                        sortDomain: competency.sortDomain,
                        sortLevel1: competency.sortLevel1,
                        sortLevel2: competency.sortLevel2,
                        sortTitle: competency.sortTitle,
                        optional: competency.optional,
                        note: competency.remark,
                        hidden: hiddenCompetencyIds.includes(competency.id)
                    }).toGui()
                );
                if (guiDomain.topics === "" && competency.topics !== "") {
                    guiDomain.topics = competency.topics;
                }
            }
        }

        for (let domain of result.domains.values()) {
            domain.hasLevel2 = false;
            domain.level1SortPrios = {};
            domain.level2SortPrios = {};
            for (let level1 of domain.marks.keys()) {
                for (let level2 of domain.marks.get(level1).keys()) {
                    for (let item of domain.marks.get(level1).get(level2)) {
                        domain.level1SortPrios[level1] = Math.min(
                            item.sortLevel1 > 0 ? item.sortLevel1 : 999_999,
                            level1 in domain.level1SortPrios ? domain.level1SortPrios[level1] : 999_999
                        );
                        domain.level2SortPrios[
                            level1 + "|" + level2
                        ] = Math.min(
                            item.sortLevel2 > 0 ? item.sortLevel2 : 999_999,
                            level2 in domain.level2SortPrios ? domain.level2SortPrios[level1 + "|" + level2] : 999_999
                        );
                    }
                }
            }

            for (let level1 of Utils.arrayFromIt(domain.marks.keys()).sort((a, b) => Utils.cmp(domain.level1SortPrios[a], domain.level1SortPrios[b])
            )) {
                let level1Span = 0;
                for (let level2 of domain.marks.get(level1).keys()) {
                    level1Span += domain.marks.get(level1).get(level2).length;
                }
                let l1First = true;
                for (let level2 of Utils.arrayFromIt(
                    domain.marks.get(level1).keys()
                ).sort((a, b) => Utils.cmp(domain.level2SortPrios[level1 + "|" + a], domain.level2SortPrios[level1 + "|" + b]))) {
                    let l2First = true;
                    for (let item of domain.marks.get(level1).get(level2).sort((a, b) => Utils.cmp(a.sortTitle, b.sortTitle))) {
                        item.level1Span = l1First ? level1Span : 0;
                        item.level2Span = l2First ? domain.marks.get(level1).get(level2).length : 0;
                        domain.marksFlat.push(item);
                        l1First = false;
                        l2First = false;
                        domain.hasLevel2 ||= level2 !== "";
                    }
                }
            }
        }

        return result;
    }

    checkIntegrity(): void {
        for (let mark of this.marks) {
            mark.checkIntegrity();
        }
    }

    fixMissingDomains(): void {
        for (let mark of Utils.arraySafeIt(this.marks)) {
            let domain = this.domains.find(d => d.domain == mark.domain);
            if (domain == null) {
                this.domains.push(Utils.fromPlain(CertificateDomain, {
                    domain: mark.domain
                }));
            }
        }
    }
}

import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";
import { EnaioObjectTypeImportParams } from "./EnaioObjectTypeImportParams";

export class EnaioArchiveImportParams {
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    includeChildren = true;
    documents = true;
    import = true;
    @Type(() => EnaioObjectId)
    destArchive: EnaioObjectId;
    @Type(() => EnaioObjectTypeImportParams)
    objectTypes: EnaioObjectTypeImportParams[];
}

<div class="grid">
    <div class="col-2">
        <sm-form  [form]="formFilter">
        </sm-form>
        <p-button class="toolbar-element" icon="fas fa-filter" label="Holen" (onClick)="get()"></p-button>
        <p-button class="toolbar-element" icon="fas fa-filter" label="Alle Holen & Log Rest" (onClick)="getAll()"></p-button>
    </div>
    <p-card class="col-10">
        <sm-table-on-push *ngIf="table != null" [data]="table" [deltaHeight]=150></sm-table-on-push>
    </p-card>
</div>
<app-progressor-overlay [progressor]="progressor.value"></app-progressor-overlay>

import { Utils } from "src/modules/utils/shared/utils";

export class FrontendScriptDefinition {
    id: string;
    name: string;
    parents: string[];
    includes: string[];
    language: string;
    code: string;
    raw: any;

    getFullName(): string {
        return Utils.arrayItemsToString([...this.parents, this.name], "->");
    }
}

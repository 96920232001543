<p-table
    #table
    *ngIf="_data != null"
    [class.p-datatable-striped]="striped"
    [styleClass]="gridLines ? 'p-datatable-gridlines' : undefined"
    [columns]="_data.columns"
    [value]="_data.rows"
    [selectionMode]="allowSelection ? 'single' : undefined"
    [showInitialSortBadge]="false"
    [sortMode]="allowSort ? 'multiple' : 'single'"
    [autoLayout]="true"
    [multiSortMeta]="multiSortMeta"
    [customSort]="true"
    [groupRowsBy]="_Utils.isNoe(groupRowsBy) ? undefined : 'values.' + groupRowsBy"
    [rowGroupMode]="_Utils.isNoe(groupRowsBy) ? undefined : 'subheader'"
    [scrollHeight]="getDeltaHeight() > 0 ? 'calc(100vh - ' + getDeltaHeight() + 'px)': scrollHeight"
    [scrollable]="getDeltaHeight() > 0 || scrollHeight != null"
    [virtualScroll]="(getDeltaHeight() > 0 || scrollHeight != null) && virtualScrollHeight > 0 && _data?.rows?.length * _data.columns?.length >= 500"
    [virtualScrollDelay]=1
    [virtualScrollItemSize]="getDeltaHeight() > 0 || scrollHeight != null ? virtualScrollHeight : null"
    [globalFilterFields]="allowGlobalFilter ? filterFieldNames : undefined"
    [(selection)]="_selection"
    breakpoint="680px"
    (sortFunction)="customSort($event)"
    (onRowSelect)="_onRowSelect($event)">
    <ng-template *ngIf="allowGlobalFilter" pTemplate="caption">
        <div class="flex">
            <span class="p-input-icon-left ml-auto">
                <i class="fas fa-search"></i>
                <input pInputText type="text" (input)="filterGlobal($event)" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let column of _data.columns; index as index" class="align-left" pFrozenColumn [frozen]="column.frozen" [pSortableColumn]="column.id" [style]="{width: column.getWidth(), 'min-width': column.getMinWidth(), 'max-width': column.getMaxWidth()}">
          <button *ngIf="allowExport && index == 0" type="button" pButton pRipple icon="fas fa-cog" (click)="openSettings($event)" class="p-button-text tools-button" pTooltip="Optionen" tooltipPosition="bottom"></button>
          <p *ngIf="rotateHeaders" style="transform: rotate(-90deg); height: 200px; width: 50px">{{column.caption}}</p><span *ngIf="!rotateHeaders">{{column.caption}}</span>
          <p-sortIcon *ngIf="column.sortable" [field]="column.id"></p-sortIcon>
          <p-columnFilter *ngIf="allowFilter && filterButtons && column.filterable && !filterRow" type="text" [field]="'values.' + column.id" [showOperator]=false [showMatchModes]=false [showAddButton]=false matchMode="contains" display="menu"></p-columnFilter>
      </th>
      <th *ngIf="allowDelete" [style]="{width: '50px'}">
      </th>
    </tr>
    <tr *ngIf="allowFilter && filterRow">
        <th *ngFor="let column of _data.columns" class="align-left">
            <p-columnFilter type="text" [field]="'values.' + column.id"></p-columnFilter>
          </th>
        <th *ngIf="allowDelete" [style]="{width: '50px'}"></th>
      </tr>
  </ng-template>
  <ng-template pTemplate="groupheader" let-item>
    <tr pRowGroupHeader>
        <td [colSpan]="_data.columns.length">
            <span class="font-bold ml-2">{{item.values[groupRowsBy]}}</span>
        </td>
    </tr>
</ng-template>
  <ng-template pTemplate="body" let-item let-index="rowIndex">
    <tr [pSelectableRow]="item" [style.height]="virtualScrollHeight > 0 ? virtualScrollHeight + 'px' : undefined">
        <td *ngFor="let column of _data.columns" pFrozenColumn [frozen]="column.frozen" [class]="'tc ' + (item.values[column.id]?.styleClass ?? item.styleClass ?? '')" [style]="item.values[column.id]?.style ?? item.style ?? ''" [style]="{width: column.getWidth(), 'min-width': column.getMinWidth(), 'max-width': column.getMaxWidth()}" [pEditableColumn]="column.editable">
            <sm-table-cell
                class="width100p"
                [class.display-block]=true
                [class.no-wrap]="column.noWrap"
                [(value)]="item.values[column.id]"
                [editable]="column.editable"
                [editableMaxLength]="column.editableMaxLength"
                [actionButtons]="actionButtons"
                [showNull]="column.showNull"
                [noWrapCell]="noWrapCell"
                [type]="column.cellType"
                [editor]="column.editor"
                [parentTable]="this"
                [columnIdOnParent]="column.id"
                (clicked)="cellClicked(item, column.id, $event)"
                (valueChange)="cellEdited(item, column.id)"
            >
            </sm-table-cell>
        </td>
        <td *ngIf="allowDelete" [style]="{width: '50px'}">
            <p-button class="p-button-secondary" icon="fas fa-trash" (onClick)="deleteItem(item, index)"></p-button>
        </td>
    </tr>
  </ng-template>
  <ng-template *ngIf="allowInsert" pTemplate="footer">
    <td *ngFor="let column of _data.columns; index as index" class="tc" [style]="{width: column.getWidth(), 'min-width': column.getMinWidth(), 'max-width': column.getMaxWidth()}">
        <p-button *ngIf="index == 0" class="p-button-secondary" icon="fas fa-plus" (onClick)="addItem()"></p-button>
    </td>
    <td *ngIf="allowDelete" [style]="{width: '50px'}"></td>
</ng-template>
</p-table>
<p-contextMenu #cm [model]="contextMenu" appendTo="body"></p-contextMenu>

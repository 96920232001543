import { Type } from "class-transformer";
import { FrontendFieldListItem } from "src/modules/sm-base/models/frontend-field-list-item.model";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { ConfigClassDefinition } from "./config-class-definition.model";

export class ConfigDefinition {
    @Type(() => ConfigClassDefinition)
    classes: ConfigClassDefinition[];

    byName: OrdinaryObject<ConfigClassDefinition> = {};

    init(): void {
        this.byName = {};
        for (let clazz of this.classes) {
            this.byName[clazz.name] = clazz;
        }
        for (let clazz of this.classes) {
            if (!Utils.isNoe(clazz.baseName)) {
                let base = this.getByName(clazz.baseName);
                if (base != null) {
                    base.subClasses.push(clazz);
                }
            }
        }
        for (let clazz of this.classes) {
            clazz.guiListItems = Utils.arraySort(clazz.subClasses.map(c => new FrontendFieldListItem(c.name, Utils.getSubstringFromLast(c.name, "."))));
        }
    }

    getByName(name: string): ConfigClassDefinition {
        return this.byName[name];
    }
}

import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { EnaioMetaDataField } from 'src/modules/enaio/shared/EnaioMetaDataField';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'enaio-document-meta-data',
    templateUrl: './document-meta-data.component.html',
    styleUrls: []
})
export class DocumentMetaDataComponent {

    _document: EnaioDocument = null;
    metaData: EnaioMetaDataField[] = null;
    @Input()
    anonymousGuid: string;

    constructor(private http: HttpClient) {
    }

    @Input()
    set document(value: EnaioDocument) {
        this._document = value;
        this.metaData = this._document != null ? this._document.metaData : null;
        if (this._document != null/* && this.metaData == null*/) {
            if (!Utils.isNoe(this.anonymousGuid)) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                RestEndpoint.main().query({anonymousGuid: this.anonymousGuid, id: this._document.id}).run("api/doc/def/anonymousobject").get(EnaioDocument).then(result => {
                    this.metaData = result.metaData;
                    this.filterMetaData();
                });
            }
            else {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                RestEndpoint.main().findById(this._document.id).run("api/doc/document/object").get(EnaioDocument).then(result => {
                    this.metaData = result.metaData;
                    this.filterMetaData();
                });
            }
        }
        this.filterMetaData();
    }

    get document(): EnaioDocument {
        return this._document;
    }

    private filterMetaData(): void {
        this.metaData = this.metaData?.filter(field => !field.field.system);
    }

}

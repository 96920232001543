import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { TemplateFieldPersistentData } from '../../shared/template-field-persistent-data.entity';

@Component({
    selector: 'edit-template-fields',
    templateUrl: './edit-fields.component.html',
    styleUrls: ['./edit-fields.component.scss']
})
export class EditTemplateFieldsComponent implements OnInit {

    templateId = "";
    listData: TemplateFieldPersistentData[];
    table: TableData;

    constructor(private app: MainAppService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.app.subscribeToParamsChanges(this.route, this.init.bind(this));
    }

    async init(params: any): Promise<void> {
        this.templateId = params.templateId as string;
        this.listData = await this.app.loadDataHandler(async () => RestEndpoint.main().find({templateId: this.templateId}).run("api/tpl/templatefieldpersistentdata").list(TemplateFieldPersistentData), this);

        this.table = new TableData([
            new TableColumn("caption", "Bezeichung"),
            new TableColumn("fieldName", "Feld")
        ], this.listData.map(o => new TableRow(o, {
            fieldName: o.fieldName,
            caption: o.caption
        })), [new TableSortColumn("caption")]);
    }
}

import { GenericDataType } from "./GenericDataType";
import { Utils } from "src/modules/utils/shared/utils";
import { Type } from "class-transformer";
import { GenericFieldDefinition } from "./GenericFieldDefinition";

export class PlotResult {
    @Type(() => GenericFieldDefinition)
    fields: GenericFieldDefinition[];
    rows: any[][];

    ensureDataTypes(): void {
        for (let row of this.rows) {
            for (let i = 0; i < this.fields.length; i++) {
                row[i] = this.convert(row[i], this.fields[i].type);
            }
        }
    }

    private convert(o: any, type: GenericDataType): any {
        switch (type) {
            case GenericDataType.string:
                return Utils.toString(o);
            case GenericDataType.int:
                return Utils.toNumber(o);
            case GenericDataType.decimal:
                return Utils.toNumber(o);
            case GenericDataType.bool:
                return Utils.toBool(o);
            case GenericDataType.date:
                return Utils.toDate(o);
            case GenericDataType.dateTime:
                return Utils.toDate(o);
            case GenericDataType.array:
                return Utils.toString(o);
            case GenericDataType.object:
                return Utils.toString(o);
        }
    }


}

import { Utils } from "src/modules/utils/shared/utils";
import { SimLogStatType } from "./SimLogStatType";

export class SimLogStatKey {
    type: SimLogStatType;
    children: string[];

    toString(): string {
        return SimLogStatType.getName(this.type) + (Utils.isNoe(this.children) ? "" : " (" + Utils.arrayItemsToString(this.children, "/") + ")");
    }


}

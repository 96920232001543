import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { AnonymousSearchConfig } from '../../models/anonymous-search-config.model';

@Component({
    selector: 'app-anonymous-search',
    templateUrl: './anonymous-search.component.html',
    styleUrls: ['./anonymous-search.component.scss']
})
export class AnonymousSearchComponent extends ComponentView {

    defId: string;
    searchString: string;
    config: AnonymousSearchConfig;
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { defId: "string", searchString: "string?" };
    }

    async initParams(): Promise<boolean> {
        this.config = await RestEndpoint.main().query({defId: this.defId}).run("api/doc/def/anonymoussearchdef").get(AnonymousSearchConfig);
        if (Utils.isNoe(this.searchString)) {
            this.form = new FrontendFormDefinition(this.config.formFields);
        }
        else {
            await this.search(this.searchString);
        }
        return true;
    }

    async search(searchString?: string): Promise<void> {
        if (searchString == null) {
            if (!this.app.validateForm(this.form)) {
                return;
            }

            searchString = Utils.arrayItemsToString(this.form.fields, ";", f => f.id + "=" + (f.value instanceof Date ? Utils.dateFormat(f.value, "dd.mm.yyyy") : f.value));
        }

        let [ok, guid] = await this.app.genericDataHandler(async () => RestEndpoint.main().query({defId: this.defId, searchString}).run("api/doc/def/anonymoussearch").getText(), "Fehler bei der Suche", "toast");
        if (ok && !Utils.isNoe(guid)) {
            await this.app.navigateTo(["/enaio-documents", "definition", "as", this.defId, guid]);
        }
    }

}

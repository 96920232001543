import { Component, ElementRef, ViewChild } from '@angular/core';
import { Tree } from 'primeng/tree';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DeploymentOptionsDocumentHandler } from 'src/modules/enaio/shared/DeploymentOptionsDocumentHandler';
import { DocumentHandlerTaskStatus } from 'src/modules/enaio/shared/DocumentHandlerTaskStatus';
import { UpdatePackageMetaData } from 'src/modules/enaio/shared/UpdatePackageMetaData';
import { SmTimer } from 'src/modules/sm-base/models/sm-timer.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { TreeSelectionHelper } from 'src/modules/utils/misc/tree-selection-helper';
import { Progressor } from 'src/modules/utils/misc/Progressor';
import { Utils } from 'src/modules/utils/shared/utils';
import { Wrapper } from 'src/modules/utils/misc/wrapper.model';
import { DhCustomer } from '../../models/dh-customer.model';
import { DhTools } from '../../models/dh-tools.model';
import { AttachedDocumentHandlerProcess } from 'src/modules/enaio/shared/AttachedDocumentHandlerProcess';

@Component({
    selector: 'app-document-handler-updater',
    templateUrl: './document-handler-updater.component.html',
    styleUrls: ['./document-handler-updater.component.scss']
  })
  export class DocumentHandlerUpdaterComponent extends ComponentView {

    _Utils = Utils;

    customers: DhCustomer[];
    selectedCustomer = "";

    progressor = new Wrapper<Progressor>();
    devMode = false;
    metaData: UpdatePackageMetaData;
    changesTree: TreeSelectionHelper;
    waitText: string = null;
    fileName = "";
    selLeftText = "";
    selRightText = "";
    taskStatusTimer: SmTimer;

    @ViewChild("tree") tree: Tree;
    @ViewChild("main", {read: ElementRef}) private mainRef: ElementRef;

    async initParams(): Promise<boolean> {
        this.addKeyboardListener("Ctrl-S", event => {
            if (GuiUtils.isComponentVisible(this.mainRef)) {
                this.devMode = true;
                Utils.setTimerOnce(60_000, () => {
                    this.devMode = false;
                });
                event.preventDefault();
            }
        });

        this.customers = await DhTools.backendCall("api/dh/listCustomers").list(DhCustomer);
        this.selectedCustomer = this.customers.some(c => c.name == "sm") ? "sm" : Utils.arrayGetSafe(this.customers.filter(c => c.name != "tools"), 0)?.name ?? "tools";

        return Promise.resolve(true);
    }

    async copyConfigToClipboard(): Promise<void> {
        await DhTools.backendCall("api/dh/updaterCopyConfigToClipboard").getText();
        this.app.showToast("success", "Erfolgreich", "Der Config-Ordner befindet sich in der Zwischenablage");
    }

    async copyConfigToDev(): Promise<void> {
        await DhTools.backendCall("api/dh/updaterCopyConfigToDev").getText();
        this.app.showToast("success", "Erfolgreich", "Der Config-Ordner befindet sich in der Zwischenablage");
    }

    async getUpdate(): Promise<void> {
        let options = Utils.fromPlain(DeploymentOptionsDocumentHandler, {
            customer: this.selectedCustomer,
            updateId: Utils.generateGuid()
        });
        DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/dh/updaterGenerateUpdate", { options }).getText(),
        async result => this.updateArrived(Utils.fromPlainUnsafe(UpdatePackageMetaData, Utils.fromJson(result.value))),
        result => this.app.showToast("error", "Das Update ist fehlgeschlagen", result));
    }

    async updateArrived(metaData: UpdatePackageMetaData): Promise<void> {
        this.metaData = metaData;//
        this.waitText = "Rufe Aufgabenplanung ab ...";
        this.taskStatusTimer = this.addTimer(new SmTimer(0, 1000, async () => {
            let status = await DhTools.backendCall("api/dh/getTaskStatus").list(DocumentHandlerTaskStatus);
            let runningProcesses = await DhTools.backendCall("api/dhinstance/getRunning").list(AttachedDocumentHandlerProcess);

            let statusText = Utils.arrayWithoutNull([
                ...status.map(s => s.isRunning ? s.name + " (Läuft)" :  Utils.dateDiffSeconds(new Date(), s.nextRun) <= 10 && Utils.dateDiffSeconds(new Date(), s.nextRun) >= -10 ? s.name + " (Startet in " + Utils.dateDiffSeconds(new Date(), s.nextRun) + " Sekunden)" : null),
                ...runningProcesses.map(s => "Prozess " + s.processId + " (Läuft)")
            ]);
            this.waitText = statusText.length == 0 ? null : Utils.arrayItemsToString(statusText, ", ");
        }, true));
        this.changesTree = new TreeSelectionHelper(this.tree);
        this.changesTree.setItems(GuiUtils.generateTree([...this.metaData.deleteFiles.map(fileName => ({ fileName, type: "d" })), ...this.metaData.updateFiles.map(fileName => ({ fileName, type: "u" }))],
        file => [file.fileName.toLowerCase().startsWith("config") ? "Konfigurationen" : "Weitere Dateien", file.type == "d" ? "Gelöscht" : "Aktualisiert/Neu", file.fileName],
        (file, key, index) => ({ label: Utils.toString(key[index]), data: key[index], expanded: index < 2 }), true));
        this.changesTree.setSelected(this.changesTree.items);

        let allSafe = Utils.isNoe(this.metaData.deleteFiles) && !this.metaData.updateFiles.some(fileName => fileName.toLowerCase().startsWith("config"));
        if (allSafe) {
            this.app.showToast("info", "Info", "Update wird ohne Rückfrage eingespielt, da es keine unsicheren Änderungen gibt");
            await this.runUpdate(true);
        }
    }

    async runUpdate(all?: boolean): Promise<void> {
        this.taskStatusTimer?.destroy();
        await DhTools.backendCall("api/dh/updaterRunUpdate", { id: this.metaData.id, fileNames: all ? [...this.metaData.deleteFiles, ...this.metaData.updateFiles] : this.changesTree.getSelected() }).getText();
        this.updateFinished();
    }

    updateFinished(): void {
        this.cancelUpdate();
        this.app.showToast("success", "Erfolgreich", "Das Update wird eingespielt ...");
    }

    cancelUpdate(): void {
        this.clearTimers();
        this.metaData = null;
        this.changesTree = null;
        this.waitText = null;
        this.fileName = "";
        this.selLeftText = "";
        this.selRightText = "";
    }

    async selectFile(event: any): Promise<void> {
        if ((event.node.label as string).toLowerCase().startsWith("config")) {
            this.fileName = event.node.label;
            [this.selRightText, this.selLeftText] = await DhTools.backendCall("api/dh/updaterGetFileContents", { id: this.metaData.id, fileName: this.fileName, maxLength: 20_000 }).listStrings();
        }
        else {
            this.selLeftText = "";
            this.selRightText = "";
        }
    }
}

<div class="grid">
    <div class="col-4">
        <sm-form #form [form]="formInput">
        </sm-form>
        <p-button icon="fas fa-search" label="Suchen" (onClick)="run()"></p-button>
    </div>
    <div class="col-8 single-page-view">
        <sm-toolbar *ngIf="actionButtons != null && actionButtons.length > 0">
            <p-button *ngFor="let actionButton of actionButtons" icon="fas fa-run" [label]="actionButton.label" (onClick)="actionButton.command(null)"></p-button>
        </sm-toolbar>
        <sm-table-on-push *ngIf="resultTable != null" [data]="resultTable" [deltaHeight]=160 [useTableSizer]=true></sm-table-on-push>
    </div>
</div>
<app-progressor-overlay [progressor]="progressor.value"></app-progressor-overlay>

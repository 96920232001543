import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioCallGetChangesInTimeFrame } from 'src/modules/enaio/shared/EnaioCallGetChangesInTimeFrame';
import { EnaioChangeEntry } from 'src/modules/enaio/shared/EnaioChangeEntry';
import { GeneralObjectKey } from 'src/modules/enaio/shared/GeneralObjectKey';
import { EnaioHelper } from 'src/modules/enaio/shared/enaio-helper.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableCell } from 'src/modules/sm-base/models/table-cell.model';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhObjectActions } from '../../models/dh-object-actions.model';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-enaio-what-happened',
    templateUrl: './enaio-what-happened.component.html',
    styleUrls: ['./enaio-what-happened.component.scss']
  })
  export class EnaioWhatHappenedComponent extends ComponentView {

    _Utils = Utils;

    formSearch: FrontendFormDefinition;
    result: EnaioChangeEntry[];
    table: TableData;

    async initParams(): Promise<boolean> {
       this.formSearch = new FrontendFormDefinition([
            new FrontendFieldDefinition("beginDate", "Startdatum", FrontendFieldType.datePicker, { value: Utils.dateAdd(new Date(), "day", -1), mandatory: true, datePickerWithTime: true }),
            new FrontendFieldDefinition("endDate", "Enddatum", FrontendFieldType.datePicker, { value: Utils.dateAdd(new Date(), "day", 1), datePickerWithTime: true }),
            new FrontendFieldDefinition("getObjectChanges", "Objektänderungen", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("getWorkflowChanges", "Workflowänderungen", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("getLogEntries", "Logeinträge", FrontendFieldType.checkBox, { visible: false }),
            new FrontendFieldDefinition("processIds", "Nur folgende Workflow-IDs", FrontendFieldType.textArea)
        ]);
        return Promise.resolve(true);
    }

    async search(): Promise<void> {
        let call = this.formSearch.get(new EnaioCallGetChangesInTimeFrame());
        call.processIds = Utils.splitLines(this.formSearch.getValue("processIds") as string);
        this.result = await DhTools.enaioCall<EnaioChangeEntry[]>(call);

        this.table = new TableData(Utils.arrayWithoutNull([
            new TableColumn("date", "Datum", TableCellType.dateTime),
            new TableColumn("userName", "Benutzername"),
            new TableColumn("type", "Typ"),
            new TableColumn("id", "ID"),
            new TableColumn("name", "Name"),
            new TableColumn("description", "Beschreibung")
        ]), this.result.map(entry => new TableRow(entry, {
            date: new TableCell(entry.date, { internal: [new GeneralObjectKey("enaio.object.history", { id: entry.id, date: entry.date })], actionProviders: [new DhObjectActions()] }),
            userName: entry.userName,
            type: EnaioHelper.changeEntryTypeToString(entry.type),
            id: new TableCell(entry.id, { internal: [new GeneralObjectKey("enaio.object", { id: entry.id })], actionProviders: [new DhObjectActions()] }),
            name: (entry as any).name,
            description: entry.description
        })), [new TableSortColumn("date")]);
    }

}

import { Utils } from "src/modules/utils/shared/utils";
import { FrontendFieldDefinition } from "./frontend-field-definition.model";
import { TableCellType } from "./table-cell-type.enum";
import { TableCell } from "./table-cell.model";
import { FrontendFieldListItem } from "./frontend-field-list-item.model";
import { FrontendFieldType } from "./frontend-field-type.enum";

export class TableColumn {

    id = "";
    caption: string;
    cellType = TableCellType.text;
    sortable = true;
    filterable = true;
    width: string = null;
    minWidth: string = null;
    maxWidth: string = null;
    width2: string = null;
    noWrap: boolean;
    showNull = true;
    editable = false;
    editableMaxLength = 0;
    isLink = false;
    frozen = false;
    editor: FrontendFieldDefinition;

    calculatedWidth: string = null;

    constructor(id: string, caption: string, cellType = TableCellType.text, props: Partial<TableColumn> = null) {
        this.id = id;
        this.caption = caption;
        this.cellType = cellType;
        if (props != null) {
            Object.assign(this, props);
        }
    }

    toCsvString(value: any): string {
        if (value instanceof TableCell) {
            value = value.value;
        }
        switch (this.cellType) {
            case TableCellType.date:
                return Utils.dateFormat(value as Date, "dd.MM.yyyy");
            case TableCellType.dateTime:
                return Utils.dateFormatDefault(value as Date);
            case TableCellType.yesNo:
                return Utils.toBool(value) ? "Ja" : "Nein";
            default:
                return Utils.toString(value);
        }
    }

    getWidth(): string {
        return this.calculatedWidth ?? this.width;
    }

    getMinWidth(): string {
        return this.minWidth ?? this.getWidth();
    }

    getMaxWidth(): string {
        return this.maxWidth ?? this.getWidth();
    }

    toFrontendField(): FrontendFieldDefinition {
        let result = new FrontendFieldDefinition(this.id, this.caption, FrontendFieldType.text, { enabled: true, maxLength: this.editableMaxLength });
        switch (this.cellType) {
            case TableCellType.text:
                break;
            case TableCellType.dropdown:
                result.type = FrontendFieldType.comboBox;
                break;
            case TableCellType.number:
                result.type = FrontendFieldType.number;
                break;
            case TableCellType.yesNo:
                result.type = FrontendFieldType.checkBox;
                break;
            case TableCellType.date:
                result.type = FrontendFieldType.datePicker;
                break;
            case TableCellType.dateTime:
                result.type = FrontendFieldType.datePicker;
                break;
            default:
                throw new Error("Der Zelltyp " + this.cellType + " kann nicht in FrontendFieldType konvertiert werden");
        }
        return result;
    }

}

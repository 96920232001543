import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DhConfigInfo } from 'src/modules/enaio/shared/DhConfigInfo';
import { HandlerConfigMetricsSummary } from 'src/modules/enaio/shared/HandlerConfigMetricsSummary';
import { SimLogStatType } from 'src/modules/enaio/shared/SimLogStatType';
import { FrontendFieldAddon } from 'src/modules/sm-base/models/frontend-field-addon.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhCustomer } from '../../models/dh-customer.model';
import { DhTools } from '../../models/dh-tools.model';
@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent extends ComponentView {

    customers: DhCustomer[];
    configNames: string[];
    data: HandlerConfigMetricsSummary;
    formInput: FrontendFormDefinition;
    tableData: TableData;
    displayTime = true;
    displayObjectCount = true;
    displayInternalFolders = true;
    displaySimLog = true;
    displayMostUsedObjects = true;
    displayOther = true;

    async initParams(): Promise<boolean> {
        this.customers = await DhTools.backendCall("api/dh/listCustomers").list(DhCustomer);
        this.configNames = [];
        for (let customer of this.customers) {
            if (customer.name != "tools") {
                this.configNames = [...this.configNames, ...(await DhTools.backendCall("api/dh/listConfigs", {customer: customer.name}).list(DhConfigInfo)).map(item => Utils.getFileNameWithoutPathAndExtension(item.name))];
            }
        }
        this.configNames = Utils.arraySort(Utils.arrayGetUnique(this.configNames));

        this.formInput = new FrontendFormDefinition([
            new FrontendFieldDefinition("configNames", "Konfigurationen", FrontendFieldType.comboBoxMulti, { mandatory: true, listItems: this.configNames.map(m => new FrontendFieldListItem(m, m)), value: this.configNames}),
            new FrontendFieldDefinition("includeUnknownConfigs", "Unbekannte (alte) Konfigurationen einschließen", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("beginDate", "Startdatum", FrontendFieldType.datePicker, { value: Utils.dateAdd(new Date(), "year", -1)}),
            new FrontendFieldDefinition("endDate", "Enddatum", FrontendFieldType.datePicker, { value: Utils.dateEndOf(new Date(), "day"),
                addOnButtons: [new FrontendFieldAddon("", "", "fas fa-ellipsis", true)], onAddOnClick: (addOn, evt) => DhTools.showDateIntervalOptions(evt, this.viewContainerRef, this.formInput) }),
            new FrontendFieldDefinition("internalFolders", "interne Verzeichnisse analysieren", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("simLog", "SimLog analysieren (dauert...)", FrontendFieldType.checkBox)
        ]);

        return true;
    }

    load(configName: string): void {
        this.formInput.setValue("configNames", [Utils.getFileNameWithoutPath(Utils.stringDef(configName))]);
    }

    async run(): Promise<void> {
        this.displayInternalFolders = this.formInput.getValue("internalFolders") as boolean;
        this.displaySimLog = this.formInput.getValue("simLog") as boolean;
        this.displayMostUsedObjects = this.displaySimLog;
        this.data = await DhTools.backendCall("api/dh/handlerConfigMetricsSummary", {
            configNames: Utils.arrayItemsToString((this.formInput.getValue("configNames") as string[]).map(s => Utils.stringRemoveSuffix(s, ".json")), "|"),
            includeUnknownConfigs: this.formInput.getValue("includeUnknownConfigs") as boolean,
            beginDate: Utils.dateStartOf(this.formInput.getValue("beginDate") as Date, "day"),
            endDate: Utils.dateEndOf(this.formInput.getValue("endDate") as Date, "day"),
            internalFolders: this.displayInternalFolders,
            simLog: this.displaySimLog
        }).get(HandlerConfigMetricsSummary);

        this.updateTable();
    }

    updateTable(): void {
        this.tableData = new TableData(Utils.arrayWithoutNull([
            new TableColumn("configName", "Handler"),
            new TableColumn("count", "Läufe", TableCellType.number),
            this.displayTime ? new TableColumn("time", "Zeit", TableCellType.duration) : null,
            this.displayTime ? new TableColumn("timeAvg", "Zeit \u00d8", TableCellType.duration) : null,
            this.displayTime ? new TableColumn("cpuMillis", "CPU Zeit", TableCellType.duration) : null,
            this.displayTime ? new TableColumn("cpuMillisAvg", "CPU Zeit \u00d8", TableCellType.duration) : null,
            this.displayObjectCount ? new TableColumn("numObjects", "Objekte", TableCellType.number) : null,
            this.displayObjectCount ? new TableColumn("numObjectsAvg", "Objekte \u00d8", TableCellType.number) : null,
            this.displayObjectCount ? new TableColumn("numObjectsWithWork", "Bearbeitet", TableCellType.number) : null,
            this.displayObjectCount ? new TableColumn("numObjectsWithWorkAvg", "Bearbeitet \u00d8", TableCellType.number) : null,
            this.displayObjectCount ? new TableColumn("numErrorObjects", "Fehlerhaft", TableCellType.number) : null,
            this.displayObjectCount ? new TableColumn("numErrorObjectsAvg", "Fehlerhaft \u00d8", TableCellType.number) : null,
            this.displayInternalFolders ? new TableColumn("doneDirCount", "done Dateien", TableCellType.number) : null,
            this.displayInternalFolders ? new TableColumn("doneDirSize", "done Größe", TableCellType.byteSize) : null,
            this.displayInternalFolders ? new TableColumn("errorDirCount", "error Dateien", TableCellType.number) : null,
            this.displayInternalFolders ? new TableColumn("errorDirSize", "error Größe", TableCellType.byteSize) : null,
            this.displayInternalFolders ? new TableColumn("errorNoGroupDirCount", "error Ungruppiert Dateien", TableCellType.number) : null,
            this.displayInternalFolders ? new TableColumn("errorNoGroupDirSize", "error Ungruppiert Größe", TableCellType.byteSize) : null,
            this.displaySimLog ? new TableColumn("statFileCreated", "Dateien erstellt", TableCellType.number) : null,
            this.displaySimLog ? new TableColumn("statEnaioObjectCreated", "enaio Objekte erstellt", TableCellType.number) : null,
            this.displaySimLog ? new TableColumn("statEnaioObjectUpdated", "enaio Objekte aktualisiert", TableCellType.number) : null,
            this.displayMostUsedObjects ? new TableColumn("mostUsedObjectId", "Meistg. Objekt", TableCellType.text) : null,
            this.displayMostUsedObjects ? new TableColumn("mostUsedObjectIdCount", "Meistg. Objekt Anzahl", TableCellType.number) : null,
            this.displayMostUsedObjects ? new TableColumn("mostUsedObjectIdRatio", "Meistg. Objekt %", TableCellType.number) : null,
            this.displayOther ? new TableColumn("numErrors", "Fehler", TableCellType.number) : null,
            this.displayOther ? new TableColumn("usedMem", "Speicherverbrauch \u00d8", TableCellType.byteSize) : null
        ]), this.data.grouped.map(group => new TableRow(group, {
            configName: group.configName,
            count: group.count,
            time: group.time,
            timeAvg: group.timeAvg,
            cpuMillis: group.cpuMillis,
            cpuMillisAvg: group.cpuMillisAvg,
            numObjects: group.numObjects,
            numObjectsAvg: group.numObjectsAvg,
            numObjectsWithWork: group.numObjectsWithWork,
            numObjectsWithWorkAvg: group.numObjectsWithWorkAvg,
            numErrorObjects: group.numErrorObjects,
            numErrorObjectsAvg: group.numErrorObjectsAvg,
            doneDirCount: group.doneDirCount,
            doneDirSize: group.doneDirSize,
            errorDirCount: group.errorDirCount,
            errorDirSize: group.errorDirSize,
            errorNoGroupDirCount: group.errorNoGroupDirCount,
            errorNoGroupDirSize: group.errorNoGroupDirSize,
            statFileCreated: group.stats.find(s => s.key.type == SimLogStatType.fileCreated && Utils.isNoe(s.key.children)) ?? 0,
            statEnaioObjectCreated: group.stats.find(s => s.key.type == SimLogStatType.enaioObjectCreated && Utils.isNoe(s.key.children)) ?? 0,
            statEnaioObjectUpdated: group.stats.find(s => s.key.type == SimLogStatType.enaioObjectUpdated && Utils.isNoe(s.key.children)) ?? 0,
            mostUsedObjectId: group.mostUsedObjectId,
            mostUsedObjectIdCount: group.mostUsedObjectIdCount,
            mostUsedObjectIdRatio: group.mostUsedObjectIdRatio,
            numErrors: group.numErrors,
            usedMem: Math.max(0, Math.floor(group.usedMem / Math.max(1, group.count)))
        }), [new TableSortColumn("configName")]));
    }

}

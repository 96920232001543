import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from '../../models/rest-endpoint.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';

@Component({
    selector: 'crud-list',
    templateUrl: './crud-list.component.html',
    styleUrls: ['./crud-list.component.scss']
})
export class CrudListComponent implements OnInit {

    @Input()
    data: TableData;
    @Input()
    detailUrl: string;
    @Input()
    restUrl: string;
    @Input()
    allowCreate = true;
    @Input()
    allowDelete = true;
    @Input()
    gridLines = false;
    @ContentChild("toolbarTemplate", {static: true})
    toolbarTemplate?: TemplateRef<any>;
    @Output()
    onDelete = new EventEmitter<TableRow>();

    constructor(private app: MainAppService) {
    }

    ngOnInit(): void {
    }

    async rowSelect(row: TableRow): Promise<void> {
        await this.app.navigateToUrl(this.detailUrl + row.raw.id);
    }

    async deleteRow(row: [TableRow, number]): Promise<void> {
        if ((await this.app.deleteDataHandler<string>(async () => RestEndpoint.main().query({id: row[0].raw.id}).delete().run(this.restUrl).getText()))[0]) {
            this.onDelete.emit(row[0]);
        }
    }
}

import { Type } from "class-transformer";
export class SimLogResultEntry {
    value = "";
    fileName = "";
    id = "";
    configName = "";
    @Type(() => Date)
    date: Date;
    fullLog = "";
}

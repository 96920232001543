import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { ConfigEditorTreeComponent } from '../../../sm-base/components/config-editor-tree/config-editor-tree.component';
import { ConfigFieldType } from 'src/modules/sm-base/models/config-field-type.enum';
import { ConfigDefinition } from 'src/modules/sm-base/models/config-definition.model';
import { ConfigProperty } from 'src/modules/sm-base/models/config-property.model';
import { ConfigFieldDefinition } from 'src/modules/sm-base/models/config-field-definition.model';

@Component({
    selector: 'app-config-editor',
    templateUrl: './config-editor.component.html',
    styleUrls: ['./config-editor.component.scss']
})
export class ConfigEditorComponent implements OnInit {

    _ConfigFieldType = ConfigFieldType;
    _Utils = Utils;

    loaded = false;

    plain: OrdinaryObject;
    json: string;
    def: ConfigDefinition;
    config: ConfigProperty;
    selectedWorkers: any[] = [];

    helpText: string;
    speakingNames = true;
    quickValues = true;

    @ViewChild("configEditorTree") configEditorTree: ConfigEditorTreeComponent;

    async ngOnInit(): Promise<void> {
        this.def = await DhTools.backendCall("api/config/definition").get(ConfigDefinition);
        this.def.init();

        this.plain = Utils.fromJson(await DhTools.backendCall("api/dh/config", { customer: "internal", config: "test_enaio_users.json" }).getText());

        let mainFieldDef = Utils.fromPlain(ConfigFieldDefinition, { name: "main", type: ConfigFieldType.object, typeName: "DocumentHandler.HandlerConfig"});
        this.config = new ConfigProperty({}, "DocumentHandler.HandlerConfig", true, mainFieldDef, [], this.plain);

        this.config.properties = mainFieldDef.getProperties(this.def.getByName("DocumentHandler.HandlerConfig"), this.plain, this.def);

        this.configEditorTree.setObject(this.config);
        this.loaded = true;
    }

    getWorkers(): ConfigProperty[] {
        return this.config.properties.find(p => p.def.name == "workers").properties;
    }

    getShortTypeName(s: string): string {
        return Utils.getSubstringFrom(Utils.getSubstringTo(s, "Worker"), "DocumentHandler.DocumentHandler.");
    }

    selectWorker(): void {
        this.configEditorTree.updateNodes();
    }
}

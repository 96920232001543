import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { CompletePersonellDataDto } from '../../models/complete-personell-data.dto';

@Component({
  selector: 'app-complete-personell-data',
  templateUrl: './complete-personell-data.component.html',
  styleUrls: ['./complete-personell-data.component.scss']
})
export class CompletePersonellDataComponent implements OnInit {

    id = 0;
    done = false;
    form: FrontendFormDefinition;

    constructor(private app: MainAppService, private route: ActivatedRoute, private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.app.subscribeToParamsChanges(this.route, this.init.bind(this));
    }

    init(params): void {
        this.id = Utils.toNumber(Utils.getSubstringTo(params.id as string, "f"));

        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("svNumber", "Sozialversicherungsnummer", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("birthDate", "Geburtstag", FrontendFieldType.datePicker, { mandatory: true }),
            new FrontendFieldDefinition("birthPlace", "Geburtsort", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("maritalStatus", "Familienstand", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: true, listItems: [
                new FrontendFieldListItem("ledig", "ledig"),
                new FrontendFieldListItem("verheiratet", "verheiratet"),
                new FrontendFieldListItem("verwitwet", "verwitwet"),
                new FrontendFieldListItem("gescheiden", "geschieden")
            ]})
        ]);

        let item = new CompletePersonellDataDto();
        item.maritalStatus = "ledig";
        this.form.fill(item);
    }

    async send(): Promise<void> {
        let errors = this.form.validate();
        if (errors != null) {
            this.messageService.add({ severity: "error", summary: "Ungültige Angaben", life: 3000, detail: Utils.toString(Utils.objectGetValues(errors)[0])});
            return;
        }

        let item = new CompletePersonellDataDto();
        item.id = this.id;
        this.form.get(item);

        this.done = true;
        await RestEndpoint.main().body(item).post().run("api/doc/personell/completeData").getText();
    }
}

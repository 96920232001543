<div class="grid">
    <div class="col-2">
        <sm-form  [form]="form">
        </sm-form>
        <p-button icon="fas fa-filter" label="Holen" (onClick)="get()"></p-button>
    </div>
    <div class="col-10">
        <sm-toolbar>
            <p-radioButton name="all" value="all" [(ngModel)]="source" inputId="sourceAll" (ngModelChange)="updateTable()"></p-radioButton>
            <label for="sourceAll">Alle</label>
            <p-radioButton name="enaio" value="enaio" [(ngModel)]="source" inputId="sourceEnaio" (ngModelChange)="updateTable()"></p-radioButton>
            <label for="sourceEnaio">enaio</label>
            <p-radioButton name="datev" value="datev" [(ngModel)]="source" inputId="sourceDatev" (ngModelChange)="updateTable()"></p-radioButton>
            <label for="sourceDatev">datev</label>
        </sm-toolbar>
        <sm-table-on-push *ngIf="table != null" [data]="table" [deltaHeight]=200 (onCellClicked)="clickCell($event)"></sm-table-on-push>
    </div>
</div>

export enum PlotType {
    line = 0,
    area = 1,
    barX = 2,
    barY = 3,
    dot = 4,
    tickX = 5,
    tickY = 6,
    pie = 7
}
export namespace PlotType{
    export function getName(value: PlotType): string {
        switch (value) {
            case PlotType.line:
                return "line";
            case PlotType.area:
                return "area";
            case PlotType.barX:
                return "barX";
            case PlotType.barY:
                return "barY";
            case PlotType.dot:
                return "dot";
            case PlotType.tickX:
                return "tickX";
            case PlotType.tickY:
                return "tickY";
            case PlotType.pie:
                return "pie";
        }
    }
}

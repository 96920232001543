import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { EnaioCall } from "./EnaioCall";
import { EnaioUser } from "./EnaioUser";

export class EnaioCallGetUserList extends EnaioCall <EnaioUser[]> {
    detailed = false;
    returnGroups = false;
    returnWfUser = false;
    returnWfUserAttributes = false;
    returnWfUserParents = false;
    onlyActive = false;
    onlyWithWfUser = false;
    onlyGroups: string[];
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioUser, 1);
    }

}

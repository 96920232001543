import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DatevEndpoint } from 'src/modules/enaio/shared/DatevEndpoint';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { Progressor } from 'src/modules/utils/misc/Progressor';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Wrapper } from 'src/modules/utils/misc/wrapper.model';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-datev-data-fetcher',
    templateUrl: './datev-data-fetcher.component.html',
    styleUrls: ['./datev-data-fetcher.component.scss']
  })
  export class DatevDataFetcherComponent extends ComponentView {

    _Utils = Utils;

    endpoints: DatevEndpoint[];
    formFilter: FrontendFormDefinition;
    result: OrdinaryObject[];
    progressor = new Wrapper<Progressor>();
    table: TableData;

    async initParams(): Promise<boolean> {
        this.endpoints = await DhTools.backendCall("api/datev/GetEndpoints").list(DatevEndpoint);
        this.formFilter = new FrontendFormDefinition([
            new FrontendFieldDefinition("type", "Art", FrontendFieldType.comboBox, { mandatory: true, dropdownEditable: false, listItems: this.endpoints.map(type => new FrontendFieldListItem(type, type.description)) }),
            new FrontendFieldDefinition("firstYear", "Startjahr", FrontendFieldType.number, { value: new Date().getFullYear() }),
            new FrontendFieldDefinition("logRest", "REST-Ergebnis loggen", FrontendFieldType.checkBox)
        ]);
        return Promise.resolve(true);
    }

    async get(): Promise<void> {
        let type = this.formFilter.getValue("type") as DatevEndpoint;
        this.result = Utils.arraySubList(await DhTools.backendCall("api/datev/getData", {
            type: type.type + "." + type.name,
            firstYear: this.formFilter.getValue("firstYear"),
            logRest: this.formFilter.getValue("logRest")
        }).listObjects(), 0, 999);

        let allVars = Utils.arraySort(Utils.arrayGetUnique(Utils.arrayExplode(this.result, p => [...Utils.getOwnPropertyNames(p)])));

        this.table = new TableData(allVars.map(v => new TableColumn("variable_" + v, v, TableCellType.text, { width: "200px", noWrap: true })),
        this.result.map(p => new TableRow(p, ({
            ...Utils.arrayToPlainMap(allVars, v => "variable_" + v, v => v in p ? Utils.replaceAll(Utils.toString(p[v]), "\\r?\\n", " ") : "---")
        }) as OrdinaryObject)));
    }

    async getAll(): Promise<void> {
        DhTools.startProgressor(this.progressor, await DhTools.backendCall("api/datev/getAllDataLogRest", {
            firstYear: this.formFilter.getValue("firstYear")
        }).getText(), _ => {
            this.app.showToast("success", "Erfolgreich", "Alle Daten wurden geholt & geloggt");
        });
    }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { EnaioCertificatesTools } from 'src/modules/enaio-certificates/shared/enaio-certificates-tools';
import { SchoolType } from 'src/modules/enaio-certificates/shared/school-type.enum';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { MessageDialogService } from 'src/modules/sm-base/services/message-dialog.service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { DomainHoursPerWeek } from '../../shared/domain-hours-per-week.entity';
import { DomainSettings } from '../../shared/domain-settings.entity';
import { ExamTypeFactor } from '../../shared/exam-type-factor.entity';
import { ExamType } from '../../shared/exam-type.entity';
import { GradeSettings } from '../../shared/grade-settings.entity';
import { GradeType } from '../../shared/grade-type.enum';
import { MarkTranslation } from '../../shared/mark-translation.entity';
import { NumberFormatMode } from '../../shared/number-format-mode.enum';
import { SchoolTypeSettings } from '../../shared/school-type-settings.entity';

@Component({
    selector: 'app-grade-book-admin',
    templateUrl: './grade-book-admin.component.html'
})
export class GradeBookAdminComponent implements OnInit {

    _ExamType = ExamType;
    _GradeType = GradeType;
    _SchoolType = SchoolType;
    _GuiUtils = GuiUtils;
    _Utils = Utils;

    inited = false;
    schoolType: SchoolType;
    year: number;
    isHalfYear: boolean;
    settings: SchoolTypeSettings;
    domains: string[];
    grades: number[];
    domainHoursPerWeek: OrdinaryObject<DomainHoursPerWeek>;
    selectedDomain: string;
    selectedDomainSettings: DomainSettings;
    selectedGrade: number;
    selectedGradeSettings: GradeSettings;
    markTranslationGeneral: MarkTranslation[];
    markTranslationDictation: MarkTranslation[];
    formOptions: FrontendFormDefinition;
    examTypeAdded = false;

    constructor(private app: MainAppService, private service: EnaioCertificateService, public messageDialog: MessageDialogService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.app.subscribeToParamsChanges(this.route, this.init.bind(this));
    }

    async init(params: any): Promise<void> {
        this.schoolType = Utils.toNumber(params.schoolType);
        this.year = Utils.toNumber(params.year);
        this.isHalfYear = Utils.toBool(params.isHalfYear);
        await this.reload();
    }

    async reload(): Promise<any> {
        let restResult = await Promise.all([
            RestEndpoint.main().find({ schoolType: this.schoolType, year: this.year, isHalfYear: this.isHalfYear }).run("api/cer/schooltypesettings").get(SchoolTypeSettings),
            this.service.restGetDomains(null, this.schoolType)
        ]);
        this.app.updateNavigation("Einstellungen " + this.schoolType, [
            this.service.getHomeGradeBook(),
            { label: "Einstellungen  " + (this.schoolType == SchoolType.elementary ? "Grundschule" : this.schoolType == SchoolType.secondary ? "Gesamtschule" : ""), routerLink: ["/enaio-certificates/grade-book", "admin", this.schoolType, this.year, this.isHalfYear] }
        ]);
        this.settings = restResult[0];
        this.domains = Utils.arraySort(Utils.arrayGetUnique(restResult[1].filter(domain => !EnaioCertificatesTools.isConductGrade(domain))));
        this.selectedDomain = this.domains[0];
        for (let domain of this.domains) {
            let existing = this.settings.domainSettings.find(ds => ds.domain == domain);
            if (existing == null) {
                existing = Utils.fromPlain(DomainSettings, { domain});
                this.settings.domainSettings.push(existing);
            }
        }
        this.grades = EnaioCertificatesTools.getValidGrades(this.settings.type);
        this.domainHoursPerWeek = {};
        for (let domain of this.domains) {
            for (let grade of this.grades) {
                this.domainHoursPerWeek[domain + "|" + grade] = this.settings.domainHoursPerWeek.find(hpw => hpw.domain == domain && hpw.grade == grade) ?? new DomainHoursPerWeek(domain, grade);
            }
        }

        this.selectedGrade = this.grades[0];
        this.changeSelectedDomain();
        this.changeSelectedGrade();
        this.formOptions = new FrontendFormDefinition([
            new FrontendFieldDefinition("roundingType", "Formatierung", FrontendFieldType.comboBox, {
                mandatory: true,
                dropdownEditable: false,
                listItems: Utils.getEnumValues(NumberFormatMode).map(nfm => new FrontendFieldListItem(nfm, EnaioCertificatesTools.getNumberFormatModeName(nfm))),
                value: this.settings.roundingType || 0
            })
        ]);

        this.examTypesUpdated();
        this.inited = true;
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.isChanged()) {
            return true;
        }

        return window.confirm("Änderungen verwerfen?");
    }

    isChanged(): boolean {
        return true;
    }

    changeSelectedDomain(): void {
        this.selectedDomainSettings = this.settings.domainSettings.find(ds => ds.domain == this.selectedDomain);
        for (let et of this.settings.examTypes) {
            if (et.id > 0 && this.selectedDomainSettings.examTypeFactors.find(etf => etf.examType?.id == et.id) == null) {
                this.selectedDomainSettings.examTypeFactors = [...this.selectedDomainSettings.examTypeFactors, Utils.fromPlain(ExamTypeFactor, { examType: et })];
            }
        }
    }

    changeSelectedGrade(): void {
        if (this.selectedGradeSettings != null) {
            this.selectedGradeSettings.markTranslation = [...this.markTranslationGeneral, ...this.markTranslationDictation];
        }

        this.selectedGradeSettings = this.settings.getGradeSettings(this.selectedGrade);
        this.markTranslationGeneral = this.selectedGradeSettings.getTranslation(false);
        this.markTranslationDictation = this.selectedGradeSettings.getTranslation(true);
    }

    async save(): Promise<void> {
        this.app.showSpinner = true;
        this.changeSelectedGrade();
        this.settings.roundingType = this.formOptions.getField("roundingType").value;
        this.settings.domainHoursPerWeek = Utils.objectGetValues(this.domainHoursPerWeek);
        await RestEndpoint.main().upsert(this.settings).run("api/cer/schooltypesettings").getText();
        await this.reload();
        this.app.showSpinner = false;
    }

    addExamType(): void {
        this.settings.examTypes = [...this.settings.examTypes, new ExamType()];
        this.examTypesUpdated();
    }

    async deleteExamType(index: number): Promise<void> {
        if (await this.messageDialog.yesNo("Sind Sie sicher, dass Sie diesen Leistungsnachweistyp löschen möchten? WARNUNG: Der Verweis in Leistungsnachweisen, die diesem Typen zugeordnet sind, wird automatisch entfernt!", "Warnung")) {
            this.settings.examTypes.splice(index, 1);
            this.examTypesUpdated();
        }
    }

    examTypesUpdated(): void {
        this.examTypeAdded = this.settings.examTypes.find(et => et.id == 0) != null;
    }

    addMarkTranslation(forDictation: boolean): void {
        if (forDictation) {
            this.markTranslationDictation = [...this.markTranslationDictation, new MarkTranslation(0, "", forDictation)];
        }
        else {
            this.markTranslationGeneral = [...this.markTranslationGeneral, new MarkTranslation(0, "", forDictation)];
        }
    }

    async deleteMarkTranslation(index: number, forDictation: boolean): Promise<void> {
        if (await this.messageDialog.yesNo("Sind Sie sicher, dass Sie diese Bewertungsstufe löschen möchten?", "Warnung")) {
            (forDictation ? this.markTranslationDictation : this.markTranslationGeneral).splice(index, 1);
        }
    }

    async copyMarkTranslation(): Promise<void> {
        let form = new FrontendFormDefinition(this.grades.filter(grade => grade != this.selectedGrade).map(grade => new FrontendFieldDefinition("copyToGrade" + grade, "Klassenstufe " + grade, FrontendFieldType.checkBox)));
        if (await this.messageDialog.form(form, "Kopiere zu den folgenden Klassenstufen") == 'ok') {
            for (let grade of this.grades) {
                if (grade != this.selectedGrade && form.getField("copyToGrade" + grade).value) {
                    let set = this.settings.getGradeSettings(grade);
                    set.gradeType = this.selectedGradeSettings.gradeType;
                    set.useDictationTranslation = this.selectedGradeSettings.useDictationTranslation;
                    set.useTextualGrades = this.selectedGradeSettings.useTextualGrades;
                    set.markTranslation = this.selectedGradeSettings.markTranslation.map(mt => Utils.fromPlain(MarkTranslation, { settings: mt.settings, fromPercent: mt.fromPercent, text: mt.text, forDictation: mt.forDictation }));
                }
            }
        }
    }

    async copyExamTypeFactors(): Promise<void> {
        let form = new FrontendFormDefinition(this.domains.filter(domain => domain != this.selectedDomain).map(domain => new FrontendFieldDefinition("copyToDomain" + domain, domain, FrontendFieldType.checkBox)));
        if (await this.messageDialog.form(form, "Kopiere zu den folgenden Kompetenzbereichen") == 'ok') {
            for (let domain of this.domains) {
                if (domain != this.selectedDomain && form.getField("copyToDomain" + domain).value) {
                    let set = this.settings.domainSettings.find(ds => ds.domain == domain);
                    set.examTypeFactors = this.selectedDomainSettings.examTypeFactors.map(etf => Utils.fromPlain(ExamTypeFactor, { settings: etf.settings, examType: etf.examType, factor: etf.factor }));
                }
            }
        }

    }

}

import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioCallFindEventTexts } from 'src/modules/enaio/shared/EnaioCallFindEventTexts';
import { EnaioEventFound } from 'src/modules/enaio/shared/EnaioEventFound';
import { EnaioEventFoundText } from 'src/modules/enaio/shared/EnaioEventFoundText';
import { AceEditorComponent } from 'src/modules/sm-base/components/ace-editor/ace-editor.component';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { TreeNode } from 'primeng/api';
import { ArrayOrSingle } from 'src/modules/sm-base/shared/array-or-single.model';

@Component({
  selector: 'app-enaio-search-and-replace',
  templateUrl: './enaio-search-and-replace.component.html',
  styleUrls: ['./enaio-search-and-replace.component.scss']
})
export class EnaioSearchAndReplaceComponent extends ComponentView {

    _Utils = Utils;

    formSearch: FrontendFormDefinition;
    result: EnaioEventFound;
    codeLines: string[] = [];
    expanded: boolean[] = [];
    tree: TreeNode2[];
    selectedMatch: ArrayOrSingle<TreeNode>;

    @ViewChildren('resultCards') resultCards: QueryList<ElementRef>;
    @ViewChild('ace') ace: AceEditorComponent;

    async initParams(): Promise<boolean> {
        let templates: { name: string; text: string; regex: boolean }[] = [
            { name: "DocumentHandler Aufrufe", text: "dh\\.(bat|cmd)|DocumentHandler\\.exe|config[\\\\/].*?[\\\\/]assets[\\\\/]", regex: true },
            { name: "EnaioImportGui Aufrufe", text: "EnaioImportGui(Starter)?\\.exe", regex: true },
            { name: "Portal Aufrufe", text: "https?://.*(documenthandler|enaio-certificates|enaio-learn|enaio-templates|enaio-documents)", regex: true }
        ];
        this.formSearch = new FrontendFormDefinition([
            new FrontendFieldDefinition("template", "Vorlage", FrontendFieldType.comboBox, { dropdownEditable: false, listItems:
                [new FrontendFieldListItem(null, "<Klicken um Vorlage auszuwählen>"), ...templates.map(t => new FrontendFieldListItem(t, t.name))], onValueChanged: this.templateChanged.bind(this) }),
            new FrontendFieldDefinition("text", "Suchtext", FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("regex", "Regulärer Ausdruck", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("caseSensitive", "Groß- und Kleinschreibung beachten", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("onlyActiveWfModels", "Nur aktive Workflowmodelle", FrontendFieldType.checkBox, { value: true }),
            new FrontendFieldDefinition("onlyCurrentWfModels", "Nur letzte/startbare Workflowmodelle", FrontendFieldType.checkBox)
        ]);
        return Promise.resolve(true);
    }

    templateChanged(): void {
        let t = this.formSearch.getValue("template") as { name: string; text: string; regex: boolean };
        if (t != null) {
            this.formSearch.setValue("text", t.text);
            this.formSearch.setValue("regex", t.regex);
        }
    }

    selectedMatchChanged(): void {
        GuiUtils.angularTimer(() => {
            this.ace.jumpToLine((Utils.asSingle(this.selectedMatch).data as EnaioEventFoundText).line, true);
        });
    }

    async search(): Promise<void> {
        this.result = await DhTools.enaioCall<EnaioEventFound>(Utils.fromPlain(EnaioCallFindEventTexts, {
            text: this.formSearch.getValue("text"),
            regex: this.formSearch.getValue("regex"),
            caseSensitive: this.formSearch.getValue("caseSensitive"),
            onlyActiveWfModels: this.formSearch.getValue("onlyActiveWfModels"),
            onlyCurrentWfModels: this.formSearch.getValue("onlyCurrentWfModels")
        }));
        this.codeLines = this.result.matches.map(found => Utils.arrayGetSafe(Utils.splitLines(found.wfEventId != null ? this.result.wfEventDefinitions[found.wfEventId].code : this.result.clientEventDefinitions[found.clientEventId].code), found.line - 1));
        this.expanded = this.result.matches.map(_ => false);

        this.tree = GuiUtils.generateTree(this.result.matches, m => [m.wfEventId != null ? "Workflow" : "Client-Event", m.eventName, "Zeile " + m.line], (item, key, index) => ({data: index == 2 ? item : null, label: key[index], expanded: true}), true);
    }

}

import { GenericDataType } from "src/modules/enaio/shared/GenericDataType";
import { TableCellType } from "src/modules/sm-base/models/table-cell-type.enum";
import { Utils } from "src/modules/utils/shared/utils";

export class EnaioFrontendColumn {
    name = "";
    internalName = "";
    onParent = false;
    type: GenericDataType;

    constructor(name: string, internalName: string) {
        this.name = name;
        this.internalName = internalName;
    }

    toTableCellType(): TableCellType {
        switch (this.type) {
            case GenericDataType.string:
                return TableCellType.text;
            case GenericDataType.int:
                return TableCellType.number;
            case GenericDataType.decimal:
                return TableCellType.number;
            case GenericDataType.bool:
                return TableCellType.yesNo;
            case GenericDataType.date:
                return TableCellType.date;
            case GenericDataType.dateTime:
                return TableCellType.dateTime;
            default:
                throw new Error("Der GenericDataType " + this.type + " kann nicht zu TableCellType konvertiert werden");
        }
    }

    toTableValue(value: any): any {
        switch (this.type) {
            case GenericDataType.string:
                return Utils.toString(value);
            case GenericDataType.int:
                return Utils.toNumber(value);
            case GenericDataType.decimal:
                return Utils.toNumber(value);
            case GenericDataType.bool:
                return Utils.toBool(value);
            case GenericDataType.date:
                return Utils.toDate(value);
            case GenericDataType.dateTime:
                return Utils.toDate(value);
            default:
                throw new Error("Der GenericDataType " + this.type + " kann nicht zu TableCellType konvertiert werden");
        }
    }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SmTableComponent } from '../sm-table/sm-table.component';

@Component({
    selector: 'sm-table-on-push',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../sm-table/sm-table.component.html',
    styleUrls: ['../sm-table/sm-table.component.scss']
})
export class SmTableOnPushComponent extends SmTableComponent {

}

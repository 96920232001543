import { Component, ViewChild } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { ConfigEditorTreeComponent } from '../../../sm-base/components/config-editor-tree/config-editor-tree.component';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { MenuItem } from 'primeng/api';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { ClassType } from 'src/modules/utils/shared/class-type';
import { EnaioConfig } from 'src/modules/enaio/shared/EnaioConfig';
import { DbConnectionConfig } from 'src/modules/enaio/shared/DbConnectionConfig';
import { FtpConnectionParams } from 'src/modules/enaio/shared/FtpConnectionParams';
import { ConfigDefinition } from 'src/modules/sm-base/models/config-definition.model';
import { ConfigProperty } from 'src/modules/sm-base/models/config-property.model';
import { ConfigFieldDefinition } from 'src/modules/sm-base/models/config-field-definition.model';
import { ConfigFieldType } from 'src/modules/sm-base/models/config-field-type.enum';
import { LdapConnection2 } from 'src/modules/enaio/shared/LdapConnection2';

@Component({
  selector: 'app-connection-tester',
  templateUrl: './connection-tester.component.html',
  styleUrls: ['./connection-tester.component.scss']
})
export class ConnectionTesterComponent extends ComponentView {

    def: ConfigDefinition;
    config: ConfigProperty;
    form: FrontendFormDefinition;

    types: ConnectionTesterType[];
    selectedType: ConnectionTesterType;
    nodes: TreeNode2[];
    result = "";

    connectionItems: MenuItem[] = [];

    @ViewChild("configEditorTree") configEditorTree: ConfigEditorTreeComponent;

    async initParams(): Promise<boolean> {
        this.def = await DhTools.backendCall("api/config/definition").get(ConfigDefinition);
        this.def.init();
        this.configEditorTree.def = this.def;

        this.types = [
            Utils.fromPlain(ConnectionTesterType, { name: "enaio", typeName: "CsUtils.EnaioConfig", type: EnaioConfig, fields: ["connectionType", "server", "port", "userName", "password", "passwordEncrypted"]}),
            Utils.fromPlain(ConnectionTesterType, { name: "Datenbank", typeName: "CsUtils.DbConnectionConfig", type: DbConnectionConfig}),
            Utils.fromPlain(ConnectionTesterType, { name: "FTP", typeName: "CsUtils.FtpConnectionParams", type: FtpConnectionParams}),
            Utils.fromPlain(ConnectionTesterType, { name: "LDAP", typeName: "CsUtils.LdapConnection2", type: LdapConnection2})
        ];

        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("type", "Art", FrontendFieldType.comboBox, { value: this.types[0], dropdownEditable: false, listItems: this.types.map(type => new FrontendFieldListItem(type, type.name)), onValueChanged: this.typeChanged.bind(this) })
        ]);
        this.typeChanged();
        return Promise.resolve(true);
    }

    typeChanged(): void {
        this.selectedType = this.form.getValue("type") as ConnectionTesterType;
        if (this.selectedType == null) {
            return;
        }

        let plain = {};

        let mainFieldDef = Utils.fromPlain(ConfigFieldDefinition, { name: "main", type: ConfigFieldType.object, typeName: this.selectedType.typeName});
        this.config = new ConfigProperty({}, this.selectedType.typeName, true, mainFieldDef, [], plain);
        let d = this.def.getByName(this.selectedType.typeName);
        this.config.properties = mainFieldDef.getProperties(d, plain, this.def);

        this.configEditorTree.setObject(this.config);
    }

    async updateConnectionItems(): Promise<void> {
        let items = await DhTools.backendCall("api/dh/getConfigConnections", { typeName: this.selectedType.typeName }).getOrdinaryObject(this.selectedType.type);
        this.connectionItems = [
        {
            label: "Aus Clipboard",
            command: async () => {
                await this.loadConnection(true);
            }
        },
        {
            label: "Eingabe ...",
            command: async () => {
                await this.loadConnection();
            }
        },
        ...Utils.getOwnPropertyNames(items).map(item => ({
            label: item,
            command: () => {
                this.fillConnection(items[item]);
            }
        }))];
    }

    async loadConnection(useClip = false): Promise<void> {
        let s = "";
        if (useClip) {
            s = await GuiUtils.fromClipboard();
        }
        else {
            s = await this.app.messageDialog.input(new FrontendFieldDefinition("", "Verbindung", FrontendFieldType.textArea, { value: GuiUtils.isClipboardReadSupported() ? await GuiUtils.fromClipboard() : ""}), "Verbindung im DocumentHandler-JSON-Format");
            if (s == null) {
                return;
            }
        }
        this.fillConnection(Utils.fromJson(s) as OrdinaryObject);
    }

    fillConnection(connection: OrdinaryObject): void {
        let mainFieldDef = Utils.fromPlain(ConfigFieldDefinition, { name: "main", type: ConfigFieldType.object, typeName: this.selectedType.typeName});
        this.config = new ConfigProperty({}, this.selectedType.typeName, true, mainFieldDef, [], connection);
        let d = this.def.getByName(this.selectedType.typeName);
        this.config.properties = mainFieldDef.getProperties(d, connection, this.def);

        this.configEditorTree.setObject(this.config);
    }

    async test(): Promise<void> {
        let o = this.configEditorTree.getObject();
        this.result = await DhTools.backendCall("api/tools/connectionTester", { typeName: this.selectedType.typeName, options: o}).getString();
    }

/*

enaio
FTP
DATEV
Mail Get
Mail Send
LDAP
Database
*/

}


class ConnectionTesterType {
    name: string;
    typeName: string;
    type: ClassType<object>;
    fields: string[];
}

import { Component, OnInit } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { PayrollUser } from '../../shared/payroll-user.model';

@Component({
  selector: 'app-payroll-users',
  templateUrl: './payroll-users.component.html',
  styleUrls: ['./payroll-users.component.scss']
})
export class PayrollUsersComponent implements OnInit {


    listData: PayrollUser[];
    table: TableData;

    constructor(private app: MainAppService) {
    }

    async ngOnInit(): Promise<void> {
        this.listData = await RestEndpoint.main().find().run("api/pay/payroll/users").list(PayrollUser);
        this.table = new TableData([new TableColumn("userName", "Benutzername"), new TableColumn("reset", "", TableCellType.button)],
            this.listData.map(o => new TableRow(o, { userName: o.userName, reset: "fa fa-lock-open"})), [new TableSortColumn("userName")]);
    }

    async cellClicked(event: any): Promise<void> {
        if (!await this.app.messageDialog.yesNo("Möchten Sie das Passwort für " + event[0].raw.userName + " zurücksetzen", "Frage")) {
            return;
        }

        let newPassword = await RestEndpoint.main().query({id: event[0].raw.id}).run("api/pay/payroll/resetUser").getText();

        let clipboardOk = false;
        let permission: PermissionStatus = null;
        try {
            permission = await navigator.permissions.query({name: "clipboard-write" as any});
            if (permission.state == "granted" || permission.state == "prompt") {
                await navigator.clipboard.writeText(newPassword);
                clipboardOk = true;
            }
        }
        catch (ex) {
        }

        await this.app.messageDialog.info("Das neue Initialpasswort lautet:<br><br>" + newPassword + "<br><br>" +
            (clipboardOk ? "Das Passwort befindet sich in der Zwischenablage." : "Das Passwort konnte nicht automatisch in die Zwischenablage kopiert werden."));
    }
}

import { Type } from "class-transformer";
import { EnaioObjectTypeId } from "./EnaioObjectTypeId";

export class EnaioObjectTypeExportParams {
    @Type(() => EnaioObjectTypeId)
    objectType: EnaioObjectTypeId;
    includeChildren = true;
    documents = true;
    export = true;
    exportConditions: string;
    overwriteDocumentFiles = false;
}

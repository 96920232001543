export enum GenericDataType {
    string = 0,
    int = 1,
    decimal = 2,
    bool = 3,
    date = 4,
    dateTime = 5,
    array = 6,
    object = 7
}
export namespace GenericDataType{
    export function getName(value: GenericDataType): string {
        switch (value) {
            case GenericDataType.string:
                return "string";
            case GenericDataType.int:
                return "int";
            case GenericDataType.decimal:
                return "decimal";
            case GenericDataType.bool:
                return "bool";
            case GenericDataType.date:
                return "date";
            case GenericDataType.dateTime:
                return "dateTime";
            case GenericDataType.array:
                return "array";
            case GenericDataType.object:
                return "object";
        }
    }
}

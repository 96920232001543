import { Component, inject } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassInstruction } from '../../shared/class-instruction.entity';
import { ClassRegister } from '../../shared/class-register.entity';
import { Pupil } from '../../shared/pupil.model';
import { Teacher } from '../../shared/teacher.model';

@Component({
    selector: 'app-class-instruction',
    templateUrl: './class-instruction.component.html',
    styleUrls: ['./class-instruction.component.scss']
})
export class ClassInstructionComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;

    classRegister: ClassRegister;
    rowCount: number;

    secondInstructions: number[] = [];
    rows: ClassInstruction[] = [];
    orgRows: ClassInstruction[] = [];
    classPupils: Pupil[] = [];
    classTeachers: Teacher[] = [];
    pupilsGui: FrontendFieldListItem[] = [];
    instructionNames: string[] = [];
    instructionOptions: SelectItem[] = [];

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, ["instructions"], null, true);
        this.classPupils = [new Pupil(), ...await this.service.restGetPupils(this.classRegister.schoolClassId)];
        this.classTeachers = [new Teacher("", ""), ...await this.service.restGetTeachers(this.classRegister.schoolClassId)];
        this.instructionNames = await RestEndpoint.main().query({ year: this.classRegister.enaioSchoolClass.getYearNumber(), isHalfYear: false }).run("api/cer/classregister/instructiontexts").listStrings();
        this.instructionOptions = [{ label: "\xa0", value: "" }, ...Utils.arraySort(this.instructionNames).map(c => ({ label: c, value: c }))];

        this.pupilsGui = this.classPupils.filter(p => p.firstName != "").sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase())).map(d => new FrontendFieldListItem(d.id, d.getFullName())); // d.firstName + " " + d.lastName

        this.rows = [];
        for (let instruction of this.classRegister.instructions) {
            this.rows.push(instruction);
            if (instruction.secondTopic != "") {
                this.secondInstructions.push(this.rows.length - 1);
            }
        }
        this.orgRows = Utils.cloneDeep(this.rows);
        this.rowCount = this.rows.length;

        this.app.updateNavigation(null, [
            { label: "Digitales Klassenbuch", routerLink: ["/enaio-certificates", "class-registers", "home"] },
            { label: "Klasse " + this.classRegister.enaioSchoolClass.getDisplayName(), routerLink: ["/enaio-certificates", "class-registers", "class-register", this.id] },
            { label: "Belehrungen", routerLink: ["/enaio-certificates", "class-registers", "instructions", this.id] }
        ]);
        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? "" : null;
    }

    isChanged(): boolean {
        return !Utils.equalsDeep(this.rows, this.orgRows);
    }

    changeDate(event: Date, row: ClassInstruction): void {
        row.date = event;
    }

    changeTopic(event: any, row: ClassInstruction): void {
        row.topic = event;
    }

    changeMissing(event: any, row: ClassInstruction): void {
        row.missingPupilIds = event;
    }

    changeSecondTopic(event: any, row: ClassInstruction): void {
        row.secondTopic = event;
        row.secondDate = Utils.isNoe(row.secondTopic) ? null : new Date();
        row.secondInstructedBy = this.app.getUserName();
    }

    changeSecondDate(event: any, row: ClassInstruction): void {
        row.secondDate = event;
    }

    async save(): Promise<void> {
        await this.app.saveDataHandler(async () => RestEndpoint.main().query({ id: this.id }).body(this.rows).post().run("api/cer/classregister/instructions").getText(), this, true, true);
    }

    addRow(): void {
        let newRow = new ClassInstruction();
        newRow.date = new Date();
        newRow.instructedBy = this.app.getUserName();

        this.rows.push(newRow);
    }

    removeRow(row: ClassInstruction): void {
        console.log("test");
        this.rows.splice(this.rows.findIndex(r => r.id == row.id), 1);
    }

    getPupilList(pupilIds: number[]): string[] {
        let list: string[] = [];

        for (let pId of pupilIds) {
            let pupil: Pupil = this.classPupils.find(p => p.id == pId);
            if (pupil != null) {
                list.push(pupil.firstName + " " + pupil.lastName);
            }
        }
        return list;
    }

    getTeacherByUser(user: string): string {
        let t = this.classTeachers.find(t2 => t2.userName == user);
        return t != null ? t.getDisplayName() : user;
    }
}

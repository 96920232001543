<div class="grid">
    <div class="col-2">
        <sm-form  [form]="formFilter">
        </sm-form>
        <p-button icon="fas fa-open" label="Öffnen" (onClick)="open()"></p-button>
    </div>
    <p-tabView class="col-10">
        <p-tabPanel header="Masken">
            <div class="grid">
                <p-dropdown class="col-12" [styleClass]="'width300'" [options]="masksGui" [(ngModel)]="selectedMask" (onChange)="changeMask()"></p-dropdown>
                <p-tabView class="col-10">
                    <p-tabPanel header="Tabellarisch">
                        <sm-table-on-push #wfTableComponent *ngIf="fieldsTable != null" class="col-12 wf-table" [data]="fieldsTable" [deltaHeight]=300 (onCellClicked)="editField($event)"></sm-table-on-push>
                    </p-tabPanel>
                    <p-tabPanel header="Grafisch">
                        <sm-form [form]="maskForm"></sm-form>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Variablen">
            <sm-table-on-push #wfTableComponent *ngIf="variablesTable != null" class="col-12" [data]="variablesTable" [deltaHeight]=240></sm-table-on-push>
        </p-tabPanel>
        <p-tabPanel header="Skripte">
            <app-frontend-script-editor [scripts]="scripts"></app-frontend-script-editor>
        </p-tabPanel>
    </p-tabView>
</div>

<p-message *ngIf="state != null && state.finished" class="col-12 mt-2" severity="warn" text="Die Instanz ist bereits beendet!"></p-message>
<p-tabView [(activeIndex)]="tabIndex" (activeIndexChange)="activeTabChanged()">
    <p-tabPanel header="Status">
        <div class="status-panel">
            <p-progressBar [value]="_Utils.toNumber(_Utils.restrictDecimalDigits(state.workObjectsCount == 0 ? 0 : _Utils.divSafe(state.currentWorkObjectIndex * 100, state.workObjectsCount), 0))"></p-progressBar>
        </div>
        <div class="status-panel">
            Konfiguration: <span class="bold">{{state.configName}}</span>
            Objekt: <span class="bold">{{state.workObjectsCount == 0 ? 0 : state.currentWorkObjectIndex + 1}} von {{state.workObjectsCount}}</span>
            Objektname: <span class="bold">{{state.currentWorkObjectName}}</span>
            <span *ngIf="state.globalLoopItem != null"> GlobalLoopItem: <span class="bold">{{state.globalLoopItem}}</span></span>
            <span *ngIf="!_Utils.isNoe(state.currentWorkerName)"> Worker: <span class="bold">{{state.currentWorkerName}}</span></span>
        </div>

        <div class="grid">
            <div class="col-2">
                <sm-form [form]="form"></sm-form>
                <div class="status-panel form-group p-field grid">
                    <span class="col-12">
                        <p-button *ngIf="!state.breakpointReached && !state.breakpointRequested" class="toolbar-element" icon="fas fa-stop-circle" label="Unterbrechen" (onClick)="break()"></p-button>
                        <p-button *ngIf="state.breakpointReached" class="toolbar-element" icon="fas fa-person-walking" label="Fortsetzen" (onClick)="continue()"></p-button>
                        <p-progressSpinner [style]="{width: '25px', height: '25px'}" *ngIf="state.breakpointRequested"></p-progressSpinner>
                        <span *ngIf="state.breakpointReached" style="color: red; font-weight: bold">Unterbrochen!</span>
                    </span>
                </div>

                <p-splitButton styleClass="mb-2" label="Verschiedenes" icon="fas fa-location-crosshairs" [model]="actionItems" (onDropdownClick)="updateActionItems()"></p-splitButton>
            <!--  <span class="dev" (click)="dev = true">.</span>
                <p-card *ngIf="dev">
                    <a [routerLink]="['/documenthandler', 'config-editor']">Config-Editor</a>
                </p-card>
                <p-card *ngIf="dev">
                    <a [routerLink]="['/documenthandler', 'script-tracing']">ScriptTracing (Developer)</a>
                </p-card>-->
                <div class="mb-3">Durchgeführte Aktionen</div>
                <sm-table-on-push *ngIf="actionCountTable != null" [data]="actionCountTable"></sm-table-on-push>
            </div>
            <sm-table-on-push *ngIf="errorTable != null" class="col-10" [data]="errorTable" [deltaHeight]=250 [useTableSizer]=true (onCellClicked)="errorCellClicked($event)"></sm-table-on-push>
        </div>

    </p-tabPanel>
    <p-tabPanel header="Worker">
        <div class="grid">
            <div class="col-6">
                <sm-table-on-push *ngIf="workerTable != null" class="col-10" [data]="workerTable" [deltaHeight]=250 (onRowSelect)="workerCellClicked($event)"></sm-table-on-push>
            </div>
            <div class="col-6">
                <textarea pInputTextarea spellcheck=false class="width100p full-height-200x monospace no-resize" [ngModel]="workerDump"></textarea>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Objekt-Analyse">
        <div class="grid">
            <div class="col-2">
                <p-message *ngIf="state.workObjectsCount == 0" severity="warn" text="Keine Objekte vorhanden"></p-message>
                <sm-form *ngIf="state.workObjectsCount > 0" [form]="formWorkObjectsAnalyses"></sm-form>
                <div><p-button *ngIf="state.workObjectsCount > 0" class="toolbar-element" icon="fas fa-open" label="Auswerten" (onClick)="analyseWorkObjects()"></p-button></div>
                <div class="mt-2"><p-button *ngIf="state.workObjectsCount > 0" class="toolbar-element" icon="fas fa-open" label="Alle Metadaten exportieren" (onClick)="workObjectsToCsv(false)"></p-button></div>
                <div class="mt-2"><p-button *ngIf="state.workObjectsCount > 0" class="toolbar-element" icon="fas fa-open" label="Alle eindeutigen Metadaten exportieren" (onClick)="workObjectsToCsv(true)"></p-button></div>
            </div>
            <sm-table-on-push *ngIf="workObjectsAnalysesTable != null" class="col-10" [data]="workObjectsAnalysesTable" [deltaHeight]=250></sm-table-on-push>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Auswertungskonsole">
        <app-document-handler-eval-console #viewEvalConsole [guid]="guid"></app-document-handler-eval-console>
    </p-tabPanel>
    <p-tabPanel header="Profiling">
        <p-tabView [(activeIndex)]="activeIndexProfiling">
            <p-tabPanel header="Templates">
                <sm-table-on-push *ngIf="tableTemplateProfiling != null" [data]="tableTemplateProfiling" [deltaHeight]=220 (onCellEdited)="editTemplateProfilingCell($event)" (onCellClicked)="clickTemplateProfilingCell($event)"></sm-table-on-push>
            </p-tabPanel>
            <p-tabPanel header="Worker">
                <sm-toolbar>
                    <p-checkbox name="workerProfilingRefresh" [(ngModel)]="workerProfilingRefresh" [binary]="true"></p-checkbox>
                    <label class="form-label mr-3">Aktualisieren</label>
                    <p-checkbox name="workerProfilingGroupObject" [(ngModel)]="workerProfilingGroupObject" [binary]="true" (ngModelChange)="fillWorkerProfilingTable()"></p-checkbox>
                    <label class="form-label mr-3">Gruppiere nach Objekt</label>
                    <p-checkbox name="workerProfilingGroupWorker" [(ngModel)]="workerProfilingGroupWorker" [binary]="true" (ngModelChange)="fillWorkerProfilingTable()"></p-checkbox>
                    <label class="form-label mr-3">Gruppiere nach Worker</label>
                </sm-toolbar>
                <sm-table-on-push *ngIf="tableWorkerProfiling != null" [data]="tableWorkerProfiling" [deltaHeight]=270></sm-table-on-push>
            </p-tabPanel>
            <p-tabPanel header="Server API">
                <sm-toolbar>
                    <p-checkbox name="enaioCallsProfilingRefresh" [(ngModel)]="enaioCallsProfilingRefresh" [binary]="true"></p-checkbox>
                    <label class="form-label mr-3">Aktualisieren</label>
                    <p-checkbox name="enaioCallsProfilingGroupParams" [(ngModel)]="enaioCallsProfilingGroupParams" [binary]="true" (ngModelChange)="fillEnaioCallsProfilingTable()"></p-checkbox>
                    <label class="form-label mr-3">Gruppiere nach Parametern</label>
                </sm-toolbar>
                <sm-table-on-push *ngIf="tableEnaioCallsProfiling != null" [data]="tableEnaioCallsProfiling" [deltaHeight]=270></sm-table-on-push>
            </p-tabPanel>
        </p-tabView>
    </p-tabPanel>
    <p-tabPanel header="Stack Trace">
        <textarea pInputTextarea spellcheck=false class="width100p full-height-200x monospace no-resize" [ngModel]="stackTrace"></textarea>
    </p-tabPanel>
    <p-tabPanel header="Server API">
    <app-server-api-caller #viewEnaioServerApiCaller [instanceGuid]="guid" [embeddedComponent]=true>
    </app-server-api-caller>
</p-tabPanel>
</p-tabView>

import { Component, Input, ViewChild } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { SmTimer } from 'src/modules/sm-base/models/sm-timer.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-enaio-web-client-debugger',
    templateUrl: './enaio-web-client-debugger.component.html',
    styleUrls: ['./enaio-web-client-debugger.component.scss']
    })
    export class EnaioWebClientDebuggerComponent extends ComponentView {

    @Input()
    guid: string = null;

    evaluateText = "";
    evaluationTree: TreeNode2[] = [];
    selectedNode: TreeNode2;

    dumpGuid: string;

    @ViewChild("evaluateTextComponent") evaluateTextComponent: any;

    async keyPress(event: KeyboardEvent): Promise<void> {
        if (event.key == "Enter") {
            await this.evaluateInput();
        }
    }

    async evaluateInput(): Promise<void> {
        this.dumpGuid = Utils.generateGuid();

        let clip = `(function(obj, pretty){
            const seen = new WeakSet();
            const replacer = function(key, value) {
                if (typeof value === "object" && value != null) {
                    if (seen.has(value)) {
                        return;
                    }
                    seen.add(value);
                }
                return value;
            };
            var result = pretty ? JSON.stringify(obj, replacer, 2) : JSON.stringify(obj, replacer);
            var request = new XMLHttpRequest();
            request.open('POST', 'http://localhost:8765/api/tools/postJsDump');
            request.send(JSON.stringify({guid: "${this.dumpGuid}", value: result}));
            return null;
        })(${this.evaluateText}, true)`;

        if (GuiUtils.isClipboardWriteSupported()) {
            await GuiUtils.copyToClipboard(clip);
        }
        this.app.showToast('info', "Aktion erforderlich", "Bitte jetzt in Chrome den Inhalt der Zwischenablage in die Konsole einfügen und ausführen");
        this.clearTimers();
        this.addTimer(new SmTimer(1000, 1000, async () => this.getDebuggerOutput()));
    }

    async getDebuggerOutput(): Promise<void> {
        let result = Utils.fromJson(await DhTools.backendCall("api/tools/getJsDump", { guid: this.dumpGuid }).getText());
        if (!Utils.isNoe(result)) {
            this.evaluationTree = GuiUtils.objectToTree(result);
            this.clearTimers();
        }
    }
}


import { Utils } from "src/modules/utils/shared/utils";

export enum TableCellType {
    text,
    dropdown,
    number,
    yesNo,
    date,
    dateTime,
    color,
    button,
    byteSize,
    duration,
    link,
    icon,
    image
}

export namespace TableCellType {
    export function convertValue(value: any, type: TableCellType): any {
        switch (type) {
            case TableCellType.number:
                return Utils.toNumber(value);
            case TableCellType.yesNo:
                return Utils.toBool(value);
            case TableCellType.date:
                return Utils.isString(value) ? new Date(value as string) : value;
            case TableCellType.dateTime:
                return Utils.isString(value) ? new Date(value as string) : value;
            default:
                return value;
        }
    }

}

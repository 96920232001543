import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";
import { EnaioObjectTypeExportParams } from "./EnaioObjectTypeExportParams";

export class EnaioArchiveExportParams {
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    @Type(() => EnaioObjectTypeExportParams)
    objectTypes: EnaioObjectTypeExportParams[];
}

/* eslint-disable @typescript-eslint/no-inferrable-types */

import { Type } from "class-transformer";
import { Utils } from "src/modules/utils/shared/utils";

export class LearningContentInspectionPhase {
    index: number = 0;
    done: boolean = false;
    current: boolean = false;
    toDo: boolean = false;

    phaseId: number = 0;
    responsibleUsers: number[] = [];
    substituteUsers: number[] = [];

    phaseName: string = "";
    responsibleUserNames: string[] = [];
    substituteUserNames: string[] = [];

    @Type(() => Date)
    inspectedDate: Date;
    inspectedBy: number;
    inspectedByUserName: string;

    virtualEditing: boolean = false;
    virtualDone: boolean = false;

    getAllUserNames(): string[] {
        return Utils.arrayGetUnique([...this.responsibleUserNames, this.inspectedByUserName]);
    }
}

import "@angular/compiler"; //Dieser Import wird benötigt, weil es sonst ein Problem beim Laden der Klasse PrimeNGConfig. Der Import sollte in der Zukunft irgendwann wieder rausfliegen
import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { Config } from './modules/app-template/models/config.model';

Error.stackTraceLimit = 100;
if (Config.get().production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(moduleRef => {
    let debug = true;
    if (debug) {
        const applicationRef = moduleRef.injector.get(ApplicationRef);
        const componentRef = applicationRef.components[0];
        enableDebugTools(componentRef);
        //In der Browser-Konsole: ng.profiler.timeChangeDetection()
}
  }).catch(err => console.error(err));

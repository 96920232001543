import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldGuiHint } from 'src/modules/sm-base/models/frontend-field-gui-hint.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassLessonPupil } from '../../shared/class-lesson-pupil.entity';
import { ClassLesson } from '../../shared/class-lesson.entity';
import { ClassRegister } from '../../shared/class-register.entity';
import { Pupil } from '../../shared/pupil.model';
import { SickNote } from '../../shared/sick-note.entity';

@Component({
  selector: 'app-class-lesson',
  templateUrl: './class-lesson.component.html',
  styleUrls: ['./class-lesson.component.scss']
})
export class ClassLessonComponent extends ComponentView {

    _Utils = Utils;
    _GuiUtils = GuiUtils;

    service = inject(EnaioCertificateService);

    id: number;
    classId: number;
    classRegister: ClassRegister;
    lesson: ClassLesson;
    sickNotes: SickNote[];
    pupils: Pupil[];
    domains: string[];
    form: FrontendFormDefinition;

    pupilRows: PupilRow[];

    constructor() {
        super();
        this.neededParams = { id: "number", classId: "number?" };
    }

    async initParams(): Promise<boolean> {
        this.lesson = await RestEndpoint.main().findById(this.id).run("api/cer/classregister/lesson").get(ClassLesson);
        this.classRegister = null;
        if (this.classId > 0) {
            this.classRegister = await this.service.restGetClassRegister(this.classId, null, null, true);
        }
        if (this.classRegister == null) {
            this.classRegister = await this.service.restGetClassRegister(this.lesson.classRegisterIds[0], null, null, true);
        }
        this.pupils = this.lesson.course != null ? await RestEndpoint.main().query({id: this.lesson.course.id}).run("api/cer/classregister/coursepupils").list(Pupil) : await this.service.restGetPupils(this.classRegister.enaioSchoolClass.id);
        let settings = await this.service.restGetClassRegisterSettings(this.classRegister.enaioSchoolClass.getYearNumber(), this.classRegister.isHalfYear);
        this.domains = ["\xa0", ...await this.service.restGetDomains(this.classRegister.enaioSchoolClass.grade, this.classRegister.enaioSchoolClass.getSchoolType(), false, settings, settings)];
        this.sickNotes = await RestEndpoint.main().query({ids: Utils.arrayItemsToString(this.lesson.classRegisterIds, ";"), courseId: this.lesson.course?.id ?? 0, date: Utils.dateFormat(this.lesson.date, "yyyymmdd"), hour: this.lesson.hour}).run("api/cer/classregister/sicknote").list(SickNote);

        this.pupilRows = Utils.arraySortBy(this.pupils.map(p => new PupilRow(p, this.lesson.pupils.find(p2 => p2.pupilId == p.id) ?? Utils.fromPlain(ClassLessonPupil, {
            pupilId: p.id
        }), this.sickNotes.find(sn => sn.pupilId == p.id && sn.includes(this.lesson.date, this.lesson.hour)))), p => p.pupil.getFullName());

        for (let row of this.pupilRows) {
            if (row.lessonPupil.id == 0 && row.sickNote != null) {
                row.lessonPupil.missing = true;
            }
        }

        this.form = new FrontendFormDefinition(Utils.arrayWithoutNull([
            this.lesson.course == null ? new FrontendFieldDefinition("domain", "Fach", FrontendFieldType.text, { enabled: false }) : null,
            this.lesson.course != null ? new FrontendFieldDefinition("__course", "Kurs", FrontendFieldType.text, { enabled: false }) : null,
            new FrontendFieldDefinition("skipped", "Ausgefallen", FrontendFieldType.checkBox, {
                guiHints: !this.lesson.skipped || Utils.isNoe(this.lesson.autoSkippedHoliday) ? null : [new FrontendFieldGuiHint("Automatisch aufgrund von Klassenfahrt " + this.lesson.autoSkippedHoliday, "fas fa-info", "green")]
            }),
            new FrontendFieldDefinition("isSubstitute", "Vertretungsstunde", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("substituteDomain", "Vertretungsfach", FrontendFieldType.comboBox, { dropdownEditable: false, listItems: this.domains.map(d => new FrontendFieldListItem(d, d))}),
            new FrontendFieldDefinition("description", "Dokumentation", FrontendFieldType.textArea, { fixedHeight: "100px"}),
            new FrontendFieldDefinition("homework", "Hausaufgaben", FrontendFieldType.textArea, { fixedHeight: "100px"}),
            new FrontendFieldDefinition("note", "Bemerkung", FrontendFieldType.textArea, { fixedHeight: "100px"}),
            new FrontendFieldDefinition("incidents", "Vorkommnisse", FrontendFieldType.textArea, { fixedHeight: "100px"})
        ]));

        this.form.fill(this.lesson);
        if (this.lesson.course != null) {
            this.form.setValue("__course", this.lesson.course.title);
        }

        this.form.getField("isSubstitute").onValueChanged = this.substituteChanged.bind(this);
        this.substituteChanged();

        this.app.updateNavigation("Klasse " + this.classRegister.enaioSchoolClass.getDisplayName(), [
            { label: "Digitales Klassenbuch", routerLink: ["/enaio-certificates/class-registers/home"] },
            { label: "Klasse " + this.classRegister.enaioSchoolClass.getDisplayName(), routerLink: ["/enaio-certificates", "class-registers", "class-register", this.classRegister.id] },
            { label: "Unterrichtswoche", routerLink: ["/enaio-certificates", "class-registers", "week", this.classRegister.id, Utils.dateFormat(Utils.dateStartOf(this.lesson.date, "week"), "yyyymmdd")] },
            { label: this.lesson.getTitle(), routerLink: Utils.arrayWithoutNull(["/enaio-certificates", "class-registers", "lesson", this.id, this.classId]) }
        ]);
        return true;
    }

    substituteChanged(): void {
        this.form.getField("substituteDomain").enabled = this.form.getValue("isSubstitute");
        if (!this.form.getField("isSubstitute").value) {
            this.form.setValue("substituteDomain", "");
        }
    }

    async save(): Promise<void> {
        let lesson2 = Utils.cloneDeep(this.lesson);
        this.form.get(lesson2);
        lesson2.pupils = Utils.arrayWithoutNull(this.pupilRows.map(pr => pr.lessonPupil));

        if (!lesson2.missingPupilsChecked && !lesson2.skipped &&
            !await this.app.messageDialog.yesNo("Sie haben die Abwesenheit noch nicht geprüft. Sie können speichern, die Unterrichtseinheit gilt dann aber noch nicht als abgeschlossen. Fortfahren?", "Warnung")) {
            return;
        }

        await this.app.saveDataHandler(async () => RestEndpoint.main().body(lesson2).post().run("api/cer/classregister/lesson").getText(), this, true, true);
    }

    async addSickNote(row: PupilRow): Promise<void> {
        if (!await this.app.messageDialog.yesNo("Möchten Sie eine Krankmeldung für " + row.pupil.getFullName() + " eintragen?", "Frage")) {
            return;
        }

        let form = this.service.getSickNoteForm(null, true);
        let sn = Utils.fromPlain(SickNote, {
            pupilId: row.pupil.id,
            fromDate: this.lesson.date,
            fromHour: this.lesson.hour,
            toDate: this.lesson.date
        });
        form.fill(sn);
        if (await this.app.messageDialog.form(form, "Krankmeldung eintragen", null, true) != "ok") {
            return;
        }
        form.get(sn);

        await RestEndpoint.main().post().query({isHalfYear: this.classRegister.isHalfYear}).body(sn).run("api/cer/classregister/sicknote").getText();

        this.app.showToast("success", "Abgelegt", "Die Krankmeldung wurde abgelegt");

        row.lessonPupil.missing = true;
        row.sickNote = sn;
    }
}

class PupilRow {

    constructor(public pupil: Pupil, public lessonPupil: ClassLessonPupil, public sickNote: SickNote) {
    }
}

import { Component, OnInit } from '@angular/core';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningContentCategory } from '../../shared/learning-content-category.entity';

@Component({
    selector: 'app-edit-learning-content-categories',
    templateUrl: './edit-learning-content-categories.component.html',
    styleUrls: ['./edit-learning-content-categories.component.scss']
})
export class EditLearningContentCategoriesComponent implements OnInit {

    listData: LearningContentCategory[];
    table: TableData;

    constructor(private service: EnaioLearnService) {
    }

    async ngOnInit(): Promise<void> {
        [this.listData, this.table] = await this.service.adminInitList<LearningContentCategory>(LearningContentCategory, "lea.category", "learning-content-categories", "learning-content-category", "api/lrn/learningcontentcategory", this,
        [new TableColumn("name", null), new TableColumn("color", null, TableCellType.color)], [new TableSortColumn("name")], o => new TableRow(o, { name: o.name, color: o.color }));
    }
}

<div class="grid">
    <div class="col-2">
        <sm-form  [form]="formFilter">
        </sm-form>
        <p-button icon="fas fa-filter" label="Filter" (onClick)="filter()"></p-button>
    </div>
    <div *ngIf="wfTable != null" class="col-10">
        <sm-toolbar *ngIf="lastCall != null && lastCall.getVariables">
            <p-checkbox name="unrollVariables" [(ngModel)]="unrollVariables" [binary]="true" (ngModelChange)="fillTable()"></p-checkbox>
            <label class="form-label mr-3">Variablen entrollen</label>
        </sm-toolbar>
        <sm-table-on-push [data]="wfTable" [deltaHeight]=200 (onCellClicked)="cellClicked($event)">
        </sm-table-on-push>
        <div class="mt-2">
            Anzahl gefundener Workflows: {{wfTable.rows.length}}
        </div>
    </div>
</div>
<p-contextMenu #cm [model]="contextMenu" appendTo="body"></p-contextMenu>

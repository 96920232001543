export class AceSelection {
    lineNumber = 0;
    columnNumber = 0;
    token = "";
    selection = "";

    constructor(lineNumber: number, columnNumber: number, token: string, selection: string) {
        this.lineNumber = lineNumber;
        this.columnNumber = columnNumber;
        this.token = token;
        this.selection = selection;
    }
}

<div class="flex-row">
    <div *ngIf="inited" class="flex-col flex-item-fixed width300">
        <p-listbox class="flex-item-fill full-height-120 tree-items-full-width" scrollHeight="auto" [options]="users" optionLabel="userName" optionValue="userName" selectionMode="single" [filter]=true filterBy="userName,fullName"
            [(ngModel)]="selectedUser" (onChange)="selectUser()">
        </p-listbox>
    </div>
    <p-tabView class="flex-item-fill" [class.hidden]="selectedUser == null">
        <p-tabPanel header="Benutzerrechte">
            <sm-toolbar>
                <span class="mr-3">Schrank:<p-dropdown class="ml-2" styleClass="width400" [options]="archivesGui" [(ngModel)]="selectedArchive" (onChange)="run()"></p-dropdown></span>
                <span class="mr-3">Vergleichen mit:<p-dropdown class="ml-2" styleClass="width300" [options]="compareToUsersGui" [(ngModel)]="selectedCompareToUser" (onChange)="run()"></p-dropdown></span>
             </sm-toolbar>
            <sm-table-on-push *ngIf="userTable != null" [deltaHeight]=300 [data]="userTable" groupRowsBy="objectType">
            </sm-table-on-push>
        </p-tabPanel>
        <p-tabPanel header="Benutzerkonfiguration">
            <sm-table-on-push *ngIf="prefsTable != null" [deltaHeight]=200 [data]="prefsTable">
            </sm-table-on-push>
        </p-tabPanel>
    </p-tabView>
</div>

import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { FrontendFieldDefinition } from '../../models/frontend-field-definition.model';
import { FrontendFieldGuiHint } from '../../models/frontend-field-gui-hint.model';
import { FrontendFieldType } from '../../models/frontend-field-type.enum';
import { SmFormFieldInternalComponent } from '../sm-form-field-internal/sm-form-field-internal.component';

@Component({
    selector: 'sm-form-field',
    templateUrl: './sm-form-field.component.html',
    styleUrls: ['./sm-form-field.component.scss']
})
export class SmFormFieldComponent {

    _FrontendFieldType = FrontendFieldType;

    @Input()
    field: FrontendFieldDefinition = null;
    @Input()
    multipleFields: FrontendFieldDefinition[] = null;
    @Input()
    stackLabels = true;
    @Input()
    mandatoryFieldsRedAsterisk = false;

    @Output()
    onClick = new EventEmitter<FrontendFieldDefinition>();

    @ViewChild("componentInternal") componentInternal: SmFormFieldInternalComponent;

    focus(): void {
        this.componentInternal.focus();
    }

    clickGuiHint(hint: FrontendFieldGuiHint): void {
        if (hint.onClick != null) {
            hint.onClick();
        }
    }
}

<div class="topbar">
    <div class="topbar-left">
        <span class="logo">
            <a [routerLink]="['']">
                <img class="logo-img" src="assets/layout/images/logo.png">
            </a>
        </span>
        <span *ngIf="app.toolbarBackButton">
            <a class="left-icon" href="javascript:history.back()">
                <i class="fas fa-arrow-left"></i>
            </a>
        </span>

       <p-breadcrumb *ngIf="app.breadcrumbsVisible && app.breadcrumbsInToolBar" class="breadcrumbs" [home]="app.breadcrumbsHome" [model]="app.breadcrumbsItems"></p-breadcrumb>
       <div *ngIf="app.staticHeaderHtml != null" class="ml-5" [innerHTML]="app.staticHeaderHtml"></div>
    </div>
    <ul class="burger-menu">
        <li>
            <a class="right-icon" href="#" (click)="appComponent.onTopbarMenuButtonClick($event)">
                <i class="fas fa-bars"></i>
            </a>
        </li>
    </ul>
    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': appComponent.topbarMenuActive}">
        <li *ngIf="app.toolbarShowUser" #profile class="profile-item" [ngClass]="{'active-topmenuitem':appComponent.activeTopbarItem === profile}">
            <a href="#" (click)="appComponent.onTopbarItemClick($event,profile)">
                <div class="profile-image">
                    <p-avatar [label]="getUserNameShort()" styleClass="mr-2" size="large" shape="circle"></p-avatar>
                </div>
                <div class="profile-info">
                    <span class="topbar-item-name profile-name">{{getUserName()}}</span>
                </div>
            </a>

            <ul class="fadeInDown">
                <li role="menuitem">
                    <a (click)="logout()">
                        <i class="fas fa-sign-out-alt"></i>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </li>
        <li *ngIf="app.toolbarHomeButton">
            <a href=# [routerLink]="['/enaio-learn', 'home']">
                <i class="topbar-icon fas fa-home"></i>
                <span class="topbar-item-name">Home</span>
            </a>
        </li>
        <li *ngIf="app.toolbarNotificationsButton">
            <a href="#" [routerLink]="['/enaio-learn', 'notifications']">
                <i class="topbar-icon fas fa-bell"></i>
                <span *ngIf="app.unreadNotificationCount > 0" class="topbar-badge">{{app.unreadNotificationCount}}</span>
                <span class="topbar-item-name">Nachrichten</span>
            </a>
        </li>
        <ng-container *ngFor="let button of app.toolbarButtons">
            <li *ngIf="button.visible">
                <a href="#" [routerLink]="button.routerLink" (click)="buttonClick(button)">
                    <i [ngClass]="'topbar-icon ' + button.icon"></i>
                    <span class="topbar-item-name">{{button.label}}</span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>

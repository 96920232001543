<ng-container *ngIf="field.hasControl" [ngSwitch]="field.type">
    <p-fieldset *ngSwitchCase="_FrontendFieldType.groupBox"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [legend]="field.caption"
        [style.text-transform]="field.upperCase ? 'uppercase' : null"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [style.z-index]="'-1'"
        styleClass="transparent"
    >
        <div
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        >
        </div>
    </p-fieldset>
    <input *ngSwitchCase="_FrontendFieldType.text" type="text" pInputText
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [maxLength]="field.maxLength > 0 ? field.maxLength : 255"
        [disabled]="!field.enabled"
        [style.text-transform]="field.upperCase ? 'uppercase' : null"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width]="field.isFixedPosition() ? field.fixedControlBounds.width + 'px' : '100%'"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [(ngModel)]="field.value"
        (change)="valueChanged()"
    >
    <p-password *ngSwitchCase="_FrontendFieldType.password"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [feedback]="field.passwordFeedback"
        [disabled]="!field.enabled"
        [toggleMask]="true"
        [style.text-transform]="field.upperCase ? 'uppercase' : null"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [style]="{'width': '100%'}"
        [inputStyle]="{'width': '100%'}"
        [(ngModel)]="field.value"
        (change)="valueChanged()"
    ></p-password>
    <p-inputNumber #componentInputNumber *ngSwitchCase="_FrontendFieldType.number"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [name]="field.id"
        [showButtons]="true"
        [format]="false"
        [disabled]="!field.enabled"
        [min]="field.minValue == null ? undefined : field.minValue"
        [max]="field.maxValue == null ? undefined : field.maxValue"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [style]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 'px'} : undefined"
        [(ngModel)]="field.value"
        (change)="valueChanged()"
    >
    </p-inputNumber>
    <p-rating #componentInputNumber *ngSwitchCase="_FrontendFieldType.rating"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [name]="field.id"
        [disabled]="!field.enabled"
        [stars]="field.maxValue >= 0 ? field.maxValue : 5"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [style]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 'px'} : undefined"
        [(ngModel)]="field.value"
        (change)="valueChanged()"
    >
    </p-rating>
    <p-inputMask *ngSwitchCase="_FrontendFieldType.maskField"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [mask]="field.regexMask"
        [maxlength]="field.maxLength > 0 ? field.maxLength : 255"
        [disabled]="!field.enabled"
        [style.text-transform]="field.upperCase ? 'uppercase' : null"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [style]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 'px'} : undefined"
        [(ngModel)]="field.value"
        (onComplete)="valueChanged()"
    >
    </p-inputMask>
    <ng-container *ngSwitchCase="_FrontendFieldType.comboBoxMulti">
            <p-multiSelect *ngIf="!field.dropdownAutoComplete"
            [class.ng-invalid]="field.getErrorMessage() != null"
            [class.ng-dirty]="field.getErrorMessage() != null"
            [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [options]="field.listItems"
            appendTo="body"
            [defaultLabel]="'Keine Auswahl'"
            [disabled]="!field.enabled"
            [style.text-transform]="field.upperCase ? 'uppercase' : null"
            [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
            [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
            [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
            [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
            [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
            [style]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 1 + 'px'} : undefined"
            [(ngModel)]="field.value"
            (onChange)="valueChanged()"
        >
        </p-multiSelect>
        <p-autoComplete *ngIf="field.dropdownAutoComplete"
            [class.ng-invalid]="field.getErrorMessage() != null"
            [class.ng-dirty]="field.getErrorMessage() != null"
            [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
            [suggestions]="autoCompleteSuggestions"
            [dropdown]=true
            field="label"
            dataKey="value"
            appendTo="body"
            [disabled]="!field.enabled"
            [multiple]=true
            [style.text-transform]="field.upperCase ? 'uppercase' : null"
            [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
            [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
            [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
            [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
            [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
            [style]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 1 + 'px'} : undefined"
            [(ngModel)]="field.value"
            (completeMethod)="autoComplete($event)"
            (onChange)="valueChanged()"
        >
            <ng-template let-item pTemplate="item">
                <span [class.word-wrap]="field.dropdownWordWrap" [innerHTML]="item.label | keepHtml">
                </span>
            </ng-template>
        </p-autoComplete>
    </ng-container>
    <ng-container *ngSwitchCase="_FrontendFieldType.comboBox">
        <p-dropdown *ngIf="!field.dropdownAutoComplete"
            [class.ng-invalid]="field.getErrorMessage() != null"
            [class.ng-dirty]="field.getErrorMessage() != null"
            [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
            [options]="field.listItems"
            optionLabel="label"
            optionValue="value"
            appendTo="body"
            [disabled]="!field.enabled"
            [editable]="field.dropdownEditable"
            [style.text-transform]="field.upperCase ? 'uppercase' : null"
            [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
            [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
            [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
            [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
            [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
            [style]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 1 + 'px'} : undefined"
            [(ngModel)]="field.value"
            (onChange)="valueChanged()"
        >
            <ng-template *ngIf="!field.dropdownEditable" let-item pTemplate="selectedItem">
                {{item?.labelPlain ?? item?.label}}
            </ng-template>
            <ng-template let-item pTemplate="item">
                <span [class.word-wrap]="field.dropdownWordWrap"  [innerHTML]="item.html ? (item.label | keepHtml) : item.label">
                </span>
            </ng-template>
        </p-dropdown>
        <p-autoComplete *ngIf="field.dropdownAutoComplete"
            [class.ng-invalid]="field.getErrorMessage() != null"
            [class.ng-dirty]="field.getErrorMessage() != null"
            [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
            [suggestions]="autoCompleteSuggestions"
            [dropdown]=true
            field="label"
            dataKey="value"
            appendTo="body"
            [disabled]="!field.enabled"
            [style.text-transform]="field.upperCase ? 'uppercase' : null"
            [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
            [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
            [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
            [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
            [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
            [style]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 1 + 'px'} : undefined"
            [(ngModel)]="field.value"
            (completeMethod)="autoComplete($event)"
            (onChange)="valueChanged()"
        >
            <ng-template let-item pTemplate="item">
                <span [class.word-wrap]="field.dropdownWordWrap" [innerHTML]="item.label | keepHtml">
                </span>
            </ng-template>
        </p-autoComplete>
    </ng-container>

  <!-- TODO [options]="field.listItems" entfernt bei Upgrade auf PrimeNG 16-->
  <p-cascadeSelect *ngSwitchCase="_FrontendFieldType.comboBoxCascade"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [disabled]="!field.enabled"
        [name]="field.id"
        optionLabel="label"
        optionGroupLabel="label"
        appendTo="body"
        [optionGroupChildren]="['children', 'children', 'children', 'children', 'children', 'children', 'children', 'children', 'children', 'children']"
        [style.text-transform]="field.upperCase ? 'uppercase' : null"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [style]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 1 + 'px'} : undefined"
        [(ngModel)]="field.value"
        (onChange)="valueChanged()"
    >
    </p-cascadeSelect>
    <p-listbox *ngSwitchCase="_FrontendFieldType.listBox"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [options]="field.listItems"
        [disabled]="!field.enabled"
        [checkbox]="field.listBoxCheckBoxes"
        [multiple]="field.listBoxCheckBoxes"
        [style.text-transform]="field.upperCase ? 'uppercase' : null"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [style]="{width: '100%'}"
        [(ngModel)]="field.value"
        (onChange)="valueChanged()"
    >
    </p-listbox>
    <textarea *ngSwitchCase="_FrontendFieldType.textArea" pInputTextarea
        spellcheck=false
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [maxLength]="field.maxLength > 0 ? field.maxLength : 65535"
        [disabled]="!field.enabled"
        [style.text-transform]="field.upperCase ? 'uppercase' : null"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [style.resize]="'none'"
        [style.font-family]="field.monospaced ? 'monospace' : undefined"
        [style]="{width: '100%', height: field.fixedHeight || '200px'}"
        [(ngModel)]="field.value"
        (change)="valueChanged()"
    >
    </textarea>
    <p-calendar *ngSwitchCase="_FrontendFieldType.datePicker" dateFormat="dd.mm.yy"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [disabled]="!field.enabled"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [inputStyle]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 'px'} : {'width': '100%'}"
        [style]="{'width': '100%'}"
        [(ngModel)]="field.value"
        appendTo="body"
        [firstDayOfWeek]=1
        [showTime]="field.datePickerWithTime"
        (onInput)="valueChanged()"
        (onClose)="valueChanged()"
    >
    </p-calendar>
    <p-checkbox *ngSwitchCase="_FrontendFieldType.checkBox"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [binary]="true"
        [(ngModel)]="field.value"
        [label]="field.hasLabel ? '' : field.caption"
        [disabled]="!field.enabled"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width - 50 : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        (onInput)="valueChanged()"
        (onChange)="valueChanged()"
    >
    </p-checkbox>
    <p-radioButton *ngSwitchCase="_FrontendFieldType.radioButton"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [(ngModel)]="field.value"
        [name]="field.radioButtonGroup"
        [value]="field.radioButtonValue"
        [label]="field.hasLabel ? '' : field.caption"
        [disabled]="!field.enabled"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width - 50 : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        (onClick)="valueChanged()"
    >
    </p-radioButton>
    <ng-container *ngSwitchCase="_FrontendFieldType.color">
        <sm-color-editor *ngIf="field.predefinedColors == null"
            [class.ng-invalid]="field.getErrorMessage() != null"
            [class.ng-dirty]="field.getErrorMessage() != null"
            [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
            [(value)]="field.value"
            [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
            [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
            [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
            [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width - 50 : undefined"
            [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
            (valueChange)="valueChanged()"
        >
        </sm-color-editor>
        <ngx-colors ngx-colors-trigger [ngModel]="_Utils.toColorHexString(field.value)" [palette]="getColorPalette()" (input)="colorChanged($event)"></ngx-colors>
    </ng-container>
    <p-button *ngSwitchCase="_FrontendFieldType.button"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [label]="field.caption"
        [disabled]="!field.enabled"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        (onClick)="buttonClick($event)"
    >
    </p-button>
    <p-chips *ngSwitchCase="_FrontendFieldType.stringList"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [(ngModel)]="field.value"
        [disabled]="!field.enabled"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        (onAdd)="valueChanged()"
        (onRemove)="valueChanged()"
    >
    </p-chips>
    <app-sm-file-upload *ngSwitchCase="_FrontendFieldType.fileUpload"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [(value)]="field.value"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        (onValueChanged)="valueChanged()"
    >
    </app-sm-file-upload>
    <!--  [tableStyle]="field.isFixedPosition() ? {'width': field.fixedControlBounds.width + 'px', 'height': field.fixedControlBounds.height + 'px'} : undefined"
        -->
    <p-table *ngSwitchCase="_FrontendFieldType.table"
        [class.ng-invalid]="field.getErrorMessage() != null"
        [class.ng-dirty]="field.getErrorMessage() != null"
        [class.compact]=true
        [pTooltip]="field.tooltipOnControl ? field.tooltip : null"
        [styleClass]="'p-datatable-gridlines'"
        [value]="field.value"
        selectionMode="single"
        [style.position]="field.isFixedPosition() ? 'absolute' : 'relative'"
        [style.left.px]="field.isFixedPosition() ? field.fixedControlBounds.x : undefined"
        [style.top.px]="field.isFixedPosition() ? field.fixedControlBounds.y : undefined"
        [style.width.px]="field.isFixedPosition() ? field.fixedControlBounds.width : undefined"
        [style.height.px]="field.isFixedPosition() ? field.fixedControlBounds.height : undefined"
        [style.background]="'lightgray'"
        [scrollable]="true"
        [resizableColumns]="true"
        [scrollHeight]="field.fixedControlBounds != null ? field.fixedControlBounds.height - 40 + 'px' : undefined"
        (onEditInit)="tableEditInit()"
        (onEditComplete)="tableEditComplete()"
    >
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let column of field.tableColumns" class="align-left" [style]="{width: column.width}">{{column.caption}}</th>
                <th class="align-left" [style]="{width: '40px'}"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
            <tr [pSelectableRow]="item">
                <td *ngFor="let column of field.tableColumns; index as columnIndex" pEditableColumn class="tc">
                    <sm-table-cell [class.no-wrap]="column.noWrap" [(value)]="item[column.id]" [type]="column.cellType" [editor]="column.editor"
                        [editable]="field.isTableCellEnabled(0, columnIndex)" [showNull]="column.showNull"></sm-table-cell>
                </td>
                <td [style]="{width: '40px'}">
                    <button pButton type="button" class="action-button ui-button-secondary p-button-text" icon="fas fa-trash" (click)="deleteTableRow(index)"></button>
                </td>
   <!--             <td [style]="{width: '40px'}">
                    <button pButton type="button" class="action-button ui-button-secondary p-button-text" icon="fas fa-pencil" (click)="editTableRow(index)"></button>
                </td>
            -->     </tr>
        </ng-template>
         <ng-template pTemplate="footer">
            <tr>
               <td [attr.colspan]="field.tableColumns.length + 1">
                    <button pButton type="button" class="action-button ui-button-secondary p-button-text" icon="fas fa-plus" (click)="addTableRow()"></button>
               </td>
            </tr>
          </ng-template>
    </p-table>
</ng-container>
<ng-template [insertionPoint]></ng-template>

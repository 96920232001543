import { Type } from "class-transformer";
import { EnaioFieldChange } from "./EnaioFieldChange";
import { UpsertType } from "./UpsertType";

export class EnaioUpsertResult {
    documentId = 0;
    upsertType: UpsertType;
    noChanges = false;
    objectTypeInternalName: string;
    @Type(() => EnaioFieldChange)
    changedFields: EnaioFieldChange[];
    addedLocations: number[];
    removedLocations: number[];
}

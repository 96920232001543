import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnaioImportOptions } from 'src/modules/enaio/shared/EnaioImportOptions';
import { EnaioObjectDef } from 'src/modules/enaio/shared/EnaioObjectDef';
import { EnaioObjectId } from 'src/modules/enaio/shared/EnaioObjectId';
import { EnaioObjectType } from 'src/modules/enaio/shared/EnaioObjectType';
import { EnaioObjectTypeImportParams } from 'src/modules/enaio/shared/EnaioObjectTypeImportParams';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';

@Component({
  selector: 'app-enaio-import-options',
  templateUrl: './enaio-import-options.component.html',
  styleUrl: './enaio-import-options.component.scss'
})
export class EnaioImportOptionsComponent {
    @Input()
    def: EnaioObjectDef;

    _options: EnaioImportOptions;

    form: FrontendFormDefinition;
    forms: FrontendFormDefinition[] = [];
    tables: TableData[] = [];

    @Output()
    onAction = new EventEmitter<boolean>();


    @Input()
    set options(value: EnaioImportOptions) {
        this._options = value;

        this.form = value == null ? null : new FrontendFormDefinition([
            new FrontendFieldDefinition("singleStep", "Single Step", FrontendFieldType.checkBox, { value: value.singleStep})
        ]);

        this.forms = value == null ? [] : value.archives.map(archive => new FrontendFormDefinition([
            new FrontendFieldDefinition("import", "Importieren", FrontendFieldType.checkBox, { value: archive.import }),
            new FrontendFieldDefinition("includeChildren", "Unterlemente importieren", FrontendFieldType.checkBox, { value: archive.includeChildren }),
            new FrontendFieldDefinition("documents", "Dokumente importieren", FrontendFieldType.checkBox, { value: archive.documents })
        ]));

        this.tables = value == null ? [] : value.archives.map(archive => {
            let cab = this.def.cabinets.find(cab2 => cab2.internalName == archive.archive.internalName);
            let otListItems = cab.objectTypes.map(ot => new FrontendFieldListItem(ot, ot.name));
            return new TableData([
                new TableColumn("import", "Importieren", TableCellType.yesNo, { editable: true }),
                new TableColumn("objectType", "Quell-Objekttyp", TableCellType.text),
                new TableColumn("destObjectType", "Ziel-Objekttyp", TableCellType.dropdown, { editable: true, editor:
                    new FrontendFieldDefinition("field", "Feld", FrontendFieldType.comboBox, { dropdownEditable: false, listItems: otListItems}) }),
                new TableColumn("fixedParentId", "Fester Standort (ID)", TableCellType.number, { editable: true }),
                new TableColumn("fieldNameSourceObjectId", "Quell-Objekt-ID in dieses Feld einfügen (interner Name)", TableCellType.text, { editable: true }),
                new TableColumn("keyFieldsAll", "Alle Felder sind Schlüsselfeld", TableCellType.yesNo, { editable: true })
            ], archive.objectTypes.map(ot => new TableRow(ot, {
                objectType: ot.objectType.name,
                destObjectType: ot.destObjectType == null ? null : otListItems.find(ot2 => ot2.value.name == ot.destObjectType.name),
                fixedParentId: ot.fixedParentId,
                fieldNameSourceObjectId: ot.fieldNameSourceObjectId,
                import: ot.import,
                keyFieldsAll: ot.keyFieldsAll
            })));
        });
    }

    import(): void {
        this.form.get(this._options);
        for (let i = 0; i < this._options.archives.length; i++) {
            this.forms[i].get(this._options.archives[i]);
            for (let row of this.tables[i].rows) {
                let ot = row.raw as EnaioObjectTypeImportParams;
                ot.import = row.values.import;
                let otd = (row.values.destObjectType as FrontendFieldListItem).value as EnaioObjectType;
                ot.destObjectType = new EnaioObjectId(otd.name, otd.internalName);
                ot.fixedParentId = row.values.fixedParentId;
                ot.fieldNameSourceObjectId = row.values.fieldNameSourceObjectId;
                ot.keyFieldsAll = row.values.keyFieldsAll;
            }
        }
        this.onAction.emit(true);
    }

    cancel(): void {
        this.onAction.emit(false);
    }
}

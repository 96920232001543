import { GenericDataType } from 'src/modules/enaio/shared/GenericDataType';

export class GenericFieldDefinition {

    name = "";
    type = GenericDataType.string;

    constructor(name: string, type = GenericDataType.string) {
        this.name = name;
        this.type = type;
    }
}

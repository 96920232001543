import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { CipherMateConfig } from "./CipherMateConfig";
import { EnaioAutoStar } from "./EnaioAutoStar";
import { EnaioCall } from "./EnaioCall";
import { EnaioChildObjectSelectionType } from "./EnaioChildObjectSelectionType";
import { EnaioCondition } from "./EnaioCondition";
import { EnaioConvertEmptyValuesMode } from "./EnaioConvertEmptyValuesMode";
import { EnaioDocument } from "./EnaioDocument";
import { EnaioDocumentConversion } from "./EnaioDocumentConversion";
import { EnaioIndexDataSelectParams } from "./EnaioIndexDataSelectParams";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioCallSelect extends EnaioCall <EnaioDocument[]> {
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    globalSearch = false;
    selectObjectId = 0;
    selectMultipleObjectIds: number[];
    selectByDigest: string;
    selectByParentId = 0;
    selectByParentIdIsFolder: boolean = null;
    selectByParentIdAllChildren = false;
    allFields: boolean = null;
    getFileProperties = false;
    getBaseParams = false;
    getParentInfo = false;
    maxHits = -1;
    offset: number = null;
    @Type(() => EnaioObjectId)
    fields: EnaioObjectId[];
    @Type(() => EnaioCondition)
    conditions: EnaioCondition[];
    @Type(() => EnaioObjectId)
    parentObject: EnaioObjectId;
    @Type(() => EnaioObjectId)
    parentFields: EnaioObjectId[];
    createdSince: Date;
    modifiedSince: Date;
    modifiedBefore: Date;
    advancedModifiedSince = false;
    advancedModifiedBefore = false;
    getDocStats = false;
    getDocuments = false;
    getDigest = false;
    getVariants = false;
    getRemarks = false;
    followRemarkObjects = false;
    getObjectHistory = false;
    includeTrash = false;
    @Type(() => EnaioIndexDataSelectParams)
    getIndexDataHistory: EnaioIndexDataSelectParams;
    convert: EnaioDocumentConversion;
    addAnnotationsToDocument = false;
    skipObjectsWithoutDocument = false;
    registerContext = true;
    convertEmptyValues: EnaioConvertEmptyValuesMode;
    autoStar: EnaioAutoStar;
    childObjectSelection: EnaioChildObjectSelectionType;
    @Type(() => CipherMateConfig)
    cipherMateConfig: CipherMateConfig;
    cipherMateDecrypt = false;
    cipherMateDecryptOnlyIfExtensionMatching = false;
    pageSize = -1;
    countOnly = false;
    lowLevel = true;
    lowLevelFallback = true;
    lowLevelDebug = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioDocument, 1);
    }

}

import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegisterSettings } from '../../shared/class-register-settings.entity';
import { EnaioCertificatesTools } from '../../shared/enaio-certificates-tools';
import { Holiday } from '../../shared/holiday.entity';
import { SchoolType } from '../../shared/school-type.enum';
import { SpecialDomain } from '../../shared/special-domain.entity';
import { TeacherAdditional } from '../../shared/teacher-additional.entity';
import { Teacher } from '../../shared/teacher.model';

@Component({
  selector: 'app-class-register-admin',
  templateUrl: './class-register-admin.component.html',
  styleUrls: ['./class-register-admin.component.scss']
})
export class ClassRegisterAdminComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    year: number;
    isHalfYear: boolean;
    settings: ClassRegisterSettings;
    orgSettings: ClassRegisterSettings;
    teachers: Teacher[];

    form: FrontendFormDefinition;
    domains: string[];
    allowedDomainsTable: TableData;
    specialDomainsTable: TableData;
    teacherTable: TableData;
    holidayTable: TableData;
    bWeeksTable: TableData;

    constructor() {
        super();
        this.neededParams = { year: "number", isHalfYear: "bool" };
    }

    async initParams(): Promise<boolean> {
        [this.settings, this.teachers] = await Promise.all([
            this.service.restGetClassRegisterSettings(this.year, this.isHalfYear),
            this.service.restGetTeachers()
        ]);

        this.orgSettings = Utils.cloneDeep(this.settings);

        this.domains = await this.service.restGetDomains(null, null, true);

        this.app.updateNavigation("Einstellungen", [
            this.service.getHomeClassRegister(),
            { label: "Einstellungen", routerLink: ["/enaio-certificates", "class-registers", "admin", this.year, this.isHalfYear] }
        ]);

        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("firstDayOfSchoolYear", "Schuljahresbeginn", FrontendFieldType.datePicker, { value: this.settings.firstDayOfSchoolYear}),
            new FrontendFieldDefinition("lastDayOfSchoolYear", "Schuljahresende", FrontendFieldType.datePicker, { value: this.settings.lastDayOfSchoolYear}),
            new FrontendFieldDefinition("managerElementary", "Bereichsleiter " + EnaioCertificatesTools.getSchoolTypeNames()[SchoolType.elementary], FrontendFieldType.comboBox, {
                value: this.settings.managerElementary,
                dropdownEditable: false,
                listItems: [new FrontendFieldListItem("", ""), ...this.teachers.filter(t => t.isFor(SchoolType.elementary, this.settings)).map(t => new FrontendFieldListItem(t.userName, t.getDisplayName()))]
            }),
            new FrontendFieldDefinition("managerSecondary", "Bereichsleiter " + EnaioCertificatesTools.getSchoolTypeNames()[SchoolType.secondary], FrontendFieldType.comboBox, {
                value: this.settings.managerSecondary,
                dropdownEditable: false,
                listItems: [new FrontendFieldListItem("", ""), ...this.teachers.filter(t => t.isFor(SchoolType.secondary, this.settings)).map(t => new FrontendFieldListItem(t.userName, t.getDisplayName()))]
            }),
            new FrontendFieldDefinition("managerSpecial", "Bereichsleiter " + EnaioCertificatesTools.getSchoolTypeNames()[SchoolType.special], FrontendFieldType.comboBox, {
                value: this.settings.managerSpecial,
                dropdownEditable: false,
                listItems: [new FrontendFieldListItem("", ""), ...this.teachers.filter(t => t.isFor(SchoolType.special, this.settings)).map(t => new FrontendFieldListItem(t.userName, t.getDisplayName()))]
            }),
            new FrontendFieldDefinition("updatePupilCourses", "enaio Arbeitsgemeinschaften aktualisieren", FrontendFieldType.button, { onClick: this.updatePupilCourses.bind(this), hasLabel: false})
        ]);

        this.allowedDomainsTable = new TableData([
            new TableColumn("domain", "Fach", TableCellType.text),
            new TableColumn("allowed", "Erlaubt", TableCellType.yesNo, { editable: true })
        ], this.domains.map(domain => new TableRow(domain, {
            domain,
            allowed: this.settings.allowedDomains.includes(domain)
        })));

        this.specialDomainsTable = new TableData([
            new TableColumn("title", "Bezeichnung", TableCellType.text, { editable: true }),
            new TableColumn("isPlaceholder", "Ist Platzhalter", TableCellType.yesNo, { editable: true })
        ], this.settings.specialDomains.map(sd => new TableRow(sd)), [new TableSortColumn("title")], async () => Promise.resolve(new TableRow(new SpecialDomain())));

        this.teacherTable = new TableData([
            new TableColumn("name", "Name"),
            new TableColumn("forElementary", "Grundschule", TableCellType.yesNo, { editable: true }),
            new TableColumn("forSecondary", "Gesamtschule", TableCellType.yesNo, { editable: true }),
            new TableColumn("forSpecial", "Förderschule", TableCellType.yesNo, { editable: true })
        ], this.teachers.map(t => new TableRow(t, {
            name: t.fullName,
            forElementary: Utils.arrayFindAndConvert(this.settings.teacher, t2 => t2.userName == t.userName, t2 => t2.forElementary),
            forSecondary: Utils.arrayFindAndConvert(this.settings.teacher, t2 => t2.userName == t.userName, t2 => t2.forSecondary),
            forSpecial: Utils.arrayFindAndConvert(this.settings.teacher, t2 => t2.userName == t.userName, t2 => t2.forSpecial)
        })), [new TableSortColumn("name")]);

        this.holidayTable = new TableData([
            new TableColumn("from", "Von", TableCellType.date, { editable: true }),
            new TableColumn("to", "Bis", TableCellType.date, { editable: true }),
            new TableColumn("title", "Bezeichnung", TableCellType.text, { editable: true })
        ], this.settings.holidays.map(h => new TableRow(h)), [new TableSortColumn("from")], async () => Promise.resolve(new TableRow(Utils.fromPlain(Holiday, {
            from: new Date(),
            to: new Date(),
            title: ""
        }))));

        this.bWeeksTable = new TableData([
            new TableColumn("calendarWeek", "KW", TableCellType.text),
            new TableColumn("date", "Datum", TableCellType.date),
            new TableColumn("bWeek", "B-Woche", TableCellType.yesNo, { editable: true })
        ], Utils.arrayMapWithIndex(this.settings.getWeekDates(), (date, index) => new TableRow(date, {
            calendarWeek: Utils.dateCalendarWeek(date),
            date,
            bWeek: Utils.arrayGetSafe(this.settings.bWeeks, index, false)
        })));
        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? "" : null;
    }

    async updatePupilCourses(): Promise<void> {
        await RestEndpoint.main().post().query({ year: this.year, isHalfYear: this.isHalfYear }).run("api/cer/pupil/updatePupilCourses").getText();
    }

    isChanged(): boolean {
        let gui = this.fromGui();
        this.orgSettings.specialDomains.sort((a, b) => Utils.cmp(a.id, b.id));
        gui.specialDomains.sort((a, b) => Utils.cmp(a.id, b.id));
        return this.form != null && this.settings != null && !Utils.equalsDeep(gui, this.orgSettings);
    }

    private fromGui(): ClassRegisterSettings {
        let result = Utils.cloneDeep(this.settings);
        this.form.get(this.settings);
        result.dutyNames = result.dutyNames.filter(d => d != "");
        result.instructionTexts = result.instructionTexts.filter(d => d != "");
        result.specialDomains = this.specialDomainsTable.rows.map(sd => sd.raw as SpecialDomain);
        result.holidays = this.holidayTable.rows.map(h => h.raw as Holiday);
        result.bWeeks = this.bWeeksTable.rows.map(r => r.values.bWeek as boolean);
        result.allowedDomains = Utils.arraySort(this.allowedDomainsTable.rows.filter(r => r.values.allowed as boolean).map(r => r.values.domain as string));

        for (let t of this.teacherTable.rows) {
            let teacher = result.teacher.find(t2 => t2.userName == t.raw.userName);
            if (teacher == null && (t.values.forElementary || t.values.forSecondary || t.values.forSpecial)) {
                teacher = Utils.fromPlain(TeacherAdditional, {
                    userName: t.raw.userName
                });
                result.teacher.push(teacher);
            }
            if (teacher != null) {
                teacher.forElementary = t.values.forElementary ?? false;
                teacher.forSecondary = t.values.forSecondary ?? false;
                teacher.forSpecial = t.values.forSpecial ?? false;
            }
        }
        return result;
    }

    async save(): Promise<void> {
        await this.app.saveDataHandler(async () => RestEndpoint.main().body(this.fromGui()).post().run("api/cer/classregister/settings").getText(), this, true, true);
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnaioExportOptions } from 'src/modules/enaio/shared/EnaioExportOptions';
import { EnaioObjectDef } from 'src/modules/enaio/shared/EnaioObjectDef';
import { EnaioObjectTypeExportParams } from 'src/modules/enaio/shared/EnaioObjectTypeExportParams';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';

@Component({
  selector: 'app-enaio-export-options',
  templateUrl: './enaio-export-options.component.html',
  styleUrl: './enaio-export-options.component.scss'
})
export class EnaioExportOptionsComponent {
    @Input()
    def: EnaioObjectDef;

    _options: EnaioExportOptions;

    form: FrontendFormDefinition;
    forms: FrontendFormDefinition[] = [];
    tables: TableData[] = [];

    @Output()
    onAction = new EventEmitter<boolean>();


    @Input()
    set options(value: EnaioExportOptions) {
        this._options = value;

        this.form = value == null ? null : new FrontendFormDefinition([
        ]);

        this.tables = value == null ? [] : value.archives.map(archive => new TableData([
                new TableColumn("export", "Exportieren", TableCellType.yesNo, { editable: true }),
                new TableColumn("objectType", "Objekttyp", TableCellType.text),
                new TableColumn("includeChildren", "Mit Unterlementen", TableCellType.yesNo, { editable: true }),
                new TableColumn("documents", "Mit Dokumenten", TableCellType.yesNo, { editable: true }),
                new TableColumn("overwriteDocumentFiles", "Bestehende Dokumentdateien im Exportverzeichnis überschreiben", TableCellType.yesNo, { editable: true }),
                new TableColumn("exportConditions", "Export Bedingungen", TableCellType.text, { editable: true, showNull: false })
            ], archive.objectTypes.map(ot => new TableRow(ot, {
                export: ot.export,
                objectType: ot.objectType.objectType.name,
                includeChildren: ot.includeChildren,
                documents: ot.documents,
                doNotOverwriteDocumentFiles: ot.overwriteDocumentFiles,
                exportConditions: ot.exportConditions
            }))));
    }

    import(): void {
        this.form.get(this._options);
        for (let i = 0; i < this._options.archives.length; i++) {
            for (let row of this.tables[i].rows) {
                let ot = row.raw as EnaioObjectTypeExportParams;
                ot.export = row.values.export;
                ot.includeChildren = row.values.includeChildren;
                ot.documents = row.values.documents;
                ot.overwriteDocumentFiles = row.values.overwriteDocumentFiles;
                ot.exportConditions = row.values.exportConditions;
            }
        }
        this.onAction.emit(true);
    }

    cancel(): void {
        this.onAction.emit(false);
    }
}

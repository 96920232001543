import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { CipherMateConfig } from "./CipherMateConfig";
import { EnaioAssignment } from "./EnaioAssignment";
import { EnaioCall } from "./EnaioCall";
import { EnaioCondition } from "./EnaioCondition";
import { EnaioCpmQueueConditions } from "./EnaioCpmQueueConditions";
import { EnaioLocation } from "./EnaioLocation";
import { EnaioObjectId } from "./EnaioObjectId";
import { EnaioRemarkObject } from "./EnaioRemarkObject";
import { EnaioTableAssignment } from "./EnaioTableAssignment";
import { EnaioUpsertResult } from "./EnaioUpsertResult";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioCallInsert extends EnaioCall <EnaioUpsertResult> {
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    @Type(() => EnaioLocation)
    location: EnaioLocation;
    @Type(() => EnaioAssignment)
    fields: EnaioAssignment[];
    @Type(() => EnaioTableAssignment)
    tableFields: EnaioTableAssignment[];
    documentFileName: string;
    documentBase64: string;
    documentUseExtension: string;
    linkToId = 0;
    linkToIdOsFile = 0;
    additionalLocationIds: number[];
    deleteAdditionalLocationIds: number[];
    intoWfTray = false;
    typeless = false;
    typelessMainType = 0;
    @Type(() => EnaioCondition)
    searchConditions: EnaioCondition[];
    @Type(() => EnaioRemarkObject)
    remarkObjects: EnaioRemarkObject[];
    @Type(() => CipherMateConfig)
    cipherMateEncrypt: CipherMateConfig;
    setArchivable = false;
    autoTruncateColumns = false;
    ignoreAll = false;
    ignoreInvalidCatalogueValues = false;
    ignoreEmptyMandatoryFields = false;
    ignoreMissingUserRights = false;
    ignoreKeyFields = false;
    ignoreReadOnlyFields = false;
    mailImport = false;
    rawOptions: OrdinaryObject<string>;
    @Type(() => EnaioCpmQueueConditions)
    cpmQueueConditions: EnaioCpmQueueConditions;
    cpmQueueImmediatelyRemove = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioUpsertResult);
    }

}

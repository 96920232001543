import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassHoliday } from '../../shared/class-holiday.entity';
import { ClassRegister } from '../../shared/class-register.entity';

@Component({
  selector: 'app-class-holidays',
  templateUrl: './class-holidays.component.html',
  styleUrls: ['./class-holidays.component.scss']
})
export class ClassHolidaysComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;
    classRegister: ClassRegister;
    orgData: ClassHoliday[];
    table: TableData;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, ["holidays"], null, true);

        this.orgData = Utils.cloneDeep(this.classRegister.holidays);

        this.table = new TableData([
            new TableColumn("from", "Von", TableCellType.date, { editable: true }),
            new TableColumn("to", "Bis", TableCellType.date, { editable: true }),
            new TableColumn("title", "Bezeichnung", TableCellType.text, { editable: true })
        ], this.classRegister.holidays.map(h => new TableRow(h)), [new TableSortColumn("from")], async () => Promise.resolve(new TableRow(Utils.fromPlain(ClassHoliday, {
            from: new Date(),
            to: new Date(),
            title: ""
        }))));

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Klassenfahrten", routerLink: ["/enaio-certificates", "class-registers", "holidays", this.id] }
        ]);
        return true;
    }

    override getCanDeactivateMessage(): string {
        return this.isChanged() ? "" : null;
    }

    isChanged(): boolean {
        return !Utils.equalsDeep(this.fromGui(), this.orgData);
    }

    fromGui(): ClassHoliday[] {
        return this.table.rows.map(h => h.raw as ClassHoliday);
    }

    async save(): Promise<void> {
        await this.app.saveDataHandler(async () => RestEndpoint.main().query({ id: this.id }).body(this.fromGui()).post().run("api/cer/classregister/holidays").getText(), this, true, true);
    }
}

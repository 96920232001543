import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningSettings } from '../../shared/learning-settings.entity';

@Component({
    selector: 'app-edit-learning-settings',
    templateUrl: './edit-learning-settings.component.html',
    styleUrls: ['./edit-learning-settings.component.scss']
})
export class EditLearningSettingsComponent extends ComponentView {

    service = inject(EnaioLearnService);

    id: number;
    item: LearningSettings;
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.item = await RestEndpoint.main().find().run("api/lrn/learningsettings").get(LearningSettings);

        this.form = this.app.tForm("lea.generalSettings", new FrontendFormDefinition([
            new FrontendFieldDefinition("activationMailSubject", null, FrontendFieldType.text, { mandatory: true }),
            new FrontendFieldDefinition("activationMailText", null, FrontendFieldType.textArea, { mandatory: true }),
            new FrontendFieldDefinition("needsPrivacyPolicy", null, FrontendFieldType.checkBox),
            new FrontendFieldDefinition("privacyPolicyText", null, FrontendFieldType.textArea),
            new FrontendFieldDefinition("adminContactInfo", null, FrontendFieldType.textArea, { mandatory: true }),
            new FrontendFieldDefinition("allowedFormatting", null, FrontendFieldType.text)
        ]));

        this.form.fill(this.item);
        this.service.updateNavigation(null, [{ label: this.app.t("lea.admin.title"), routerLink: ["/enaio-learn", "admin"]}, { label: this.app.t("lea.generalSettings.typeName"), routerLink: ["/enaio-learn", "admin", "general-settings"] }]);
        return Promise.resolve(true);
    }

    save(): void {
        if (!this.app.validateForm(this.form)) {
            return;
        }

        this.form.get(this.item);
        RestEndpoint.main().upsert(this.item).run("api/lrn/learningsettings");
        this.app.showToast("success", this.app.t("general.updatedTitle"), this.app.t("general.updatedText"));
        this.app.navigateBack();
    }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { AppTemplateModule } from '../app-template/app-template.module';
import { AppMenuItem } from '../app-template/models/app-menu-item.model';
import { AppTab } from '../app-template/models/app-tab.model';
import { EnaioWorkflowsRoutingModule } from '../enaio-workflows/enaio-workflows-routing.module';
import { PrimeModule } from '../prime/prime.module';
import { FeatureModule } from '../sm-base/models/feature.module';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { Utils } from '../utils/shared/utils';
import { EnaioWorkItemComponent } from './components/enaio-work-item/enaio-work-item.component';
import { EnaioWorkflowsMainComponent } from './components/enaio-workflows-main/enaio-workflows-main.component';
import { EnaioDocumentsModule } from '../enaio-documents/enaio-documents.module';
import { PersonellFileComponent } from './components/personell-file/personell-file.component';

@NgModule({
    declarations: [
        EnaioWorkflowsMainComponent,
        EnaioWorkItemComponent,
        PersonellFileComponent
    ],
    imports: [
        AppTemplateModule,
        CommonModule,
        PrimeModule,
        SmBaseModule,
        EnaioDocumentsModule,
        EnaioWorkflowsRoutingModule
    ]
})
export class EnaioWorkflowsModule implements FeatureModule {

    constructor(private featureModuleService: FeatureModuleService) {
        featureModuleService.register(this);
    }

    registerSidebar(model: AppTab[]): void {
        model.push(new AppTab("workflows", "Workflows", "", 100, [
            Utils.fromPlain(AppMenuItem, { sort: 170, label: "Workflows", icon: "", routerLink: ["/enaio-workflows", "home"] })
        ]));

        EnaioDocumentsModule.mainBreadcrumb =  { label: "Personalakte", routerLink: ["/enaio-documents", "home"] };
    }

    async updateSidebarVisibility(_: AppTab[]): Promise<void> {
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("enaio-workflows", () => EnaioWorkflowsRoutingModule);
    }
}

import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningContentCategory } from '../../shared/learning-content-category.entity';

@Component({
    selector: 'app-edit-learning-content-category',
    templateUrl: './edit-learning-content-category.component.html',
    styleUrls: ['./edit-learning-content-category.component.scss']
})
export class EditLearningContentCategoryComponent extends ComponentView {

    service = inject(EnaioLearnService);

    id: number;
    item: LearningContentCategory;
    form: FrontendFormDefinition;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.form = this.app.tForm("lea.category", new FrontendFormDefinition([
            new FrontendFieldDefinition("name", null, FrontendFieldType.text, {mandatory: true}),
            new FrontendFieldDefinition("color", null, FrontendFieldType.color, { predefinedColors: Utils.getMaterialColors()})
        ]));
        this.item = await this.service.adminInitEdit(LearningContentCategory, this.id, this.form, "lea.category", "learning-content-categories", "learning-content-category", "api/lrn/learningcontentcategory");
        return true;
    }

    save(): void {
        this.service.adminUpdate(this.form, LearningContentCategory, this.item, "api/lrn/learningcontentcategory");
    }

}

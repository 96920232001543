import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { ResetPasswordDto } from 'src/modules/utils/shared/reset-password-dto.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { RestEndpoint } from '../../models/rest-endpoint.model';
import { AuthRules } from '../../shared/auth-rules.model';
import { BackendResponse } from '../../shared/backend-response.model';
import { FrontendFieldDefinition } from '../../models/frontend-field-definition.model';
import { FrontendFieldType } from '../../models/frontend-field-type.enum';
import { FrontendFormDefinition } from '../../models/frontend-form-definition.model';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    form1: FrontendFormDefinition;
    form2: FrontendFormDefinition;
    authRules: AuthRules;
    recoveryQuestions: string[];

    userName = null;
    running = false;
    done = false;

    constructor(public app: MainAppService, private message: MessageService) {
    }

    async ngOnInit(): Promise<void> {
        this.authRules = await RestEndpoint.main().find().run("api/app/auth/authrules").get(AuthRules);
        this.form1 = new FrontendFormDefinition([
            new FrontendFieldDefinition("userName", "Benutzername", FrontendFieldType.text, { mandatory: true})
        ]);
    }

    async send(): Promise<void> {
        if (this.userName == null) {
            let errors = this.form1.validate();
            if (errors != null) {
                this.message.add({ severity: "error", summary: "Ungültige Angaben", life: 10000, detail: Utils.toString(Utils.objectGetValues(errors)[0])});
                return;
            }

            try {
                this.recoveryQuestions = await RestEndpoint.main().find({"userName": this.form1.getValue("userName")}).run("api/app/auth/recoveryquestions").listStrings();
            }
            catch (ex) {
                this.message.add({ severity: "error", summary: "Der Benutzername ist nicht bekannt"});
                return;
            }
            let userName = this.form1.getValue("userName");

            if (this.authRules.canRevokePasswordSelf) {
                this.userName = userName;
                this.form2 = new FrontendFormDefinition([
                    new FrontendFieldDefinition("userName", "Benutzername", FrontendFieldType.text, { mandatory: true, enabled: false, value: this.userName}),
                    new FrontendFieldDefinition("password", "Neues Passwort", FrontendFieldType.password, { mandatory: true, passwordFeedback: false}),
                    new FrontendFieldDefinition("passwordRepeat", "Neues Passwort wiederholen", FrontendFieldType.password, { mandatory: true, passwordFeedback: false}),
                    ...Utils.arrayMapWithIndex(this.recoveryQuestions, (aq, index) => new FrontendFieldDefinition("authQuestion" + index, aq, FrontendFieldType.text, { mandatory: true }))
                ]);
            }
            else if (await this.app.messageDialog.yesNo(!Utils.isNoe(this.authRules.revokePasswordMessage) ? this.authRules.revokePasswordMessage : "Soll das Passwort zurückgesetzt werden?", "Bestätigung")) {
                await RestEndpoint.main().post().body(Utils.fromPlain(ResetPasswordDto, {
                    userName
                })).run("api/app/auth/resetpassword").get(BackendResponse);
                this.message.add({ severity: "success", summary: !Utils.isNoe(this.authRules.revokePasswordAfterMessage) ? this.authRules.revokePasswordAfterMessage : "Das Passwort wurde erfolgreich zurückgesetzt.", life: 10000});
                Utils.setTimerOnce(2000, async () => {
                    await this.app.navigateTo(["/base", "login"]);
                });
            }


            this.running = false;
        }
        else {
            let errors = this.form2.validate();
            if (errors != null) {
                this.message.add({ severity: "error", summary: "Ungültige Angaben", life: 10000, detail: Utils.toString(Utils.objectGetValues(errors)[0])});
                return;
            }

            let pw1 = this.form2.getValue("password") as string;
            let pw2 = this.form2.getValue("passwordRepeat") as string;

            if (pw1 != pw2) {
                this.message.add({ severity: "error", summary: "Ungültige Angaben", life: 10000, detail: "Die beiden neuen Passwörter stimmen nicht überein"});
                return;
            }

            if (!this.authRules.passwordRules.isSafe(pw1)) {
                this.message.add({ severity: "error", summary: "Ungültige Angaben", life: 10000, detail: "Das neue Passwort stimmt nicht mit den Vorgaben überein. Es muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten"});
                return;
            }

            this.running = true;
            try {
                await RestEndpoint.main().post().body(Utils.fromPlain(ResetPasswordDto, {
                    userName: this.userName,
                    password: pw1,
                    recoveryAnswers: Utils.getRange(0, this.recoveryQuestions.length - 1).map(index => this.form2.getField("authQuestion" + index).getValue())
                })).run("api/app/auth/resetpassword").get(BackendResponse);
                this.message.add({ severity: "success", summary: "Das Passwort wurde erfolgreich zurückgesetzt.", life: 10000});
                Utils.setTimerOnce(2000, async () => {
                    await this.app.navigateTo(["/base", "login"]);
                });
            }
            catch (ex) {
                // let error = Utils.fromPlainUnsafe(BackendResponse, Utils.fromJson(ex.error));
                this.message.add({ severity: "error", summary: "Mindestens eine der Fragen wurde nicht richtig beantwortet", life: 10000});
            }
            this.running = false;
        }
    }

    cancel(): void {

    }

}

import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { GlobalSettings } from '../../shared/global-settings.entity';

@Component({
    selector: 'app-global-settings',
    templateUrl: './global-settings.component.html',
    styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    globalSettings: GlobalSettings = null;
    form: FrontendFormDefinition;

    async initParams(): Promise<boolean> {
        this.globalSettings = await RestEndpoint.main().find().run("api/cer/globalsettings").get(GlobalSettings);

        this.form = new FrontendFormDefinition([
            new FrontendFieldDefinition("editingAllowed", "Bearbeiten von Zeugnissen und Noten erlauben", FrontendFieldType.checkBox),
            new FrontendFieldDefinition("dateOfHalfYearCertificate", "Datum des Halbjahreszeugnisses", FrontendFieldType.datePicker),
            new FrontendFieldDefinition("dateOfFullCertificate", "Datum des Endjahreszeugnisses", FrontendFieldType.datePicker)
        ]);
        this.form.fill(this.globalSettings);

        this.app.updateNavigation("Administration", [
            this.service.getHomeCertificates(),
            { label: "Administration", routerLink: ["/enaio-certificates/globalsettings"] }
        ]);

        return true;
    }

    async save(): Promise<void> {
        if (!this.app.validateForm(this.form)) {
            return;
        }
        this.form.get(this.globalSettings);
        await this.app.saveDataHandler(async () => RestEndpoint.main().upsert(this.globalSettings).run("api/cer/globalsettings").getText(), this, true, true);
    }

}

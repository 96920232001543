import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { IncidentItem } from '../../shared/incident-item.model';

@Component({
  selector: 'app-class-analyses-incidents',
  templateUrl: './class-analyses-incidents.component.html',
  styleUrls: ['./class-analyses-incidents.component.scss']
})
export class ClassAnalysesIncidentsComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;
    incidents: IncidentItem[];
    table: TableData;

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.incidents = await RestEndpoint.main().query({id: this.id}).run("api/cer/classregister/incidents").list(IncidentItem);

        this.table = new TableData([
            new TableColumn("date", "Datum", TableCellType.date),
            new TableColumn("hour", "Stunde", TableCellType.number),
            new TableColumn("domain", "Fach"),
            new TableColumn("incidents", "Vorkommnisse")
        ], this.incidents.map(inc => new TableRow(inc, {
            date: inc.date,
            hour: inc.hour + 1,
            domain: inc.domain,
            incidents: inc.incidents
        })), [new TableSortColumn("date", false), new TableSortColumn("hour", false)]);

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Auswertungen", routerLink: ["/enaio-certificates", "class-registers", "analyses", this.id] },
            { label: "Übersicht von Vorkommnissen", routerLink: ["/enaio-certificates", "class-registers", "analyses", "incidents", this.id] }
        ]);
        return true;
    }

    async selectRow(event: any): Promise<void> {
        await this.app.navigateTo(["/enaio-certificates", "class-registers", "lesson", (event.raw as IncidentItem).lessonId, this.id]);
    }
}

import { Component, OnInit } from '@angular/core';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { UserGroup } from '../../shared/user-group.entity';

@Component({
    selector: 'app-edit-user-groups',
    templateUrl: './edit-user-groups.component.html',
    styleUrls: ['./edit-user-groups.component.scss']
  })
export class EditUserGroupsComponent implements OnInit {

    listData: UserGroup[];
    table: TableData;

    constructor(private service: EnaioLearnService) {
    }

    async ngOnInit(): Promise<void> {
        [this.listData, this.table] = await this.service.adminInitList<UserGroup>(UserGroup, "lea.userGroup", "user-groups", "user-group", "api/lrn/usergroup", this,
        [new TableColumn("name", null)], [new TableSortColumn("name")], o => new TableRow(o, { name: o.name }));
    }
}

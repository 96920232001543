export enum SimLogStatType {
    fileCreated = 0,
    fileMoved = 1,
    ftpFileCreated = 2,
    enaioObjectCreated = 3,
    enaioObjectUpdated = 4,
    enaioObjectDeleted = 5,
    databaseRowCreated = 6,
    databaseRowUpdated = 7
}
export namespace SimLogStatType{
    export function getName(value: SimLogStatType): string {
        switch (value) {
            case SimLogStatType.fileCreated:
                return "fileCreated";
            case SimLogStatType.fileMoved:
                return "fileMoved";
            case SimLogStatType.ftpFileCreated:
                return "ftpFileCreated";
            case SimLogStatType.enaioObjectCreated:
                return "enaioObjectCreated";
            case SimLogStatType.enaioObjectUpdated:
                return "enaioObjectUpdated";
            case SimLogStatType.enaioObjectDeleted:
                return "enaioObjectDeleted";
            case SimLogStatType.databaseRowCreated:
                return "databaseRowCreated";
            case SimLogStatType.databaseRowUpdated:
                return "databaseRowUpdated";
        }
    }

/*export namespace SimLogStatType {
    export function getText(value: SimLogStatType): string {
        switch (value) {
            case SimLogStatType.fileCreated:
                return "Dateien erstellt";
            case SimLogStatType.fileMoved:
                return "Dateien verschoben";
            case SimLogStatType.ftpFileCreated:
                return "FTP-Dateien erstellt";
            case SimLogStatType.enaioObjectCreated:
                return "enaio Objekte erstellt";
            case SimLogStatType.enaioObjectUpdated:
                return "enaio Objekte aktualisiert";
            case SimLogStatType.enaioObjectDeleted:
                return "enaio Objekte gelöscht";
            case SimLogStatType.databaseRowCreated:
                return "Datenbankzeilen erstellt";
            case SimLogStatType.databaseRowUpdated:
                return "Datenbankzeilen aktualisiert";
        }
    }
}*/


}

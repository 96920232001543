import { Type } from "class-transformer";
import { EnaioArchiveImportParams } from "./EnaioArchiveImportParams";
import { ExportStorageHeader } from "./ExportStorageHeader";

export class EnaioImportOptions {
    id: string;
    singleStep = false;
    @Type(() => ExportStorageHeader)
    header: ExportStorageHeader;
    @Type(() => EnaioArchiveImportParams)
    archives: EnaioArchiveImportParams[];
}

import { Component, OnInit } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Notification } from 'src/modules/database/shared/notification.entity';
import { ReplyNotificationDto } from 'src/modules/database/shared/reply-notification.dto';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

    listData: Notification[];

    constructor(private app: MainAppService) {
    }

    async ngOnInit(): Promise<void> {
        this.listData = Utils.arraySortBy(await this.app.loadDataHandler(async () => RestEndpoint.main().find().run("api/app/notification").list(Notification), this), n => n.date, false);
        this.app.updateNavigation(null, [
            { label: "Benachrichtigungen", routerLink: ["/enaio-learn", "notifications"] }
        ]);
    }

    async openNotification(index: number): Promise<void> {
        if (this.listData[index].readDate == null) {
            this.listData[index].readDate = new Date();
            await RestEndpoint.main().query({id: this.listData[index].id}).post().run("api/app/notification/read").getText();
        }
    }

    async linkClicked(index: number): Promise<void> {
        await this.openNotification(index);
    }

    async answer(index: number): Promise<void> {
        let text = await this.app.messageDialog.input<string>(new FrontendFieldDefinition("text", "Text", FrontendFieldType.textArea, { mandatory: true}), this.app.t("notifications.reply_title"));
        if (text != null) {
            await RestEndpoint.main().body(Utils.fromPlain(ReplyNotificationDto, {
                notificationId: this.listData[index].id,
                subject: "Antwort: " + this.listData[index].subject,
                text
            })).post().run("api/app/notification/reply").getText();
        }
    }
}

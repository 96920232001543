import { OrdinaryObject } from "./ordinary-object.model";
import { Utils } from "./utils";

export class Map2<K, V> {

    static ofArray<K, V, T>(arr: T[], toKey: (item: T) => K, toValue: (item: T) => V): Map2<K, V> {
        let result = new Map2<K, V>();
        for (let item of arr) {
            result.set(toKey(item), toValue(item));
        }
        return result;
    }

    static ofArrayKeys<K, T>(arr: T[], toKey: (item: T) => K): Map2<K, T> {
        let result = new Map2<K, T>();
        for (let item of arr) {
            result.set(toKey(item), item);
        }
        return result;
    }

    static ofArrayMulti<K, V, T>(arr: T[], toKey: (item: T) => K, toValue: (item: T) => V): Map2<K, V[]> {
        let result = new Map2<K, V[]>();
        for (let item of arr) {
            result.getOrAdd(toKey(item), () => []).push(toValue(item));
        }
        return result;
    }

    static ofArrayMultiKeys<K, T>(arr: T[], toKey: (item: T) => K): Map2<K, T[]> {
        let result = new Map2<K, T[]>();
        for (let item of arr) {
            result.getOrAdd(toKey(item), () => []).push(item);
        }
        return result;
    }

    internal: Map<K, V>;

    constructor(map?: Map<K, V>) {
        this.internal = map ?? new Map<K, V>();
    }

    clear(): void {
        this.internal.clear();
    }

    convert<KR, VR>(keyConverter: (item: K) => KR, valueConverter: (item: V) => VR): Map2<KR, VR> {
        let result = new Map2<KR, VR>();
        for (let key of this.keys()) {
            result.set(keyConverter(key), valueConverter(this.get(key)));
        }
        return result;
    }

    delete(key: K): boolean {
        return this.internal.delete(key);
    }

    equals(map2: Map2<K, V>): boolean {
        if (this.size !== map2.size) {
            return false;
        }
        for (let key of this.keys()) {
            let testVal = map2.get(key);
            if (testVal !== this.get(key) || testVal == null && !map2.has(key)) {
                return false;
            }
        }
        return true;
    }

    get(key: K): V {
        return this.internal.get(key);
    }

    getOrAdd(key: K, generator: () => V): V {
        if (!this.has(key)) {
            this.set(key, generator());
        }
        return this.get(key);
    }

    getSafe(key: K, def: V): V {
        return this.has(key) ? this.internal.get(key) : def;
    }

    has(key: K): boolean {
        return this.internal.has(key);
    }

    itemsToString(sep = ", ", keyValueSep = " = ", keyToString: (key: K) => string = null, valueToString: (value: V) => string = null): string {
        return this.size() == 0 ? "" : Utils.arrayItemsToString([...this.keys()], sep, key =>
            (keyToString != null ? keyToString(key) : Utils.toString(key)) + keyValueSep + (valueToString != null ? valueToString(this.get(key)) : Utils.toString(this.get(key))));
    }

    keys(): IterableIterator<K> {
        return this.internal.keys();
    }

    set(key: K, value: V): void {
        this.internal.set(key, value);
    }

    size(): number {
        return this.internal.size;
    }

    toOrdinaryObject(): OrdinaryObject<V> {
        let result: OrdinaryObject<V> = {};
        for (let key of this.keys()) {
            result[Utils.toString(key)] = this.get(key);
        }
        return result;
    }

    valuesList(): V[] {
        return Utils.arrayFromIt(this.values());
    }


    values(): IterableIterator<V> {
        return this.internal.values();
    }
}

<div class="grid">
    <div class="col-2">
        <sm-form #form [form]="formInput">
        </sm-form>
        <p-button icon="fas fa-run" label="Starten" (onClick)="run()"></p-button>
    </div>
    <div class="col-10">
        <sm-toolbar>
            <p-checkbox name="displayTime" [(ngModel)]="displayTime" [binary]="true" (ngModelChange)="updateTable()"></p-checkbox>
            <label class="form-label mr-3">Zeit</label>
            <p-checkbox name="displayObjectCount" [(ngModel)]="displayObjectCount" [binary]="true" (ngModelChange)="updateTable()"></p-checkbox>
            <label class="form-label mr-3">Objektmenge</label>
            <p-checkbox name="displayInternalFolders" [(ngModel)]="displayInternalFolders" [binary]="true" (ngModelChange)="updateTable()"></p-checkbox>
            <label class="form-label mr-3">Interne Verzeichnisse</label>
            <p-checkbox name="displaySimLog" [(ngModel)]="displaySimLog" [binary]="true" (ngModelChange)="updateTable()"></p-checkbox>
            <label class="form-label mr-3">Sim Log</label>
            <p-checkbox name="displayMostUsedObjects" [(ngModel)]="displayMostUsedObjects" [binary]="true" (ngModelChange)="updateTable()"></p-checkbox>
            <label class="form-label mr-3">Meistverarbeitetes Objekt</label>
            <p-checkbox name="displayOther" [(ngModel)]="displayOther" [binary]="true" (ngModelChange)="updateTable()"></p-checkbox>
            <label class="form-label mr-3">Anderes</label>
        </sm-toolbar>
        <sm-table-on-push *ngIf="tableData != null" [data]="tableData" [deltaHeight]=200></sm-table-on-push>
    </div>
</div>

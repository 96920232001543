import { Component, Input } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { FrontendFieldDefinition } from 'src/modules/sm-base/models/frontend-field-definition.model';
import { FrontendFieldListItem } from 'src/modules/sm-base/models/frontend-field-list-item.model';
import { FrontendFieldType } from 'src/modules/sm-base/models/frontend-field-type.enum';
import { FrontendFormDefinition } from 'src/modules/sm-base/models/frontend-form-definition.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { CitationBlockType } from '../../shared/citation-block-type.enum';
import { CitationType } from '../../shared/citation-type.model';
import { LearningContentCitation } from '../../shared/learning-content-citation.entity';

@Component({
  selector: 'edit-citations',
  templateUrl: './edit-citations.component.html',
  styleUrls: ['./edit-citations.component.scss']
})
export class EditCitationsComponent {

    _Utils = Utils;

    @Input()
    items: LearningContentCitation[];

    constructor(private app: MainAppService) {
    }

    removeItem(index: number): void {
        this.items.splice(index, 1);
    }

    async editItem(index: number): Promise<void> {
        await this.editCitation(this.items[index], true);
    }

    async addItem(): Promise<void> {
        let citation = new LearningContentCitation();

        let typeName = await this.app.messageDialog.input<string>(new FrontendFieldDefinition("type", "Welche Art von Quelle möchten Sie hinzufügen?", FrontendFieldType.comboBox,
            { mandatory: true, listItems: CitationType.types.map(t => new FrontendFieldListItem(t.id, t.name)), dropdownEditable: false}), "Quelle");

        if (typeName == null) {
            return;
        }

        citation.type = typeName;
        let type = CitationType.types.find(t => t.id == citation.type);

        for (let block of type.blocks) {
            citation.citationKeys.push(block.id);
            citation.citationValues.push(block.type == CitationBlockType.year ? Utils.toString(new Date().getFullYear()) : block.type == CitationBlockType.number ? "1" : "");
        }

        if (await this.editCitation(citation, false)) {
            this.items.push(citation);
        }
    }

    private async editCitation(citation: LearningContentCitation, edit: boolean): Promise<boolean> {
        let fields = citation.getFields();
        let formFields: FrontendFieldDefinition[] = [];
        for (let field of fields) {
            formFields.push(new FrontendFieldDefinition(field.block.id, field.block.name, field.block.type == CitationBlockType.number || field.block.type == CitationBlockType.year ? FrontendFieldType.number : FrontendFieldType.text, { value: field.value, mandatory: true }));
        }

        let editCitationForm = new FrontendFormDefinition(formFields);
        if (await this.app.messageDialog.form(editCitationForm, edit ? "Quelle editieren" : "Quelle hinzufügen", [this.app.messageDialog.save, this.app.messageDialog.cancel]) == "cancel") {
            return false;
        }

        for (let i = 0; i < fields.length; i++) {
            citation.citationValues[i] = formFields[i].value;
        }

        return true;
    }
}

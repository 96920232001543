export enum EnaioTableDataType {
    string = 0,
    number = 1,
    decimal = 2,
    date = 3
}
export namespace EnaioTableDataType{
    export function getName(value: EnaioTableDataType): string {
        switch (value) {
            case EnaioTableDataType.string:
                return "string";
            case EnaioTableDataType.number:
                return "number";
            case EnaioTableDataType.decimal:
                return "decimal";
            case EnaioTableDataType.date:
                return "date";
        }
    }
}

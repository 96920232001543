import { Component, OnInit } from '@angular/core';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningContentType } from '../../shared/learning-content-type.entity';

@Component({
    selector: 'app-edit-learning-content-types',
    templateUrl: './edit-learning-content-types.component.html',
    styleUrls: ['./edit-learning-content-types.component.scss']
})
export class EditLearningContentTypesComponent implements OnInit {

    listData: LearningContentType[];
    table: TableData;

    constructor(private service: EnaioLearnService) {
    }

    async ngOnInit(): Promise<void> {
        [this.listData, this.table] = await this.service.adminInitList<LearningContentType>(LearningContentType, "lea.learningContentType", "learning-content-types", "learning-content-type", "api/lrn/learningcontenttype", this,
            [new TableColumn("name", null)], [new TableSortColumn("name")], o => new TableRow(o, { name: o.name }));
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnaioWorkflowsMainComponent } from '../enaio-workflows/components/enaio-workflows-main/enaio-workflows-main.component';
import { EnaioWorkItemComponent } from '../enaio-workflows/components/enaio-work-item/enaio-work-item.component';
import { PersonellFileComponent } from './components/personell-file/personell-file.component';

const routes: Routes = [
    {
        path: 'home',
        component: EnaioWorkflowsMainComponent
    },
    {
        path: 'workitem/:processId',
        component: EnaioWorkItemComponent
    },
    {
        path: 'personell-file',
        component: PersonellFileComponent
    },
    {
        path: '',
        redirectTo: "home",
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EnaioWorkflowsRoutingModule {
}

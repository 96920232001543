import { Bounds } from 'src/modules/utils/shared/bounds.model';
import { Point } from 'src/modules/utils/shared/point.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { FrontendFieldAddon } from './frontend-field-addon.model';
import { FrontendFieldGuiHint } from './frontend-field-gui-hint.model';
import { FrontendFieldListItem } from './frontend-field-list-item.model';
import { FrontendFieldType } from './frontend-field-type.enum';
import { FrontendFormDefinition } from './frontend-form-definition.model';
import { TableColumn } from './table-column.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { EnaioWorkItemParameter } from 'src/modules/enaio/shared/EnaioWorkItemParameter';

export class FrontendFieldDefinition {

    id = "";
    caption = "";
    type: FrontendFieldType = FrontendFieldType.text;
    tabOrder = 0;
    mandatory = false;
    fixedLabelBounds: Bounds = null;
    fixedControlBounds: Bounds = null;
    hasLabel = true;
    hasControl = true;
    tooltip: string = null;
    tooltipOnControl = false;
    maxLength = 0;
    monospaced = false;
    minValue: number = null;
    maxValue: number = null;
    minValueInclusive = true;
    maxValueInclusive = true;
    upperCase = false;
    enaioSource: any = null; //EnaioGuiField = null;
    labelRightAligned = false;
    listItems: FrontendFieldListItem[] = [];
    listItemValuePath: string;
    tableColumns: TableColumn[] = [];
    tableCanAddRow = true;
    tableCanDeleteRow = false;
    tableDisabledCells: { row: number; column: number }[];
    tableInvalidCells: OrdinaryObject<string> = {};
    regexMask = "";
    radioButtonGroup = "";
    radioButtonValue: any = "";
    dropdownEditable = true;
    dropdownAutoComplete = false;
    dropdownWordWrap = false;
    listBoxCheckBoxes = false;
    passwordFeedback = true;
    predefinedColors: number[];
    datePickerWithTime = false;

    dateIntervalToField = "";
    dateIntervalFromField = "";

    fixedHeight: string = null;

    visible = true;
    enabled = true;

    enaioSectionName = "";

    guiGroup = "";
    guiCols = 12;
    guiHints: FrontendFieldGuiHint[] = [];
    addOnButtons: FrontendFieldAddon[] = null;
    componentType: any = null; // type = Type<any>

    value: any = null;
    context: any;
    component: any;

    errorMessage: string = null;
    errorMessageFromScript: string = null;

    generateEmptyTableItem: () => {};

    validator: (field: FrontendFieldDefinition, item: FrontendFormDefinition) => string;

    onClick: (event: MouseEvent) => any;
    onValueChanged: () => any;
    onBeforeAddRow: () => any;
    onAddOnClick: (item: FrontendFieldAddon, event: MouseEvent) => any;

    toForm: (value: any) => any;

    constructor(id: string, caption: string, type: FrontendFieldType, props: Partial<FrontendFieldDefinition> = null) {
        this.id = id;
        this.caption = caption;
        this.type = type;
        if (props != null) {
            Object.assign(this, props);
        }
    }

    setComponent(component: any /* type = Type<any>*/): this {
        this.componentType = component;
        return this;
    }

    setCellValid(row: number, column: number, valid: boolean, errorMessage?: string): void {
        let key = row + "," + column;
        if (valid) {
            delete this.tableInvalidCells[key];
        }
        else {
            this.tableInvalidCells[key] = errorMessage;
        }
    }

    getErrorMessage(withCalculated = true): string {
        let result = Utils.stringDef(withCalculated ? this.errorMessage : null, this.errorMessageFromScript);
        if (Utils.isNoe(result)) {
            let invalidCells = Utils.getOwnPropertyNames(this.tableInvalidCells);
            if (invalidCells.length > 0) {
                let pos = Utils.splitOnce(invalidCells[0], ",");
                result = "Tabellenzeile " + (Utils.toNumber(pos[0]) + 1) + " Spalte " + (Utils.toNumber(pos[1]) + 1) + ": " + this.tableInvalidCells[invalidCells[0]];
            }
        }
        return result;
    }

    isFixedPosition(): boolean {
        return this.fixedLabelBounds != null || this.fixedControlBounds != null;
    }

    getMaxPosition(): Point {
        let result = new Point(0, 0);
        if (this.fixedLabelBounds != null) {
            result = result.max(this.fixedLabelBounds.getRight(), this.fixedLabelBounds.getBottom());
        }
        if (this.fixedControlBounds != null) {
            result = result.max(this.fixedControlBounds.getRight(), this.fixedControlBounds.getBottom());
        }
        return result;
    }

    updateValueFromGui(): void {
        if (this.component != null) {
            this.value = this.component.getValue();
        }
    }

    getUpdatedValue(): any {
        this.updateValueFromGui();
        return this.value;
    }

    isGuiOneLine(): boolean {
        return this.type == FrontendFieldType.checkBox || this.type == FrontendFieldType.radioButton;
    }

    getFrontendValueForWorkItem(param: EnaioWorkItemParameter): any {
        let value = param.getPlainValue();
        if (this.type == FrontendFieldType.checkBox) {
            return Utils.toBool(value);
        }
        else {
            return value;
        }
    }

    validate(form: FrontendFormDefinition): string {
        if (this.visible) {
            if (this.mandatory && Utils.isNoe(this.value)) {
                return this.caption + " ist ein Pflichtfeld";
            }
            if (this.minValue != null && (this.minValueInclusive ? this.value < this.minValue : this.value <= this.minValue)) {
                return this.caption + " muss größer " + (this.minValueInclusive ? "oder gleich " : "") + this.minValue + " sein";
            }
            if (this.maxValue != null && (this.maxValueInclusive ? this.value > this.maxValue : this.value >= this.maxValue)) {
                return this.caption + " muss kleiner " + (this.maxValueInclusive ? "oder gleich " : "") + this.maxValue + " sein";
            }
            if (this.validator != null) {
                return this.validator(this, form);
            }
            if (!Utils.isNoe(this.dateIntervalToField)) {
                let toValue = form.getValue(this.dateIntervalToField);
                let thisValue = this.getValue();

                if (toValue instanceof Date && thisValue instanceof Date && thisValue > toValue) {
                    return this.caption + " darf nicht nach " + form.getField(this.dateIntervalToField).caption + " liegen";
                }
            }
            if (!Utils.isNoe(this.dateIntervalFromField)) {
                let fromValue = form.getValue(this.dateIntervalFromField);
                let thisValue = this.getValue();

                if (fromValue instanceof Date && thisValue instanceof Date && thisValue < fromValue) {
                    return this.caption + " darf nicht vor " + form.getField(this.dateIntervalFromField).caption + " liegen";
                }
            }
            return this.getErrorMessage(false);
        }
        return null;
    }

    setValue(value: any): void {
        this.value = this.toForm != null ? this.toForm(value) : value;
        if (this.type == FrontendFieldType.comboBoxMulti && this.dropdownAutoComplete && Utils.isArray(this.value)) {
            this.value = (this.value as any[]).map(item => this.listItems.find(li => item == li.value));
        }
        else if (this.type == FrontendFieldType.comboBox && !this.dropdownEditable && !(value instanceof FrontendFieldListItem)) {
            this.value = this.listItemValuePath == null || value == null ? value : Utils.getPropertyNested(value, this.listItemValuePath);
        }
    }

    getValue(): any {
        if (this.type == FrontendFieldType.datePicker && !this.datePickerWithTime && this.value instanceof Date) {
            return Utils.dateWithoutTime(this.value);
        }
        else if (this.type == FrontendFieldType.comboBox) {
            if (this.value instanceof FrontendFieldListItem) {
                return this.value.internalObject ?? this.value.value;
            }
            else {
                return this.listItemValuePath == null ? this.value : this.listItems.find(item => Utils.getPropertyNested(item.internalObject, this.listItemValuePath) == this.value)?.internalObject;
            }
        }
        else if (this.type == FrontendFieldType.checkBox && this.value == null) {
            return false;
        }
        else {
            return this.value;
        }
    }

    isTableCellEnabled(row: number, column: number): boolean {
        return this.tableDisabledCells == null || !this.tableDisabledCells.some(cell => cell.row == row && cell.column == column);
    }

}
